<script>
import { CUSTOMER_INVEST_TRANSACTION, CUSTOMER_TRANSFER } from "@/utils/customerApiUrl";
import { formatPrice, getParams, formatDate } from "@/utils/function";
import ProjectSelect from "@/views/client/projects/components/ProjectSelect.vue";
//import SearchInvestSelect from '@/views/client/profile/transactions/components/SearchInvestSelect.vue';
// import moment from 'moment/moment';
import ModalOverviewProject from "@/views/client/profile/transactions/components/ModalOverviewProject.vue";
import ModalShowInterest from "@/views/client/profile/transactions/components/ModalShowInterest.vue";
import { mapState } from "vuex";
import SuccessModel from "@/views/client/components/dialog/SuccessModel.vue";
import ErrorDialog from "@/views/client/components/dialog/ErrorDialog.vue";

export default {
  name: "NoticesTab",
  components: {
    ErrorDialog,
    SuccessModel,
    ModalShowInterest,
    ModalOverviewProject,
    // SearchInvestSelect,
    ProjectSelect,
  },
  computed: {
    ...mapState(["wallet", "feeAccountBalance"]),
  },
  data() {
    return {
      formatDate: formatDate,
      formatPrice: formatPrice,
      dateRange: null,
      params: {
        take: 10,
        skip: 1,
        projectId: "",
        startDate: "",
        endDate: "",
        search: "",
        invest_no: "",
        status: "",
        is_crowdfunding: "",
      },
      loading: false,
      totalPages: 0,
      total: 0,
      data: [],
      headers: [
        { align: "start", key: "id", sortable: false, title: "Project ID" },
        { key: "invest_no", title: "Notice No" },
        { key: "buy_share", title: "share" },
        { key: "price_per_share", title: " Price per share" },
        { key: "interest", title: "Interest rate (%)" },
        { key: "star_rank", title: "Ratio" },
        { key: "type", title: "Terms" },
        { key: "contrast", title: "Contract" },
        { key: "interest", title: "Total interest rates (%)" },
        //{ key: 'status', title: 'Transfer' },
        { key: "interest_profit", title: "Interest" },
        { key: "total_profit", title: "Total interests" },
        { key: "created_at", title: "Starts" },
        { key: "created_at", title: "Ends" },
        { key: "is_crowdfunding", title: "Types" },
        { key: "status", title: "Status" },
      ],
      // for test
      dialog: false,
      row: {},
      // project overview
      dialogOverview: false,
      // transfer
      dialogTransfer: false,
      dialogTransferLoading: false,
      // modal message
      isErrorDialog: false,
      isErrorMessage: "",
      // modal success
      isSuccessDialog: false,
      isSuccessMessage: "",

      form: {
        customerId: "",
        investId: "",
        transactionNo: "",
        amount: 0,
        status: "Done",
        remark: "",
        transactionDate: "",
        riskGuaranteeStatus: true,
        exchangeStatus: true,
        consultantStatus: true,
      },
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let params = getParams(this.params);
      await this.axios
        .get(CUSTOMER_INVEST_TRANSACTION + params)
        .then((response) => {
          let { data, total, totalPages } = response.data;
          ///console.log(response.data.data);
          this.data = data;
          this.total = total;
          this.totalPages = totalPages;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Failed to", error);
        });
    },

    callbackProject(project) {
      if (project) {
        this.params.projectId = project.id;
        this.fetchData();
      }
    },

    // callbackInvest(invest) {
    //   if (invest) {
    //     this.params.invest_no = invest.invest_no;
    //     this.fetchData();
    //   }
    // },

    // modal interest
    rowClick(row) {
      this.dialog = true;
      this.row = row;
    },
    closeModalShowInterest() {
      this.dialog = false;
    },

    // modal overview
    rowClickDialogOverview(row) {
      this.dialogOverview = true;
      this.row = row;
    },
    closeModal() {
      this.dialogOverview = false;
    },

    // modal transfer
    openModalTransfer(row) {
      if (row.status === "Done" && row.isTransfer === null) {
        this.dialogTransfer = true;
        this.row = row;
        this.form = {
          ...this.form,
          investId: row.id,
          amount: row.total_profit,
          status: row.status,
        };
      }
    },
    closeModalTransfer() {
      this.dialogTransfer = false;
    },
    async onTransfer() {
      console.log({ ...this.row });

      this.dialogTransferLoading = true;
      let form = {
        ...this.form,
      };
      await this.axios
        .post(CUSTOMER_TRANSFER, form)
        .then((response) => {
          //console.log("response to", response.data);
          setTimeout(() => {
            this.dialogTransferLoading = false;
            this.dialogTransfer = false;
            this.fetchData();
            this.isSuccessMessage = response.data.message;
            this.isSuccessDialog = true;
          }, 2000);

          setTimeout(() => {
            this.isSuccessDialog = false;
            this.$store.dispatch("getUserProfile");
          }, 5000);
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.isErrorMessage = message;
          if (Array.isArray(message)) {
            this.isErrorMessage = message[0];
          }
          this.isErrorDialog = true;
          this.dialogTransferLoading = false;
          //console.error("Failed to", error.response.data.message);
        });
    },

    onCloseModalError(dialog) {
      this.isErrorDialog = dialog;
    },

    onCloseModalSuccess(dialog) {
      this.isSuccessDialog = dialog;
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    // filter page
    "params.skip": function () {
      this.fetchData();
    },
    "params.take": function () {
      this.fetchData();
    },
    "params.search": function (search) {
      this.params.search = search;
      this.fetchData();
    },
    "params.is_crowdfunding": function (is_crowdfunding) {
      if (is_crowdfunding.includes("All")) {
        this.params.is_crowdfunding = "";
      }
      this.fetchData();
    },
    "params.status": function (status) {
      if (status.includes("All")) {
        this.params.status = "";
      }
      this.fetchData();
    },
    // 'dateRange': function(dateRange) {
    //   if (dateRange && dateRange.length > 0) {
    //     let startDate = dateRange[0];
    //     let endDate = dateRange[dateRange.length - 1];
    //     this.params.startDate = moment(startDate).format('YYYY-MM-DD');
    //     this.params.endDate = moment(endDate).format('YYYY-MM-DD');
    //     this.fetchData();
    //   }
    // },
  },
};
</script>

<template>
  <!--        <pre>-->
  <!--          {{ data[0] }}-->
  <!--        </pre>-->
  <v-card class="pa-5" rounded="0" min-height="140" elevation="0" border>
    <v-row>
      <!--      <v-col cols="12" md="2">-->
      <!--        <p class="ma-2">Choose Date Range</p>-->

      <!--        <div>-->
      <!--          <v-date-input-->
      <!--            prepend-icon=""-->
      <!--            v-model="dateRange"-->
      <!--            density="comfortable"-->
      <!--            rounded="xl"-->
      <!--            variant="outlined"-->
      <!--            class="elevation-0"-->
      <!--            append-inner-icon="mdi-calendar-range"-->
      <!--            placeholder="YYYY/MM/DD - YYYY/MM/DD"-->
      <!--            color="blue"-->
      <!--            multiple="range"-->
      <!--          ></v-date-input>-->
      <!--        </div>-->

      <!--      </v-col>-->

      <v-col cols="12" md="3">
        <ProjectSelect
          :callback-project="callbackProject"
          is-density="comfortable"
          is-title="Project ID"
          is-variant="outlined"
          :propertyAll="false"
        />
      </v-col>

      <!--      <v-col cols="12" md="2">-->
      <!--        <SearchInvestSelect-->
      <!--          :callback-invest="callbackInvest"-->
      <!--          is-density="comfortable"-->
      <!--          is-title="Select Invest No"-->
      <!--          is-variant="outlined" />-->
      <!--      </v-col>-->

      <v-col cols="12" md="3">
        <p class="ma-2">Status</p>

        <div>
          <v-autocomplete
            menu-icon="mdi-chevron-right"
            v-model="params.status"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['All', 'Done', 'Pending']"
            class="elevation-0"
            color="blue"
          ></v-autocomplete>
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <p class="ma-2">Project Type</p>
        <v-autocomplete
          menu-icon="mdi-chevron-right"
          v-model="params.is_crowdfunding"
          density="comfortable"
          rounded="xl"
          variant="outlined"
          :items="['All', 'Normal', 'Crowdfunding']"
          class="elevation-0"
          color="blue"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-card>

  <br />

  <v-row>
    <v-col cols="12" md="2">
      <div class="d-flex justify-start">
        <span class="ma-2">Show</span>

        <div>
          <v-select
            menu-icon=""
            v-model="params.take"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['10', '25', '50', '100', '500', '1000']"
            class="elevation-0"
            color="blue"
          ></v-select>
        </div>

        <span class="ma-2">Entries</span>
      </div>
    </v-col>
    <v-col cols="12" md="10">
      <div class="d-flex justify-end">
        <span class="ma-2">Search</span>

        <div style="width: 30%">
          <v-text-field
            placeholder="search ..."
            append-inner-icon="mdi-magnify"
            width="100%"
            menu-icon=""
            v-model="params.search"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            class="elevation-0"
            color="blue"
          ></v-text-field>
        </div>
      </div>
    </v-col>
  </v-row>

  <v-card rounded="0">
    <v-data-table density="comfortable" hide-default-footer :loading="loading">
      <v-card elevation="0" rounded="0" class="pa-5" v-if="data.length < 1">
        No record available.
      </v-card>

      <thead>
        <tr class="bg-primary">
          <th
            v-for="row in headers"
            v-bind:key="row.key"
            class="row-none-wrap text-capitalize"
          >
            {{ row.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in data" v-bind:key="row.id" class="row-none-wrap">
          <td>
            {{ row.Project.project_id }}
            <v-btn
              rounded="xl"
              @click="rowClickDialogOverview(row)"
              density="compact"
              variant="text"
              color="primary"
              icon="mdi-help-circle-outline"
            >
            </v-btn>
          </td>
          <td class="font-weight-bold">
            <v-btn
              rounded="xl"
              @click="rowClick(row)"
              density="compact"
              variant="tonal"
              color="primary"
            >
              <v-icon>mdi-clipboard-list-outline</v-icon>
              {{ row.Project.notice_no }}
            </v-btn>
          </td>
          <td>{{ row.buy_share }}</td>
          <td>$ {{ formatPrice(row.price_per_share) }}</td>
          <td>{{ formatPrice(row.interest) }}</td>
          <td>{{ formatPrice(row.level_percent) }}</td>
          <td>{{ row.interest_name }}</td>
          <td>{{ row.contrast }} days</td>
          <td>{{ formatPrice(row.interest + row.level_percent) }}</td>
          <!--        <td>-->
          <!--          <v-btn icon="mdi-swap-horizontal" color="green" class="text-none" density="compact" v-if="row.status === 'Done' && row.isTransfer === null" @click="openModalTransfer(row)">-->
          <!--          </v-btn>-->
          <!--        </td>-->

          <td>$ {{ formatPrice(row.profit) }}</td>
          <td class="text-green">$ {{ formatPrice(row.total_profit) }}</td>
          <td>
            {{ formatDate(row.project_start, "YYYY-MM-DD HH:mm A") }}
          </td>
          <td>{{ formatDate(row.project_end, "YYYY-MM-DD HH:mm A") }}</td>
          <td>{{ row.is_crowdfunding }}</td>
          <td>
            <v-sheet class="d-flex" width="100">
              <v-btn
                block
                :color="row.status === 'Pending' ? 'primary' : 'green'"
                class="text-none"
                density="compact"
                rounded="xl"
                variant="tonal"
              >
                {{ row.status === "Pending" ? "Active" : row.status }}
              </v-btn>
            </v-sheet>
          </td>
        </tr>
      </tbody>
    </v-data-table>
  </v-card>

  <div class="app-paginate mt-5">
    <v-pagination
      v-model="params.skip"
      :length="totalPages"
      class="d-flex align-center"
      rounded="circle"
      color="primary"
      border
      total-visible="10"
    >
      <template #prev>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip = 1"
            :disabled="1 === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            First
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip--"
            :disabled="params.skip === 1"
            variant="outlined"
            border
            color="primary"
          >
            Previous
          </VBtn>
        </div>
      </template>
      <template #next>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip++"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Next
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip = totalPages"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Last
          </VBtn>
        </div>
      </template>
    </v-pagination>
  </div>

  <ModalShowInterest
    v-if="row"
    :is-dialog="dialog"
    :row="row"
    :close-modal="closeModalShowInterest"
  />

  <ModalOverviewProject
    v-if="row"
    :is-dialog="dialogOverview"
    :row="row"
    :close-modal="closeModal"
  />

  <v-dialog width="600" v-model="dialogTransfer" persistent>
    <v-card rounded="xl">
      <v-card-title class="my-a">
        <div class="d-flex justify-space-between">
          <p class="text-primary">
            <v-icon>mdi-bank-transfer</v-icon>
            Transfer
          </p>
          <v-btn
            color="red"
            rounded="xl"
            density="comfortable"
            icon="mdi-close"
            @click="closeModalTransfer()"
          >
          </v-btn>
        </div>

        <v-card-subtitle>
          Transfer from account balance to available balance
        </v-card-subtitle>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card
              rounded="xl"
              class="mx-auto"
              subtitle="your wallet balance "
              title="Personal Account"
              variant="outlined"
              color="primary"
            >
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-cash-check"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:append>
                $
                <h1>{{ formatPrice(row.price_per_share) }}</h1>
              </template>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card
              elevation="0"
              class="mx-auto"
              v-if="feeAccountBalance.risk_guarantee_status > 0"
              :subtitle="
                feeAccountBalance.risk_guarantee_text +
                ' ' +
                feeAccountBalance.risk_guarantee_fee +
                ' %'
              "
            >
              <template v-slot:prepend>
                <v-icon color="red" icon="mdi-circle"></v-icon>
              </template>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card
              elevation="0"
              class="mx-auto"
              v-if="feeAccountBalance.consultant_status > 0"
              :subtitle="
                feeAccountBalance.consultant_text +
                ' ' +
                feeAccountBalance.consultant_fee +
                ' %'
              "
            >
              <template v-slot:prepend>
                <v-icon color="red" icon="mdi-circle"></v-icon>
              </template>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card
              elevation="0"
              class="mx-auto"
              v-if="feeAccountBalance.exchange_status > 0"
              :subtitle="
                feeAccountBalance.exchange_text +
                ' ' +
                feeAccountBalance.exchange_fee +
                ' %'
              "
            >
              <template v-slot:prepend>
                <v-icon color="red" icon="mdi-circle"></v-icon>
              </template>
            </v-card>
          </v-col>
        </v-row>

        <!--        <pre>-->
        <!--          {{feeAccountBalance}}-->
        <!--        </pre>-->
      </v-card-text>

      <v-card-text>
        <v-btn
          rounded="xl"
          class="text-none"
          color="primary"
          block
          @click="onTransfer()"
          :loading="dialogTransferLoading"
        >
          Continue to Transfer
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>

  <template v-if="isErrorDialog">
    <ErrorDialog
      :dialog="isErrorDialog"
      :message="isErrorMessage"
      :onCloseModal="onCloseModalError"
    />
  </template>

  <template v-if="isSuccessDialog">
    <SuccessModel
      :dialog="isSuccessDialog"
      :message="isSuccessMessage"
      :onCloseModal="onCloseModalSuccess"
    />
  </template>
</template>

<style scoped></style>
