<template>
  <FrontendLayout>
    <v-container class="pa-4">
      <v-card flat>
        <div style="background-color: #1565c0">
          <v-toolbar color="#1565C0" dark>
            <v-btn icon @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>Transfers</v-toolbar-title>
          </v-toolbar>

          <!-- Transfer Button -->
          <div class="text-center">
            <v-avatar color="red" size="70" class="mt-2">
              <v-icon icon="mdi-swap-horizontal" size="60"></v-icon>
            </v-avatar>
          </div>

          <!-- Description Text -->
          <p class="mt-3 text-center text-white text-subtitle-1">
            Transfer to other account
          </p>
          <br />
        </div>

        <p class="text-subtitle-1 text-center mb-5"></p>

        <v-form ref="form" lazy-validation>
          <v-p
            class="text-subtitle-1 mb-3 text-red text-h3"
            v-if="msgInvalid !== null"
            :color="msgInvalid !== 'Record created successfully.' ? 'error' : 'success'"
          >
            {{ msgInvalid }}
            <p class="text-subtitle-1 text-center mb-5"></p>
          </v-p>

          <v-text-field
            v-model="formAccountOwner.availableBalance"
            label="Select your account"
            variant="outlined"
            prepend-inner-icon="mdi-briefcase-arrow-left-right-outline"
            density="compact"
            style="font-weight: bold"
            readonly
          >
          </v-text-field>
          <v-text-field
            v-model="formTransferTo.trandferNo"
            append-inner-icon="mdi-account-box-outline"
            label="Enter receiver account number"
            variant="outlined"
            prepend-inner-icon="mdi-account-outline"
            density="compact"
            :rules="[() => !!formTransferTo.trandferNo || 'This field is required']"
          ></v-text-field>
          <v-text-field
            v-model.number="formTransferTo.amount"
            append-inner-icon="mdi-cash"
            label="Amount"
            variant="outlined"
            prepend-inner-icon="mdi-cash"
            type="number"
            :step="0.1"
            required
            :rules="[() => !!formTransferTo.amount || 'This field is required']"
            density="compact"
          ></v-text-field>
          <v-text-field
            v-model="formTransferTo.decsr"
            label="Remark (optional)"
            variant="outlined"
            prepend-inner-icon="mdi-pencil-outline"
            density="compact"
          ></v-text-field>
          <strong class="text-caption text-h6"
            >Amount should be between $0.01 & $50,000</strong
          >

          <v-divider class="my-4"></v-divider>
          <v-btn block color="#1565C0" class="mt-4" dark @click="checkValidation"
            >TRANSFER</v-btn
          >
        </v-form>

        <!-- OTP Dialog -->
        <v-dialog v-model="showOtpDialog" max-width="400px">
          <v-card
            class="py-8 px-6 text-center mx-auto ma-4"
            elevation="12"
            max-width="400"
            width="100%"
          >
            <h3 class="text-h6 mb-4">Verify Your PIN Code</h3>

            <v-sheet color="surface">
              <v-otp-input v-model="otp" :length="4" :loading="loadingOTP"></v-otp-input>
            </v-sheet>

            <div class="text-caption text-center">
              <v-btn
                :disabled="blockTime ? true : otp.length < 4 || loadingOTP"
                @click="onClick"
                class="my-4"
                color="purple"
                height="40"
                text="Verify"
                variant="flat"
                width="70%"
              ></v-btn>
            </div>
            <v-div class="text-caption text-red text-h6" v-show="isInvalidPINCode">
              PIN Code is incorrect. You have
              {{ 3 - incorrectAttempts }} attempts remaining.
            </v-div>

            <v-div class="text-caption text-red text-h6" v-show="blockTime">
              You are blocked for 2 hours due to multiple incorrect attempts.
            </v-div>
          </v-card>
        </v-dialog>
        <!-- OTP Dialog -->
        <!-- DialogTransferSuccess -->
        <v-dialog v-model="showDialogSuccess" max-width="600px" persistent>
          <DialogTransferSuccess
            :transferToAccountCollection="transferToAccountCollection"
          ></DialogTransferSuccess>
        </v-dialog>
        <!-- DialogTransferSuccess -->
      </v-card>
    </v-container>
  </FrontendLayout>
</template>

<script>
import { mapState } from "vuex";
import FrontendLayout from "../components/FrontendLayout.vue";
import axios from "axios";
import {
  URL_TRANSFER_TO_ACCOUNT,
  URL_UPDATE_WALLET_FROM_TRANSFER_TO_ACCOUNT,
} from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import DialogTransferSuccess from "./DialogTransferSuccess.vue";

export default {
  name: "TransferToAccount",
  components: { FrontendLayout, DialogTransferSuccess },
  computed: {
    ...mapState(["profile", "wallet"]),
  },
  data() {
    return {
      msgInvalid: null,

      formTransferTo: {
        fromUserId: null,
        toUserId: null,
        country: null,
        trandferNo: null,
        apv: null,
        amount: null,
        purchase: null,
        reference: null,
        decsr: null,
        pinCode: null,
        status: "done",
        availableBalanceReciever: null,
        walletTypeOfSender: null,
      },

      formAccountOwner: {
        availableBalance: null,
        pinCode: null,
        fromUserId: null,
      },

      isBusineseAccount: false,
      transferToAccountCollection: [],
      recieverAccount: [],
      showOtpDialog: false, // Controls OTP dialog visibility
      showDialogSuccess: false,
      loadingOTP: false,
      otp: "", // OTP input value
      isInvalidPINCode: false,
      incorrectAttempts: 0,
      blockTime: localStorage.getItem("blockTime")
        ? parseInt(localStorage.getItem("blockTime"))
        : null,
    };
  },
  mounted() {
    // Retrieve block time from localStorage
    const savedBlockTime = localStorage.getItem("blockTime");
    if (savedBlockTime) {
      this.blockTime = parseInt(savedBlockTime, 10);
      this.checkBlockStatus(); // Check if the block should be lifted
    }
  },
  methods: {
    onClick() {
      this.loadingOTP = true;

      setTimeout(() => {
        if (this.formAccountOwner.pinCode !== this.otp) {
          this.incorrectAttempts++;

          if (this.incorrectAttempts >= 3) {
            //this.isBlocked = true;
            this.blockTime = new Date().getTime();
            localStorage.setItem("blockTime", this.blockTime); // Save block time
            this.loadingOTP = false;
          } else {
            this.isInvalidPINCode = true;
            this.loadingOTP = false;
          }

          this.otp = ""; // Clear OTP input after an attempt
          return;
        } else {
          this.loadingOTP = false;
          this.incorrectAttempts = 0;
          this.isInvalidPINCode = false;
          this.showOtpDialog = false;
          this.transfer();
          this.showDialogSuccess = true;
        }
      }, 2000);
    },
    checkBlockStatus() {
      if (this.blockTime) {
        const currentTime = new Date().getTime();
        const timePassed = currentTime - this.blockTime;

        if (timePassed >= 2 * 60 * 60 * 1000) {
          // 2 hours have passed, unblock user
          this.blockTime = null;
          localStorage.removeItem("blockTime");
          this.incorrectAttempts = 0;
        }
      }
    },
    async checkValidation() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.recieverAccount = await this.findRecieverAccount();

        if (this.recieverAccount.length > 0) {
          const isBusineseAccount =
            this.formAccountOwner.walletTypeOfSender === "business account"
              ? true
              : false;

          if (!isBusineseAccount) {
            this.msgInvalid = "Your account is not business account.";
            return;
          }

          if (
            parseFloat(this.formAccountOwner.availableBalance) <
            parseFloat(this.formTransferTo.amount)
          ) {
            this.msgInvalid = "Your ballance is not available to transfer.";
            return;
          }

          this.formTransferTo.toUserId = this.recieverAccount[0]?.customerId;
          this.formTransferTo.apv = this.generateRandomNumber(100000, 999999);
          this.formTransferTo.purchase = this.generateRandomNumber(
            100000000000000,
            999999999999999
          ).toString();

          this.formTransferTo.reference = this.generateRandomReference();
          this.formTransferTo.availableBalanceReciever = (
            this.recieverAccount.availableBalance ?? 0
          ).toFixed(2);

          this.msgInvalid = null;
          this.showOtpDialog = true; // show dialog pin code
          this.otp = "";

          // ...
        } else {
          this.msgInvalid = "Receiver account is invalid.";
        }
      }
    },

    async transfer() {
      if (this.formAccountOwner.pinCode === this.otp) {
        this.isInvalidPINCode = false;

        const data = {
          fromUserId: this.formAccountOwner.fromUserId,
          toUserId: this.formTransferTo.toUserId,
          country: this.recieverAccount[0]?.customer.country,
          trandferNo: this.formTransferTo.trandferNo,
          apv: this.formTransferTo.apv.toString(),
          amount: parseFloat(this.formTransferTo.amount),
          purchase: this.formTransferTo.purchase,
          reference: this.formTransferTo.reference,
          decsr: this.formTransferTo.decsr,
          typeBank: "Sender",
          recieverTypeBank: "Reciever",
          status: this.formTransferTo.status,
        };

        // Proceed with the transfer logic here
        await axios
          .post(URL_TRANSFER_TO_ACCOUNT, data, { headers: getTokenHeaders() })
          .then((response) => {
            //this.msgInvalid = response.data.message;
            this.msgInvalid = null;
            this.transferToAccountCollection = response.data.transferEntity;

            // update wallet owner account
            const returnAmountToDb =
              parseFloat(this.formAccountOwner.availableBalance) -
              parseFloat(this.formTransferTo.amount);

            // update wallet owner
            this.updateWalletAvailableBallance(
              this.formAccountOwner.fromUserId,
              returnAmountToDb
            );

            // update wallet reciever account
            const recieverAmountAvailableBallance =
              parseFloat(this.recieverAccount[0]?.availableBalance) +
              parseFloat(this.formTransferTo.amount);

            this.updateWalletAvailableBallance(
              this.formTransferTo.toUserId,
              recieverAmountAvailableBallance
            );

            console.log("===> ", this.transferToAccountCollection);
            // Handle successful transfer response here
          })
          .catch((error) => {
            this.msgInvalid = "Transfer failed. Please try again.";
            console.error("Error during transfer:", error);
          });
      } else {
        this.isInvalidPINCode = true;
      }
    },

    generateRandomNumber(fromDigit, toDigit) {
      return Math.floor(fromDigit + Math.random() * toDigit);
    },

    generateRandomReference() {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const numbers = "01234567890123";
      let concatNumberAndCharacters = "";
      let result = "";
      for (let i = 0; i < 14; i++) {
        concatNumberAndCharacters += numbers.charAt(
          Math.floor(Math.random() * numbers.length)
        );
      }
      for (let i = 0; i < 2; i++) {
        concatNumberAndCharacters += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      for (let i = 0; i < 14; i++) {
        result += concatNumberAndCharacters.charAt(
          Math.floor(Math.random() * concatNumberAndCharacters.length)
        );
      }

      return result;
    },

    async findRecieverAccount() {
      try {
        const response = await axios.get(
          URL_TRANSFER_TO_ACCOUNT + this.formTransferTo.trandferNo,
          {
            headers: getTokenHeaders(),
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching receiver account:", error);
        this.msgInvalid = "Receiver account is invalid.";
      }

      return {};
    },

    async updateWalletAvailableBallance(customerId, amount) {
      try {
        const urlUpdateRecord =
          URL_UPDATE_WALLET_FROM_TRANSFER_TO_ACCOUNT + `${customerId}/${amount}`;
        const response = await axios.patch(
          urlUpdateRecord,
          {},
          { headers: getTokenHeaders() }
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error updating wallet available balance:", error);
        this.msgInvalid = "Failed to update wallet available balance.";
      }
    },
  },
  created() {
    setTimeout(() => {
      this.formAccountOwner = {
        availableBalance: this.wallet[0].availableBalance.toFixed(2) + " USD",
        pinCode: this.wallet[0].pin_code,
        fromUserId: this.wallet[0].customerId,
        walletTypeOfSender: this.wallet[0].walletType,
      };
    }, 1000);
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
}
</style>
