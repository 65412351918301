<script>
import { mapState } from "vuex";
import ErrorDialog from "@/views/client/components/dialog/ErrorDialog.vue";
import SuccessModel from "@/views/client/components/dialog/SuccessModel.vue";
import { CUSTOMER_DEPOSIT, UPLOAD_PHOTO } from "@/utils/customerApiUrl";
import { formatPrice, validateImage } from "@/utils/function";
import { useFormData, useJsonForm } from "@/servicesAxios/axiosInstance";

let usdtQr =
  "https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg";

let previewImage =
  "https://media.istockphoto.com/id/931643150/vector/picture-icon.jpg?s=612x612&w=0&k=20&c=St-gpRn58eIa8EDAHpn_yO4CZZAnGD6wKpln9l3Z3Ok=";

export default {
  name: "DepositMoneyComponents",
  components: { SuccessModel, ErrorDialog },
  computed: {
    ...mapState([
      "profile",
      "bankType",
      "fee",
      "defaultCurrency",
      "bankAccountWithdraw",
      "bankAccountDeposit",
    ]),
  },
  data() {
    return {
      usdtQr: usdtQr,
      previewImage: previewImage,
      bankSelected: null,
      mainCurrency: "USD",
      remark: "N/A",
      bankTypeName: {
        bank: "Bank Transfer",
        crypto: "Crypto",
      },
      form: {
        deposit_amount: 0,
        amount_received: 0,
        amount_dollar: 0,
        deposit_amount_exchange: 0,
        fee: 0,
        tax_fee: 0,
        name: "",
        email: "",
        phone: "",
        purpose: "deposit",
        currency: "",
        typeBank: "Bank Transfer",
        bank_account_withdraw_id: 0,
        status: "successful",
        depositExchangeId: "",
        imageReference: "",
        address: "",
        usdtQr: "",
      },
      loading: false,
      // modal message
      isErrorDialog: false,
      isErrorMessage: "",
      // modal success
      isSuccessDialog: false,
      isSuccessMessage: "",
      //
      tooltip: 'copy'
    };
  },
  directives: {
    onlyNumberSingleDotPositive: {
      bind(el) {
        el.addEventListener("input", () => {
          let value = el.value;
          // Remove any character that is not a digit or a dot.
          value = value.replace(/[^0-9.]/g, "");
          // Ensure only a single dot exists in the value.
          const parts = value.split(".");
          if (parts.length > 2) {
            value = parts.shift() + "." + parts.join("");
          }
          // Update the element's value if modifications were made.
          if (el.value !== value) {
            el.value = value;
            // Dispatch input event to update v-model.
            el.dispatchEvent(new Event("input"));
          }
        });
      },
    },
  },
  methods: {
    async UploadProfile(e) {
      const image = e.target.files[0];
      if (image) {
        let isValidateImage = validateImage(e);
        if (isValidateImage) {
          this.isErrorMessage = isValidateImage;
          this.isErrorDialog = true;
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onload = (e) => {
            this.previewImage = e.target.result;
          };
          let data = new FormData();
          data.append("file", e.target.files[0]);

          useFormData();
          this.axios
            .post(UPLOAD_PHOTO, data)
            .then((response) => {
              useJsonForm();
              this.form.imageReference = response.data.filename; //console.log("Image has been uploaded.", response);
            })
            .catch((error) => {
              useJsonForm();
              this.message = error.response.data.message;
              this.dialog = true;
            });
        }
      }
    },

    formatPrice,
    setCurrency(tab) {
      this.form.currency = tab;
      //
      if (this.bankSelected) {
        this.setDefaultByBank();
      }
    },

    onCloseModalError(dialog) {
      this.isErrorDialog = dialog;
    },

    onCloseModalSuccess(dialog) {
      this.isSuccessDialog = dialog;
    },

    chooseBankType(bankSelected) {
      this.bankSelected = bankSelected;
      this.remark = bankSelected?.depositExchangeRate?.desc;
      this.form.bank_account_withdraw_id = bankSelected.id;
      this.form.depositExchangeId = bankSelected?.depositExchangeRate?.id ?? 0;
      this.form.currency = "";
      // crypto
      if (bankSelected.typeBank.includes(this.bankTypeName.crypto)) {
        this.form.currency = bankSelected.wallet ?? "";
        // find qr code from account deposit
        let walletQrCodeAndLink =
          this.bankAccountDeposit.find((x) => x.name === bankSelected.wallet) ??
          null;
        this.form.address = walletQrCodeAndLink
          ? walletQrCodeAndLink.usdt_link
          : "";
        this.form.usdtQr = walletQrCodeAndLink
          ? walletQrCodeAndLink.usdt_qr_code
          : "";
        this.usdtQr = walletQrCodeAndLink
          ? walletQrCodeAndLink.usdt_qr_code
          : usdtQr;
      }
      this.setDefaultByBank();
    },

    setDefaultByBank() {
      let deposit_amount_exchange =
        this.bankSelected?.depositExchangeRate?.rate ?? 0;
      let fee = 0;
      let tax_fee = 0;
      // Bank Transfer && Online Bank Transfer
      if (this.bankSelected.typeBank.includes(this.bankTypeName.bank)) {
        tax_fee = this.fee.deposit_fee ?? 0;
        // USD currency
        if (this.form.currency === this.mainCurrency) {
          deposit_amount_exchange = 1;
        }
      }
      // Crypto add top up
      else if (this.bankSelected.typeBank.includes(this.bankTypeName.crypto)) {
        fee = this.fee.percent_crypto ?? 0;
        tax_fee = 0;
      }
      this.form.typeBank = this.bankSelected?.typeBank;
      this.form.tax_fee = tax_fee;
      this.form.fee = fee;
      this.form.deposit_amount_exchange = deposit_amount_exchange;
      this.form.deposit_amount = 0;
      this.form.amount_received = 0;
      this.form.amount_dollar = 0;
    },

    async submitDeposit() {
      this.isErrorMessage = "Please contact to service center. try again later. ";
      this.isErrorDialog = true;
    },
    async submitDeposits() {

      if (this.form.currency.toLowerCase() === 'usd') {
        this.isErrorMessage = "Please choose your currency.USD is not available your bank.";
        this.isErrorDialog = true;
        return;
      }

      if (!this.form.depositExchangeId) {
        this.isErrorMessage = "Please contact to service center. ";
        this.isErrorDialog = true;
        return;
      }

      if (this.form.deposit_amount_exchange < 0) {
        this.isErrorMessage =
          "Please contact to service center.for exchange " + this.form.typeBank;
        this.isErrorDialog = true;
        return;
      }

      if (!this.form.deposit_amount) {
        this.isErrorMessage = "Please enter deposit amount.";
        this.isErrorDialog = true;
        return;
      }

      if (this.form.amount_received < this.fee.mini_deposit) {
        this.isErrorMessage =
          "Deposit amount should be minimum $" + this.fee.mini_deposit;
        this.isErrorDialog = true;
        return;
      }

      if (this.form.amount_received > this.fee.max_deposit) {
        this.isErrorMessage =
          "Deposit amount should be maximum $" +
          this.fee.max_deposit +
          ". Please upgrade account type.";
        this.isErrorDialog = true;

        setTimeout(() => {
          window.location = "/profile?tab=Transfer";
        }, 2000);

        return;
      }

      if (this.profile.status.toLowerCase() !== "done") {
        this.isErrorMessage =
          "Your account is not complete document. Please submit your document.";
        this.isErrorDialog = true;
        return;
      }

      const data = this.form;

      // Crypto
      if (this.form.typeBank.includes("Crypto")) {
        this.form.status = "pending";
        // imageReference
        if (!this.form.imageReference) {
          this.isErrorMessage = "Please attachment your invoice.";
          this.isErrorDialog = true;
          return;
        }
        // address
        if (!this.form.address) {
          this.isErrorMessage =
            "Please contact to service center.For wallet address.";
          this.isErrorDialog = true;
          return;
        }
      }
      // Bank
      else if (this.form.typeBank.includes("Bank")) {
        this.form.status = "successful";
      }

      this.loading = true;

      await this.axios
        .post(CUSTOMER_DEPOSIT, data)
        .then((response) => {
          setTimeout(() => {
            this.loading = false;
            //
            this.form.deposit_amount = 0;
            this.form.deposit_amount_currency = 0;
            this.form.tax_fee = 0;
            this.form.fee = 0;
            this.form.amount_dollar = 0;
            this.form.amount_received = 0;
            this.form.bank_account_withdraw_id = "";
            this.form.depositExchangeId = "";
            //
            this.bankSelected = null;
            //
            this.isSuccessMessage = response.data.message;
            this.isSuccessDialog = true;
          }, 2000);

          setTimeout(() => {
            this.isSuccessDialog = false;
          }, 5000);
          //
          this.$store.dispatch("getUserProfile");
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.isErrorMessage = message;
          if (Array.isArray(message)) {
            this.isErrorMessage = message[0];
          }
          this.isErrorDialog = true;
          this.loading = false;
        });
    },

    // address
    copyAddress(address){
      if (address != null) {
        navigator.clipboard.writeText(address);
        this.tooltip = 'copied';
      }
    }
  },
  created() {
    setTimeout(() => {
      this.form.name = this.profile.name;
      this.form.email = this.profile.email;
      this.form.phone = this.profile.phone;
      this.form.currency = this.defaultCurrency;
    }, 500);
  },
  watch: {
    "form.deposit_amount"(deposit_amount = 0) {
      if (deposit_amount > 0) {
        //
        let amount_received = 0;
        let amount_dollar = 0;
        let deposit_amount_exchange = this.form.deposit_amount_exchange;
        let tax_fee = this.form.tax_fee;
        let fee = this.form.fee;
        // Bank Transfer && Online Bank Transfer
        if (this.bankSelected.typeBank.includes(this.bankTypeName.bank)) {
          // USD currency
          if (this.form.currency === this.mainCurrency) {
            amount_received = deposit_amount * deposit_amount_exchange;
          } else {
            amount_received = deposit_amount / deposit_amount_exchange;
          }
          amount_dollar = amount_received - (tax_fee / 100) * amount_received;
        }
        // Crypto
        else if (
          this.bankSelected.typeBank.includes(this.bankTypeName.crypto)
        ) {
          amount_received = deposit_amount / deposit_amount_exchange;
          amount_dollar = amount_received + (fee / 100) * amount_received;
        }
        this.form.deposit_amount = deposit_amount;
        this.form.amount_received = amount_received;
        this.form.amount_dollar = amount_dollar;
      }
    },
  },
};
</script>

<template>
  <v-card class="pa-5">
    <h1>
      <v-icon color="primary">mdi-bank-circle</v-icon>
      Deposit
    </h1>
    <br />
    <h3>All payment methods</h3>
    <br />
    <div class="d-flex align-content-space-around flex-wrap">
      <v-card
        v-for="bank in bankAccountWithdraw"
        v-bind:key="bank.id"
        border
        height="100"
        width="160"
        class="text-center ma-2 align-content-center"
        :variant="
          bankSelected?.bankType?.id === bank.bankType.id ? 'outlined' : 'text'
        "
        color="primary"
        @click="chooseBankType(bank)"
      >
        <v-avatar>
          <v-img alt="John" :src="bank.logoBank"></v-img>
        </v-avatar>
        <p class="text-black">{{ bank.bankType.bankName }}</p>
      </v-card>
    </div>
  </v-card>
  <br />
  <!--  Bank Transfer-->
  <v-card
    class="v-col-xl-6 v-col-sm-12 ma-0 pa-0"
    v-if="form.typeBank.includes(bankTypeName.bank)"
  >
    <v-card-title>
      <v-icon color="primary">mdi-credit-card-sync-outline</v-icon>
      <span> Account Information</span>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <h3>Choose currency</h3>
      <v-col cols="12" md="12">
        <v-card
          elevation="0"
          color="grey-lighten-4"
          height="50"
          rounded="xl"
          class="d-flex justify-center align-center px-3"
        >
          <v-btn
            @click="setCurrency('USD')"
            :variant="form.currency === 'USD' ? 'flat' : 'text'"
            :color="form.currency === 'USD' ? 'blue' : 'black'"
            rounded="xl"
            width="50%"
            class="mx-1 text-none"
          >
            USD
          </v-btn>

          <v-btn
            @click="setCurrency(defaultCurrency)"
            :variant="form.currency === defaultCurrency ? 'flat' : 'text'"
            :color="form.currency === defaultCurrency ? 'blue' : 'black'"
            rounded="xl"
            width="50%"
            class="mx-1 text-none"
          >
            {{ defaultCurrency }}
          </v-btn>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <br />

        <div>
          <p class="mb-2">Amount</p>
          <v-text-field
            type="number"
            v-only-number-single-dot-positive
            prepend-inner-icon="mdi-wallet"
            v-model.number="form.deposit_amount"
            placeholder="Enter Amount"
            variant="outlined"
            density="compact"
            hide-details
            color="primary"
            :suffix="form.currency"
            rounded="xl"
            :disabled="bankSelected === null || !form.currency"
          ></v-text-field>
        </div>
      </v-col>

      <v-col cols="12" md="12">
        <div>
          <p class="mb-2">Purpose</p>
          <v-text-field
            prepend-inner-icon="mdi-pencil"
            v-model.number="form.purpose"
            placeholder="Enter Purpose"
            variant="outlined"
            density="compact"
            hide-details
            color="primary"
            rounded="xl"
          ></v-text-field>
        </div>
      </v-col>

      <v-col cols="12" md="12">
        <h3 class="text-primary my-2">Summary</h3>

        <p class="text-red">
          Note : Deposit Amount should be between ${{
            formatPrice(fee.mini_deposit)
          }}
          & ${{ formatPrice(fee.max_deposit) }}
        </p>

        <br />

        <v-card class="pa-4" elevation="0" color="grey-lighten-4" rounded="xl">
          <div class="d-flex justify-space-between">
            <p>Amount To Received :</p>
            <p>{{ formatPrice(form.amount_received) }} {{ mainCurrency }}</p>
          </div>
          <br />
          <div
            class="d-flex justify-space-between"
            v-if="form.currency !== mainCurrency"
          >
            <p>Exchange Rate :</p>
            <p>
              1 USD = {{ formatPrice(form.deposit_amount_exchange) }}
              {{ form.currency }}
            </p>
          </div>
          <br v-if="form.currency !== mainCurrency" />
          <div class="d-flex justify-space-between">
            <p>Tax :</p>
            <p>{{ formatPrice(form.tax_fee) }} %</p>
          </div>
          <br />

          <div class="d-flex justify-space-between">
            <p>Amount To Dollar :</p>
            <p>{{ formatPrice(form.amount_dollar) }} {{ mainCurrency }}</p>
          </div>

          <br />
          <v-divider />
          <br />

          <div class="d-flex justify-space-between">
            <p>Debit Amount :</p>
            <h3>{{ formatPrice(form.deposit_amount) }} {{ form.currency }}</h3>
          </div>

          <br />

          <div class="">
            <div class="font-weight-bold">Note :</div>
            <div v-html="remark"></div>
          </div>
        </v-card>
      </v-col>

      <br />

      <v-col cols="12" md="12">
        <div class="d-flex justify-space-between">
          <span />
          <v-btn
            class="text-none"
            rounded="xl"
            variant="tonal"
            color="primary"
            border
            min-width="180"
            @click="submitDeposit()"
            :disabled="bankSelected === null"
            :loading="loading"
          >
            Deposit
          </v-btn>
        </div>
      </v-col>
    </v-card-text>
  </v-card>
  <!--  Bank Transfer-->

  <!--  Cryptocurrency-->
  <v-card
    class="v-col-xl-6 v-col-sm-12 ma-0 pa-0"
    v-if="form.typeBank.includes(bankTypeName.crypto)"
  >
    <v-card-title>
      <v-icon color="primary">mdi-credit-card-sync-outline</v-icon>
      <span> Cryptocurrency Information</span>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-col cols="12" md="12">
        <br />

        <div>
          <p class="mb-2">Amount</p>
          <v-text-field
            type="number"
            v-only-number-single-dot-positive
            prepend-inner-icon="mdi-wallet"
            v-model.number="form.deposit_amount"
            placeholder="Enter Amount"
            variant="outlined"
            density="compact"
            hide-details
            color="primary"
            :suffix="form.currency"
            rounded="xl"
            :disabled="bankSelected === null"
          ></v-text-field>
        </div>

        <br />

        <p class="mb-2">Address :</p>

        <v-chip v-if="form.address" variant="outlined" color="primary">
          {{ form.address }}
        </v-chip>

        <v-btn icon rounded="xl" density="compact" class="mx-2" variant="text">
          <v-tooltip
            activator="parent"
            location="top"
          >{{tooltip}}</v-tooltip>
          <v-icon size="x-small" class="" @click="copyAddress(form.address)">mdi-content-copy</v-icon>
        </v-btn>

      </v-col>

      <v-col cols="12" md="12">
        <div class="d-flex justify-space-between">
          <v-avatar variant="outlined" color="grey" rounded="xl" size="350">
            <v-img :src="previewImage" cover />
          </v-avatar>

          <div>
            <v-avatar variant="outlined" color="grey" rounded="xl" size="350">
              <v-img :src="usdtQr" cover />
            </v-avatar>
            <br />
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="4">
        <v-file-input
          @change="UploadProfile"
          color="primary"
          rounded="xl"
          label="Attachment File"
          variant="outlined"
          density="compact"
        />
      </v-col>

      <v-col cols="12" md="12">
        <h3 class="text-primary my-2">Summary</h3>

        <p class="text-red">
          Note : Deposit Amount should be between ${{
            formatPrice(fee.mini_deposit)
          }}
          & ${{ formatPrice(fee.max_deposit) }}
        </p>

        <br />

        <v-card class="pa-4" elevation="0" color="grey-lighten-4" rounded="xl">
          <div class="d-flex justify-space-between">
            <p>Amount To Received :</p>
            <p>{{ formatPrice(form.amount_received) }} {{ mainCurrency }}</p>
          </div>
          <br />
          <div
            class="d-flex justify-space-between"
            v-if="form.currency !== mainCurrency"
          >
            <p>Exchange Rate :</p>
            <p>
              1 USD = {{ form.deposit_amount_exchange }} {{ form.currency }}
            </p>
          </div>
          <br v-if="form.currency !== mainCurrency" />
          <div class="d-flex justify-space-between">
            <p>Fee :</p>
            <p>+{{ formatPrice(form.fee) }} %</p>
          </div>
          <br />

          <div class="d-flex justify-space-between">
            <p>Amount To Dollar :</p>
            <p>{{ formatPrice(form.amount_dollar) }} {{ mainCurrency }}</p>
          </div>

          <br />
          <v-divider />
          <br />

          <div class="d-flex justify-space-between">
            <p>Debit Amount :</p>
            <h3>{{ form.deposit_amount }} {{ form.currency }}</h3>
          </div>

          <br />

          <div class="">
            <div class="font-weight-bold">Note :</div>
            <div v-html="remark"></div>
          </div>
        </v-card>
      </v-col>

      <br />

      <v-col cols="12" md="12">
        <div class="d-flex justify-space-between">
          <span />
          <v-btn
            class="text-none"
            rounded="xl"
            variant="tonal"
            color="primary"
            border
            min-width="180"
            @click="submitDeposit()"
            :disabled="bankSelected === null"
            :loading="loading"
          >
            Deposit
          </v-btn>
        </div>
      </v-col>
    </v-card-text>
  </v-card>
  <!--  Cryptocurrency-->

  <!--    <pre class="text-red">-->
  <!--       {{ form }}-->
  <!--    </pre>-->

  <template v-if="isErrorDialog">
    <ErrorDialog
      :dialog="isErrorDialog"
      :message="isErrorMessage"
      :onCloseModal="onCloseModalError"
    />
  </template>

  <template v-if="isSuccessDialog">
    <SuccessModel
      :dialog="isSuccessDialog"
      :message="isSuccessMessage"
      :onCloseModal="onCloseModalSuccess"
    />
  </template>
</template>

<style scoped></style>
