<template>
  <div class="bg-grey-lighten-4 my-bg margin-auto">
    <VRow>
      <VCol cols="12" md="8">
        <br />
        <VCard
          width="90%"
          elevation="0"
          style="background-color: transparent"
        >
          <p class="text-whites text-content text-justify text-8-line text-control" v-html="project?.description">
          </p>
          <br />
          <router-link to="/projects" class="text-decoration-none">
            <VBtn
              width="150"
              rounded="xl"
              class="text-none"
              color="white"
              variant="outlined"
            >
              Learn More
            </VBtn>
          </router-link>
        </VCard>
      </VCol>

      <VCol cols="12" md="4">
        <br />
        <VCard
          rounded="xl"
          color="black"
          class="pa-5"
          style="opacity: 0.5"
        >
          <br />
          <h3 class="text-white">Calculate Profit</h3>
          <br />

          <ProjectSelect is-variant="outlined" is-title="" is-density="compact" :callback-project="callbackProject"
                         :property-all="true" />

          <br />
          <div class="my-0" />

          <VTextField
            type="number"
            suffix="shares"
            rounded="xl"
            :density="variant"
            variant="outlined"
            v-model.number="form.buy_share"
            class="text-white"
            color="white"
          />

          <div class="my-2" />
          <div class="my-2" />
          <VTextField
            rounded="xl"
            :density="variant"
            variant="outlined"
            v-model="form.price_per_share"
            class="text-white"
            color="white"
            prepend-inner-icon="mdi-currency-usd"
            readonly
          />
          <div class="my-2" />
          <div class="my-1 d-flex justify-space-between">
            <v-row>
              <v-col v-for="(item, index) in compensations" cols="6" :md="index < 3 ? 4 : 6" v-bind:key="index">
                <VBtn
                  block
                  width="30%"
                  class="text-none"
                  variant="outlined"
                  rounded="xl"
                  @click="onInterestChange(item)"
                  :disabled="form.contrast / item.contrast<1 "
                >
                  {{ item.interest_name }}
                </VBtn>
              </v-col>
            </v-row>
          </div>

          <div class="my-2" />

          <div class="my-5 d-flex justify-space-between">
            <VBtn
              border
              width="48%"
              class="text-none"
              rounded="xl"
              variant="outlined"
              @click="onCalculate()"
            >Calculate
            </VBtn
            >
          </div>

          <br />

          <div class="containers">
            <div class="cells border-radius-top-right">
              <div class="titles">Total Interest Rate</div>
              <div class="values">{{ formatPrice(form.interest + form.level_percent) }} %</div>
            </div>
            <div class="cells border-radius-top-left">
              <div class="titles">Profits</div>
              <div class="values">$ {{ formatPrice(form.profit) }}</div>
            </div>
            <div class="cells border-radius-bottom-right">
              <div class="titles">Total Profits</div>
              <div class="values">$ {{ formatPrice(form.total_profit) }}</div>
            </div>
            <div class="cells border-radius-bottom-left">
              <div class="titles">Total Return</div>
              <div class="values">$ {{ formatPrice(form.total_profit + (form.buy_share * form.price_per_share)) }}</div>
            </div>
          </div>

          <br />

        </VCard>
      </VCol>
    </VRow>
    <br />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ProjectSelect from '@/views/client/projects/components/ProjectSelect.vue';
import { formatPrice } from '@/utils/function';

export default {
  name: 'CalculateProfit',
  components: { ProjectSelect },
  computed: {
    ...mapState([
      'project',
      'compensations',
      'startRankReferrals',
      'profile',
    ]),
  },
  data() {
    return {
      variant: 'compact',
      size: 'large',
      categories: [
        'Select Project',
        'Civil work',
        'Consultant Service',
        'Goods',
        'Non-Consulting',
      ],
      form: {
        project_name: 'Select Project',
        'id': 29,
        'image_project': null,
        'project_title': '',
        'project_id': '',
        'project_share': 0,
        'price_per_share': 0,
        'limit_share': 0,
        'contrast': 0,
        'daily': 0,
        'weekly': 0,
        'monthly': 0,
        'quarterly': 0,
        'semi_annually': 0,
        'annually': 0,
        buy_share: 0,
        number_send: 0,
        profit: 0,
        total_profit: 0,
        interest: 0,
        level_percent: 0,
      },
      projects: {},
    };
  },
  methods: {
    formatPrice,
    callbackProject(project) {
      this.projects = project;
      this.form = {
        ...this.form,
        ...project,
      };
    },

    onCalculate() {
      let interest = this.form.interest;
      let level_percent = this.form.level_percent;
      let buy_share = this.form.buy_share;
      let price_per_share = this.form.price_per_share;
      // total
      if (interest > 0 ) { // && level_percent > 0
        this.form.total_interest = level_percent;
        let profit = (buy_share * price_per_share) * ((interest + level_percent) / 100);
        this.form.profit = profit;
        let totalSent = Math.ceil(this.form.contrast / this.form.number_send);
        this.form.total_profit = profit * totalSent;
      }
    },

    onInterestChange(item) {
      let starRank = this.startRankReferrals.filter(i => i.rank_name.includes(this.profile.numberLevel));
      if (starRank.length > 0) {
        this.form.level_percent = starRank[0].percent_ratio;
      }

      //console.log({ ...item });
      this.form.compensation_id = item.id;
      this.form.interest_name = item.interest_name;
      this.form.number_send = item.contrast;

      switch (item.interest_name) {
        case 'Daily':
          this.form.interest = this.projects.daily;
          break;
        case 'Weekly':
          this.form.interest = this.projects.weekly;
          break;
        case 'Monthly':
          this.form.interest = this.projects.monthly;
          break;
        case 'Quarterly':
          this.form.interest = this.projects.quarterly;
          break;
        case 'Semi-Annually':
          this.form.interest = this.projects.semi_annually;
          break;
        case 'Annually':
          this.form.interest = this.projects.semi_annually;
          break;
      }
      this.onCalculate();
    },
  },
};
</script>
<style scoped>
.my-bg {
  background-image: url("https://miro.medium.com/v2/resize:fit:1400/0*O1qMOlTgeO9ZwIfo");
  min-height: 550px;
  color: #fff;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-8-line {
  display: -webkit-box; /* Enable flex container for webkit */
  -webkit-line-clamp: 8; /* Limit to 3 lines */
  -webkit-box-orient: vertical; /* Set vertical box orientation */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add "..." for truncated text */
}

/* class total report */
.containers {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px;
}

.cells {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: transparent;
}

.titles {
  font-size: 15px;
  text-align: start;
  font-weight: 500;
}

.values {
  font-size: 20px;
  font-weight: bold;
  color: #007BFF;
  text-align: end;

}

.border-radius-top-right {
  border-bottom: 0 white solid;
  border-right: 0 white solid;
  border-radius: 20px 0 0 0;
}

.border-radius-top-left {
  border-bottom: 0 white solid;
  border-radius: 0 20px 0 0;
}

.border-radius-bottom-right {
  border-right: 0 white solid;
  border-radius: 0 0 0 20px;
}

.border-radius-bottom-left {
  border-radius: 0 0 20px 0;
}

/* class total report */



</style>
