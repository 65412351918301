const countReferralReward = (customerReferralReward, nameLevel, numberReferralAll) => {
  return customerReferralReward.filter(x => x.nameLevel === nameLevel && x.numberReferralAll === numberReferralAll).length > 0;
};

export const createRewardsForClaim = (moneyClaimLevel, customerReferralReward, collectionReferralByLevel) => {
  //  console.log(moneyClaimLevel);
  //  console.log(customerReferralReward);
  //  console.log(collectionReferralByLevel);
  //  console.log(rewards);

  let {
    ordinary,
    starOne,
    starTwo,
    starThree,
    starFour,
    starFive,
  } = collectionReferralByLevel;

  // console.log(ordinary);
  // console.log(starOne);
  // console.log(starTwo);
  // console.log(starThree);
  // console.log(starFour);
  // console.log(starFive);

  let ordinaryClaim = 0;
  let starOneClaim = 0;
  let starTwoClaim = 0;
  let starThreeClaim = 0;
  let starFourClaim = 0;
  let starFiveClaim = 0;

  // console.log('===============');
  // console.log(ordinaryClaim);
  // console.log(starOneClaim);
  // console.log(starTwoClaim);
  // console.log(starThreeClaim);
  // console.log(starFourClaim);
  // console.log(starFiveClaim);
  // console.log('===============');

  customerReferralReward.forEach(x => {
    let level = x.nameLevel.toLowerCase().trim();
    //
    if (level.includes('ordinary') && x.isClearReferral > 0) {
      ordinaryClaim += x.numberReferralAll;
    }
    //
    else if (level.includes('1 star') && x.isClearReferral > 0) {
      starOneClaim += x.numberReferralAll;
    }
    //
    else if (level.includes('2 star') && x.isClearReferral > 0) {
      starTwoClaim += x.numberReferralAll;
    }
    //
    else if (level.includes('3 star') && x.isClearReferral > 0) {
      starThreeClaim += x.numberReferralAll;
    }
    //
    else if (level.includes('4 star') && x.isClearReferral > 0) {
      starFourClaim += x.numberReferralAll;
    }
    //
    else if (level.includes('5 star') && x.isClearReferral > 0) {
      starFiveClaim += x.numberReferralAll;
    }
  });

  ordinary = ordinary - ordinaryClaim;
  starOne = starOne - starOneClaim;
  starTwo = starTwo - starTwoClaim;
  starThree = starThree - starThreeClaim;
  starFour = starFour - starFourClaim;
  starFive = starFive - starFiveClaim;

  // console.log('===============');
  // console.log(ordinary);
  // console.log(starOne);
  // console.log(starTwo);
  // console.log(starThree);
  // console.log(starFour);
  // console.log(starFive);
  // console.log('===============');

  let data = moneyClaimLevel.map((row) => {
    let numberPerson1 = countReferralReward(customerReferralReward, row.nameLevel, row.numberPerson1);
    let numberPerson2 = countReferralReward(customerReferralReward, row.nameLevel, row.numberPerson2);
    let numberPerson3 = countReferralReward(customerReferralReward, row.nameLevel, row.numberPerson3);
    let numberPerson4 = countReferralReward(customerReferralReward, row.nameLevel, row.numberPerson4);
    let numberPerson5 = countReferralReward(customerReferralReward, row.nameLevel, row.numberPerson5);
    let numberPerson6 = countReferralReward(customerReferralReward, row.nameLevel, row.numberPerson6);

    let nameLevel = row.nameLevel.toLowerCase();

    let claim1 = true;
    let claim2 = true;
    let claim3 = true;
    let claim4 = true;
    let claim5 = true;
    let claim6 = true;

    let available = 0;

    if (nameLevel.includes('ordinary')) {
      available = ordinary;
      claim1 = !(numberPerson1 === false && ordinary >= row.numberPerson1);
      claim2 = !(numberPerson2 === false && ordinary >= row.numberPerson2);
      claim3 = !(numberPerson3 === false && ordinary >= row.numberPerson3);
      claim4 = !(numberPerson4 === false && ordinary >= row.numberPerson4);
      claim5 = !(numberPerson5 === false && ordinary >= row.numberPerson5);
      claim6 = !(numberPerson6 === false && ordinary >= row.numberPerson6);
    }
    //
    else if (nameLevel.includes('1 star')) {
      available = starOne;
      claim1 = !(numberPerson1 === false && starOne >= row.numberPerson1);
      claim2 = !(numberPerson2 === false && starOne >= row.numberPerson2);
      claim3 = !(numberPerson3 === false && starOne >= row.numberPerson3);
      claim4 = !(numberPerson4 === false && starOne >= row.numberPerson4);
      claim5 = !(numberPerson5 === false && starOne >= row.numberPerson5);
      claim6 = !(numberPerson6 === false && starOne >= row.numberPerson6);
    }
    //
    else if (nameLevel.includes('2 star')) {
      available = starTwo;
      claim1 = !(numberPerson1 === false && starTwo >= row.numberPerson1);
      claim2 = !(numberPerson2 === false && starTwo >= row.numberPerson2);
      claim3 = !(numberPerson3 === false && starTwo >= row.numberPerson3);
      claim4 = !(numberPerson4 === false && starTwo >= row.numberPerson4);
      claim5 = !(numberPerson5 === false && starTwo >= row.numberPerson5);
      claim6 = !(numberPerson6 === false && starTwo >= row.numberPerson6);
    }
    //
    else if (nameLevel.includes('3 star')) {
      available = starThree;
      claim1 = !(numberPerson1 === false && starThree >= row.numberPerson1);
      claim2 = !(numberPerson2 === false && starThree >= row.numberPerson2);
      claim3 = !(numberPerson3 === false && starThree >= row.numberPerson3);
      claim4 = !(numberPerson4 === false && starThree >= row.numberPerson4);
      claim5 = !(numberPerson5 === false && starThree >= row.numberPerson5);
      claim6 = !(numberPerson6 === false && starThree >= row.numberPerson6);
    }
    //
    else if (nameLevel.includes('4 star')) {
      available = starFour;
      claim1 = !(numberPerson1 === false && starFour >= row.numberPerson1);
      claim2 = !(numberPerson2 === false && starFour >= row.numberPerson2);
      claim3 = !(numberPerson3 === false && starFour >= row.numberPerson3);
      claim4 = !(numberPerson4 === false && starFour >= row.numberPerson4);
      claim5 = !(numberPerson5 === false && starFour >= row.numberPerson5);
      claim6 = !(numberPerson6 === false && starFour >= row.numberPerson6);
    }
    //
    else if (nameLevel.includes('5 star')) {
      available = starFive;
      claim1 = !(numberPerson1 === false && starFive >= row.numberPerson1);
      claim2 = !(numberPerson2 === false && starFive >= row.numberPerson2);
      claim3 = !(numberPerson3 === false && starFive >= row.numberPerson3);
      claim4 = !(numberPerson4 === false && starFive >= row.numberPerson4);
      claim5 = !(numberPerson5 === false && starFive >= row.numberPerson5);
      claim6 = !(numberPerson6 === false && starFive >= row.numberPerson6);
    }

    // console.log(numberPerson1);
    // console.log(numberPerson2);
    // console.log(numberPerson3);
    // console.log(numberPerson4);
    // console.log(numberPerson5);
    // console.log(numberPerson6);

    let list = [
      {
        key: 'claim1',
        value: claim1,
      },
      {
        key: 'claim2',
        value: claim2,
      },
      {
        key: 'claim3',
        value: claim3,
      },
      {
        key: 'claim4',
        value: claim4,
      },
      {
        key: 'claim5',
        value: claim5,
      },
      {
        key: 'claim6',
        value: claim6,
      },
    ];
    let data = list.filter(x => x.value === false);
    let claim = {
      claim1: claim1,
      claim2: claim2,
      claim3: claim3,
      claim4: claim4,
      claim5: claim5,
      claim6: claim6,
    };
    if (data.length > 1) {
      let lastObject = data[data.length - 1];
      claim = {
        claim1: true,
        claim2: true,
        claim3: true,
        claim4: true,
        claim5: true,
        claim6: true,
      };
      claim[lastObject.key] = lastObject.value;
    }

    return {
      ...row,
      ...claim,
      available: available,
    };
  });
  return data ?? [];
};


// verifyListForClaim(moneyClaimLevel = [], customerReferralReward = [], countCustomerReferrals = 0) {
//   //let count = 0;
//   // customerReferralReward.forEach((value) => {
//   //   count += value.numberReferralAll;
//   // });
//   //let available = countCustomerReferrals - count;
//   // console.log('count =' + count);
//   // console.log('countCustomerReferrals =' + countCustomerReferrals);
//   // console.log('available =' + available);
//   /**
//    * calculate find referral not claims
//    */
//   console.log(countCustomerReferrals);
//   moneyClaimLevel.forEach((row) => {
//     //row.available = 0;
//
//     //if (this.profile.numberLevel) {
//       //if (row.nameLevel.includes(this.profile.numberLevel)) {
//         // console.log(this.profile.numberLevel);
//         // console.log(row.nameLevel);
//         //row.available = available;
//         // if (!numberPerson1) {
//         //   //console.log('1 ' + numberPerson1);
//         //   if (available >= row.numberPerson1) {
//         //     numberPerson1 = false;
//         //   } else {
//         //     numberPerson1 = true;
//         //   }
//         // }
//         //
//         // if (!numberPerson2) {
//         //   //console.log('2 ' + numberPerson2);
//         //   if (available >= row.numberPerson2) {
//         //     numberPerson2 = false;
//         //   } else {
//         //     numberPerson2 = true;
//         //   }
//         // }
//         //
//         //
//         // if (!numberPerson3) {
//         //   //console.log('3 ' + numberPerson3);
//         //   if (available >= row.numberPerson3) {
//         //     numberPerson3 = false;
//         //   } else {
//         //     numberPerson3 = true;
//         //   }
//         // }
//         //
//         // if (!numberPerson4) {
//         //   //console.log('4 ' + numberPerson4);
//         //   if (available >= row.numberPerson4) {
//         //     numberPerson4 = false;
//         //   } else {
//         //     numberPerson4 = true;
//         //   }
//         // }
//         //
//         // if (!numberPerson5) {
//         //   //console.log('5 ' + numberPerson5);
//         //   if (available >= row.numberPerson5) {
//         //     numberPerson5 = false;
//         //   } else {
//         //     numberPerson5 = true;
//         //   }
//         // }
//         //
//         // if (!numberPerson6) {
//         //   //console.log('6 ' + numberPerson6);
//         //   if (available >= row.numberPerson6) {
//         //     numberPerson6 = false;
//         //   } else {
//         //     numberPerson6 = true;
//         //   }
//         // }
//       //}
//       // else {
//       //   numberPerson1 = true;
//       //   numberPerson2 = true;
//       //   numberPerson3 = true;
//       //   numberPerson4 = true;
//       //   numberPerson5 = true;
//       //   numberPerson6 = true;
//       // }
//     //}
//
//     let numberPerson1 = customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberReferralAll === row.numberPerson1).length > 0;
//     let numberPerson2 = customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberReferralAll === row.numberPerson2).length > 0;
//     let numberPerson3 = customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberReferralAll === row.numberPerson3).length > 0;
//     let numberPerson4 = customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberReferralAll === row.numberPerson4).length > 0;
//     let numberPerson5 = customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberReferralAll === row.numberPerson5).length > 0;
//     let numberPerson6 = customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberReferralAll === row.numberPerson6).length > 0;
//
//     row.listClaim = [
//       /**
//        * person 1
//        */
//       {
//         referral: row.numberPerson1 + ' Refs',
//         price: row.moneyClaim1,
//         claim: numberPerson1,
//       },
//       /**
//        * person 2
//        */
//       {
//         referral: row.numberPerson2 + ' Refs',
//         price: row.moneyClaim2,
//         claim: numberPerson2,
//       },
//       /**
//        * person 3
//        */
//       {
//         referral: row.numberPerson3 + ' Refs',
//         price: row.moneyClaim3,
//         claim: numberPerson3,
//       },
//       /**
//        * person 4
//        */
//       {
//         referral: row.numberPerson4 + ' Refs',
//         price: row.moneyClaim4,
//         claim: numberPerson4,
//       },
//       /**
//        * person 5
//        */
//       {
//         referral: row.numberPerson5 + ' Refs',
//         price: row.moneyClaim4,
//         claim: numberPerson5,
//       },
//       /**
//        * person 6
//        */
//       {
//         referral: row.numberPerson6 + ' Refs',
//         price: row.moneyClaim6,
//         claim: numberPerson6,
//       },
//     ];
//   });
//   console.table(moneyClaimLevel);
//   return moneyClaimLevel;
// },