<template>
  <div>
    <v-divider />

    <div v-if="profile.status.toLowerCase() !== 'done'" style="height: 120px; transform: rotate(180deg);">
      <svg width="100%" height="100%" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg"
           preserveAspectRatio="none" overflow="auto" shape-rendering="auto" fill="#ffffff">
        <defs>
          <path id="wavepath"
                d="M 0 2000 0 500 Q 60 459 125 500 t 125 0 125 0 125 0 125 0 125 0 125 0 125 0 125 0 125 0 125 0  v1000 z" />
          <path id="motionpath" d="M -200 0 0 0" />
        </defs>
        <g>
          <use xlink:href="#wavepath" y="100" fill="#ffdbdc">
            <animateMotion
              dur="5s"
              repeatCount="indefinite">
              <mpath xlink:href="#motionpath" />
            </animateMotion>
          </use>
        </g>
      </svg>
    </div>

    <v-card min-height="220" elevation="0">
      <v-row>
        <v-col cols="12" sm="12" md="5">
          <div class="mt-10">
            <div class="text-center">
              <h3>User Profile</h3>
              <p>Home | {{ reactiveStore.state.menuName }}</p>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="12" md="2">
          <div class="mt-5 text-center">
            <v-hover>
              <template v-slot:default="{ isHovering, props }">
                <v-badge
                  :color="
                  profile?.status.toLowerCase() !== 'done' ? 'red' : 'green'
                "
                  inline
                  stacked
                  location="top"
                  style="margin-left: 15px; position: absolute; z-index: 1"
                >
                </v-badge>
                <v-avatar size="150" v-bind="props" color="grey">
                  <v-img
                    onclick="document.getElementById('profile').click()"
                    v-if="isHovering"
                    cover
                    alt="John"
                    :src="defaultImage"
                  ></v-img>
                  <input
                    style="display: none"
                    type="file"
                    id="profile"
                    @change="UploadProfile"
                  />

                  <v-img
                    v-if="!isHovering"
                    cover
                    alt="John"
                    :src="previewImage"
                  ></v-img>
                </v-avatar>
              </template>
            </v-hover>
          </div>
        </v-col>

        <v-col cols="12" sm="12" md="5">
          <div class="mt-5 profile-center">
            <h4 class="text-primary text-uppercase">
            <span v-if="profile?.lastName">
              {{ profile?.firstName + ' ' + profile?.lastName }}
            </span>
              <span v-else> Guest </span>
            </h4>

            <span>@</span>
            <span v-if="profile?.lastName" class="text-lowercase">
            {{ profile?.firstName + '' + profile?.lastName }}
          </span>
            <span v-else> Guest </span>

            <div v-if="profile?.numberLevel">
              <v-icon
                v-for="i in getLevel(profile?.numberLevel)"
                :key="i"
                size="18"
                color="orange"
                icon="mdi-star"
              ></v-icon>
              <v-icon
                v-for="i in 5 - getLevel(profile?.numberLevel)"
                :key="i"
                size="18"
                color="grey"
                icon="mdi-star"
              ></v-icon>
            </div>

            <span class="text-blue">
            {{ wallet[0]?.bankAccount }}
          </span>

            <br />

            <h4 class="text-grey-darken-1">
              <span class="text-grey-darken-1"> {{ $t('available_balance') }} : </span>
              <span class="text-orange"
              >{{ getAvailableBalance(wallet) }} $
            </span>
            </h4>
            <div class="mt-1" />
            <h4>
              <span class="text-grey-darken-1"> {{ $t('account_balance') }} : </span>
              <span class="text-primary"
              >{{ getAccountBalance(wallet) }} $
            </span>
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <template v-if="dialog">
      <ErrorDialog :dialog="dialog" :message="message" :onCloseModal="onCloseModal" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_PATH,
  UPDATE_PHOTO,
} from '@/utils/customerApiUrl';
import ErrorDialog from '@/views/client/components/dialog/ErrorDialog.vue';
import { getAccountBalance, getAvailableBalance, validateImage } from '@/utils/function';
import reactiveStore from '@/views/client/profile/reactive/reactiveStore';

let defaultImage = 'https://t4.ftcdn.net/jpg/02/83/72/41/360_F_283724163_kIWm6DfeFN0zhm8Pc0xelROcxxbAiEFI.jpg';

export default {
  name: 'CardProfile',
  components: { ErrorDialog },
  computed: {
    ...mapState(['profile', 'wallet']),
  },
  setup() {
    return {
      reactiveStore,
    };
  },
  data() {
    return {
      defaultImage,
      getAvailableBalance,
      dialog: false,
      message: '',
      previewImage: null,
    };
  },
  methods: {
    getAccountBalance,

    async UploadProfile(e) {
      const image = e.target.files[0];
      if (image) {
        let isValidateImage = validateImage(e);
        if (isValidateImage) {
          this.message = isValidateImage;
          this.dialog = true;
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onload = (e) => {
            this.previewImage = e.target.result;
          };
          let data = new FormData();
          data.append('file', e.target.files[0]);
          this.axios.defaults.headers['Content-Type'] = `multipart/form-data`;
          this.axios.post(UPLOAD_PHOTO, data)
            .then((response) => {
              this.axios.defaults.headers['Content-Type'] = `application/json`;
              this.postData(response.data.filename); //console.log("Image has been uploaded.", response);
            })
            .catch((error) => {
              this.axios.defaults.headers['Content-Type'] = `application/json`;
              this.message = error.response.data.message;
              this.dialog = true;
            });
        }
      }
    },

    onCloseModal(dialog) {
      this.dialog = dialog;
    },

    async postData(photo) {
      this.axios
        .post(UPDATE_PHOTO, { photo })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch('getUserProfile');
          }, 4000);
        })
        .catch((error) => {
          console.error('Failed to', error);
        });
    },

    getLevel(level) {
      for (let i = 1; i <= 5; i++) {
        if (level.includes(i.toString())) {
          return i;
        }
      }
      return 0;
    },
  },
  created() {
    setTimeout(() => {
      let image = this.profile.profile;
      if (image && !image.includes('http')) {
        image = UPLOAD_PHOTO_PATH + '' + this.profile.profile;
      }
      this.previewImage = this.profile.profile
        ? image
        : defaultImage;
    }, 1000);
  },
};
</script>

<style scoped></style>
