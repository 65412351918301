<script>
export default {
  name: 'BoxMessageError',
  props: ['isMessage'],
  data(){
    return { message: 'This is message.' };
  },
  created() {
    this.message = this.isMessage;
  }
};
</script>

<template>
  <v-alert
    color="red"
    variant="outlined"
    type="warning"
  >
    {{ message }}
  </v-alert>
</template>

<style scoped>

</style>