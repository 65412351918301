<script>
import { formatDate , formatPrice} from '@/utils/function';

export default {
  name: 'ModalShowInterest',
  props: ['row', 'isDialog', 'closeModal'],
  data() {
    return {
      formatDate: formatDate,
      formatPrice: formatPrice,
      dialog: false,
    };
  },
  created() {
    this.dialog = this.isDialog;
  },
  watch: {
    isDialog(dialog) {
      this.dialog = dialog;
    },
  },
};
</script>

<template>
  <v-dialog width="1000" v-model="dialog" persistent>
    <v-card rounded="xl">
      <v-card-title class="my-3">
        <div class="d-flex justify-space-between">
          <p class="text-primary">
            <v-icon>mdi-clipboard-list-outline</v-icon>
            Report Interest Receiving
          </p>
          <v-btn color="red" rounded="xl" density="comfortable" icon="mdi-close" @click="closeModal()">
          </v-btn>
        </div>
      </v-card-title>
      <v-divider />
      <div>
        <v-data-table hide-default-footer density="compact">
          <thead>
          <tr class="text-capitalize">
            <th>no</th>
            <th>interest name</th>
            <th>interest receiving</th>
            <th>return date</th>
            <th>is return</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row,index) in row.returnInterest" v-bind:key="index">
            <td>{{ (index + 1) }}</td>
            <td>{{ row.interest_name }} - {{ (index + 1) }}</td>
            <td>${{ formatPrice(row.profit) }}</td>
            <td>{{ formatDate(row.return_date, 'YYYY-MM-DD HH:mm A') }}</td>
            <td>
              <v-icon v-if="row.dateReturnInterest> 0" color="blue">mdi-check-circle</v-icon>
              <v-icon v-else color="pink">mdi-clock-outline</v-icon>
            </td>
          </tr>
          </tbody>
        </v-data-table>

      </div>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="text-none"
          block
          rounded="xl"
          width="200"
          color="primary"
          text="Disabled"
          variant="flat"
          @click="closeModal()"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>