<template>
  <div class="my-app-bar bg-primary">
    <div>
      <p class="main-title font-weight-bold">
        {{ company.companyName }}
      </p>
      <v-btn class="icon-nav" variant="text" @click="nav = !nav">
        <v-icon size="35" v-if="!nav">mdi-menu</v-icon>
        <v-icon size="35" v-else>mdi-window-close</v-icon>
      </v-btn>
    </div>

    <div class="d-flex">
      <router-link
        to="/sign-in"
        class="pt-1 text-decoration-none hide"
        v-if="!login"
      >
        <p class="text-white">Login</p>
      </router-link>

      <router-link
        to="/sign-up"
        class="pt-1 text-decoration-none hide"
        v-if="!login"
      >
        <p class="ml-10 text-white">Register</p>
      </router-link>

      <v-btn
        class="pt-1 text-none hide"
        variant="text"
        @click="logOut()"
        v-if="login"
      >
        <p class="text-white">Log out</p>
      </v-btn>

      <v-btn
        variant="text"
        class="hide"
        @click="share('facebook', adminSocialMedia.facebook ?? '')"
        icon="mdi-facebook"
        size="small"
      ></v-btn>

      <v-btn
        variant="text"
        class="hide"
        @click="share('facebook', adminSocialMedia.telegram ?? '')"
        icon="mdi-youtube"
        size="small"
      ></v-btn>

      <v-btn
        variant="text"
        class="hide"
        @click="share('facebook', adminSocialMedia.twitter ?? '')"
        icon="mdi-twitter"
        size="small"
      ></v-btn>

      <v-btn
        variant="text"
        class="hide"
        @click="share('facebook', adminSocialMedia.whatApp ?? '')"
        icon="mdi-whatsapp"
        size="small"
      ></v-btn>

      <div class="icon-nav my-2">
        <v-badge
          color="error"
          :content="notification"
          class="mx-2"
          v-if="login"
        >
          <v-icon size="large" color="white" @click="openDrawer()">
            mdi-bell</v-icon
          >
        </v-badge>
      </div>

      <router-link to="/">
        <v-img
          class="icon-nav"
          height="50px"
          width="200px"
          :src="company.logo"
        />
      </router-link>
    </div>
  </div>

  <v-layout class="my-layout">
    <v-main>
      <template v-if="nav">
        <div class="my-nav-collapse">
          <MobileScreen
            :aboutUsData="aboutUs"
            :contactData="contact"
            :projectData="project"
            :ourProcessData="ourProcess"
          />
        </div>
      </template>

      <nav class="navs">
        <div class="items hide">
          <div class="logo">
            <router-link to="/">
              <v-img
                style="height: 40px; width: 215px"
                :src="company.logo"
                alt=""
              />
            </router-link>
          </div>
        </div>

        <div class="items hide">
          <router-link to="/covid">
            <v-btn color="red font-format" size="small" rounded="0"
              >Covid-19</v-btn
            >
          </router-link>
        </div>

        <div class="items hide">
          <a class="text-decoration-none" href="#">
            <router-link
              to="/asia-pacific-web"
              class="text-decoration-none text-black font-format"
            >
              Asia Pacific
            </router-link>
          </a>
          <div class="all-subs hide">
            <div class="ma-5">
              <v-row>
                <v-col cols="12" md="2">
                  <v-img
                    class="img-border"
                    width="200"
                    height="160"
                    rounded="xl"
                    :src="aboutUs.image"
                    cover
                  ></v-img>
                </v-col>
                <v-col cols="12" md="4">
                  <p
                    class="text-start text-white pa-3 three-line font-format text-control"
                    v-html="truncateDescription(aboutUs.description)"
                  ></p>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="border-end">
                    <br /><br /><br /><br />
                    <router-link to="/asia-pacific-web">
                      <v-btn
                        width="50%"
                        color="white"
                        variant="outlined"
                        border
                        rounded="xl"
                        density="default"
                      >
                        See More
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>
                <v-col cols="12" md="3">
                  <p class="ma-1">
                    <router-link
                      to="/asia-pacific-web"
                      class="text-decoration-none text-white font-format"
                    >
                      Asia pacific
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/annual-report"
                      class="text-decoration-none text-white font-format"
                    >
                      Annual Report
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/political-risk-insurances"
                      class="text-decoration-none text-white font-format"
                    >
                      Political risk & Insurance
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/events"
                      class="text-decoration-none text-white font-format"
                    >
                      Events
                    </router-link>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="items hide">
          <a class="text-decoration-none" href="#">
            <router-link
              to="/projects"
              class="text-decoration-none text-black font-format"
            >
              Projects
            </router-link>
          </a>
          <div class="all-subs">
            <div class="ma-5">
              <v-row>
                <v-col cols="12" md="2">
                  <v-img
                    class="img-border"
                    width="200"
                    height="160"
                    rounded="xl"
                    :src="project.image"
                    cover
                  ></v-img>
                </v-col>
                <v-col cols="12" md="4">
                  <p
                    class="text-start text-white pa-3 three-line font-format text-control"
                    v-html="truncateDescription(project.description)"
                  ></p>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="border-end">
                    <br /><br /><br /><br />
                    <router-link to="/projects">
                      <v-btn
                        width="50%"
                        color="white"
                        variant="outlined"
                        border
                        rounded="xl"
                        density="default"
                      >
                        See More
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>
                <v-col cols="12" md="3">
                  <p class="ma-1">
                    <router-link
                      to="/newest-project"
                      class="text-decoration-none text-white font-format"
                    >
                      Newest Projects
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/feature-projects"
                      class="text-decoration-none text-white font-format"
                    >
                      Featured Projects
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/closing-soon"
                      class="text-decoration-none text-white font-format"
                    >
                      Closing Soon
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/crowd-funding"
                      class="text-decoration-none text-white font-format"
                    >
                      Crowdfunding
                    </router-link>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="items hide">
          <a class="text-decoration-none" href="#">
            <router-link
              to="/contact-us"
              class="text-decoration-none text-black font-format"
            >
              Contact
            </router-link>
          </a>
          <div class="all-subss"></div>
        </div>

        <div class="items hide">
          <a class="text-decoration-none" href="#">
            <router-link
              to="/our-process"
              class="text-decoration-none text-black font-format"
            >
              Our Process
            </router-link>
          </a>
          <div class="all-subs">
            <div class="ma-5">
              <v-row>
                <v-col cols="12" md="2">
                  <v-img
                    class="img-border"
                    width="200"
                    height="160"
                    rounded="xl"
                    :src="ourProcess.image"
                    cover
                  ></v-img>
                </v-col>
                <v-col cols="12" md="4">
                  <p
                    class="text-start text-white pa-3 three-line font-format text-control"
                    v-html="truncateDescription(ourProcess.desc)"
                  ></p>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="border-end">
                    <br /><br /><br /><br />
                    <router-link to="/our-process">
                      <v-btn
                        width="50%"
                        color="white"
                        variant="outlined"
                        border
                        rounded="xl"
                        density="default"
                      >
                        See More
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>
                <v-col cols="12" md="3">
                  <p
                    class="ma-1"
                    v-for="row in ourProcessMenu"
                    v-bind:key="row.title"
                  >
                    <router-link
                      :to="row.link"
                      class="text-decoration-none text-white font-format"
                    >
                      {{ row.title }}
                    </router-link>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="items hide">
          <div class="d-flex">
            <v-badge
              color="red"
              :content="notification"
              class="mx-2"
              v-if="login"
            >
              <v-icon size="large" color="primary" @click="openDrawer()">
                mdi-bell
              </v-icon>
            </v-badge>

            <router-link
              to="/profile"
              class="text-decoration-none text-black"
              v-if="login"
            >
              <VIcon size="small" class="mx-2">
                <v-icon color="primary">mdi-account</v-icon>
              </VIcon>
            </router-link>
          </div>
        </div>
      </nav>
      <div class="div" v-if="visibleAnimation">
        <p
          class="text-error"
          style="white-space: nowrap; cursor: pointer"
          v-on:click="redirectToAccountSettings()"
          v-on:mouseleave="
            animationTitle =
              'Your account has been registered successfully but remains unverified. Please complete identity verification by uploading a copy of your National ID.'
          "
        >
          {{ animationTitle }}
        </p>
      </div>
      <div>
        <slot />
      </div>

      <div>
        <v-navigation-drawer
          v-model="drawer"
          temporary
          location="right"
          width="400"
          class="overflow-y-hidden overflow-x-hidden"
        >
          <DrawerNotification
            :closeDrawer="closeDrawer"
            :notificationCount="notification"
            :drawer="drawer"
          />
        </v-navigation-drawer>
      </div>

      <FooterPage />
    </v-main>
  </v-layout>

  <v-dialog max-width="500" v-model="dialog" persistent>
    <v-card title="Pin Code" rounded="xl" prepend-icon="mdi-key">
      <v-card-text>
        <v-sheet class="mx-auto">
          <v-form ref="form">
            <v-text-field
              @paste.prevent
              @keypress="handleChange($event)"
              v-model="form.pin_code"
              variant="outlined"
              density="compact"
              autocomplete="off"
              placeholder="Enter your pin code"
              prepend-inner-icon="mdi-lock"
              :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible ? 'text' : 'password'"
              @click:append-inner="visible = !visible"
              maxlength="4"
            />

            <v-checkbox
              color="primary"
              v-model="checkbox"
              :rules="[(v) => !!v || 'You must agree to continue!']"
              label="I agree to the Terms and Conditions."
              required
              density="compact"
            ></v-checkbox>

            <div class="d-flex flex-column">
              <v-btn
                :disabled="!checkbox"
                :loading="loading"
                rounded="xl"
                class="mt-4"
                color="success"
                block
                @click="updatePinCode"
              >
                <v-icon>mdi-lock</v-icon>
                Submit
              </v-btn>
            </div>
          </v-form>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FooterPage from "./FooterPage";
import store from "../../../store/customerStore";
import { mapState } from "vuex";
import DrawerNotification from "@/views/client/components/DrawerNotification.vue";
import MobileScreen from "@/views/client/components/MobileScreen.vue";
import { SET_PIN_CODE } from "@/utils/customerApiUrl";

export default {
  name: "FrontendLayout",
  components: { MobileScreen, DrawerNotification, FooterPage },
  computed: {
    ...mapState([
      "login",
      "profile",
      "company",
      "aboutUs",
      "contact",
      "project",
      "ourProcess",
      "notification",
      "socialMedia",
      "adminSocialMedia",
      "wallet",
    ]),
  },
  data() {
    return {
      form: {
        id: 0,
        pin_code: "",
      },
      checkbox: false,
      loading: false,
      dialog: false,
      visible: false,
      //
      nav: false,
      visibleAnimation: false,
      drawer: null,
      about: [],
      ourProcessMenu: [
        {
          title: "Our Impact",
          link: "/our-impact",
        },
        // {
        //   title: " Asia Pacific",
        //   link: "/asia-pacific-web",
        // },
        {
          title: "Guatantee Agency",
          link: "/about-us",
        },
        {
          title: "Small Investment Program",
          link: "/small-investment-program",
        },
        {
          title: "Currency",
          link: "/currency-web",
        },
      ],
      animationTitle: null,
      token: null,
      linkToShare: null,
    };
  },
  methods: {
    redirectToAccountSettings() {
      if (this.token) {
        window.location.href = "/profile?tab=Settings&nav=Account+Settings";
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },
    async logOut() {
      setTimeout(() => {
        const blockTime = localStorage.getItem("blockTime"); // Preserve block time
        //this.$router.push("/projects");
        localStorage.removeItem("client_token");
        localStorage.clear();
        if (blockTime) {
          localStorage.setItem("blockTime", blockTime); // Restore block time
        }
        store.commit("SET_PROFILE", {});
        store.commit("SET_LOGIN", false);
        location.href = "/projects";
      }, 1000);
    },

    closeDrawer() {
      this.drawer = null;
    },

    async openDrawer() {
      this.drawer = true;
    },

    truncateDescription(desc) {
      if (desc) {
        return desc.length > 200 ? desc.substring(0, 300) + "..." : desc;
      }
    },

    // async shareLink() {
    //   if (this.token !== null) {
    //     const shareData = {
    //       title: "Share link",
    //       text: "Check this out!",
    //       url: this.linkToShare,
    //     };
    //     try {
    //       await navigator.share(shareData);
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   } else {
    //     this.$router.push({ path: "/sign-in" });
    //   }
    // },

    share(platform, link) {
      let shareUrl;
      if (this.token !== null) {
        switch (platform) {
          case "telegram":
            shareUrl = `${link}`;
            break;
          case "facebook":
            shareUrl = `${link}`;
            break;
          case "twitter":
            shareUrl = `${link}`;
            break;
          case "whatsapp":
            shareUrl = `${link}`;
            break;
          default:
            return;
        }

        window.open(shareUrl, "_blank");
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },

    // update pin code
    updatePinCode() {
      if (this.form.pin_code.length === 4) {
        this.loading = true;
        this.axios
          .post(SET_PIN_CODE, this.form)
          .then(() => {
            this.loading = false;
            this.visible = true;
            this.dialog = false;
            setTimeout(() => {
              this.$store.dispatch("getUserProfile");
            }, 4000);
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    // pin code 6
    handleChange(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      let pin = evt.target.value;
      if (pin.length > 5) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      }
    },
  },
  watch: {
    profile: {
      handler(newProfile) {
        if (newProfile && newProfile.status) {
          if (this.token) {
            this.visibleAnimation = newProfile.status.toLowerCase() !== "done";
            this.animationTitle =
              "Your account has been registered successfully but remains unverified. Please complete identity verification by uploading a copy of your National ID.";
          }
        }
      },
      immediate: true, // Run immediately on component mount if profile already has data
      deep: true, // Watch for deep changes
    },
  },
  created() {
    this.token = localStorage.getItem("client_token");
    this.$store.dispatch("getCompanyInfo");
    //!!! get user profile when store empty
    //this.visibleAnimation = this.profile?.status.toLowerCase() === "done" ? false : true;
    if (!this.profile.email) {
      this.$store.dispatch("getUserProfile");
    }

    //
    setTimeout(() => {
      let wallet = this.wallet[0];

      if (this.profile.email && wallet && wallet.pin_code == null) {
        this.dialog = true;
        this.form.id = wallet.id;
      }
    }, 3000);

    setTimeout(() => {
      this.linkToShare =
        this.baseUrl + "/sign-up?code=" + this.profile.myReferral;
    }, 2000);
  },
};
</script>

<style scoped>
.img-border {
  border: white 2px solid;
}

.border-end {
  border-right: white 1px solid;
}

.div {
  width: 100%;
  height: 50px;
  position: relative;
  transform: translateX(100%); /* Set initial position */
  animation: moveToLeft 25s linear infinite;
  animation-fill-mode: forwards;
}

.div:nth-child(1) {
  animation-delay: 0s;
}
.div:nth-child(2) {
  animation-delay: 2s;
}
.div:nth-child(3) {
  animation-delay: 4s;
}

@keyframes moveToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
