<script>
import { formatDate } from '@/utils/function';
import { LOADING_IMAGE } from '@/utils/customerApiUrl';

export default {
  props: ['exploreMores'],
  name: 'CardNews',
  data(){
    return {
      formatDate: formatDate,
      data : {},
      LOADING_IMAGE: LOADING_IMAGE,
    };
  },
  methods: {
    linkTo(item) {
      let data = {
        ...item,
        investmentCheck: true,
      };
      sessionStorage.setItem('project', JSON.stringify(data));
      sessionStorage.setItem('projectId', data.id);
      sessionStorage.setItem('invest', "true");
      this.$router.push('/project-details');
    },

    // textWord(text){
    //   let word = text ? text.split(' ') : '';
    //   return word[0]  + ' ...';
    // }

  },
};
</script>

<template>
  <div class="margin-auto">
    <VRow>
      <VCol md="4" sm="6" cols="12" v-for="data in exploreMores" v-bind:key="data.id">
        <v-card class="ma-1" height="450" min-width="" rounded="xl">

          <v-img
            height="250"
            :src="data.image_project === null ?  LOADING_IMAGE :data.urlImage"
            cover
            class="d-flex align-end pa-5s"
            lazy-src="https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif"
          >
            <div class="px-3 card-item-blur">
<!--              <h4 class="text-orange text-uppercase font-weight-regular">-->
<!--                {{-->
<!--                  data?.Country?.name === "Cryptocurrency"-->
<!--                      ? "All Country"-->
<!--                      : data?.Country?.name-->
<!--                }}-->
<!--              </h4>-->

              <h5 class="text-white three-line-title text-control" v-html="data?.project_title">
              </h5>
            </div>
          </v-img>

          <div class="d-flex justify-space-around my-2">
            <span class="text-small" v-html="(data?.Sector?.name)"/>
            <span class="text-small">
              |
            </span>
            <span class="text-small">
              {{ data?.project_status ?? 'Active' }}
            </span>
            <span class="text-small">
              |
            </span>
            <span class="text-small">
             {{ data?.board_approval_date_text ? (data?.board_approval_date_text) : '.' }}
            </span>
          </div>

          <VDivider />
          <v-card-text>

            <div class="" style="height: 70px">
              <h4 class="font-weight-regular text-three-line text-control" v-html="data?.description_project">
              </h4>
            </div>
            <br />
            <router-link to="/project-details" class="text-decoration-none">
              <h4 class="text-primary font-weight-bold text-uppercase" @click="linkTo(data)">
                Read more
                <VIcon>mdi-arrow-right</VIcon>
              </h4>
            </router-link>
            <br />
          </v-card-text>
        </v-card>
      </VCol>
    </VRow>
  </div>
  <br/>
  <br/>
</template>

<style scoped>
  .text-small{
    font-size: 12px;
  }
</style>