<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search feedback..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="feedbackCollection"
      :items-per-page="perPage"
      :server-items-length="totalCount"
      :loading="loading"
      :sort-by="[
        { key: 'created_at', order: 'desc' },
        { key: 'title', order: 'asc' },
        { key: 'nameUser', order: 'asc' },
      ]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center mb-4">
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="perPage"
                    :items="[10, 25, 50, 100, 1000, 10000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="perPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visibleMsg"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                    <v-row>
                      <v-col cols="12">
                        <input type="hidden" v-model="hiddenId" />
                        <v-text-field
                          v-model.trim="title"
                          :rules="[() => !!title || 'This field is required']"
                          label="Title"
                          placeholder="Title"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="5" md="5" lg="5" xl="5">
                        <v-text-field
                          v-model.trim="nameUser"
                          :rules="[() => !!nameUser || 'This field is required']"
                          label="Username"
                          placeholder="Username"
                          variant="outlined"
                          prepend-inner-icon="mdi-account-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="5" md="5" lg="5" xl="5">
                        <v-select
                          v-model="job"
                          :items="jobs"
                          label="Jobs"
                          :rules="[() => !!job || 'This field is required']"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-format-list-bulleted-type"
                          required
                          persistent-placeholder
                          placeholder="Choose job..."
                          return-object
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-switch
                          v-model="status"
                          color="success"
                          :label="isStatusAcitve"
                          hide-details
                          v-bind:false-value="0"
                          v-bind:true-value="1"
                          required
                        ></v-switch>
                      </v-col>
                      <v-col cols="12">
                        <v-label class="font-weight-thin x-small">Description</v-label>
                        <QuillEditor
                          toolbar="essential"
                          contentType="html"
                          style="height: 210px"
                          v-model:content="desc"
                          theme="snow"
                        />
                      </v-col>

                      <input type="hidden" v-model="hdImage" />
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <v-card variant="variant" class="mx-auto" elevation="16">
                      <v-img
                        :src="
                          previewImage === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : previewImage
                        "
                        aspect-ratio="1"
                        color="surface-variant"
                        height="450"
                        contain
                        @click="triggerFileInput"
                      >
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>

                    <v-file-input
                      v-model.trim="photoUser"
                      accept="image/png, image/jpeg, image/bmp"
                      label="Avatar"
                      placeholder="Pick an avatar"
                      prepend-icon="mdi-camera"
                      @change="uploadImage"
                      hide-input
                      class="mt-2"
                      ref="fileInput"
                      style="display: none"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.photoUser`]="{ item }">
        <div v-if="item.photoUser" class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img alt="Avatar" :src="path + item.photoUser" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.status === 1" class="text-center text-success">
          <v-icon
            color="success"
            icon="mdi-checkbox-marked-outline"
            title="Active"
          ></v-icon
          ><br />Active
        </div>
        <div v-else class="text-center text-error">
          <v-icon
            color="error"
            icon="mdi-close-circle-outline"
            title="Disactive"
          ></v-icon>
          Disactive
        </div>
      </template>
      <template v-slot:[`item.desc`]="{ item }">
        <div
          v-html="
            item.desc !== null
              ? item.desc.length > 50
                ? item.desc.substring(0, 50) + '...'
                : item.desc
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>
      <template v-slot:[`item.staff.fullName`]="{ item }">
        {{ item.staff.fullName }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-2" size="x-small" color="red-lighten-2" @click="editItem(item)">
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
              <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
            @update:model-value="populatefeedbackCollection"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_Feedback_Image,
  URL_Feedback_Admin,
  URL_FETCH_FEEDBACK_ADMIN_WITH_PAGINATION,
  URL_UPLOAD_FEEDBACK_IMAGE,
} from "@/utils/apiUrl";
import { getImageTokenHeaders, getTokenHeaders } from "@/utils/headerToken";
import { originalCode } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";
import { getFileExtension, getFileSizeInKB } from "@/utils/reusableFunctions";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    path: Path_Feedback_Image,
    loading: false, // Loading state
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: false,
    visibleMsg: true,
    isSubmitting: false,

    items: [],
    feedbackCollection: [],
    jobs: [],

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,

    title: null,
    nameUser: null,
    photoUser: null,
    job: null,
    desc: null,
    status: 1,

    previewImage: null,
    hdImage: null,
    lastSubmissionId: null,

    editedIndex: -1,
    hiddenId: 0,

    //pagination
    page: 1,
    perPage: 10,
    pageCount: 0,
    totalCount: 0, // Total records count

    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "", key: "photoUser", align: "center" },
      { title: "Status", key: "status", align: "center" },
      { title: "Title", key: "title", align: "center" },
      { title: "User Name", key: "nameUser", align: "center" },
      { title: "Job", key: "job", align: "center" },
      { title: "Description", key: "desc", align: "center" },
      { title: "Create at", key: "created_at", align: "center" },
      { title: "Created by", key: "staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Feedback" : "Update Feedback";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    isStatusAcitve() {
      return this.status === 0 ? "Disactive" : "Active";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page() {
      this.populatefeedbackCollection();
    },
    perPage() {
      this.populatefeedbackCollection();
    },
    search() {
      this.populatefeedbackCollection();
    },
  },

  created() {
    this.populatefeedbackCollection();
    this.loadJobFromJsonFile();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.title = null;
      this.nameUser = null;
      this.job = null;
      this.desc = null;
      this.status = 1;
      this.previewImage = null;
      this.visibleMsg = true;
    },

    async populatefeedbackCollection() {
      const params = {
        page: this.page,
        perPage: this.perPage,
        textSearch: this.search ? this.search : null,
      };

      await axios
        .get(URL_FETCH_FEEDBACK_ADMIN_WITH_PAGINATION, {
          params,
          headers: getTokenHeaders(),
        })
        .then((response) => {
          if (response.data && response.data.feedbackAdminCollection) {
            this.feedbackCollection = response.data.feedbackAdminCollection;
            this.totalCount = response.data.totalCount;
            this.pageCount = response.data.pageCount;
            this.items.push(this.feedbackCollection);
            this.visible = this.feedbackCollection !== 0 ? true : false;
          } else {
            console.warn("Empty API Response");
            this.feedbackCollection = [];
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.feedbackCollection.indexOf(item);
      this.hiddenId = item.id;
      this.editedItem = Object.assign({}, item);
      this.title = item.title;
      this.nameUser = item.nameUser;
      this.job = item.job;
      this.desc = item.desc;
      this.status = item.status;
      this.previewImage =
        item.photoUser === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.photoUser;
      this.hdImage = item.photoUser;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.feedbackCollection.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.feedbackCollection.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_Feedback_Admin + id;
      axios
        .delete(url, { headers: getTokenHeaders() })
        .then(function () {
          this.snakeMessage("error", "Data has been deleted.");
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async submitData() {
      // Client-side check for duplicate requests
      const currentId = new Date().getTime(); // Use a unique identifier
      if (this.lastSubmissionId === currentId) return;
      this.lastSubmissionId = currentId;
      // end

      const data = {
        title: this.title,
        nameUser: this.nameUser,
        photoUser: this.hdImage,
        job: this.job,
        desc: this.desc,
        status: this.status,
        closeNotification: 0,
      };

      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_Feedback_Admin + id;
        Object.assign(this.feedbackCollection[this.editedIndex], this.editedItem);

        await axios
          .patch(url, data, { headers: getTokenHeaders() })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visibleMsg = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      } // create new
      else {
        data.created_by = this.staffLoginId;
        await axios
          .post(URL_Feedback_Admin, data, { headers: getTokenHeaders() })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visibleMsg = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      }

      //this.clearContent();
      this.populatefeedbackCollection();
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }

        //this.dialog = false;
        //this.close();
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    uploadImage(e) {
      const file = e.target.files[0];
      if (file) {
        var obj = this.invalidFileSizeOrFileExtension(file);
        if (obj.isTrue) {
          this.visibleMsg = false;
          this.msgError = obj.msg;
          return;
        }

        this.visibleMsg = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          console.log(this.previewImage);
        };

        let data = new FormData();
        data.append("file", e.target.files[0]);

        axios
          .post(URL_UPLOAD_FEEDBACK_IMAGE, data, {
            headers: getImageTokenHeaders(),
          })
          .then((response) => {
            this.hdImage = response.data.filename;
            console.log("Image has been uploaded.");
          });
      }
    },

    invalidFileSizeOrFileExtension(file) {
      let isTrue = false;
      let msg = null;
      const fileSize = getFileSizeInKB(file);
      const fileExtension = getFileExtension(file);

      if (fileSize >= 500) {
        // 6MB
        isTrue = true;
        msg = "File size is biggest 500KB.";
      }

      if (fileExtension === null) {
        isTrue = true;
        msg = "File extension must be jpg, jpeg, png, bmp";
      }

      return { isTrue, msg };
    },

    async loadJobFromJsonFile() {
      try {
        const response = await fetch("/assets/job.json");
        this.jobs = await response.json();
      } catch (error) {
        console.error("Error loading options:", error);
      }
    },
  },
};
</script>
