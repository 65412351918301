<template>
  <FrontendLayout>
    <div class="bg-grey-lighten-4">
      <h1
        class="text-uppercase text-center text-blue-darken-3 pa-5"
        style="letter-spacing: 5px"
      >
        {{ data.title }}
      </h1>

      <div class="margin-auto">
        <v-row>
          <v-col cols="12" md="8">
            <p class="text-content text-justify font-format pa-5" v-html="data.desc"></p>
          </v-col>

          <v-col cols="12" md="4" sm="4" lg="4" xl="4">
            <div style="height: auto; top: 55px; position: sticky">
              <h2 style="letter-spacing: 2px">Related</h2>
              <hr />
              <CardRelated :events="events" />
            </div>
          </v-col>
        </v-row>

        <br /><br /><br />
      </div>
    </div>
  </FrontendLayout>
</template>
<script>
import FrontendLayout from "@/views/client/components/FrontendLayout.vue";
import CardRelated from "@/views/client/about-us/components/CardRelated.vue";
import { URL_PROJECT, URL_TERM_PRIVACY } from "@/utils/apiUrl";
import axios from "axios";
import { getTokenHeaders } from "@/utils/headerToken";

export default {
  name: "AboutUs",
  components: { FrontendLayout, CardRelated },
  data() {
    return {
      data: [],
      events: [],
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_TERM_PRIVACY, { headers: getTokenHeaders() })
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },

    async fetchProjects() {
      try {
        const response = await axios.get(URL_PROJECT, { headers: getTokenHeaders() });
        //this.events = response.data;
        if (response.data !== null) {
          this.events = response.data
            .filter((project) => project.project_type === "Active")
            .sort(() => 0.5 - Math.random())
            .slice(0, 6);
        }
      } catch (error) {
        console.error("Error fetching projects event:", error);
      }
    },
  },
  created() {
    this.fetchData();
    this.fetchProjects();
  },
};
</script>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
}
</style>
