import { createApp } from 'vue';
import App from './App.vue';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import router from './router';
import store  from './store/customerStore';
import VueCookies from 'vue-cookies';
import GoogleSignInPlugin from 'vue3-google-signin';
import { createPinia } from 'pinia';
import axiosInstance from '@/servicesAxios/axiosInstance';
import { createI18n } from 'vue-i18n';
import { messages } from '@/assets/message';
import './app.css';
import { VDateInput } from 'vuetify/labs/VDateInput';

const vuetify = createVuetify({
  components: {
    ...components,
    VDateInput,
  },
  directives,
});
const app = createApp(App);
let lang = localStorage.getItem('lang');
if (lang == null) {
  localStorage.setItem('lang', 'en');
}
const pania = createPinia();
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'ja',
  messages: messages,
  formatFallbackMessages: true,
});
app.use(i18n);
app.use(pania);
app.use(GoogleSignInPlugin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: ['profile', 'email'],
});
app.config.globalProperties.axios = axiosInstance;
app.use(VueCookies);
app.use(vuetify);
app.use(store);
app.use(router);
// production
// app.use(VueReCaptcha, { siteKey: "6LccbiwqAAAAAOIku8k_AASk_csbrGdaJRBuYwj0" });
// testing
// app.use(VueReCaptcha, { siteKey: "6LcKFBcaAAAAABi8U9SedTJ4yEeoSzdJI9cKXxrG" });
app.mount('#app');
