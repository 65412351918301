import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/admin/dashboard/Dashboard";
import User from "@/views/admin/users/User";
import Setting from "@/views/admin/setting/Setting";
import RequestBox from "@/views/admin/request-box/RequestBox";
import MediaPage from "@/views/admin/medai/MediaPage";
import FinanceContractPage from "@/views/admin/finances/FinanceContract";
import CustomerServicePage from "@/views/admin/customer-service/CustomerService";
import PageNotFound from "@/views/page-error/PageNotFound";
import SomethingWentWrongPage from "@/views/page-error/SomethingWentWrongPage";
import StaffLoginForm from "@/views/admin/auth-staff/StaffLoginForm";
import { useAuthStore } from "@/store/staffAuth";
import StaffPermission from "@/views/admin/users/StaffPermission";
import ViewStaffLogin from "@/views/admin/users/ViewStaffLogin";
import SectorPage from "@/views/admin/sector/SectorPage.vue";
import ExchangeDepositPage from "@/views/admin/finances/ExchangeDeposit";
import ExchangeWithdrawPage from "@/views/admin/finances/ExchangeWithdraw";
import CountryPage from "@/views/admin/setting/CountryPage";
import FeePage from "@/views/admin/finances/FeePage";
import TotalReportPage from "@/views/admin/finances/TotalReportPage";
import SocialMediaPage from "@/views/admin/setting/SocialMediaPage";
import UpdateProfilePage from "@/views/admin/users/UpdateProfile";
import ChangePasswordPage from "@/views/admin/auth-staff/ChangePassword";
import BlockWithdrawPage from "@/views/admin/finances/BlockWithdrawPage";
import BankTypePage from "@/views/admin/bank/BankTypePage";
import BankAccountDepositPage from "@/views/admin/bank/BankAccountDepositPage";
import FeeAccountBallancePage from "@/views/admin/finances/FeeAccountBallancePage";
import CustomerPage from "@/views/admin/customer-service/CustomerPage";
import CustomerLoginPage from "@/views/admin/customer-service/CustomerLoginPage";
import CompensationPage from "@/views/admin/compansation/CompensationPage";
import StarRankReferralPage from "@/views/admin/finances/StarRankReferalPage";
import AddMoneyClaimLevelPage from "@/views/admin/finances/AddMoneyClaimLevelPage";
import CompanyPage from "@/views/admin/setting/CompanyPage";
import ProjectTitlePage from "@/views/admin/project-title/ProjectTitlePage";
import AboutUsPage from "@/views/admin/about-us/AboutUsPage";
import ThreeLevelClaimPage from "@/views/admin/three-level-claim/ThreeLevelClaimPage";
import ProjectBackendPage from "@/views/admin/project/ProjectPage";
import AsiaPacificPage from "@/views/admin/asia-pacific/AsiaPacificPage";
import ProjectEventPage from "@/views/admin/project-event/ProjectEventPage";
import ContactUsPage from "@/views/admin/contact-us/ContactUsPage";
import CurrencyPage from "@/views/admin/currency/CurrencyPage";
import CovidBackEndPage from "@/views/admin/covid19/CovidPage";
import OurImpactBackendPage from "@/views/admin/our-impact/OurImpactPage";
import SmallInvestmentPage from "@/views/admin/small-investment/SmallInvestmentPage";
import TermPrivacyPage from "@/views/admin/term-privacy/TermPrivacyPage";
import PoliticalRiskInsuranceBackendPage from "@/views/admin/political-risk-insurance/PoliticalRiskInsurancePage";
import FeedbackAdminPage from "@/views/admin/feedback-admin/FeedbackAdminPage";
import OurProcessPage from "@/views/admin/our-process/OurProcessPage.vue";
import SubscribePage from "@/views/admin/subscribe/SubscribePage.vue";
import InviteFriendPage from "@/views/admin/invite-friend/InviteFriendPage.vue";
import { clientRouters } from "@/router/client";
import { getClientToken } from "@/utils/function";
import WithdrawPage from "@/views/admin/finances/WithdrawPage.vue";
import ConfirmDepositPage from "@/views/admin/finances/ConfirmDepositPage.vue";

const adminRoutes = [
  {
    path: "/project-title",
    name: "ProjectTitlePage",
    component: ProjectTitlePage,
    meta: { requiredAuth: false },
  },
  {
    path: "/project-backend",
    name: "ProjectBackendPage",
    component: ProjectBackendPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/backend-about-us", // to /asia-pacific
    name: "AboutUsPage",
    component: AboutUsPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/project-event",
    name: "ProjectEventPage",
    component: ProjectEventPage,
    meta: { requiredAuth: false },
  },

  // admin
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiredAuth: true }, //true: mean that if token is not null it will stay on that page, but else it will redirect to login page
  },
  {
    path: "/staff",
    name: "User",
    component: User,
    meta: { requiredAuth: true },
  },
  {
    path: "/request-box",
    name: "RequestBox",
    component: RequestBox,
    meta: { requiredAuth: false },
  },

  {
    path: "/media",
    name: "MediaPage",
    component: MediaPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    meta: { requiredAuth: true },
  },
  {
    path: "/staff-login",
    name: "StaffLoginPage",
    component: StaffLoginForm,
    meta: { requiredAuth: false },
  },
  {
    path: "/staff-permission",
    name: "StaffPermission",
    component: StaffPermission,
    meta: { requiredAuth: true },
  },
  {
    path: "/view-staff-login",
    name: "ViewStaffLogin",
    component: ViewStaffLogin,
    meta: { requiredAuth: true },
  },
  {
    path: "/country",
    name: "CountryPage",
    component: CountryPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/social-media",
    name: "SocialMediaPage",
    component: SocialMediaPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/company",
    name: "CompanyPage",
    component: CompanyPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/asia-pacific", // to /backend-about-us
    name: "AsiaPacificPage",
    component: AsiaPacificPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/backend-contact-us",
    name: "ContactUsPage",
    component: ContactUsPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/currency",
    name: "CurrencyPage",
    component: CurrencyPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/backend-covid",
    name: "CovidBackEndPage",
    component: CovidBackEndPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/impact",
    name: "OurImpactBackendPage",
    component: OurImpactBackendPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/process",
    name: "OurProcessPage",
    component: OurProcessPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/small-investment",
    name: "SmallInvestmentPage",
    component: SmallInvestmentPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/term-privacy",
    name: "TermPrivacyPage",
    component: TermPrivacyPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/political-risk-insurance",
    name: "PoliticalRiskInsuranceBackendPage",
    component: PoliticalRiskInsuranceBackendPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/feedback",
    name: "FeedbackAdminPage",
    component: FeedbackAdminPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/finance-contract",
    name: "FinanceContractPage",
    component: FinanceContractPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/block-withdraw",
    name: "BlockWithdrawPage",
    component: BlockWithdrawPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/exchange-deposit",
    name: "ExchangeDeposit",
    component: ExchangeDepositPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/exchange-withdraw",
    name: "ExchangeWithdrawPage",
    component: ExchangeWithdrawPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/fee",
    name: "FeePage",
    component: FeePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/total-report",
    name: "TotalReportPage",
    component: TotalReportPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/star-rank-referral",
    name: "StarRankReferralPage",
    component: StarRankReferralPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/money-claim-level",
    name: "AddMoneyClaimLevelPage",
    component: AddMoneyClaimLevelPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/three-level-claim",
    name: "ThreeLevelClaimPage",
    component: ThreeLevelClaimPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/customer-confirmation",
    name: "CustomerServicePage",
    component: CustomerServicePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/customer",
    name: "CustomerPage",
    component: CustomerPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/customer-login",
    name: "CustomerLoginPage",
    component: CustomerLoginPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/something-went-wrong",
    name: "SomethingWentWrongPage",
    component: SomethingWentWrongPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/sector",
    name: "Sector",
    component: SectorPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/compensation",
    name: "CompensationPage",
    component: CompensationPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/staff/update/profile",
    name: "UpdateProfilePage",
    component: UpdateProfilePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/change-password",
    name: "ChangePasswordPage",
    component: ChangePasswordPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/bank-type",
    name: "BankTypePage",
    component: BankTypePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/bank-account-deposit",
    name: "BankAccountDeposit",
    component: BankAccountDepositPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/fee-account-ballance",
    name: "FeeAccountBallancePage",
    component: FeeAccountBallancePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/customer-subscribe",
    name: "SubscribePage",
    component: SubscribePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/invite-friend",
    name: "InviteFriendPage",
    component: InviteFriendPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/withdraw",
    name: "WithdrawPage",
    component: WithdrawPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/confirm-deposit",
    name: "ConfirmDepositPage",
    component: ConfirmDepositPage,
    meta: { requiredAuth: true },
  },
];

const errorRoutes = [
  {
    path: "/:pathMatch(.*)*",
    name: "page-not-found",
    component: PageNotFound,
    meta: { requiredAuth: false },
  },
];

const routes = [
  /**
   * Client Routers
   */
  ...clientRouters,
  /**
   * Admin Routers
   */
  ...adminRoutes,
  /**
   * error Routers
   */
  errorRoutes,
];

const router = createRouter({
  history: createWebHistory(), // process.env.BASE_URL
  routes,
});

// Navigation Guards
router.beforeEach((to, from, next) => {
  // protected for client
  const isAuthenticated = getClientToken();
  const authStore = useAuthStore();

  let pathClientRouters = clientRouters.filter(
    (router) => router.path === to.path && !isAuthenticated
  );

  if (pathClientRouters.length > 0 && pathClientRouters[0].meta.requiredAuth) {
    next("/sign-in");
  } else if (
    pathClientRouters.length > 0 &&
    !pathClientRouters[0].meta.requiredAuth &&
    isAuthenticated
  ) {
    next();
  }
  // protected for admin
  if (to.meta.requiredAuth && !authStore.token) {
    return next("/staff-login");
  }
  next();
  // if (to.path !== "/staff-login" && to.meta.requiredAuth && !authStore.token) {
  //   next("/staff-login");
  // } else if (
  //   from.path === "/staff-login" &&
  //   to.meta.requiredAuth &&
  //   authStore.token
  // ) {
  //   next();
  // } else {
  //   next();
  // }
});

export default router;
