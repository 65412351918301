<script>
import FrontendLayout from "@/views/client/components/FrontendLayout.vue";
import CardNews from '@/views/client/covid/components/CardNews.vue';
import { getClientToken, getParams } from '@/utils/function';
import { API_URL_USER, PROJECT } from '@/utils/customerApiUrl';
import axios from 'axios';

export default {
  name: "OurImpactPage",
  components: { FrontendLayout, CardNews },
  data() {
    return {
      exploreMores: {},
      params: {
        projectStatus: '',
        sector: '',
        skip: 1,
        take: 9,
        country: '',
      },
      total: 10,
      totalPages: 1,
    };
  },
  methods: {

    async getLocation() {
      await fetch(API_URL_USER)
        .then((response) => response.json())
        .then((data) => {
          this.params.country = data.country_name ? data.country_name: data.name;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async fetchData() {
      let token = await getClientToken();
      let url = token ? PROJECT : PROJECT + '/by-country';
      let mainAxios = null;
      // for customer login
      if (token) {
        mainAxios = this.axios;
      }
      // for guest
      else {
        mainAxios = axios;
      }

      this.exploreMores = [];
      let params = getParams(this.params);
      mainAxios
        .get(url + params)
        .then((response) => {
          let { data, total , totalPages} = response.data;
          this.exploreMores = data;
          this.totalPages = totalPages;
          this.total = total;
        })
        .catch((error) => {
          console.error('Failed to', error);
        });
    },
  },

  mounted() {
    this.getLocation();
  },

  created() {
    setTimeout(() => {
      this.fetchData();
    }, 1000);
  },

  watch: {
    // filter page
    'params.skip': function() {
      this.fetchData();
    },
  }

};

</script>

<template>
  <FrontendLayout>
    <div class="bg-grey-lighten-4">
      <h3 class="text-uppercase text-center text-blue-darken-3 pa-5">Annual Report</h3>

      <div class="margin-auto py-3">
        <CardNews :exploreMores="exploreMores" />
      </div>

      <div class="app-paginate hide">
        <v-pagination
          v-model="params.skip"
          :length="totalPages"
          class="d-flex align-center"
          rounded="circle"
          color="primary"
          border
          total-visible="10"
        >
          <template #prev>
            <div class="d-flex">
              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none "
                @click="params.skip=1"
                :disabled="1 === totalPages"
                variant="outlined"
                border
                color="primary"
              >
                First
              </VBtn>

              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none "
                @click="params.skip--"
                :disabled="params.skip === 1"
                variant="outlined"
                border
                color="primary"
              >
                Previous
              </VBtn>
            </div>
          </template>
          <template #next>
            <div class="d-flex">
              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none "
                @click="params.skip++"
                :disabled="params.skip === totalPages"
                variant="outlined"
                border
                color="primary"
              >
                Next
              </VBtn>

              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none "
                @click="params.skip = totalPages"
                :disabled="params.skip === totalPages"
                variant="outlined"
                border
                color="primary"
              >
                Last
              </VBtn>
            </div>
          </template>
        </v-pagination>
      </div>


      <div class="mobile">
        <div class="d-flex justify-space-between">

          <VBtn
              width="100"
              rounded="xl"
              class="ma-2 text-none"
              @click="params.skip--"
              :disabled="params.skip === 1"
              variant="outlined"
              border
              color="primary"
          >
            Previous
          </VBtn>

          <VBtn
              width="100"
              rounded="xl"
              class="ma-2 text-none"
              @click="params.skip++"
              :disabled="params.skip === totalPages"
              variant="outlined"
              border
              color="primary"
          >
            Next
          </VBtn>
        </div>
      </div>

      <br /><br /><br />
    </div>
  </FrontendLayout>
</template>

<style scoped>
</style>
