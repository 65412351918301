<template>
  <div>
    <v-card elevation="0" rounded="0">
      <v-row>
        <v-col md="4" cols="12" v-for="(row, index) in list" v-bind:key="index">
          <v-card
            elevation="3"
            rounded="0"
            class="px-5 py-1"
            :color="colors[index]"
            variant="outlined"
          >
            <div class="d-flex justify-lg-space-between">
              <v-icon size="70">
                {{ row.icon }}
              </v-icon>

              <v-card-item>
                <div class="text-grey font-weight-bold">
                  {{ row.title }}
                </div>
                <div class="mt-2"></div>
                <v-card-title class="font-weight-bold">
                  {{ row.total }}
                </v-card-title>
              </v-card-item>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { PORTFOLIOS } from "@/utils/customerApiUrl";
import { formatPrice } from "@/utils/function";

export default {
  name: "BoardCard",
  data() {
    return {
      list: [
        {
          icon: "mdi-currency-usd",
          title: "Total Value",
          total: "$ 0",
        },
        {
          icon: "mdi-chart-areaspline",
          title: "Total Assets",
          total: "$ 0",
        },
        {
          icon: "mdi-cash-refund",
          title: "Total Withdrawal",
          total: "$ 0",
        },
        {
          icon: "mdi-package-variant-closed",
          title: "Total Profits",
          total: "$ 0",
        },
        {
          icon: "mdi-currency-usd",
          title: "Referral Rewards",
          total: "0",
        },
        {
          icon: "mdi-currency-usd",
          title: "Fund Transfer",
          total: "0",
        },
        {
          icon: "mdi-sigma",
          title: "Total Shares",
          total: "0",
        },
        {
          icon: "mdi-crowd",
          title: "Crowdfunding",
          total: "0",
        },
        {
          icon: "mdi-percent-circle-outline",
          title: "Portfolio Risk",
          total: "0 %",
        },
      ],
      colors: [
        "indigo",
        "blue",
        "red",
        "orange",
        "green",
        "light-blue",
        "pink",
        "cyan-darken-1",
        "yellow",
      ],
      loading: false,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.axios
        .get(PORTFOLIOS)
        .then((response) => {
          let {
            totalInvestmentAmount,
            totalDepositAmount,
            totalWithdrawAmount,
            totalInterest,
            totalReferralsRewardsAmount,
            totalTransferAmount,
            allSharesHavePurchased,
            allSharesCanResell,
            riskRatesOnInvestment,
          } = response.data;
          this.list[0].total = formatPrice(totalInvestmentAmount);
          this.list[1].total = formatPrice(totalDepositAmount);
          this.list[2].total = formatPrice(totalWithdrawAmount);

          this.list[3].total = formatPrice(totalInterest);
          this.list[4].total = formatPrice(totalReferralsRewardsAmount);
          this.list[5].total = formatPrice(totalTransferAmount);

          this.list[6].total = formatPrice(allSharesHavePurchased);
          this.list[7].total = formatPrice(allSharesCanResell);
          this.list[8].total = formatPrice(riskRatesOnInvestment);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style lang=""></style>
