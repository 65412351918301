<script>
import { formatPriceWithComma } from "@/utils/function";
import { REFERRAL } from "@/utils/customerApiUrl";
import ErrorDialog from "@/views/client/components/dialog/ErrorDialog.vue";
import SuccessModel from "@/views/client/components/dialog/SuccessModel.vue";
import { mapState } from "vuex";

export default {
  name: "InvitationRewards",
  components: { SuccessModel, ErrorDialog },
  props: ["moneyClaimLevel", "callBack"],
  computed: {
    ...mapState(["profile"]),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      formatPrice: formatPriceWithComma,

      // modal message
      isErrorDialog: false,
      isErrorMessage: "",
      // modal success
      isSuccessDialog: false,
      isSuccessMessage: "",
    };
  },
  methods: {
    onClaim(row, nameLevel) {
      this.dialog = true;
      this.row = row;
      this.nameLevel = nameLevel;
    },
    async handleConfirm() {
      let data = {
        rankStar: parseFloat(this.row.rankStar),
        numberStar: parseFloat(this.row.rankStar),
        numberReferralAll: parseFloat(this.row.available),
        amountReward: parseFloat(this.row.amountReward),
        nameLevel: this.nameLevel,
      };
      // console.log(this.row);
      // console.log(data);
      // console.log(REFERRAL);
      this.loading = true;
      this.axios
        .post(REFERRAL, data)
        .then((response) => {
          let { data } = response;
          if (data) {
            this.isSuccessMessage = response.data.message;
            this.isSuccessDialog = true;
          }
          this.loading = false;
          this.dialog = false;
          this.callBack();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.isErrorMessage = message;
          if (Array.isArray(message)) {
            this.isErrorMessage = message[0];
          }
          this.isErrorDialog = true;
          this.loading = false;
          this.dialog = false;
        });
    },

    onCloseModalError(dialog) {
      this.isErrorDialog = dialog;
    },

    onCloseModalSuccess(dialog) {
      this.isSuccessDialog = dialog;
    },

    //
    addCustomer(level) {
      let data = {
        referral: this.profile.myReferral,
        level: level.replace("Referrals", "").trim(),
      };
      this.axios
        .post(REFERRAL + "/add-customer", data)
        .then((response) => {
          let { data } = response;
          if (data) {
            this.isSuccessMessage = response.data.message;
            this.isSuccessDialog = true;
          }
          this.loading = false;
          this.callBack();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.isErrorMessage = message;
          if (Array.isArray(message)) {
            this.isErrorMessage = message[0];
          }
          this.isErrorDialog = true;
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      v-for="row in moneyClaimLevel"
      v-bind:key="row.nameLevel"
    >
      <v-card rounded="xl" height="" class="pa-3">
        <!--        <pre>{{ row }}</pre>-->
        <v-btn
          @click="addCustomer(row.nameLevel)"
          color="green"
          icon="mdi-account-plus"
          density="comfortable"
        />

        <h4 class="my-2">
          {{ row.nameLevel }} Referrals
          {{ row.available }}
        </h4>

        <v-row class="">
          <!-- 1-->
          <v-col cols="4" md="2">
            <p class="text-right text-grey text-small">
              {{ row.numberPerson1 }} Refs
            </p>

            <p class="text-small">$ {{ formatPrice(row.moneyClaim1) }}</p>

            <v-btn
              class="my-2 text-white text-none"
              elevation="1"
              rounded="xl"
              size="x-small"
              :color="row.claim1 ? 'grey' : 'orange'"
              block
              @click="
                onClaim(
                  {
                    rankStar: row.numberPerson1,
                    amountReward: row.moneyClaim1,
                    available: row.available,
                  },
                  row.nameLevel
                )
              "
              :disabled="row.claim1"
              text="Claim"
            />
          </v-col>
          <!-- 1-->

          <!-- 2-->
          <v-col cols="4" md="2">
            <p class="text-right text-grey text-small">
              {{ row.numberPerson2 }} Refs
            </p>

            <p class="text-small">$ {{ formatPrice(row.moneyClaim2) }}</p>

            <v-btn
              class="my-2 text-white text-none"
              elevation="1"
              rounded="xl"
              size="x-small"
              :color="row.claim2 ? 'grey' : 'orange'"
              block
              @click="
                onClaim(
                  {
                    rankStar: row.numberPerson2,
                    amountReward: row.moneyClaim2,
                    available: row.available,
                  },
                  row.nameLevel
                )
              "
              :disabled="row.claim2"
              text="Claim"
            />
          </v-col>
          <!-- 2-->

          <!-- 3-->
          <v-col cols="4" md="2">
            <p class="text-right text-grey text-small">
              {{ row.numberPerson3 }} Refs
            </p>

            <p class="text-small">$ {{ formatPrice(row.moneyClaim3) }}</p>

            <v-btn
              class="my-2 text-white text-none"
              elevation="1"
              rounded="xl"
              size="x-small"
              :color="row.claim3 ? 'grey' : 'orange'"
              block
              @click="
                onClaim(
                  {
                    rankStar: row.numberPerson3,
                    amountReward: row.moneyClaim3,
                    available: row.available,
                  },
                  row.nameLevel
                )
              "
              :disabled="row.claim3"
              text="Claim"
            />
          </v-col>
          <!-- 3-->

          <!-- 4-->
          <v-col cols="4" md="2">
            <p class="text-right text-grey text-small">
              {{ row.numberPerson4 }} Refs
            </p>

            <p class="text-small">$ {{ formatPrice(row.moneyClaim4) }}</p>

            <v-btn
              class="my-2 text-white text-none"
              elevation="1"
              rounded="xl"
              size="x-small"
              :color="row.claim4 ? 'grey' : 'orange'"
              block
              @click="
                onClaim(
                  {
                    rankStar: row.numberPerson4,
                    amountReward: row.moneyClaim4,
                    available: row.available,
                  },
                  row.nameLevel
                )
              "
              :disabled="row.claim4"
              text="Claim"
            />
          </v-col>
          <!-- 4-->

          <!-- 5-->
          <v-col cols="4" md="2">
            <p class="text-right text-grey text-small">
              {{ row.numberPerson5 }} Refs
            </p>

            <p class="text-small">$ {{ formatPrice(row.moneyClaim5) }}</p>

            <v-btn
              class="my-2 text-white text-none"
              elevation="1"
              rounded="xl"
              size="x-small"
              :color="row.claim5 ? 'grey' : 'orange'"
              block
              @click="
                onClaim(
                  {
                    rankStar: row.numberPerson5,
                    amountReward: row.moneyClaim5,
                    available: row.available,
                  },
                  row.nameLevel
                )
              "
              :disabled="row.claim5"
              text="Claim"
            />
          </v-col>
          <!-- 5-->

          <!-- 6-->
          <v-col cols="4" md="2">
            <p class="text-right text-grey text-small">
              {{ row.numberPerson6 }} Refs
            </p>

            <p class="text-small">$ {{ formatPrice(row.moneyClaim6) }}</p>

            <v-btn
              class="my-2 text-white text-none"
              elevation="1"
              rounded="xl"
              size="x-small"
              :color="row.claim6 ? 'grey' : 'orange'"
              block
              @click="
                onClaim(
                  {
                    rankStar: row.numberPerson6,
                    amountReward: row.moneyClaim6,
                    available: row.available,
                  },
                  row.nameLevel
                )
              "
              :disabled="row.claim6"
              text="Claim"
            />
          </v-col>
          <!-- 6-->
        </v-row>
      </v-card>
    </v-col>
  </v-row>

  <div class="text-center pa-4">
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card min-width="400" max-width="600" rounded="xl">
        <v-card-title>
          <p>
            <v-icon>mdi-gift-outline</v-icon>
            Confirmation !
          </p>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <p class="text-center">
            Are you sure you want to claim ?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            width="50%"
            class="ms-auto text-none"
            text="Cancel"
            @click="dialog = false"
          ></v-btn>

          <v-btn
            width="50%"
            class="ms-auto text-none"
            text="OK"
            @click="handleConfirm()"
            :loading="loading"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <template v-if="isErrorDialog">
    <ErrorDialog
      :dialog="isErrorDialog"
      :message="isErrorMessage"
      :onCloseModal="onCloseModalError"
    />
  </template>

  <template v-if="isSuccessDialog">
    <SuccessModel
      :dialog="isSuccessDialog"
      :message="isSuccessMessage"
      :onCloseModal="onCloseModalSuccess"
    />
  </template>
</template>

<style scoped>
.text-small {
  font-size: 12px;
}
</style>
