<template>
  <v-card class="text-start" rounded="md">
    <v-card-title class="bg-primary py-4">
     <v-icon>mdi-account</v-icon>  Contact Information
    </v-card-title>
    <v-row>
      <v-col cols="12" md="4">
        <div class="ma-4">
          <v-avatar color="grey" rounded="xl" size="320">
            <v-img :src="previewImage" cover/>
          </v-avatar>
        </div>
      </v-col>

      <v-col cols="12" md="8" >
        <br />
        <div>
          <p class="">
            First Name : {{ profile.firstName ? profile.firstName : "John" }}
          </p>
          <v-divider class="mt-1"/>
        </div>
        <br />
        <div>
          <p>Last Name : {{ profile.lastName ? profile.lastName : "Smith" }}</p>
          <v-divider class="mt-1"/>
        </div>
        <br />
        <div>
          <p>Email : {{ profile.email ? profile.email : "example@gmail.com " }}</p>
          <v-divider class="mt-1"/>
        </div>
        <br />
        <p>Phone No: {{ profile.phone ? profile.phone : "" }}</p>
        <v-divider class="mt-1"/>
        <br />
        <p style="display: ruby">
          Social Media :
          <v-btn
            @click="share('facebook', socialMedia.facebookLink)"
            icon="mdi-facebook"
            class="mx-2"
            variant="text"
            size="small"
          ></v-btn>
          <v-btn
            @click="share('telegram', socialMedia.telegramLink)"
            icon="mdi-send-circle"
            class="mx-2"
            variant="text"
            size="small"
          ></v-btn>
          <v-btn
            @click="share('twitter', socialMedia.twitterLink)"
            icon="mdi-twitter"
            class="mx-2"
            variant="text"
            size="small"
          ></v-btn>
          <v-btn
            @click="share('whatsapp', socialMedia.whatsApp)"
            icon="mdi-whatsapp"
            class="mx-2"
            variant="text"
            size="small"
          ></v-btn>
        </p>
        <br />
        <p>
          Invitation Link :
          <v-btn
            @click="copyCode(profile.myReferral ? profile.myReferral : '')"
            icon="mdi-content-copy"
            class="mx-2"
            variant="text"
            size="small"
          >
          </v-btn>
          <span class="text-blue" style="font-size: 11px"
            >{{
              getLocation() +
              "/sign-up?code=" +
              (profile.myReferral ? profile.myReferral : "")
            }}
          </span>
        </p>

        <p>
          Invitation Code :
          <v-btn
            @click="copyLink(profile.myReferral ? profile.myReferral : '')"
            icon="mdi-content-copy"
            class="mx-2"
            variant="text"
            size="small"
          >
          </v-btn>
          <span class="text-blue" style="font-size: 11px"
            >{{ profile.myReferral ? profile.myReferral : "" }}
          </span>
        </p>
        <br />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { UPLOAD_PHOTO_PATH } from "@/utils/customerApiUrl";
import { mapState } from "vuex";

export default {
  name: "ProfileInfo",
  computed: {
    ...mapState(["profile", "socialMedia"]),
  },
  data: () => ({
    previewImage: null,
  }),
  methods: {
    copyCode(text) {
      navigator.clipboard.writeText(location.origin + "/sign-up?code=" + text);
    },
    copyLink(link) {
      if (link != null) {
        navigator.clipboard.writeText(link);
      }
    },
    getLocation() {
      return location.origin;
    },
    share(platform, link) {
      let shareUrl;
      switch (platform) {
        case "telegram":
          shareUrl = `${link}`;
          break;
        case "facebook":
          shareUrl = `${link}`;
          break;
        case "twitter":
          shareUrl = `${link}`;
          break;
        case "whatsapp":
          shareUrl = `${link}`;
          break;
        default:
          return;
      }

      window.open(shareUrl, "_blank");
    },
  },
  created() {
    setTimeout(() => {
      this.previewImage = this.profile.profile
        ? UPLOAD_PHOTO_PATH + "" + this.profile.profile
        : "https://t4.ftcdn.net/jpg/02/83/72/41/360_F_283724163_kIWm6DfeFN0zhm8Pc0xelROcxxbAiEFI.jpg";
    }, 500);
  },
};
</script>

<style scoped></style>
