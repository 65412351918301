<template>
  <v-row>
    <v-col
      cols="12"
      md="3"
      v-for="(row, index) in mainRewards"
      v-bind:key="row.title"
      class="pa-0"
    >
      <v-card
        height="170px"
        class="pa-2 text-center align-content-center"
        elevation="0"
        style="margin: 1px"
      >
        <h4>
          {{ row.title }}
        </h4>
        <h2 :class="index === 0 ? 'text-blue' : 'text-red'">
          <span v-if="index === 1">$</span>
          {{ row.total }}
        </h2>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="row in rewards"
          v-bind:key="row.title"
          class="pa-0"
        >
          <v-card
            height="85px"
            class="pa-2 text-center align-content-center"
            elevation="0"
            style="margin: 1px"
          >
            <h5>
              {{ row.nameLevel }}
            </h5>
            <h4 class="text-red">$ {{ formatPrice(row._sum.amountReward) }}</h4>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <br />
  <h4 class="text-uppercase text-grey">Invitation Rewards</h4>
  <br />
  <InvitationRewards :moneyClaimLevel="moneyClaimLevel" :callBack="callBack" />
  <br />

  <!--  ListRewards-->
  <v-row>
    <v-col cols="12" md="6">
      <div class="d-flex justify-start">
        <span class="ma-2">Show</span>

        <div>
          <v-select
            menu-icon=""
            v-model="params.take"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['10', '25', '50', '100', '500', '1000']"
            class="elevation-0"
            color="blue"
          ></v-select>
        </div>

        <span class="ma-2">Entries</span>
      </div>
    </v-col>

    <v-col cols="12" md="3">
      <v-autocomplete
        placeholder="All level"
        v-model="params.remark"
        :items="['All', 'ordinary', 'Star 1', 'Star 2', 'Star 3', 'Star 4', 'Star 5']"
        density="comfortable"
        rounded="xl"
        variant="outlined"
        class="elevation-0"
        color="blue"
      >
      </v-autocomplete>
    </v-col>

    <v-col cols="12" md="3">
      <div>
        <v-date-input
          prepend-icon=""
          v-model="dateRange"
          density="comfortable"
          rounded="xl"
          variant="outlined"
          class="elevation-0"
          append-inner-icon="mdi-calendar-range"
          placeholder="YYYY/MM/DD - YYYY/MM/DD"
          color="blue"
          multiple="range"
        ></v-date-input>
      </div>
    </v-col>
  </v-row>

  <v-card rounded="0">
    <v-data-table density="comfortable" hide-default-footer :loading="loading">
      <v-card elevation="0" rounded="0" class="pa-5" v-if="data.length < 1">
        No record available.
      </v-card>

      <thead>
        <tr class="bg-primary">
          <th v-for="row in headers" v-bind:key="row.key" class="row-none-wrap">
            {{ row.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(level, index) in data" v-bind:key="index" class="row-none-wrap">
          <!-- <td class="td text-centers">0000{{ index + 1 }}</td> -->
          <td class="td">
            {{ formatDate(level.createdAt) }} {{ formatDate(level.createdAt, "HH:mm A") }}
          </td>
          <td class="td text-centers">
            {{ level.nameLevel }}
          </td>
          <td class="td text-ends">{{ formatPrice(level.numberReferralAll) }} Ref</td>
          <td class="td text">$ {{ formatPrice(level.amountReward) }}</td>
        </tr>
      </tbody>
    </v-data-table>
  </v-card>

  <div class="app-paginate mt-5">
    <v-pagination
      v-model="params.skip"
      :length="totalPages"
      class="d-flex align-center"
      rounded="circle"
      color="primary"
      border
      total-visible="10"
    >
      <template #prev>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip = 1"
            :disabled="1 === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            First
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip--"
            :disabled="params.skip === 1"
            variant="outlined"
            border
            color="primary"
          >
            Previous
          </VBtn>
        </div>
      </template>
      <template #next>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip++"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Next
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip = totalPages"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Last
          </VBtn>
        </div>
      </template>
    </v-pagination>
  </div>
  <!--  ListRewards-->
</template>

<script>
import { formatDate, getParams, formatPriceWithComma } from "@/utils/function";
import { REFERRAL } from "@/utils/customerApiUrl";
import InvitationRewards from "@/views/client/profile/referral-rewards/rewards/InvitationRewards.vue";
import moment from "moment";
import { mapState } from "vuex";
import { createRewardsForClaim } from "@/views/client/profile/referral-rewards/rewards/rewardsHelper";

let headers = [
  // { align: 'start', key: 'no', title: 'No.' },
  { align: "start", key: "date", title: "Date" },
  { key: "referral_level", title: "Referral Level" },
  { key: "referrals", title: "Referrals" },
  { key: "rewards", title: "Rewards" },
];

let rewards = [
  {
    nameLevel: "Ordinary Rewards",
    _sum: {
      amountReward: "0",
    },
  },
  {
    nameLevel: "1 Star Rewards",
    _sum: {
      amountReward: "0",
    },
  },
  {
    nameLevel: "2 Star Rewards",
    _sum: {
      amountReward: "0",
    },
  },
  {
    nameLevel: "3 Star Rewards",
    _sum: {
      amountReward: "0",
    },
  },
  {
    nameLevel: "4 Star Rewards",
    _sum: {
      amountReward: "0",
    },
  },
  {
    nameLevel: "5 Star Rewards",
    _sum: {
      amountReward: "0",
    },
  },
];

export default {
  name: "MyRewardsTab",
  components: {
    InvitationRewards,
  },
  computed: {
    ...mapState(["profile"]),
  },
  data() {
    return {
      //
      formatDate: formatDate,
      formatPrice: formatPriceWithComma,
      //
      mainRewards: [
        {
          title: " Successful Referrals",
          total: "0",
        },
        {
          title: " Total Rewards",
          total: "0",
        },
      ],
      //
      rewards: rewards,
      //
      row: {},
      nameLevel: "Ordinary Rewards",
      params: {
        take: 10,
        skip: 1,
        remark: null,
        startDate: "",
        endDate: "",
      },
      data: [],
      total: 0,
      totalPages: 0,
      referralClaims: 0,
      moneyClaimLevel: [],
      customerReferralReward: [],
      countCustomerReferrals: 0,
      loading: false,
      //
      dateRange: null,
      //
      headers: headers,
    };
  },
  methods: {
    async fetchData() {
      let params = getParams(this.params);
      this.loading = true;
      this.axios
        .get(REFERRAL + params)
        .then((response) => {
          let {
            data,
            totalPages,
            total,
            referralClaims,
            moneyClaimLevel,
            customerReferralReward,
            countCustomerReferrals,
            collectionReferralByLevel,
          } = response.data;
          ///console.log(response.data.data);
          this.loading = false;
          this.data = data;
          this.total = total;
          this.totalPages = totalPages;
          //
          this.referralClaims = referralClaims;
          //this.moneyClaimLevel = this.verifyListForClaim(moneyClaimLevel, customerReferralReward, countCustomerReferrals);
          this.customerReferralReward = customerReferralReward;
          this.countCustomerReferrals = countCustomerReferrals;
          //
          let successReferral = 0;
          let totalReward = 0;
          //
          let ordinary = 0;
          let star1 = 0;
          let star2 = 0;
          let star3 = 0;
          let star4 = 0;
          let star5 = 0;
          customerReferralReward.forEach((row) => {
            successReferral += row.numberReferralAll;
            totalReward += row.amountReward;
            //
            if (row.nameLevel.toLowerCase().includes("ordinary")) {
              ordinary += row.amountReward;
            }
            //
            else if (row.nameLevel.toLowerCase().includes("1 star")) {
              star1 += row.amountReward;
            }
            //
            else if (row.nameLevel.toLowerCase().includes("2 star")) {
              star2 += row.amountReward;
            }
            //
            else if (row.nameLevel.toLowerCase().includes("3 star")) {
              star3 += row.amountReward;
            }
            //
            else if (row.nameLevel.toLowerCase().includes("4 star")) {
              star4 += row.amountReward;
            }
            //
            else if (row.nameLevel.toLowerCase().includes("5 star")) {
              star5 += row.amountReward;
            }
          });
          this.rewards[0]._sum.amountReward = ordinary;
          this.rewards[1]._sum.amountReward = star1;
          this.rewards[2]._sum.amountReward = star2;
          this.rewards[3]._sum.amountReward = star3;
          this.rewards[4]._sum.amountReward = star4;
          this.rewards[5]._sum.amountReward = star5;
          //
          this.mainRewards[0].total = successReferral;
          this.mainRewards[1].total = totalReward;
          //
          this.moneyClaimLevel = createRewardsForClaim(
            moneyClaimLevel,
            customerReferralReward,
            collectionReferralByLevel
          );
        })
        .catch((error) => {
          this.loading = false;
          console.error("Failed to", error);
        });
    },

    callBack() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    // filter page
    "params.skip": function () {
      this.fetchData();
    },
    "params.take": function () {
      this.fetchData();
    },
    "params.remark": function (remark) {
      if (remark === "All") {
        this.params.remark = "";
      }
      this.fetchData();
    },
    dateRange: function (dateRange) {
      if (dateRange && dateRange.length > 0) {
        let startDate = dateRange[0];
        let endDate = dateRange[dateRange.length - 1];
        this.params.startDate = moment(startDate).format("YYYY-MM-DD");
        this.params.endDate = moment(endDate).format("YYYY-MM-DD");
        this.fetchData();
      }
    },
  },
};
</script>

<style scoped></style>
