<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search project event..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="projectEvents"
      :items-per-page="perPage"
      :server-items-length="totalCount"
      :loading="loading"
      :sort-by="[
        { key: 'createdAt', order: 'desc' },
        { key: 'sectorId', order: 'asc' },
        { key: 'filterType', order: 'asc' },
      ]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center mb-4">
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="perPage"
                    :items="[10, 25, 50, 100, 1000, 10000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="perPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visibleMsg"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="9" md="9" lg="9" xl="9">
                    <v-row>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-select
                          v-model="sectorId"
                          :items="sectors"
                          :rules="[() => !!sectorId || 'This field is required']"
                          :item-title="'name'"
                          :item-value="'id'"
                          label="Sector"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-filter-variant"
                          required
                          persistent-placeholder
                          placeholder="Choose sector..."
                          return-object
                          @update:modelValue="handleSectorChange"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <input type="hidden" v-model="hiddenId" />
                        <v-select
                          v-model="filterType"
                          :items="itemFilterTypes"
                          :rules="[() => !!filterType || 'This field is required']"
                          label="Filter Type"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-format-list-bulleted-type"
                          required
                          persistent-placeholder
                          placeholder="-- Choose --"
                          return-object
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-text-field
                          v-model.trim="linkYoutube"
                          label="Link Youtube"
                          placeholder="Link Youtube"
                          variant="outlined"
                          prepend-inner-icon="mdi-youtube"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-select
                          v-model="slide"
                          :items="slideItems"
                          label="Slide"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-format-list-bulleted-type"
                          required
                          persistent-placeholder
                          placeholder="-- Choose --"
                          return-object
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-label class="font-weight-thin x-small">Description 1</v-label>
                        <QuillEditor
                          toolbar="essential"
                          contentType="html"
                          style="height: 150px"
                          v-model:content="desc1"
                          theme="snow"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-label class="font-weight-thin x-small">Description 2</v-label>
                        <QuillEditor
                          toolbar="essential"
                          contentType="html"
                          style="height: 150px"
                          v-model:content="desc2"
                          theme="snow"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-label class="font-weight-thin x-small">Description 3</v-label>
                        <QuillEditor
                          toolbar="essential"
                          contentType="html"
                          style="height: 150px"
                          v-model:content="desc3"
                          theme="snow"
                        />
                      </v-col>

                      <input type="hidden" v-model="hdimage" />
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-row>
                      <v-col cols="12">
                        <v-card
                          variant="variant"
                          subtitle="Map Image"
                          class="mx-auto"
                          elevation="16"
                        >
                          <v-img
                            :src="
                              previewMapImage === null
                                ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                : previewMapImage
                            "
                            aspect-ratio="1"
                            color="surface-variant"
                            height="380"
                            contain
                            @click="triggerFileInputMap"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                              >
                                <v-progress-circular
                                  color="grey-lighten-5"
                                  indeterminate
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>

                        <v-file-input
                          v-model.trim="map"
                          accept="image/png, image/jpeg, image/bmp"
                          label="Avatar"
                          placeholder="Pick an avatar"
                          prepend-icon="mdi-camera"
                          @change="uploadMapImage"
                          hide-input
                          class="mt-2"
                          ref="fileInputMap"
                          style="display: none"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <v-card
                          variant="variant"
                          subtitle="Image"
                          class="mx-auto"
                          elevation="16"
                        >
                          <v-img
                            :src="
                              previewImage === null
                                ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                : previewImage
                            "
                            aspect-ratio="1"
                            color="surface-variant"
                            height="380"
                            contain
                            @click="triggerFileInput"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                              >
                                <v-progress-circular
                                  color="grey-lighten-5"
                                  indeterminate
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>

                        <v-file-input
                          v-model.trim="image"
                          accept="image/png, image/jpeg, image/bmp"
                          label="Avatar"
                          placeholder="Pick an avatar"
                          prepend-icon="mdi-camera"
                          @change="uploadImage"
                          hide-input
                          class="mt-2"
                          ref="fileInput"
                          style="display: none"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.image`]="{ item }">
        <div v-if="item.image" class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img alt="Avatar" :src="path + item.image" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.map`]="{ item }">
        <div v-if="item.map" class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img alt="Avatar" :src="path + item.map" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.desc1`]="{ item }">
        <div v-html="truncateDescription(item.desc1)"></div>
      </template>
      <template v-slot:[`item.desc2`]="{ item }">
        <div v-html="truncateDescription(item.desc2)"></div>
      </template>
      <template v-slot:[`item.linkYoutube`]="{ item }">
        <a :href="item.linkYoutube" target="_blank">{{ item.linkYoutube }}</a>
      </template>
      <template v-slot:[`item.desc3`]="{ item }">
        <div v-html="truncateDescription(item.desc3)"></div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:[`item.staff.fullName`]="{ item }">
        {{ item.staff.fullName }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-2" size="x-small" color="red-lighten-2" @click="editItem(item)">
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
              <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
            @update:model-value="populateprojectEvents"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_Project_Event_Image,
  URL_FETCH_PRO_EVENT_WITH_PAGINATION,
  URL_PROJECT_EVENT,
  URL_SECTOR,
  URL_UPLOAD_PROJECT_EVENT_IMAGE,
} from "@/utils/apiUrl";
import { getImageTokenHeaders, getTokenHeaders } from "@/utils/headerToken";
import { originalCode } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";
import { getFileExtension, getFileSizeInKB } from "@/utils/reusableFunctions";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    path: Path_Project_Event_Image,
    loading: false, // Loading state
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: false,
    visibleMsg: true,
    isSubmitting: false,

    items: [],
    projectEvents: [],
    sectors: [],
    itemFilterTypes: ["Event"],
    slideItems: ["Video", "Slide", "News", "Event", "Story"],

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,

    sectorId: null,
    filterType: null,
    map: null,
    image: null,
    desc1: null,
    linkYoutube: null,
    desc2: null,
    slide: null,
    desc3: null,

    previewMapImage: null,
    previewImage: null,
    hdimage: null,
    hdMapImage: null,

    editedIndex: -1,
    hiddenId: 0,

    //pagination
    page: 1,
    perPage: 10,
    pageCount: 0,
    totalCount: 0, // Total records count

    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Map Image", key: "map", align: "center" },
      { title: "Image", key: "image", align: "center" },
      { title: "Sector", key: "sector.name", align: "center" },
      { title: "Filter Type", key: "filterType", align: "center" },
      { title: "Link Youtube", key: "linkYoutube", align: "center" },
      { title: "Slide", key: "slide", align: "center" },
      { title: "Desc 1", key: "desc1", align: "center" },
      { title: "Desc 2", key: "desc2", align: "center" },
      { title: "Desc 3", key: "desc3", align: "center" },
      { title: "Create at", key: "createdAt", align: "center" },
      { title: "Created by", key: "staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Project Event" : "Update Project Event";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page() {
      this.populateprojectEvents();
    },
    perPage() {
      this.populateprojectEvents();
    },
    search() {
      this.populateprojectEvents();
    },
  },

  created() {
    this.PopulateSectors();
    this.populateprojectEvents();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.sectorId = null;
      this.filterType = null;
      this.desc1 = null;
      this.linkYoutube = null;
      this.desc2 = null;
      this.slide = null;
      this.desc3 = null;
      this.previewMapImage = null;
      this.previewImage = null;
      this.visibleMsg = true;
    },

    async PopulateSectors() {
      await axios.get(URL_SECTOR, { headers: getTokenHeaders() }).then((response) => {
        response.data.forEach((sector) => {
          this.sectors.push({
            id: sector.id,
            name: sector.name,
          });
        });
      });
    },

    async populateprojectEvents() {
      const params = {
        page: this.page,
        perPage: this.perPage,
        textSearch: this.search ? this.search : null,
      };

      await axios
        .get(URL_FETCH_PRO_EVENT_WITH_PAGINATION, {
          params,
          headers: getTokenHeaders(),
        })
        .then((response) => {
          if (response.data && response.data.projectEventsCollection) {
            this.projectEvents = response.data.projectEventsCollection;
            this.totalCount = response.data.totalCount;
            this.pageCount = response.data.pageCount;
            this.items.push(this.projectEvents);
            this.visible = this.projectEvents.length !== 0 ? true : false;
          } else {
            console.warn("Empty API Response");
            this.projectEvents = [];
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.projectEvents.indexOf(item);
      this.hiddenId = item.id;
      this.editedItem = Object.assign({}, item);
      this.sectorId = item.sectorId;
      this.filterType = item.filterType;
      this.desc1 = item.desc1;
      this.linkYoutube = item.linkYoutube;
      this.desc2 = item.desc2;
      this.slide = item.slide;
      this.desc3 = item.desc3;

      this.previewImage =
        item.image === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.image;
      this.hdimage = item.image;

      this.previewMapImage =
        item.map === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.map;
      this.hdMapImage = item.map;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.projectEvents.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.projectEvents.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_PROJECT_EVENT + id;
      axios
        .delete(url, { headers: getTokenHeaders() })
        .then(function () {
          this.snakeMessage("error", "Data has been deleted.");
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async submitData() {
      const data = {
        sectorId: this.sectorId,
        filterType: this.filterType,
        desc1: this.desc1,
        linkYoutube: this.linkYoutube,
        desc2: this.desc2,
        slide: this.slide,
        desc3: this.desc3,
        map: this.hdMapImage,
        image: this.hdimage,
      };

      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_PROJECT_EVENT + id;
        Object.assign(this.projectEvents[this.editedIndex], this.editedItem);

        await axios
          .patch(url, data, { headers: getTokenHeaders() })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visibleMsg = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      } // create new
      else {
        data.createdBy = this.staffLoginId;
        await axios
          .post(URL_PROJECT_EVENT, data, { headers: getTokenHeaders() })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visibleMsg = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      }

      //this.clearContent();
      this.populateprojectEvents();
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }

        //this.dialog = false;
        //this.close();
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    triggerFileInputMap() {
      this.$refs.fileInputMap.click();
    },

    uploadImage(e) {
      const file = e.target.files[0];
      if (file) {
        var obj = this.invalidFileSizeOrFileExtension(file);
        if (obj.isTrue) {
          this.visibleMsg = false;
          this.msgError = obj.msg;
          return;
        }

        this.visibleMsg = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          console.log(this.previewImage);
        };

        let data = new FormData();
        data.append("file", e.target.files[0]);

        axios
          .post(URL_UPLOAD_PROJECT_EVENT_IMAGE, data, {
            headers: getImageTokenHeaders(),
          })
          .then((response) => {
            this.hdimage = response.data.filename;
            console.log("Image has been uploaded.");
          });
      }
    },

    uploadMapImage(e) {
      const file = e.target.files[0];
      if (file) {
        var obj = this.invalidFileSizeOrFileExtension(file);
        if (obj.isTrue) {
          this.visibleMsg = false;
          this.msgError = obj.msg;
          return;
        }

        this.visibleMsg = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.previewMapImage = e.target.result;
          console.log(this.previewMapImage);
        };

        let data = new FormData();
        data.append("file", e.target.files[0]);

        axios
          .post(URL_UPLOAD_PROJECT_EVENT_IMAGE, data, {
            headers: getImageTokenHeaders(),
          })
          .then((response) => {
            this.hdMapImage = response.data.filename;
            console.log("Image has been uploaded.");
          });
      }
    },

    invalidFileSizeOrFileExtension(file) {
      let isTrue = false;
      let msg = null;
      const fileSize = getFileSizeInKB(file);
      const fileExtension = getFileExtension(file);

      if (fileSize >= 500) {
        // 500Kb
        isTrue = true;
        msg = "File size is biggest 500KB.";
      }

      if (fileExtension === null) {
        isTrue = true;
        msg = "File extension must be jpg, jpeg, png, bmp";
      }

      return { isTrue, msg };
    },

    handleSectorChange(newValue) {
      if (newValue) {
        this.sectorId = newValue.id; // Update old ID with new selection
      }
    },

    truncateDescription(desc) {
      if (desc) {
        return desc.length > 100 ? desc.substring(0, 100) + "..." : desc;
      }
    },
  },
};
</script>
