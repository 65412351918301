<template>
  <div class="overflow-y-auto stepper-container">
    <v-toolbar>
      <v-toolbar-title prepend-icon="mdi-form-textbox">Customer Info</v-toolbar-title>

      <v-btn icon="mdi-close" @click="goToCustomerPage"></v-btn>
    </v-toolbar>
    <v-divider class="mb-0"></v-divider>
    <v-divider class="mb-0"></v-divider>
    <v-stepper v-model="e1">
      <template v-slot:default="{ prev, next }">
        <v-stepper-header>
          <template v-for="n in steps" :key="`${n}-step`">
            <v-stepper-item
              v-if="n === 1"
              :complete="e1 > n"
              :step="`Step {{ n }}`"
              :value="n"
              color="primary"
              title="Customer Info"
            ></v-stepper-item>
            <v-stepper-item
              v-if="n === 2"
              :complete="e1 > n"
              :step="`Step {{ n }}`"
              :value="n"
              color="primary"
              title="Identification"
            ></v-stepper-item>
            <v-stepper-item
              v-if="n === 3"
              :complete="e1 > n"
              :step="`Step {{ n }}`"
              :value="n"
              color="primary"
              title="Location"
            ></v-stepper-item>
            <v-stepper-item
              v-if="n === 4"
              :complete="e1 > n"
              :step="`Step {{ n }}`"
              :value="n"
              color="primary"
              title="Confirmation Info"
            ></v-stepper-item>
            <v-stepper-item
              v-if="n === 5"
              :complete="e1 > n"
              :step="`Step {{ n }}`"
              :value="n"
              color="success"
              title="Bank Info"
            ></v-stepper-item>
            <v-stepper-item
              v-if="n === 6"
              :complete="e1 > n"
              :step="`Step {{ n }}`"
              :value="n"
              color="success"
              title="Final"
            ></v-stepper-item>

            <v-divider v-if="n !== steps" :key="n"></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-window>
          <v-stepper-window-item v-for="n in steps" :key="`${n}-content`" :value="n">
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <div class="py-2" v-if="n === 1">
                <v-row>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-card variant="variant" class="mx-auto" elevation="16">
                      <v-img
                        :src="
                          profile === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : profile
                        "
                        aspect-ratio="1"
                        class="align-end"
                        fixed
                      >
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="9" md="9" lg="9" xl="9">
                    <v-row>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="firstName"
                          label="First Name"
                          placeholder="First Name"
                          variant="outlined"
                          prepend-inner-icon="mdi-account"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="lastName"
                          label="Last Name"
                          placeholder="Last Name"
                          variant="outlined"
                          prepend-inner-icon="mdi-account"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="name"
                          label="Full Name"
                          placeholder="Full Name"
                          variant="outlined"
                          prepend-inner-icon="mdi-account"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-autocomplete
                          ref="conditionClaim"
                          v-model="gender"
                          :items="sex"
                          label="Gender"
                          placeholder="Gender"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-filter-variant"
                          required
                          autocomplete="off"
                          persistent-placeholder
                          disabled
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="dateOfBirth"
                          label="DoB"
                          placeholder="DoB"
                          variant="outlined"
                          prepend-inner-icon="mdi-clipboard-text-clock-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="email"
                          label="Email"
                          placeholder="Email"
                          variant="outlined"
                          prepend-inner-icon="mdi-email"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="phone"
                          label="Phone"
                          placeholder="Phone"
                          variant="outlined"
                          prepend-inner-icon="mdi-phone-classic"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="nationality"
                          label="Nationality"
                          placeholder="Nationality"
                          variant="outlined"
                          prepend-inner-icon="mdi-flag"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="country"
                          label="Country"
                          placeholder="Country"
                          variant="outlined"
                          prepend-inner-icon="mdi-flag"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          clear-icon="mdi-close-circle"
                          label="Address"
                          rows="3"
                          v-model="address"
                          clearable
                          variant="outlined"
                          density="compact"
                          required
                          disabled
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-text-field
                          v-model.trim="ipStaffLogin"
                          label="IP Staff Login"
                          placeholder="IP Staff Login"
                          variant="outlined"
                          prepend-inner-icon="mdi-flag"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-text-field
                          v-model.trim="countryStaffLogin"
                          label="Country Staff Login"
                          placeholder="Country Staff Login"
                          variant="outlined"
                          prepend-inner-icon="mdi-flag"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div class="py-2" v-if="n === 2">
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-card variant="variant" class="mx-auto" elevation="16">
                      <v-img
                        :src="
                          frontCard === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : frontCard
                        "
                        aspect-ratio="1"
                        class="align-end"
                        fixed
                        height="340"
                      >
                        <div class="fill-height bottom-gradient"></div>
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row> </template
                      ></v-img>
                    </v-card>
                    <div class="text-center">
                      <span class="my-2 text-button text-h6">Front ID Card Image</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-card variant="variant" class="mx-auto" elevation="16">
                      <v-img
                        :src="
                          backCard === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : backCard
                        "
                        aspect-ratio="1"
                        class="align-end"
                        fixed
                        height="340"
                      >
                        <div class="fill-height bottom-gradient"></div>
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                    <div class="text-center">
                      <span class="my-2 text-button text-h6">Back ID Card Image</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="typeCard"
                      label="Type Card"
                      placeholder="Type Card"
                      variant="outlined"
                      prepend-inner-icon="mdi-card-account-details"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="nameCard"
                      label="Card Name"
                      placeholder="Card Name"
                      variant="outlined"
                      prepend-inner-icon="mdi-card-account-details"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="cardNumber"
                      label="Card Number"
                      placeholder="Card Number"
                      variant="outlined"
                      prepend-inner-icon="mdi-card-account-details"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="expireDate"
                      label="Expired Date"
                      placeholder="Expired Date"
                      variant="outlined"
                      prepend-inner-icon="mdi-credit-card-clock"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="py-2" v-if="n === 3">
                <v-row>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="countryCode"
                      label="Country Code"
                      placeholder="Country Code"
                      variant="outlined"
                      prepend-inner-icon="mdi-code-braces"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="postalCode"
                      label="Postal Code"
                      placeholder="Postal Code"
                      variant="outlined"
                      prepend-inner-icon="mdi-code-braces"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="countryLocation"
                      label="Country Location"
                      placeholder="Country Location"
                      variant="outlined"
                      prepend-inner-icon="mdi-map-marker"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="regionName"
                      label="Region Nmae"
                      placeholder="Region Nmae"
                      variant="outlined"
                      prepend-inner-icon="mdi-map-marker"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="state"
                      label="State"
                      placeholder="State"
                      variant="outlined"
                      prepend-inner-icon="mdi-map-marker"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="city"
                      label="City"
                      placeholder="City"
                      variant="outlined"
                      prepend-inner-icon="mdi-map-marker"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="longitude"
                      label="Longitude"
                      placeholder="Longitude"
                      variant="outlined"
                      prepend-inner-icon="mdi-map-marker"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="latitude"
                      label="Latitude"
                      placeholder="Latitude"
                      variant="outlined"
                      prepend-inner-icon="mdi-map-marker"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="ipAddress"
                      label="IP Address"
                      placeholder="IP Address"
                      variant="outlined"
                      prepend-inner-icon="mdi-ip-network"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="countIpBlock"
                      label="Count IP Block"
                      placeholder="Count IP Block"
                      variant="outlined"
                      prepend-inner-icon="mdi-ip"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="dateBlockIp"
                      label="Date Block IP"
                      placeholder="Date Block IP"
                      variant="outlined"
                      prepend-inner-icon="mdi-clipboard-clock-outline"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="macAddress"
                      label="Mac Address"
                      placeholder="Mac Address"
                      variant="outlined"
                      prepend-inner-icon="mdi-ip-network-outline"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="countMacAddress"
                      label="Count Mac Address"
                      placeholder="Count Mac Address"
                      variant="outlined"
                      prepend-inner-icon="mdi-ip-network-outline"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="dateBlockMacAddress"
                      label="Date Block Mac Address"
                      placeholder="Date Block Mac Address"
                      variant="outlined"
                      prepend-inner-icon="mdi-clipboard-clock-outline"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="googleId"
                      label="Google ID"
                      placeholder="Google ID"
                      variant="outlined"
                      prepend-inner-icon="mdi-google"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="isp"
                      label="ISP"
                      placeholder="ISP"
                      variant="outlined"
                      prepend-inner-icon="mdi-domain"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="py-2" v-if="n === 4">
                <v-row>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="occupation"
                      label="Occupation"
                      placeholder="Occupation"
                      variant="outlined"
                      prepend-inner-icon="mdi-ticket-confirmation"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="numberLevel"
                      label="Number Level"
                      placeholder="Number Level"
                      variant="outlined"
                      prepend-inner-icon="mdi-ticket-confirmation"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="referral"
                      label="Referral"
                      placeholder="Referral"
                      variant="outlined"
                      prepend-inner-icon="mdi-ticket-confirmation"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="verifyCode"
                      label="Verify Code"
                      placeholder="Verify Code"
                      variant="outlined"
                      prepend-inner-icon="mdi-ticket-confirmation"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="referralLink"
                      label="Referral Link"
                      placeholder="Referral Link"
                      variant="outlined"
                      prepend-inner-icon="mdi-link-variant"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="myReferral"
                      label="My Referral"
                      placeholder="My Referral"
                      variant="outlined"
                      prepend-inner-icon="mdi-ticket-confirmation"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="countReferral"
                      label="Count Referral"
                      placeholder="Count Referral"
                      variant="outlined"
                      prepend-inner-icon="mdi-ticket-confirmation"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="serviceAgreement"
                      label="Agreement Service"
                      placeholder="Agreement Service"
                      variant="outlined"
                      prepend-inner-icon="mdi-face-agent"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="privacyPolicy"
                      label="Privacy Policy"
                      placeholder="Privacy Policy"
                      variant="outlined"
                      prepend-inner-icon="mdi-iface-agent"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="emailVerifyAt"
                      label="Email Verify At"
                      placeholder="Email Verify At"
                      variant="outlined"
                      prepend-inner-icon="mdi-sort-calendar-ascending"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.trim="confirmAt"
                      label="Confirm At"
                      placeholder="Confirm At"
                      variant="outlined"
                      prepend-inner-icon="mdi-sort-calendar-ascending"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="py-2" v-if="n === 5">
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-card class="mx-auto" subtitle="Wallet">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="availableBalance"
                              label="Available Balance"
                              placeholder="Available Balance"
                              variant="outlined"
                              prepend-inner-icon="mdi-ticket-confirmation"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="accountBalance"
                              label="Account Balance"
                              placeholder="Account Balance"
                              variant="outlined"
                              prepend-inner-icon="mdi-ip-network-outline"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="bankAccount"
                              label="Bank Account"
                              placeholder="Bank Account"
                              variant="outlined"
                              prepend-inner-icon="mdi-clipboard-clock-outline"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="walletType"
                              label="Wallet Type"
                              placeholder="Wallet Type"
                              variant="outlined"
                              prepend-inner-icon="mdi-google"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model.trim="pin_code"
                              label="PIN Code"
                              placeholder="PIN Code"
                              variant="outlined"
                              prepend-inner-icon="mdi-domain"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-card class="mx-auto" subtitle="Deposit">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="deposit_amount"
                              label="Deposit Amount"
                              placeholder="Deposit Amount"
                              variant="outlined"
                              prepend-inner-icon="mdi-ticket-confirmation"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="deposit_amount_currcency"
                              label="Deposit Amount Currcency"
                              placeholder="Deposit Amount Currcency"
                              variant="outlined"
                              prepend-inner-icon="mdi-ip-network-outline"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="tax_fee"
                              label="Tax Fee"
                              placeholder="Tax Fee"
                              variant="outlined"
                              prepend-inner-icon="mdi-clipboard-clock-outline"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="fee"
                              label="Fee"
                              placeholder="Fee"
                              variant="outlined"
                              prepend-inner-icon="mdi-google"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="amount_dollar"
                              label="Amount Dollar"
                              placeholder="Amount Dollar"
                              variant="outlined"
                              prepend-inner-icon="mdi-domain"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="amount_received"
                              label="Amount Received"
                              placeholder="Amount Received"
                              variant="outlined"
                              prepend-inner-icon="mdi-domain"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="purpose"
                              label="Purpose"
                              placeholder="Purpose"
                              variant="outlined"
                              prepend-inner-icon="mdi-domain"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div class="py-2" v-if="n === 6" v-ripple.center>
                <div class="pa-4 text-center">
                  <v-img
                    class="mb-4"
                    height="128"
                    src="https://cdn-icons-png.freepik.com/512/5610/5610944.png"
                    contain
                    @click="goToCustomerPage"
                  ></v-img>
                  <h3
                    class="text-h6 font-weight-light mb-2"
                    style="color: rgb(24, 103, 192); text-align: center"
                  ></h3>
                  <v-span class="text-caption text-grey"
                    >Thanks for review customer info! Please click icon above to go
                    back.</v-span
                  >
                </div>
                <div class="text-center">
                  <v-btn class="ma-2" color="error" @click="goToCustomerPage">
                    <v-icon icon="mdi-close-octagon-outline" start></v-icon>
                    Close
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-stepper-window-item>
        </v-stepper-window>

        <v-stepper-actions
          :disabled="disabled"
          @click:next="customNext(next)"
          @click:prev="customPrev(prev)"
        ></v-stepper-actions>
      </template>
    </v-stepper>
  </div>
</template>
<script>
import router from "@/router";
import { Path_Customer_Image, URL_CUSTOMERS_DETAIL } from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import axios from "axios";
import moment from "moment";

export default {
  props: {
    hdCustomerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      path: Path_Customer_Image,
      loading: false,
      timeout: null,

      e1: 1,
      steps: 6,
      items: [],
      sex: ["Male", "Female"],

      frontCard: null,
      backCard: null,
      profile: null,
      nationality: null,
      cardNumber: null,
      nameCard: null,
      conditionClaim: null,
      ipStaffLogin: null,
      countryStaffLogin: null,

      name: null,
      online: null,
      firstName: null,
      lastName: null,
      gender: null,
      country: null,
      countryCode: null,
      verifyCode: null,
      referralLink: null,
      myReferral: null,
      countReferral: "",
      serviceAgreement: "",
      privacyPolicy: null,
      emailVerifyAt: null,
      confirmAt: null,
      occupation: null,

      numberLevel: -1,
      referral: 0,
      typeCard: null,
      expireDate: null,
      email: null,
      phone: null,
      ip: null,
      isp: null,
      longitude: null,
      latitude: null,
      countryLocation: null,
      regionName: null,
      state: null,
      city: null,
      postalCode: null,
      countIpBlock: null,
      dateBlockIp: null,
      macAddress: null,
      countMacAddress: null,
      dateBlockMacAddress: null,
      googleId: null,
      identity: null,
      countryOfBirth: null,
      cityOfBirth: null,
      dateOfBirth: null,
      address: null,
      ipAddress: null,

      availableBalance: null,
      accountBalance: null,
      bankAccount: null,
      walletType: null,
      pin_code: null,

      deposit_amount: null,
      deposit_amount_currcency: null,
      tax_fee: null,
      fee: null,
      amount_dollar: null,
      amount_received: null,
      purpose: null,
      status: null,
    };
  },

  computed: {
    disabled() {
      return this.e1 === 1 ? "prev" : this.e1 === this.steps ? "next" : undefined;
    },
  },
  created() {
    this.getCustomerDetail();
  },

  methods: {
    async customNext(next) {
      this.e1++;

      next();
    },

    customPrev(prev) {
      this.e1--;
      //alert(this.e1);
      prev();
    },

    goToCustomerPage() {
      router.go(0);
    },

    async getCustomerDetail() {
      try {
        const response = await axios.get(
          `${URL_CUSTOMERS_DETAIL}?customer=${this.hdCustomerId}`,
          {
            headers: getTokenHeaders(),
          }
        );

        const res = response.data;

        // Directly assign properties that match
        Object.assign(this, res);

        this.ipStaffLogin = res.customerLogin[0].ip;
        this.countryStaffLogin = res.customerLogin[0].country;

        // Format dates properly
        this.dateOfBirth = res.dateOfBirth
          ? moment(res.dateOfBirth).format("YYYY-MMM-DD")
          : null;
        this.confirmAt = res.confirmAt
          ? moment(res.confirmAt).format("YYYY-MMM-DD")
          : null;
        this.expireDate = res.expireDate
          ? moment(res.expireDate).format("YYYY-MMM-DD")
          : null;

        // Helper function for setting default images
        const getImageUrl = (imagePath) =>
          imagePath
            ? this.path + imagePath
            : "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain";

        // Assign profile, frontCard, backCard images
        this.profile = getImageUrl(res.profile);
        this.frontCard = getImageUrl(res.frontCard);
        this.backCard = getImageUrl(res.backCard);
        this.nameCard = res.name;

        // Handle wallet data if available
        if (res.wallet) {
          this.availableBalance = res.wallet.availableBalance;
          this.accountBalance = res.wallet.accountBalance;
          this.bankAccount = res.wallet.bankAccount;
          this.walletType = res.wallet.walletType;
          this.pin_code = res.wallet.pin_code;
        }

        // Handle deposit data if available
        if (res.deposit) {
          Object.assign(this, res.deposit);
        }
      } catch (error) {
        console.error("Error fetching customer details:", error);
        throw new Error("Failed to fetch customer details. Please try again.");
      }
    },
  },
};
</script>
<style scoped>
.stepper-container {
  height: 80vh; /* Adjust as needed */
  overflow-y: auto;
}
</style>
