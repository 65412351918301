export function getFileSizeInMB(file) {
  if (!file || !file.size) {
    return 0;
  }
  const sizeInMB = file.size / (1024 * 1024);
  return sizeInMB.toFixed(2); // Returns size in MB with 2 decimal places
}

export function getFileSizeInKB(file) {
  if (!file || !file.size) {
    return 0;
  }
  const sizeInKB = file.size / 1024;
  return sizeInKB.toFixed(2); // Returns size in KB with 2 decimal places
}

export function getFileExtension(file) {
  if (!file || !file.name) {
    return null;
  }
  const parts = file.name.split(".");
  const extension = parts.length > 1 ? parts.pop().toLowerCase() : "";
  const validExtensions = ["jpg", "jpeg", "png", "bmp"];
  return validExtensions.includes(extension) ? extension : null;
}

export const statusWithdraw = () => {
  return [
    {
      id: 0,
      name: "-- All --",
    },
    {
      id: 1,
      name: "pending",
    },
    {
      id: 2,
      name: "done",
    },
    {
      id: 3,
      name: "fail",
    },
  ];
}; // <-- Missing closing bracket added

export function isTokenExpired(token) {
  if (!token) {
    return true;
  }
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    //const expiryDate = new Date(payload.exp * 1000);
    //alert("Token Expiry Date: " + expiryDate.toLocaleString());
    return payload.exp < currentTime;
  } catch (error) {
    console.error("Invalid token format:", error);
    return true;
  }
}
