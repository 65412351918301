<template>
  <v-row class="overflow-y-auto row-container">
    <v-col cols="12" md="6" lg="6" xl="6">
      <v-alert
        border="top"
        type="success"
        color="success"
        variant="outlined"
        prominent
        icon="mdi-access-point"
        class="d-flex justify-center"
      >
        <v-card width="300px" max-width="auto">
          <v-card-title
            class="text-uppercase font-weight-black"
            style="font-size: smaller"
            >Online</v-card-title
          >
          <v-card-text
            class="text-capitalize text-overline text-decoration-underline"
          >
            <span style="font-size: large">{{ totalOnline }}</span>
          </v-card-text>
        </v-card>
      </v-alert>
    </v-col>

    <v-col cols="12" md="6" lg="6" xl="6">
      <v-alert
        border="top"
        type="success"
        color="error"
        variant="outlined"
        prominent
        icon="mdi-access-point-off"
        class="d-flex justify-center"
      >
        <v-card width="300px" max-width="auto">
          <v-card-title
            class="text-uppercase font-weight-black"
            style="font-size: smaller"
            >Offline</v-card-title
          >
          <v-card-text
            class="text-capitalize text-overline text-decoration-underline"
          >
            <span style="font-size: large">{{ totalOffline }}</span>
          </v-card-text>
        </v-card>
      </v-alert>
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <template v-slot:text>
          <v-row>
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-select
                v-if="(allCountry === 1) | (admin === 1)"
                v-model="searchCountryId"
                :items="countries"
                :item-title="'name'"
                :item-value="'id'"
                label="Country"
                variant="outlined"
                prepend-inner-icon="mdi-flag-outline"
                required
                persistent-placeholder
                placeholder="Choose country..."
                return-object
                single-line
                @update:modelValue="filterItems"
              >
              </v-select>
              <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                    but else the data return as value normally -->
              <v-select
                v-else
                v-model="searchCountryId"
                :items="countries"
                :item-title="'name'"
                :item-value="'id'"
                label="Country"
                variant="outlined"
                prepend-inner-icon="mdi-flag-outline"
                required
                persistent-placeholder
                placeholder="Choose country..."
                return-object
                single-line
                disabled
                clearable
                @update:modelValue="filterItems"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="search"
                label="Search customers..."
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <v-snackbar
          v-model="snack"
          :timeout="3000"
          :color="snackColor"
          location="top"
        >
          {{ snackText }}
        </v-snackbar>
        <v-data-table
          color="#b2d7ef"
          rounded="compact"
          divided
          striped
          hover
          class="elevation-1"
          fixed-header
          :search="search"
          :headers="
            headers.filter(
              (h) =>
                h.key !== 'ipAddress' &&
                h.key !== 'longitude' &&
                h.key !== 'latitude' &&
                h.key !== 'countryCode' &&
                h.key !== 'regionName' &&
                h.key !== 'country' &&
                h.key !== 'test'
            )
          "
          :items="customers"
          :items-per-page="perPage"
          :server-items-length="totalCount"
          :loading="loading"
          :sort-by="[
            { key: 'createdAt', order: 'desc' },
            { key: 'lastName', order: 'asc' },
          ]"
        >
          <template v-slot:top>
            <v-sheet class="d-flex flex-row-reverse">
              <v-sheet class="ma-2 pa-2 text-center">
                <v-select
                  v-model="perPage"
                  :items="[10, 25, 50, 100, 1000, 10000]"
                  hide-details
                  density="compact"
                  variant="outlined"
                  @update:model-value="perPage = parseInt($event, 10)"
                  style="width: 100px"
                ></v-select>
              </v-sheet>
              <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
            </v-sheet>
            <v-dialog
              v-model="dialogResetPassword"
              transition="dialog-bottom-transition"
              fullscreen
            >
              <v-card>
                <v-toolbar>
                  <v-btn
                    icon="mdi-close"
                    @click="dialogResetPassword = false"
                  ></v-btn>

                  <v-toolbar-title>Reset Password</v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-toolbar-items>
                    <v-btn
                      text="Reset"
                      variant="text"
                      @click="resetPassword"
                    ></v-btn>
                  </v-toolbar-items>
                </v-toolbar>

                <v-list lines="two" subheader>
                  <v-form
                    ref="formResetPassword"
                    enctype="multipart/form-data"
                    lazy-validation
                  >
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model.trim="resetPass"
                            :rules="resetPasswordRules"
                            label="Password"
                            placeholder="Password"
                            variant="outlined"
                            prepend-inner-icon="mdi-lock"
                            :append-inner-icon="
                              visible ? 'mdi-eye-off' : 'mdi-eye'
                            "
                            :type="visible ? 'text' : 'password'"
                            @click:append-inner="visible = !visible"
                            required
                            autocomplete="off"
                            density="compact"
                            persistent-placeholder
                          ></v-text-field>
                          <input type="hidden" v-model="hdCustomerId" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-form>
                </v-list>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogImage"
              persistent
              fullscreen
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar>
                  <v-toolbar-title prepend-icon="mdi-form-textbox"
                    >Image Card</v-toolbar-title
                  >

                  <v-btn icon="mdi-close" @click="closeDialgeImage"></v-btn>
                </v-toolbar>
                <v-divider class="mb-0"></v-divider>
                <v-divider class="mb-0"></v-divider>
                <v-row>
                  <v-col cols="12">
                    <v-img
                      :lazy-src="frontCard"
                      :src="frontCard"
                      aspect-ratio="1"
                      class="bg-grey-lighten-2"
                      cover
                      max-width="auto"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          align="center"
                          class="fill-height ma-0"
                          justify="center"
                        >
                          <v-progress-circular
                            color="grey-lighten-5"
                            indeterminate
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
                <v-card-actions class="my-2 d-flex justify-end">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="text-none"
                    rounded="xl"
                    text="Cancel"
                    variant="flat"
                    color="error"
                    @click="closeDialgeImage"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogViewRecord"
              persistent
              fullscreen
              transition="dialog-bottom-transition"
            >
              <v-card>
                <DialogueViewCustomerInfo
                  :hdCustomerId="hdCustomerId"
                ></DialogueViewCustomerInfo>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogBlockCus" max-width="70%" persistent>
              <v-card>
                <v-toolbar>
                  <v-toolbar-title prepend-icon="mdi-form-textbox"
                    >Block customer?</v-toolbar-title
                  >

                  <v-btn
                    icon="mdi-close"
                    @click="dialogBlockCus = false"
                  ></v-btn>
                </v-toolbar>
                <v-divider class="mb-0"></v-divider>
                <v-divider class="mb-0"></v-divider>
                <v-card-title class="text-h5">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12">
                        <v-radio-group v-model="radios">
                          <template v-slot:label>
                            <div class="text-primary">
                              Do you want to block
                              <strong>{{ customernameToBlock }}</strong>
                            </div>
                          </template>
                          <v-radio value="block" color="error">
                            <template v-slot:label>
                              <div>
                                Of course i want to block
                                <strong class="text-error">{{
                                  customernameToBlock
                                }}</strong>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="noBlocking" color="success">
                            <template v-slot:label>
                              <div>
                                No. I think you don't have to block
                                <strong class="text-success">{{
                                  customernameToBlock
                                }}</strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12">
                        <v-label class="font-weight-thin x-small"
                          >Description</v-label
                        >
                        <QuillEditor
                          toolbar="essential"
                          contentType="html"
                          style="height: 300px"
                          v-model:content="description"
                          theme="snow"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-title>
                <input type="hidden" v-model="hiddenId" />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    variant="flat"
                    rounded="sm"
                    @click="closeDialogBlockCustomer"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="success"
                    variant="flat"
                    rounded="sm"
                    @click="blockCustomer"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogUpdateLocation" max-width="70%" persistent>
              <v-card>
                <v-toolbar>
                  <v-toolbar-title prepend-icon="mdi-form-textbox"
                    >Update Customer Location</v-toolbar-title
                  >

                  <v-btn
                    icon="mdi-close"
                    @click="dialogUpdateLocation = false"
                  ></v-btn>
                </v-toolbar>
                <v-divider class="mb-0"></v-divider>
                <v-divider class="mb-0"></v-divider>
                <v-card-title class="text-h5">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-autocomplete
                          v-model="countryName"
                          :items="countriesApi"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-flag-outline"
                          required
                          autocomplete="off"
                          persistent-placeholder
                          placeholder="Choose region code..."
                          :item-title="'name'"
                          :item-value="'id'"
                          @update:modelValue="
                            PopulateCountryCodeByRegionCode(countryName)
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="regionCode"
                          readonly
                          label="Country Code"
                          placeholder="Region Code"
                          variant="outlined"
                          prepend-inner-icon="mdi-flag"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          @keypress="onlyNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="countryCode"
                          readonly
                          label="Country Code"
                          placeholder="Country Code"
                          variant="outlined"
                          prepend-inner-icon="mdi-flag"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          @keypress="onlyNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model="ipAddress"
                          label="IP Address"
                          variant="outlined"
                          prepend-inner-icon="mdi-ip-network-outline"
                          required
                          persistent-placeholder
                          placeholder="IP Address..."
                          density="compact"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model="longtitude"
                          label="Longitude"
                          variant="outlined"
                          prepend-inner-icon="mdi-map-marker-outline"
                          required
                          persistent-placeholder
                          placeholder="Longitude..."
                          density="compact"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model="latitude"
                          label="Latitude"
                          variant="outlined"
                          prepend-inner-icon="mdi-map-marker-outline"
                          required
                          persistent-placeholder
                          placeholder="Latitude..."
                          density="compact"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-title>
                <input type="hidden" v-model="hiddenId" />
                <v-card-actions class="d-flex justify-end">
                  <v-btn
                    color="error"
                    variant="flat"
                    rounded="sm"
                    @click="closeDialogUpdateLocation"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="success"
                    variant="flat"
                    rounded="sm"
                    @click="updaeCustomerLocation"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogUpdateToTestOrNormal"
              width="auto"
              persistent
            >
              <v-card>
                <v-toolbar>
                  <v-toolbar-title prepend-icon="mdi-all-inclusive" class="my-4"
                    >Update Customer To Test or Normal</v-toolbar-title
                  >

                  <v-btn
                    icon="mdi-close"
                    @click="dialogUpdateToTestOrNormal = false"
                  ></v-btn>
                </v-toolbar>
                <v-divider class="mt-0"></v-divider>
                <v-card-title class="text-h5">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12">
                        <v-radio-group
                          v-model="testOrNormal"
                          messages="Select a item from the radio group"
                        >
                          <v-radio label="Normal" value="Normal"></v-radio>
                          <v-radio label="Test" value="Test"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-title>
                <input type="hidden" v-model="hiddenId" />
                <v-divider></v-divider>
                <v-card-actions class="d-flex justify-end">
                  <v-btn
                    color="error"
                    variant="flat"
                    rounded="sm"
                    @click="closeDialogUpdateToTestOrNormal"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="success"
                    variant="flat"
                    rounded="sm"
                    @click="updaeCustomerToTestOrNormal"
                    >Update</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!-- detect when error  -->
          <template v-slot:no-data>
            <v-alert
              v-if="items.length > 0"
              :value="true"
              color="error"
              icon="warning"
              class="text-left"
            >
              No data found.
            </v-alert>
          </template>
          <!-- end detect when error  -->
          <!-- Custom column table -->
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              size="small"
              v-if="item.status === 'Pending'"
              prepend-icon="mdi-account-clock-outline"
              color="info"
            >
              {{ item.status }}
            </v-chip>
            <v-chip
              size="small"
              v-if="item.status === 'Done'"
              prepend-icon="mdi-account-check-outline"
              color="success"
            >
              {{ item.status }}
            </v-chip>
            <v-chip
              size="small"
              v-if="item.status === 'Review'"
              prepend-icon="mdi-account-check-outline"
              color="warning"
            >
              {{ item.status }}
            </v-chip>
            <v-chip
              size="small"
              v-if="item.status === 'Reject'"
              prepend-icon="mdi-account-check-outline"
              color="error"
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`item.online`]="{ item }">
            <v-chip size="small" v-if="item.online === 1" color="success">
              <v-icon icon="mdi-access-point"></v-icon>
            </v-chip>
            <v-chip size="small" v-if="item.online === 0" color="error">
              <v-icon icon="mdi-access-point-off"></v-icon>
            </v-chip>
          </template>
          <template v-slot:[`item.profile`]="{ item }">
            <v-avatar size="64" class="my-1" v-if="item.profile">
              <v-img :src="path + item.profile" cover>
                <div
                  :class="
                    item.online === 1 ? 'dot online-green' : 'dot offline-red'
                  "
                ></div>
                <template v-slot:placeholder>
                  <v-row
                    align="center"
                    class="fill-height ma-0"
                    justify="center"
                  >
                    <v-progress-circular
                      color="grey-lighten-5"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
            <v-avatar
              class="my-1"
              v-else
              image="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              size="80"
            >
            </v-avatar>
          </template>
          <template v-slot:[`item.frontCard`]="{ item }">
            <v-avatar
              size="64"
              class="my-1"
              v-if="item.frontCard"
              @click="showDialogueImage(item.frontCard)"
            >
              <v-img :src="path + item.frontCard" cover>
                <template v-slot:placeholder>
                  <v-row
                    align="center"
                    class="fill-height ma-0"
                    justify="center"
                  >
                    <v-progress-circular
                      color="grey-lighten-5"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
            <v-avatar
              class="my-1"
              v-else
              image="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              size="80"
            >
            </v-avatar>
          </template>
          <template v-slot:[`item.backCard`]="{ item }">
            <v-avatar
              size="64"
              class="my-1"
              v-if="item.backCard"
              @click="showDialogueImage(item.backCard)"
            >
              <v-img :src="path + item.backCard" cover>
                <template v-slot:placeholder>
                  <v-row
                    align="center"
                    class="fill-height ma-0"
                    justify="center"
                  >
                    <v-progress-circular
                      color="grey-lighten-5"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
            <v-avatar
              class="my-1"
              v-else
              image="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              size="80"
            >
            </v-avatar>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ new Date(item.createdAt).toLocaleString() }}
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div
              v-html="
                item.description !== null
                  ? item.description.length > 50
                    ? item.description.substring(0, 50) + '...'
                    : item.description
                  : ''
              "
            ></div>
          </template>
          <template v-slot:[`item.emailVerifyAt`]="{ item }">
            {{ new Date(item.emailVerifyAt).toLocaleString() }}
          </template>
          <template v-slot:[`item.confirmAt`]="{ item }">
            {{ new Date(item.confirmAt).toLocaleString() }}
          </template>
          <template v-slot:[`item.dateBlockIp`]="{ item }">
            {{ new Date(item.dateBlockIp).toLocaleString() }}
          </template>
          <template v-slot:[`item.dateBlockMacAddress`]="{ item }">
            {{ new Date(item.dateBlockMacAddress).toLocaleString() }}
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-chip
              size="small"
              v-if="item.active === 1"
              prepend-icon="mdi-account-cancel"
              color="error"
            >
              Disabled
            </v-chip>
            <v-chip
              size="small"
              v-else
              prepend-icon="mdi-account-check-outline"
              color="success"
            >
              Enabled
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon="mdi-dots-vertical"
                  color="primary"
                  variant="text"
                  density="compact"
                ></v-btn>
              </template>
              <v-list>
                <v-list-item @click="editResetPassword(item)">
                  <v-list-item-icon>
                    <v-icon icon="mdi-lock-reset" color="info"></v-icon> Reset
                    Password
                  </v-list-item-icon>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="showDialogBlockCustomer(item)">
                  <v-list-item-icon>
                    <v-icon icon="mdi-account-cancel" color="error"></v-icon>
                    Block Customer
                  </v-list-item-icon>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="viewCustomerRecord(item)">
                  <v-list-item-icon>
                    <v-icon icon="mdi-eye" color="success"></v-icon> View
                    Details
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="showDialogUpdateLocation(item)">
                  <v-list-item-icon>
                    <v-icon icon="mdi-map-marker" color="orange"></v-icon>
                    Update Location
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="showDialogUpdateToTestOrNormal(item)">
                  <v-list-item-icon>
                    <v-icon icon="mdi-map-marker" color="orange"></v-icon>
                    Update to Test or Normal
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:bottom>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                rounded="circle"
                :length="pageCount"
                :total-visible="7"
                @update:model-value="populatecustomers(staffLoginCountryCode)"
              ></v-pagination>
            </div>
          </template>
          <!-- End custom column table -->
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import {
  URL_COUNTRY,
  URL_CUSTOMERS,
  URL_RESET_PASS_CUS,
  Path_Customer_Image,
  URL_FETCH_CUSTOMER_WITH_PAGINATION,
  URL_PERMISSION,
  URL_UPDATE_CUS_LOCATION,
  URL_UPDATE_CUS_TO_TEST_OR_NORMAL,
} from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import { formatNumber, originalCode } from "@/utils/formatNumber";
import DialogueViewCustomerInfo from "./DialogueViewCustomerInfo";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  components: {
    DialogueViewCustomerInfo,
    QuillEditor,
  },
  data: () => ({
    path: Path_Customer_Image,
    loading: false, // Loading state
    snack: false,
    dialog: false,
    dialogResetPassword: false,
    dialogViewRecord: false,
    visible: true,
    visibleConfirmPassword: false,
    dialogImage: false,
    dialogBlockCus: false,
    dialogUpdateLocation: false,
    dialogUpdateToTestOrNormal: false,

    sex: ["Male", "Female"],
    items: [],
    customers: [],
    countries: ["-- All --"],
    radios: "noBlocking",
    countriesApi: [],

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    description: null,

    resetPass: null,
    searchCountryId: null,
    customernameToBlock: null,

    editedIndex: -1,
    hdCustomerId: 0,
    totalOnline: 0,
    totalOffline: 0,

    //pagination
    page: 1,
    perPage: 10,
    pageCount: 0,
    totalCount: 0, // Total records count

    staffCountryId: originalCode(parseInt(localStorage.getItem("countryId"))),
    admin: originalCode(parseInt(localStorage.getItem("admin"))),
    allCountry: originalCode(parseInt(localStorage.getItem("allCountry"))),
    staffLoginCountryCode: originalCode(parseInt(localStorage.getItem("code"))),
    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    ipAddress: null,
    longtitude: null,
    latitude: null,
    countryCode: null,
    regionCode: null,
    countryName: null,
    testOrNormal: "Normal",

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Front Image", key: "frontCard", align: "center" },
      { title: "Back Image", key: "backCard", align: "center" },
      { title: "Account", key: "profile", align: "center" },
      { title: "Is Active?", key: "active", align: "center" },
      { title: "First Name", key: "firstName", align: "center" },
      { title: "Last Name", key: "lastName", align: "center" },
      { title: "Sex", key: "gender", align: "center" },
      { title: "Email", key: "email", align: "center" },
      { title: "Phone", key: "phone", align: "center" },
      { title: "Nationality", key: "nationality", align: "center" },
      { title: "Country", key: "country", align: "center" },
      { title: "online?", key: "online", align: "center" },
      { title: "Description", key: "description", align: "center" },
      { title: "", key: "ipAddress", align: "center" },
      { title: "", key: "longitude", align: "center" },
      { title: "", key: "latitude", align: "center" },
      { title: "", key: "countryCode", align: "center" },
      { title: "", key: "regionName", align: "center" },
      { title: "", key: "country", align: "center" },
      { title: "", key: "test", align: "center" },
    ],

    resetPasswordRules: [
      (v) =>
        (v &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
            v
          )) ||
        "Minimum 6 characters, One capital latter, Special charater, Number",
      (v) =>
        (v && v.length <= 50) || "Password must be less than 50 characters",
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Customer" : "Update Customer";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogResetPassword(val) {
      val || this.closeDialogResetPass();
    },
    dialogImage(val) {
      val || this.close();
    },
    dialogBlockCus(val) {
      val || this.close();
    },
    dialogUpdateLocation(val) {
      val || this.close();
    },
    dialogUpdateToTestOrNormal(val) {
      val || this.close();
    },
    page() {
      this.populatecustomers(this.staffLoginCountryCode);
    },
    perPage() {
      this.populatecustomers(this.staffLoginCountryCode);
    },
    search() {
      this.populatecustomers(this.staffLoginCountryCode);
    },
  },

  created() {
    this.PopulateCountries();
    this.fetchCApiCountries();
    this.staffLoginCountryCode =
      this.isAdminOrAllCountryPermission() ||
      this.getStaffPermissionCustomerByStaffLogin()
        ? null
        : this.staffLoginCountryCode;

    this.populatecustomers(this.staffLoginCountryCode);
  },
  methods: {
    async fetchCApiCountries() {
      try {
        const response = await fetch("/assets/countries.json");
        const countriesJson = await response.json();

        this.countriesApi = [];
        this.countriesApi = countriesJson.map((item) => ({
          id: item.alpha2Code,
          name: item.name,
        }));
      } catch (error) {
        console.error("Error loading options:", error);
      }
    },

    async updaeCustomerLocation() {
      const selectedCountry = this.countriesApi.find(
        (country) => country.id === this.countryName
      );

      const id = this.hdCustomerId;
      var customerUrl = URL_UPDATE_CUS_LOCATION + id;
      const data = {
        ipAddress: this.ipAddress,
        longitude: this.longtitude,
        latitude: this.latitude,
        countryCode: this.countryCode,
        regionName: this.regionCode,
        country: selectedCountry.name,
      };
      
      await axios
        .patch(customerUrl, data, { headers: getTokenHeaders() })
        .then((response) => {
          this.snakeMessage("success", response.data.message);
          this.dialogUpdateLocation = false;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.snakeMessage("error", error.response.data.message); // This will log the error message
          } else if (error.request) {
            // The request was made but no response was received
            this.snakeMessage("error", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            this.snakeMessage("error", error.message);
          }
        });

      this.populatecustomers(this.staffLoginCountryCode);
    },

    async updaeCustomerToTestOrNormal() {
      const id = this.hdCustomerId;
      await axios
        .patch(
          `${URL_UPDATE_CUS_TO_TEST_OR_NORMAL}${id}/${this.testOrNormal}`,
          {},
          { headers: getTokenHeaders() }
        )
        .then((response) => {
          this.snakeMessage("success", response.data.message);
          this.dialogUpdateToTestOrNormal = false;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.snakeMessage("error", error.response.data.message); // This will log the error message
          } else if (error.request) {
            // The request was made but no response was received
            this.snakeMessage("error", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            this.snakeMessage("error", error.message);
          }
        });

      this.populatecustomers(this.staffLoginCountryCode);
    },

    async PopulateCountryCodeByRegionCode(component) {
      try {
        const response = await axios.get(
          `https://restcountries.com/v2/alpha/${component}`
        );

        this.countryCode = response.data.callingCodes[0];
        this.regionCode = response.data.alpha2Code;
      } catch (error) {
        console.error("Error fetching country data:", error);
      }

      // const selectedCountry = this.countriesApi.find(
      //   (country) => country.id === component
      // );
      // this.countryCode = selectedCountry ? selectedCountry.id : null;
    },

    showDialogueImage(item) {
      this.frontCard = this.path + item;
      this.dialogImage = true;
    },

    showDialogBlockCustomer(item) {
      this.hdCustomerId = item.id;
      this.customernameToBlock = item.firstName;
      this.description = item.description;
      this.dialogBlockCus = true;
    },

    showDialogUpdateLocation(item) {
      this.hdCustomerId = item.id;
      this.countryName = item.country;
      this.ipAddress = item.ipAddress;
      this.longtitude = item.longitude;
      this.latitude = item.latitude;
      this.countryCode = item.countryCode;
      this.regionCode = item.regionName;
      this.dialogUpdateLocation = true;
    },

    showDialogUpdateToTestOrNormal(item) {
      this.hdCustomerId = item.id;
      this.testOrNormal = item.test ?? "Normal";
      this.dialogUpdateToTestOrNormal = true;
    },

    closeDialgeImage() {
      this.dialogImage = false;
    },

    closeDialogResetPass() {
      this.dialogDelete = false;
    },

    closeDialogBlockCustomer() {
      this.description = null;
      this.dialogBlockCus = false;
    },

    closeDialogUpdateLocation() {
      this.ipAddress = null;
      this.longitude = null;
      this.latitude = null;
      this.countryCode = null;
      this.regionName = null;
      this.dialogUpdateLocation = false;
    },

    closeDialogUpdateToTestOrNormal() {
      this.testOrNormal = "Normal";
      this.hdCustomerId = 0;
      this.dialogUpdateToTestOrNormal = false;
    },

    viewCustomerRecord(item) {
      this.hdCustomerId = item.id;
      this.dialogViewRecord = true;
    },

    isAdminOrAllCountryPermission() {
      return (this.admin || this.allCountry) === 1 ? true : false;
    },

    async filterItems(component) {
      const countryName = this.getCountryCodeByCountryId(component.id);
      this.populatecustomers(countryName);
    },

    async populatecustomers(countryName) {
      const params = {
        page: this.page,
        perPage: this.perPage,
        countryName: countryName,
        textSearch: this.search ? this.search : null,
      };

      this.loading = true;
      try {
        await axios
          .get(URL_FETCH_CUSTOMER_WITH_PAGINATION, {
            params,
            headers: getTokenHeaders(),
          })
          .then((response) => {
            if (response.data && response.data.customerCollection) {
              this.customers = response.data.customerCollection;
              this.totalCount = response.data.totalCount;
              this.pageCount = response.data.pageCount;
              this.items.push(this.customers);
              this.getStatistic(this.customers);
            } else {
              this.loading = false;
              console.warn("Empty API Response");
              this.customers = [];
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },

    getStatistic(items) {
      const online = items.filter((item) => item.online == 1).length;
      const offline = items.filter((item) => item.online == 0).length;

      this.totalOnline = formatNumber(online);
      this.totalOffline = formatNumber(offline);
    },

    getCountryCodeByCountryId(countryId) {
      if (countryId === "-- All --") {
        return this.staffLoginCountryCode;
      }
      const country = this.countries.find((item) => item.id === countryId);
      return country ? country.name : null;
    },

    async getStaffPermissionCustomerByStaffLogin() {
      var isPermissionCustomer = 0;
      await axios
        .get(URL_PERMISSION + this.staffLoginId, { headers: getTokenHeaders() })
        .then((res) => {
          isPermissionCustomer = res.data.customerService;
        });

      return isPermissionCustomer;
    },

    editResetPassword(item) {
      this.hdCustomerId = item.id;
      this.dialogResetPassword = true;
    },

    async blockCustomer() {
      const isBlocked = this.radios === "noBlocking" ? 0 : 1;
      const id = this.hdCustomerId;
      var customerUrl = URL_CUSTOMERS + id;
      const data = {
        active: parseInt(isBlocked),
        description: this.description,
      };

      await axios
        .patch(customerUrl, data, { headers: getTokenHeaders() })
        .then((response) => {
          this.snakeMessage(
            "success",
            isBlocked === 1
              ? response.data.message
              : "Customer has been unblocked."
          );
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log(error.response.data.message); // This will log the error message
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });

      this.radios = "noBlocking";
      this.description = null;
      this.dialogBlockCus = false;
      this.populatecustomers();
    },

    resetPassword() {
      const id = this.hdCustomerId;
      const url = URL_RESET_PASS_CUS + id;
      const data = {
        password: this.resetPass,
      };

      axios
        .patch(url, data, { headers: getTokenHeaders() })
        .then((response) => {
          this.snakeMessage("success", response.data.message);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log(error.response.data.message); // This will log the error message
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });

      this.resetPass = "";
      this.dialogResetPassword = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    async PopulateCountries() {
      axios
        .get(URL_COUNTRY, { headers: getTokenHeaders() })
        .then((response) => {
          response.data.forEach((country) => {
            this.countries.push({
              id: country.id,
              name: country.name,
              code: country.code,
            });
          });

          this.searchCountryId = this.isAdminOrAllCountryPermission()
            ? "-- All --"
            : this.staffCountryId;
        });
    },
  },
};
</script>
<style scoped>
.dot {
  position: relative;
  /** top: 39px; */
  right: -44px;
  width: 15px;
  height: 15px;

  border-radius: 50%;
}
.online-green {
  background-color: green;
}
.offline-red {
  background-color: red;
}
</style>
