<template>
  <v-card rounded="0" class="px-10 pa-3">
    <v-row>

      <v-col cols="6" sm="6" md="2">
        <v-img class="mt-2" width="215px" height="40px" :src="company.logo" alt="" />
      </v-col>

      <v-col cols="12" sm="12" md="8" class="hidden"></v-col>

      <v-col cols="6" sm="6" md="2">
        <div class="d-flex justify-end">
          <v-btn variant="text" icon="mdi-close" @click="handleBack()"></v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>

  <div class="margin-center">
    <br />
    <br />
    <h2 class="text-center">Are you sure reset now ?</h2>
    <p class="text-center">
      <span> Already have an account </span>
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/sign-up"
      >
        Sign up ?
      </router-link>
    </p>

    <br />
    <p class="ma-1">First, enter your email address</p>
    <v-form ref="formLogin">

      <v-alert
        v-if="message && error"
        elevation="2"
        :type="'error'"
        variant="outlined"
      >
        {{ message }}
      </v-alert>

      <v-alert
        v-if="message && !error"
        elevation="2"
        :type="'success'"
        variant="outlined"
      >
        {{ message }}
      </v-alert>

      <br />

      <v-text-field
        prepend-inner-icon="mdi-email"
        v-model="form.email"
        variant="outlined"
        density="compact"
        autocomplete="off"
        placeholder="Enter your email address"
        type="email"
        :rules="nameRules"
        @keyup="handleChange"
      />

      <v-btn
        block
        rounded="xl"
        :disabled="visible"
        color="primary"
        @click="handleSubmit()"
        :loading="loading"
      >
        <p class="text-capitalize">
          Sent email
        </p>
      </v-btn>
    </v-form>

    <br />
    <br />

  </div>
</template>

<script>
import { defineComponent } from 'vue';
import axios from 'axios';
import { CUSTOMER_FORGET_PASSWORD } from '@/utils/customerApiUrl';
import { mapState } from 'vuex';

const nameRules = [
  (value) => {
    if (value) return true;
    return 'Email is required.';
  },
  (value) => {
    if (value.length > 0) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Invalid Email address';
    }
  },
];

export default defineComponent({
  name: 'ForgetPasswordPage',
  computed: {
    ...mapState(['company']),
  },
  data() {
    return {
      logo: null,
      visible: true,
      form: {
        email: '',
      },
      loading: false,
      nameRules,
      error: false,
      message: '',
    };
  },
  methods: {

    clearMessage() {
      setTimeout(() => {
        this.error = false;
        this.message = '';
      }, 4000);
    },

    handleSubmit() {
      this.loading = true;
      if (this.loading && this.form.email) {
        let payload = {
          email: this.form.email,
        };
        axios
          .post(CUSTOMER_FORGET_PASSWORD, payload)
          .then((response) => {
            const { message } = response.data;
            if (message) {
              this.error = false;
              this.loading = false;
              this.message = message;
              this.clearMessage();
            }
          })
          .catch((error) => {
            this.error = true;
            this.true = false;
            this.loading = false;
            let message = error.response.data.message;
            if (Array.isArray(message)) {
              this.message = message[0];
            } else {
              this.message = message;
            }
            this.clearMessage();
          });
      }
    },

    isStrictValidEmail(email) {
      const strictEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return strictEmailRegex.test(email);
    },

    handleChange(event) {
      let value = event.target.value;
      if (value && this.isStrictValidEmail(value)) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    },

    handleBack() {
      this.$router.back();
    },
  },
  created() {
    this.$store.dispatch("getCompanyInfo");
  }
});
</script>

<style scoped></style>
