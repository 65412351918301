<template>
  <v-row>
    <v-col md="3" sm="12" cols="12" v-for="(row, index) in tab" v-bind:key="index">
      <v-btn
        border
        size="x-large"
        block
        rounded="xl"
        class="text-none mx-2"
        width="240"
        @click="setBtnTab(row)"
        :color="btnTab === row ? 'primary' : 'grey-lighten-2'"
        elevation="0"
      >
        {{ row }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import reactiveStore from "@/views/client/profile/reactive/reactiveStore";
import { mapState } from "vuex";

export default {
  name: "ButtonTab",
  props: ["active", "set_tab"],
  computed: {
    ...mapState(["wallet"]),
  },
  data: function () {
    return {
      tabTitleUpgradeOrTransfer: "Upgrade", // Default value
      tab: [
        "Portfolios",
        "Deposit",
        // "Withdraw",
        "Currency",
        "Referral",
        "Upgrade", // Placeholder, updated dynamically
        "Exchange",
        "Statement",
        "Settings",
      ],
      btnTab: this.active,
    };
  },
  methods: {
    setBtnTab(type) {
      reactiveStore.setMenuName(type);
      this.btnTab = type;
      this.set_tab(type);
      this.$router.push({
        path: "/profile",
        query: {
          ...this.$route.query,
          tab: type,
        },
      });
    },
  },
  watch: {
    wallet: {
      handler(newWallet) {
        if (newWallet && newWallet.length > 0) {
          this.tabTitleUpgradeOrTransfer =
            newWallet[0].walletType === "business account" ? "Transfer" : "Upgrade";

          // Update the 5th tab dynamically
          this.tab[4] = this.tabTitleUpgradeOrTransfer;
        }
      },
      immediate: true, // Run immediately on component mount
      deep: true, // Watch for deep changes
    },
  },
  created() {
    if (this.wallet && this.wallet.length > 0) {
      this.tabTitleUpgradeOrTransfer =
        this.wallet[0].walletType === "business account" ? "Transfer" : "Upgrade";

      // Set tab[4] to the correct value
      this.tab[4] = this.tabTitleUpgradeOrTransfer;
    }

    this.btnTab = this.$route.query.tab || this.tab[0];
    reactiveStore.setMenuName(this.btnTab);
  },
};
</script>

<style scoped></style>
