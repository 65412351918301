<template>
  <v-card flat>
    <template v-slot:text>
      <v-row>
        <v-col cols="12">
          <v-alert
            border="top"
            type="success"
            icon="mdi-account-clock-outline"
            variant="outlined"
            prominent
            class="d-flex justify-center mb-2"
          >
            <v-card width="300px" max-width="auto">
              <v-card-title
                class="text-uppercase font-weight-black"
                style="font-size: smaller"
                >Total subscribe</v-card-title
              >
              <v-card-text
                class="text-capitalize text-overline text-decoration-underline text-h6"
              >
                <span style="font-size: larger">{{
                  convertNumber1000To1K1M1B(totalSubscribe)
                }}</span>
              </v-card-text>
            </v-card>
          </v-alert>
        </v-col>
      </v-row>
      <v-text-field
        v-model="search"
        label="Search customer subscribe..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="subscribeCollection"
      :items-per-page="perPage"
      :server-items-length="totalCount"
      :loading="loading"
      :sort-by="[{ key: 'name', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center">
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                  v-show="false"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                md="12"
                lg="12"
                xl="12"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="perPage"
                    :items="[10, 25, 50, 100, 1000, 10000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="perPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.status`]="{ item }">
        <div class="text-success">{{ item.status }}</div>
      </template>
      <template v-slot:[`item.confirm_at`]="{ item }">
        {{ item.confirm_at !== null ? new Date(item.confirm_at).toLocaleString() : "" }}
      </template>
      <template v-slot:[`item.staff.fullName`]="{ item }">
        {{ item.staff !== null ? item.staff.fullName : "" }}
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
            @update:model-value="populatesubscribeCollection"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { URL_FETCH_CUS_SUBSCRIBE_WITH_PAGINATION } from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import { formatNumber, originalCode } from "@/utils/formatNumber";

export default {
  data: () => ({
    snack: false,
    visible: false,
    loading: false, // Loading state

    items: [],
    subscribeCollection: [],

    search: null,
    snackColor: null,
    snackText: null,
    totalSubscribe: 0,

    //pagination
    page: 1,
    perPage: 10,
    pageCount: 0,
    totalCount: 0, // Total records count

    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    headers: [
      { title: "name", key: "name", align: "center" },
      { title: "Email", key: "email", align: "center" },
      { title: "Job", key: "job", align: "center" },
      { title: "Country", key: "country", align: "center" },
      { title: "Status", key: "status", align: "center" },
      { title: "Confirmed at", key: "confirm_at", align: "center" },
      { title: "Confirmed by", key: "staff.fullName", align: "center" },
    ],
  }),
  watch: {
    page() {
      this.populatesubscribeCollection();
    },
    perPage() {
      this.populatesubscribeCollection();
    },
    search() {
      this.populatesubscribeCollection();
    },
  },

  created() {
    this.populatesubscribeCollection();
  },
  methods: {
    async populatesubscribeCollection() {
      const params = {
        page: this.page,
        perPage: this.perPage,
        textSearch: this.search ? this.search : null,
      };

      await axios
        .get(URL_FETCH_CUS_SUBSCRIBE_WITH_PAGINATION, {
          params,
          headers: getTokenHeaders(),
        })
        .then((response) => {
          if (response.data && response.data.customerSubscribeCollection) {
            this.subscribeCollection = response.data.customerSubscribeCollection;
            this.totalCount = response.data.totalCount;
            this.pageCount = response.data.pageCount;
            this.items.push(this.subscribeCollection);
            this.visible = this.subscribeCollection.length !== 0 ? true : false;
            this.totalSubscribe = this.subscribeCollection.length;
          } else {
            console.warn("Empty API Response");
            this.subscribeCollection = [];
          }
        });
    },

    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },
  },
};
</script>
