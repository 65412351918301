<template>
  <v-dialog v-model="dialogVisible" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Details</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <!-- Code Block with Copy Functionality -->
          <v-col cols="12">
            <v-textarea
              v-model="code"
              :label="copy"
              rows="4"
              readonly
              outlined
              @click:prepend="copyCode"
              prepend-icon="mdi-content-copy"
              :class="{'copied': copied, 'copy-icon': true}"
            ></v-textarea>
          </v-col>

          <!-- QR Code -->
          <v-col cols="12" class="d-flex justify-center">
            <v-img :src="qrCodeUrl" max-width="200" />
          </v-col>

          <!-- Name Deposit, Phone, and Address -->
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="name"
              label="Name Deposit"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="phone"
              label="Phone"
              outlined
              dense
              type="tel"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="address"
              label="Address"
              outlined
              dense
              rows="2"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="blue" text @click="dialogVisible = false">Close</v-btn>
        <v-btn color="green" text @click="submitData">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QRCode from "qrcode";

export default {
  data() {
    return {
      dialogVisible: false, // To toggle dialog visibility
      copied: false, // To toggle the copied icon state
      snackbar: false, // To control the snackbar visibility
      
      code: "1234567890ABCDEF", // Example code to be copied
      qrCodeUrl: "", // QR code URL
      name: "",
      phone: "",
      address: "",
      copy: "Code"
    };
  },
  mounted() {
    // Generate the QR code when the component is mounted
    QRCode.toDataURL("https://example.com", (err, url) => {
      if (!err) {
        this.qrCodeUrl = url;
      }
    });
  },
  methods: {
    // Method to copy code to clipboard
    copyCode() {
      navigator.clipboard.writeText(this.code).then(() => {
        // Show feedback
        this.copied = true;
        this.snackbar = true;
        this.copy = "Copied";
        
        // Reset the copied state after 1 second (for animation)
        setTimeout(() => {
          this.copied = false;
          this.copy = "Code";
        }, 1000);
      });
    },

    // Method to handle the form submission (optional)
    submitData() {
      const formData = {
        name: this.name,
        phone: this.phone,
        address: this.address,
        code: this.code,
      };
      // Do something with the formData (e.g., send it to a server)
      console.log(formData);
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.headline {
  font-size: 1.5rem;
  font-weight: bold;
}

.v-card {
  padding: 20px;
}

.v-textarea,
.v-text-field {
  margin-bottom: 16px;
}

.v-img {
  margin-top: 20px;
}

/* CSS for the animation when the icon changes */
.copied {
  transition: all 0.3s ease;
  color: green;
}
</style>
