<template>
  <FrontendLayout>
    <div class="bg-grey-lighten-4">
      <!--
      <h1
        class="text-uppercase text-center text-blue-darken-3 pa-5"
        style="letter-spacing: 5px"
      >
        {{ data.title }}
      </h1>
      -->
      <div class="margin-auto">
        <v-row>
          <v-col cols="12" md="8" sm="8" lg="8" xl="8">
            <v-container class="pa-5 text-center">
              <!-- Banner -->
              <v-card class="pa-1 rounded-lg" color="#5897fb">
                <v-img :src="getImage()" height="300" cover></v-img>
              </v-card>
              <h2 class="font-weight-bold text-red my-10">
                {{ data?.title }}
              </h2>
              <!-- Referral Info -->
              <v-card class="mt-5 pa-5 rounded-lg" v-show="false">
                <h1 class="text-h5 font-weight-bold my-5">My Invitation Record</h1>
                <h1 class="text-h4 font-weight-bold">
                  $ {{ convertNumber1000To1K1M1B(totalReward) }}
                </h1>
                <p class="text-caption mt-3">
                  Total reward amount
                  <v-icon small color="red">mdi-help-circle</v-icon>
                </p>
                <v-row justify="center" class="mt-3">
                  <v-col cols="4">
                    <p class="text-h5 font-weight-bold">
                      {{ convertNumber1000To1K1M1B(successfullInvited) }}
                    </p>
                    <p class="text-caption">Successfully</p>
                    <p class="text-caption">Invited</p>
                  </v-col>
                  <v-col cols="4">
                    <p class="text-h5 font-weight-bold">
                      {{ convertNumber1000To1K1M1B(kyc) }}
                    </p>
                    <p class="text-caption">Successfully</p>
                    <p class="text-caption">completed KYC</p>
                  </v-col>
                  <v-col cols="4">
                    <p class="text-h5 font-weight-bold">
                      {{ convertNumber1000To1K1M1B(deposit) }}
                    </p>
                    <p class="text-caption">Successfully</p>
                    <p class="text-caption">deposited</p>
                  </v-col>
                </v-row>
              </v-card>

              <p
                class="text-content text-justify font-format pa-5"
                v-html="data?.desc"
              ></p>

              <!-- How to Invite -->
              <!-- <v-card class="mt-5 pa-5 rounded-lg text-left">
                <h3 class="font-weight-bold mb-5">How to invite?</h3>
                <p class="text-blur font-format">1. Click the "Invite Now"</p>
                <p class="text-blur font-format">
                  2. Share your referral link, code, or QR to your friend
                </p>
              </v-card> -->

              <!-- Invite Button -->
              <v-btn block color="red" class="mt-5 py-3" dark @click="showDialogInvation">
                Invite Now
              </v-btn>
            </v-container>
          </v-col>

          <v-col cols="12" md="4" sm="4" lg="4" xl="4">
            <div style="height: auto; top: 55px; position: sticky">
              <h2 style="letter-spacing: 2px">Related</h2>
              <hr />
              <CardRelated :events="events" />
            </div>
          </v-col>
        </v-row>

        <br /><br /><br />
      </div>
    </div>

    <!-- Botton sheets Share -->
    <div class="text-center">
      <v-bottom-sheet v-model="visible">
        <v-card class="text-center" height="auto" style="background-color: #e0e0e0">
          <v-card-title>
            <h4 class="mt-3">Share your code</h4>
          </v-card-title>
          <v-divider class="mb-0"></v-divider>
          <v-divider class="mb-0"></v-divider>
          <v-divider class="mb-0"></v-divider>
          <v-card-text>
            <v-row justify="center" class="my-3">
              <v-col cols="4" class="text-center">
                <v-btn icon @click="copyCode" size="x-large" class="rounded-circle mb-3">
                  <v-icon large>mdi-content-copy</v-icon>
                </v-btn>
                <p>Copy code</p>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-btn icon @click="shareLink" size="x-large" class="rounded-circle mb-3">
                  <v-icon large>mdi-share-variant</v-icon>
                </v-btn>

                <p>Share Link</p>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-btn
                  icon
                  @click="generateQRCode"
                  size="x-large"
                  class="rounded-circle mb-3"
                >
                  <v-icon large>mdi-qrcode</v-icon>
                </v-btn>
                <p>Share QR</p>
                <v-dialog activator="parent" max-width="340">
                  <template v-slot:default="{ isActive }">
                    <v-card prepend-icon="mdi-qrcode-scan" title="QR Code">
                      <div>
                        <v-img
                          :src="`https://api.qrserver.com/v1/create-qr-code/?data=${qrToShare}&size=150x150`"
                          alt="QR Code"
                          cover
                        ></v-img>
                      </div>
                      <template v-slot:actions>
                        <v-btn
                          class="ml-auto"
                          text="Close"
                          @click="isActive.value = false"
                        ></v-btn>
                      </template>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
            <div
              class="text-center py-5"
              style="border-radius: 5px; background-color: #fafafa"
            >
              <div>
                <v-p
                  class="text-h5 text-red font-weight-black"
                  style="letter-spacing: 3px !important"
                  v-model="code"
                >
                  {{ code }}
                </v-p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </div>
    <!-- Botton sheets Share -->
  </FrontendLayout>
</template>
<script>
import FrontendLayout from "@/views/client/components/FrontendLayout.vue";
import CardRelated from "@/views/client/about-us/components/CardRelated.vue";
import {
  URL_INVITE_FRIEND,
  URL_PROJECT,
  Pah_Invite_Friend,
  URL_CUSTOMERS,
} from "@/utils/apiUrl";
import axios from "axios";
import { getTokenHeaders } from "@/utils/headerToken";
import { formatNumber } from "@/utils/formatNumber";

export default {
  name: "AboutUs",
  components: { FrontendLayout, CardRelated },
  data() {
    return {
      data: [],
      events: [],
      customers: [],
      path: Pah_Invite_Friend,
      defaultImage:
        "https://thumb.ac-illust.com/55/55b4c3cab2ae5c35a359562b95b89c14_t.jpeg",

      visible: false,
      code: null,
      token: null,
      referralLink: null,
      urlToShare: "https://example.com", // URL to share
      linkToShare: null, //"Check this out!", // Text to share
      baseUrl: window.location.origin,
      qrToShare: null, // QR to share
      successfullInvited: 0,
      kyc: 0,
      deposit: 0,
      totalReward: 0,
      imgQrCode: null,
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_INVITE_FRIEND, { headers: getTokenHeaders() })
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },

    async fetchProjects() {
      try {
        const response = await axios.get(URL_PROJECT, {
          headers: getTokenHeaders(),
        });
        //this.events = response.data;
        if (response.data !== null) {
          this.events = response.data
            .filter((project) => project.project_type === "Active")
            .sort(() => 0.5 - Math.random())
            .slice(0, 6);
        }
      } catch (error) {
        console.error("Error fetching projects event:", error);
      }
    },
    async filterItemInCustomers() {
      try {
        const response = await axios.get(URL_CUSTOMERS + "null", {
          headers: getTokenHeaders(),
        });
        if (response.data !== null) {
          // block total reward
          this.totalReward = response.data
            .filter(
              (customer) =>
                customer.myReferral === this.code &&
                customer.referralReward.length > 0 &&
                customer.referralReward[0].amount_reward !== null
            )
            .reduce((sum, customer) => {
              // Access amount_reward from the first element in the referralReward array
              const amount = Number(customer.referralReward[0].amount_reward) || 0;
              return sum + amount;
            }, 0);

          // block total reward

          this.successfullInvited = this.totalReward;

          // block KYC
          this.kyc = response.data
            .filter(
              (customer) =>
                customer.status === "done" &&
                customer.myReferral === this.code &&
                customer.referralReward.length > 0 &&
                customer.referralReward[0].amount_reward !== null
            )
            .reduce((sum, customer) => {
              // Access amount_reward from the first element in the referralReward array
              const amount = Number(customer.referralReward[0].amount_reward) || 0;
              return sum + amount;
            }, 0);
          // block KYC

          // block deposit
          this.deposit = response.data
            .filter(
              (customer) =>
                customer.myReferral === this.code &&
                customer.deposit.length > 0 &&
                customer.deposit[0].deposit_amount !== null
            )
            .reduce((sum, customer) => {
              // Access amount_reward from the first element in the referralReward array
              const amount = Number(customer.deposit[0].deposit_amount) || 0;
              return sum + amount;
            }, 0);
          // block deposit
        }
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    },

    getImage() {
      const image =
        (this.data || this.data.image) === null
          ? this.defaultImage
          : this.path + this.data?.image;
      return image;
    },

    async showDialogInvation() {
      if (this.token !== null) {
        this.visible = true;
      } else {
        this.visible = false;
        this.$router.push({ path: "/sign-in" });
      }
    },
    async shareLink() {
      if (this.token !== null) {
        const shareData = {
          title: "Share link",
          text: "Check this out!",
          url: this.linkToShare,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },
    async copyCode() {
      await navigator.clipboard.writeText(this.code);
    },
    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },
  },
  created() {
    this.token = localStorage.getItem("client_token");
    setTimeout(() => {
      const userProfile = this.$store.getters.getProfile;
      this.code = this.baseUrl + "/sign-up?code=" + userProfile.myReferral;
      this.linkToShare = this.baseUrl + "/sign-up?code=" + userProfile.myReferral;
      this.qrToShare = this.baseUrl + "/sign-up?code=" + userProfile.myReferral;

      this.filterItemInCustomers();
      this.fetchData();
      this.fetchProjects();
    }, 2000);
  },
};
</script>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
}
.text-blur {
  opacity: 0.7;
}
</style>
