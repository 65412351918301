<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search fee..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="feeCollection"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[{ key: 'created_at', order: 'desc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center mb-4">
                <v-btn
                  v-show="!visible"
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visibleMsg"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <input type="hidden" v-model="hiddenId" />
                    <v-text-field
                      v-model.number.trim="tax_fee"
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                      label="Tax"
                      placeholder="Tax"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.number.trim="deposit_fee"
                      label="Deposit"
                      placeholder="Deposit"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.number.trim="withdraw_fee"
                      label="Withdraw"
                      placeholder="Withdraw"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.number.trim="service_fee"
                      label="Service"
                      placeholder="Service"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.number.trim="transfer_fee"
                      label="Transfer"
                      placeholder="Transfer"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.number.trim="consultant_fee"
                      label="Consultant"
                      placeholder="Consultant"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.number.trim="amount_withdraw"
                      label="Amount withdraw"
                      placeholder="Amount withdraw"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.number.trim="percent_crypto"
                      label="% Crypto"
                      placeholder="% Crypto"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.number.trim="business_get_commission"
                      label="Businese Commission"
                      placeholder="Businese Commission"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.number.trim="mini_deposit"
                      label="Mini Deposit"
                      placeholder="Mini Deposit"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model.number.trim="max_deposit"
                      label="Max Deposit"
                      placeholder="Max Deposit"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-label class="font-weight-regular mb-1">Crypto Desc</v-label>
                    <QuillEditor
                      toolbar="essential"
                      contentType="html"
                      style="height: 150px"
                      v-model:content="desc_crypto"
                      theme="snow"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-label class="font-weight-regular mb-1">Busines Desc</v-label>
                    <QuillEditor
                      toolbar="essential"
                      contentType="html"
                      style="height: 150px"
                      v-model:content="business_desc"
                      theme="snow"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.tax_fee`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.tax_fee) }}
      </template>
      <template v-slot:[`item.deposit_fee`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.deposit_fee) }}
      </template>
      <template v-slot:[`item.withdraw_fee`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.withdraw_fee) }}
      </template>
      <template v-slot:[`item.service_fee`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.service_fee) }}
      </template>
      <template v-slot:[`item.transfer_fee`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.transfer_fee) }}
      </template>
      <template v-slot:[`item.consultant_fee`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.consultant_fee) }}
      </template>
      <template v-slot:[`item.amount_withdraw`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.amount_withdraw) }}
      </template>
      <template v-slot:[`item.percent_crypto`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.percent_crypto) }}
      </template>
      <template v-slot:[`item.business_get_commission`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.business_get_commission) }}
      </template>
      <template v-slot:[`item.mini_deposit`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.mini_deposit) }}
      </template>
      <template v-slot:[`item.max_deposit`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.max_deposit) }}
      </template>
      <template v-slot:[`item.desc_crypto`]="{ item }">
        <div
          v-html="
            item.desc_crypto !== null
              ? item.desc_crypto.length > 50
                ? item.desc_crypto.substring(0, 50) + '...'
                : item.desc_crypto
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.business_desc`]="{ item }">
        <div
          v-html="
            item.business_desc !== null
              ? item.business_desc.length > 50
                ? item.business_desc.substring(0, 50) + '...'
                : item.business_desc
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <v-icon icon="mdi-clipboard-text-clock"></v-icon>
        {{ new Date(item.created_at).toLocaleString() }}
      </template>
      <template v-slot:[`item.staff.fullName`]="{ item }">
        {{ item.staff.fullName }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-2" size="x-small" color="red-lighten-2" @click="editItem(item)">
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
        <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { URL_FEE } from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import { formatNumber, originalCode } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: false,
    visibleMsg: true,
    isSubmitting: false,

    items: [],
    feeCollection: [],
    validation: {
      min: 0,
      max: 10,
      decimal: 10,
    },

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,

    tax_fee: 0,
    deposit_fee: 0,
    withdraw_fee: 0,
    service_fee: 0,
    transfer_fee: 0,
    consultant_fee: 0,
    amount_withdraw: 0,
    percent_crypto: 0,
    business_get_commission: 0,
    mini_deposit: 0,
    max_deposit: 0,

    desc_crypto: null,
    business_desc: null,

    editedIndex: -1,
    hiddenId: 0,

    page: 1,
    itemsPerPage: 10,

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Tax", key: "tax_fee", align: "center" },
      { title: "Deposit", key: "deposit_fee", align: "center" },
      { title: "Withdraw", key: "withdraw_fee", align: "center" },
      { title: "Service", key: "service_fee", align: "center" },
      { title: "Transfer", key: "transfer_fee", align: "center" },
      { title: "Consultant", key: "consultant_fee", align: "center" },
      { title: "Amount Withdraw", key: "amount_withdraw", align: "center" },
      { title: "Mini Deposit", key: "mini_deposit", align: "center" },
      { title: "Max Deposit", key: "max_deposit", align: "center" },
      { title: "% Crypto", key: "percent_crypto", align: "center" },
      { title: "Crypto Desc", key: "desc_crypto", align: "center" },
      { title: "Busi Commission", key: "business_get_commission", align: "center" },
      { title: "Busi Desc", key: "business_desc", align: "center" },
      { title: "Created at", key: "created_at", align: "center" },
      { title: "created by", key: "staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Fee" : "Update Fee";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.feeCollection.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulatefeeCollection();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.tax_fee = 0;
      this.deposit_fee = 0;
      this.withdraw_fee = 0;
      this.service_fee = 0;
      this.transfer_fee = 0;
      this.consultant_fee = 0;
      this.amount_withdraw = 0;
      this.desc_crypto = 0;
      this.desc_crypto = null;
      this.business_get_commission = 0;
      this.mini_deposit = 0;
      this.max_deposit = 0;
      this.business_desc = null;
      this.visibleMsg = true;
    },

    async PopulatefeeCollection() {
      await axios.get(URL_FEE, { headers: getTokenHeaders() }).then((res) => {
        this.feeCollection = res.data;
        this.items.push(res.data);
        this.visible = res.data.length !== 0 ? true : false;
      });
    },

    editItem(item) {
      this.editedIndex = this.feeCollection.indexOf(item);
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);
      this.tax_fee = item.tax_fee;
      this.deposit_fee = item.deposit_fee;
      this.withdraw_fee = item.withdraw_fee;
      this.service_fee = item.service_fee;
      this.transfer_fee = item.transfer_fee;
      this.consultant_fee = item.consultant_fee;
      this.amount_withdraw = item.amount_withdraw;
      this.percent_crypto = item.percent_crypto;
      this.desc_crypto = item.desc_crypto;
      this.business_get_commission = item.business_get_commission;
      this.mini_deposit = item.mini_deposit;
      this.max_deposit = item.max_deposit;
      this.business_desc = item.business_desc;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.feeCollection.indexOf(item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.feeCollection.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_FEE + id;
      axios
        .delete(url, { headers: getTokenHeaders() })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async submitData() {
      const staffLoginId = originalCode(parseInt(localStorage.getItem("id")));
      const data = {
        tax_fee: parseFloat(this.tax_fee),
        deposit_fee: parseFloat(this.deposit_fee),
        withdraw_fee: parseFloat(this.withdraw_fee),
        service_fee: parseFloat(this.service_fee),
        transfer_fee: parseFloat(this.transfer_fee),
        consultant_fee: parseFloat(this.consultant_fee),
        amount_withdraw: parseFloat(this.amount_withdraw),
        percent_crypto: parseFloat(this.percent_crypto),
        mini_deposit: parseFloat(this.mini_deposit),
        max_deposit: parseFloat(this.max_deposit),
        desc_crypto: this.desc_crypto,
        business_get_commission: parseFloat(this.business_get_commission),
        business_desc: this.business_desc,
        created_by: staffLoginId,
      };

      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_FEE + id;

        await axios
          .patch(url, data, { headers: getTokenHeaders() })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visibleMsg = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );

        this.close();
      } // create new
      else {
        await axios
          .post(URL_FEE, data, { headers: getTokenHeaders() })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visibleMsg = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      }

      this.clearContent();
      this.PopulatefeeCollection();

      this.dialog = false;
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },

    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },
  },
};
</script>
