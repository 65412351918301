<template>
  <v-card color="white" height="" rounded="0">
    <v-row class="margin-auto">
      <v-col md="3" cols="12">
        <v-img
          :src="company.logo !== null ? company.logo : '../../../assets/logo.png'"
          alt="no logo"
          class="footer-logo text-center"
        />
        <p class="font-format" v-html="contact.address"></p>

        <bottomSheetShare></bottomSheetShare>
      </v-col>

      <v-col md="3" sm="12"></v-col>

      <v-col md="3" cols="12">
        <div class="d-flex flex-column mb-6 bg-surface-variants">
          <v-sheet class="ma-1 pa-1">
            <router-link
              to="/"
              class="text-decoration-none text-blue-darken-3 font-format"
            >
              Useful Links
            </router-link>
          </v-sheet>
          <v-sheet class="ma-1 pa-1">
            <router-link
              to="/about-us"
              class="text-decoration-none text-black font-format"
            >
              About Us
            </router-link>
          </v-sheet>
          <v-sheet class="ma-1 pa-1">
            <router-link
              to="/contact-us"
              class="text-decoration-none text-black font-format"
            >
              Contact
            </router-link>
          </v-sheet>
          <v-sheet class="ma-1 pa-1">
            <router-link
              to="/projects"
              class="text-decoration-none text-black font-format"
            >
              Projects
            </router-link>
          </v-sheet>
          <v-sheet class="ma-1 pa-1">
            <router-link
              to="/privacy"
              class="text-decoration-none text-black font-format"
            >
              Terms & Policy
            </router-link>
          </v-sheet>
        </div>
      </v-col>

      <v-col md="3" cols="12">
        <div class="d-flex flex-column mb-6 bg-surface-variants">
          <v-sheet class="ma-1 pa-1">
            <router-link
              to="/"
              class="text-decoration-none text-blue-darken-3 font-format"
            >
              Subscribe to our newsletter
            </router-link>
          </v-sheet>

          <v-text-field
            v-model="emailSubscribe"
            label="Enter Email"
            type="text"
            variant="underlined"
            placeholder="Enter Email"
          ></v-text-field>

          <div>
            <v-btn
              color="blue-darken-3 font-format"
              rounded="xl"
              size="large"
              :disabled="isSubmitting"
              @click="navigatorToContactUs(emailSubscribe)"
            >
              Subscribe
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <hr />

    <v-row class="d-flex justify-lg-space-between ma-1">
      <!-- <v-col md="7" cols="12" class="">
        <v-img
          v-show="false"
          :src="company.logo !== null ? company.logo : '../../../assets/logo.png'"
          alt="no logo"
          class="footer-logo"
        />
        <p class="text-center text-footer-copyright">{{ company.companyName }}</p>
      </v-col> -->

      <v-col cols="12" class="align-self-center">
        <p class="text-footer-copyright text-center">{{ company.copyRight }}</p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { useEmailSubscribe } from '@/store/recordFrontendStore';
import { Path_LOGO_COMPANY } from '@/utils/apiUrl';
import { mapState } from 'vuex';
import bottomSheetShare from './dialog/bottomSheetShare.vue';
import { LOADING_IMAGE } from '@/utils/customerApiUrl';

export default {
  name: 'FooterPage',
  components: {
    bottomSheetShare,
  },
  computed: {
    ...mapState(['company', 'contact']),
  },
  data() {
    return {
      LOADING_IMAGE,
      dataContactUs: '',
      path: Path_LOGO_COMPANY,
      emailSubscribe: null,

      isSubmitting: false,
    };
  },
  methods: {
    navigatorToContactUs(email) {
      if (this.isSubmitting) return; // Prevent multiple submissions
      this.isSubmitting = true;
      try {
        // Send your form data to the server
        let useEmail = useEmailSubscribe();
        useEmail.stateEmailSubscribe(email);
        this.$router.push({
          name: 'ContactUs',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isSubmitting = false; // Re-enable the button after the request
      }
    },
  },
};
</script>

<style scoped>
.footer-logo {
  width: 60%;
  height: 40px;
}
</style>
