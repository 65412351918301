<template>
  <div>
    <v-btn
      variant="text"
      @click="share('facebook', adminSocialMedia.facebook ?? '')"
      icon="mdi-facebook"
      color="blue-darken-3"
      size="small"
    ></v-btn>

    <v-btn
      variant="text"
      @click="share('facebook', adminSocialMedia.telegram ?? '')"
      icon="mdi-send-circle"
      color="blue-darken-3"
      size="small"
    ></v-btn>

    <v-btn
      variant="text"
      @click="share('facebook', adminSocialMedia.twitter ?? '')"
      icon="mdi-twitter"
      color="blue-darken-3"
      size="small"
    ></v-btn>
    <!--
    <v-btn
      variant="text"
      @click="checkAuth"
      icon="mdi-youtube"
      color="blue-darken-3"
      size="small"
    ></v-btn>
    -->
    <v-btn
      variant="text"
      @click="share('facebook', adminSocialMedia.whatApp ?? '')"
      icon="mdi-whatsapp"
      color="blue-darken-3"
      size="small"
    ></v-btn>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "BottomSheetShare",
  computed: {
    ...mapState(["adminSocialMedia"]),
  },
  data: () => ({
    visible: false,
    token: null,
  }),
  created() {
    setTimeout(() => {
      this.token = localStorage.getItem("client_token");
    }, 500);

    //this.$store.dispatch("getUserProfile");
  },
  methods: {
    // async checkAuth() {
    //   if (this.token !== null) {
    //     this.$router.push({ path: "/invitation" });
    //   } else {
    //     this.visible = false;
    //     this.$router.push({ path: "/sign-in" });
    //   }
    // },
    share(platform, link) {
      let shareUrl;
      if (this.token !== null) {
        switch (platform) {
          case "telegram":
            shareUrl = `${link}`;
            break;
          case "facebook":
            shareUrl = `${link}`;
            break;
          case "twitter":
            shareUrl = `${link}`;
            break;
          case "whatsapp":
            shareUrl = `${link}`;
            break;
          default:
            return;
        }

        window.open(shareUrl, "_blank");
      } else {
        this.visible = false;
        this.$router.push({ path: "/sign-in" });
      }
    },
    /**
    async shareLink() {
      if (this.token !== null) {
        const shareData = {
          title: "Share link",
          text: "Check this out!",
          url: this.linkToShare,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },
    
    async shareQR() {
      if (this.token !== null) {
        const shareData = {
          title: "Share QR code",
          text: "Check this out!",
          url: this.qrToShare,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },  
    async copyCode() {
      if (this.token !== null) {
        await navigator.clipboard.writeText(this.code);
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    }, */
  },
};
</script>
