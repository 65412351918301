import { createStore } from 'vuex';
import { API_URL_USER, BANK_TYPE, COMPANY, CUSTOMER_PROFILE } from '@/utils/customerApiUrl';
import axiosInstance from '@/servicesAxios/axiosInstance';
import { getParams, getClientToken } from '@/utils/function';

export default createStore({
  state: {
    token: getClientToken(),
    login: false,
    profile: {
      email: '',
      firstName: '',
      lastName: '',
      name: '',
      profile: '',
      numberLevel: '',
      phone: '',
      status: '',
      myReferral: '',
      identity: '',
      expireDate: '',
      gender: '',
      nationality: '',
      countryOfBirth: '',
      cityOfBirth: '',
      dateOfBirth: '',
      occupation: '',
      typeCard: '',
      cardNumber: '',
      frontCard: '',
      backCard: '',
      referral: '',
      referralLink: '',
    },
    exchangeWithdraw: {
      id: 0,
      name_currency: null,
      logo: null,
      rate: 0,
      country_id: null,
      desc: null,
    },
    fee: {
      deposit_fee: 0,
      withdraw_fee: 0,
      service_fee: 0,
      transfer_fee: 0,
      consultant_fee: 0,
      amount_withdraw: 0,
    },
    wallet: [
      {
        id: 0,
        availableBalance: 0,
        accountBalance: 0,
        bankAccount: 'N/A',
        walletType: '',
        pin_code: '',
      },
    ],
    socialMedia: {
      facebookLink: null,
      telegramLink: null,
      whatsApp: null,
      linkedIn: null,
      twitterLink: null,
      remark: null,
    },
    adminSocialMedia: {
      facebook: null,
      telegram: null,
      twitter: null,
      whatApp: null,
      youtube: null,
      chat_link: null,
    },
    bankType: [
      // [
      //     {
      //         "id": null,
      //         "bankName": null,
      //         "logoBank": null,
      //         "paymentMethod": null,
      //     },
      // ]
    ],
    bankAccountWithdraw: [
      // {
      //     "id": null,
      //     "fullName": null,
      //     "typeBank": null,
      //     "wallet": null,
      //     "cardNumber": null,
      // },
    ],
    company: {},
    aboutUs: {},
    project: {},
    contact: {},
    ourProcess: {},
    covid: {},
    totalReport: {},
    featureProject: {},
    country: '',
    compensations: [],
    startRankReferrals: [],
    notification: 0,
    feeAccountBalance: {},
    companies: [],
    //
    //paymentMethods : [],
    //allBankAccountDeposit: [],
    //depositExchange: [],
    defaultCurrency: '',
    bankAccountDeposit: [],
  },
  getters: {
    getProfile: (state) => state.profile,
  },
  mutations: {
    SET_LOGIN(state, payload) {
      state.login = payload;
    },
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    SET_EXCHANGE_WITHDRAW(state, payload) {
      state.exchangeWithdraw = payload;
    },
    SET_FEE(state, payload) {
      state.fee = payload;
    },
    SET_WALLET(state, payload) {
      state.wallet = payload;
    },
    SET_SOCIAL_MEDIA(state, payload) {
      state.socialMedia = payload;
    },
    SET_ADMIN_SOCIAL_MEDIA(state, payload) {
      state.adminSocialMedia = payload;
    },
    SET_BANK_TYPE(state, payload) {
      state.bankType = payload;
    },
    SET_BANK_ACCOUNT_WITHDRAW(state, payload) {
      state.bankAccountWithdraw = payload;
    },
    SET_COMPANY(state, payload) {
      state.company = payload;
    },
    SET_ABOUT_US(state, payload) {
      state.aboutUs = payload;
    },
    SET_PROJECT(state, payload) {
      state.project = payload;
    },
    SET_CONTACT(state, payload) {
      state.contact = payload;
    },
    SET_OUR_PROCESS(state, payload) {
      state.ourProcess = payload;
    },
    SET_COVID(state, payload) {
      state.covid = payload;
    },
    SET_FEATURE_PROJECT(state, payload) {
      state.featureProject = payload;
    },
    SET_TOTAL_REPORT(state, payload) {
      state.totalReport = payload;
    },
    SET_COUNTRY(state, payload) {
      state.country = payload;
    },
    SET_COMPENSATION(state, payload) {
      state.compensations = payload;
    },
    SET_STAR_RANK_REFERRALS(state, payload) {
      state.startRankReferrals = payload;
    },
    SET_NOTIFICATION(state, payload) {
      state.notification = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_FEE_ACCOUNT_BALANCE(state, payload) {
      state.feeAccountBalance = payload;
    },
    SET_COMPANIES(state, payload) {
      state.companies = payload;
    },
    SET_DEFAULT_CURRENCY(state, payload) {
      state.defaultCurrency = payload;
    },
    SET_BANK_ACCOUNT_DEPOSIT(state, payload) {
      state.bankAccountDeposit = payload;
    },
  },
  actions: {
    async getUserProfile({ commit }) {
      if (await getClientToken()) {
        axiosInstance
          .get(CUSTOMER_PROFILE)
          .then((response) => {
            if (response.data !== null) {
              const {
                user,
                wallet,
                socialMedia,
                adminSocialMedia,
                compensations,
                startRankReferrals,
                notification,
                feeAccountBalance,
                exchangeWithdraw,
              } = response.data;
              commit('SET_PROFILE', user);
              commit('SET_EXCHANGE_WITHDRAW', exchangeWithdraw[0]);
              commit('SET_WALLET', wallet);
              commit('SET_SOCIAL_MEDIA', socialMedia[0]);
              commit('SET_ADMIN_SOCIAL_MEDIA', adminSocialMedia);
              commit('SET_LOGIN', true);
              commit('SET_COMPENSATION', compensations);
              commit('SET_STAR_RANK_REFERRALS', startRankReferrals);
              commit('SET_NOTIFICATION', notification);
              commit('SET_FEE_ACCOUNT_BALANCE', feeAccountBalance);
            }
          })
          .catch((error) => {
            commit('SET_LOGIN', false);
            console.log('profile : ', error);
          });
      }
    },

    async getCompanyInfo({ commit }) {
      let country_name = '';

      if (Object.keys(this.state.company).length === 0) {
        // get current country
        await fetch(API_URL_USER)
          .then((response) => response.json())
          .then((data) => {
            country_name = data.country_name ? data.country_name : data.name;
            commit('SET_COUNTRY', country_name);
          })
          .catch((error) => {
            console.error(error);
          });


        let paramsInfo = getParams({
          type: 'info',
          country: country_name,
        });
        axiosInstance
          .get(COMPANY + paramsInfo)
          .then((response) => {
            if (response.data !== null) {
              const {
                company,
                ourProcess,
                contact,
                project,
                aboutUs,
                covid,
                featureProject,
                totalReport,
                companies,
                countries,
              } = response.data;

              // blog country
              if (countries.length < 1) {
                window.location.href = '/not-found';
              }
              //
              else {
                const result = countries.filter((obj) => JSON.stringify(obj).toLowerCase().includes(country_name.toString().toLowerCase()));
                if (result.length < 1) {
                  window.location.href = '/not-found';
                }
              }

              commit('SET_COMPANY', company);
              commit('SET_ABOUT_US', aboutUs);
              commit('SET_CONTACT', contact);
              commit('SET_PROJECT', project);
              commit('SET_OUR_PROCESS', ourProcess);
              commit('SET_COVID', covid);
              commit('SET_FEATURE_PROJECT', featureProject);
              commit('SET_TOTAL_REPORT', totalReport);
              commit('SET_COMPANIES', companies);
              let logo = localStorage.getItem('logo');
              if (!logo) {
                localStorage.setItem('logo', company.logo);
                localStorage.setItem('companyName', company.companyName);
              }
              //
              if (company) {
                const link = document.createElement('link');
                link.rel = 'icon';
                link.type = 'image/png';
                link.href = company.logo;
                document.head.appendChild(link);
                document.head.title = company.companyName;
              }
            }
          })
          .catch((error) => {
            console.log('bank type : ', error);
          });
      }

      // get banks
      if (await getClientToken()) {
        let params = getParams({
          page: 0,
          pageSize: 50,
          country: country_name,
        });
        axiosInstance
          .get(BANK_TYPE + params)
          .then((response) => {
            if (response.data !== null) {
              const {
                allBankType,
                defaultCurrency,
                fee,
                bankAccountWithdraw,
                bankAccountDeposit,
              } = response.data;
              commit('SET_BANK_TYPE', allBankType);
              commit('SET_DEFAULT_CURRENCY', defaultCurrency);
              commit('SET_FEE', fee);
              commit('SET_BANK_ACCOUNT_WITHDRAW', bankAccountWithdraw);
              commit('SET_BANK_ACCOUNT_DEPOSIT', bankAccountDeposit);
            }
          })
          .catch((error) => {
            commit('SET_LOGIN', false);
            console.log('bank type : ', error);
          });
      }
    },
  },
});
