<template>
  <v-card rounded="0" class="px-10 pa-3">
    <v-row>
      <v-col cols="6" sm="6" md="2">
        <img class="mt-2" width="200px" src="../../../assets/logo.png" alt="" />
      </v-col>

      <v-col cols="12" sm="12" md="8" class="hidden"></v-col>

    </v-row>
  </v-card>

  <div class="margin-center">
    <br />
    <br />
    <h2 class="text-center">Enter new password </h2>

    <v-form ref="formLogin">

      <v-alert
        v-if="message && error"
        elevation="2"
        type="error"
        variant="outlined"
      >
        {{ message }}
      </v-alert>

      <v-alert
        v-if="message && !error"
        elevation="2"
        type="success"
        variant="outlined"
      >
        {{ message }}
      </v-alert>

      <p class="ma-1">Enter new password</p>

      <v-text-field
        v-model="form.password"
        variant="outlined"
        density="compact"
        autocomplete="off"
        placeholder="Enter new password"
        prepend-inner-icon="mdi-lock"
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :rules="form.confirmPassword ? newPasswordRules : []"
        :type="visible ? 'text' : 'password'"
        @click:append-inner="visible = !visible"
      />

      <p class="ma-1">Enter confirm password</p>
      <v-text-field
        v-model="form.confirmPassword"
        variant="outlined"
        density="compact"
        autocomplete="off"
        placeholder="Enter confirm password"
        prepend-inner-icon="mdi-lock"
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :rules="form.confirmPassword ? [newPasswordRules, matchingPasswords] : []"
        :type="visible ? 'text' : 'password'"
        @click:append-inner="visible = !visible"
      />

      <br />

      <v-btn
        block
        rounded="xl"
        :disabled="show"
        color="blue"
        elevation="0"
        @click="handleSubmit()"
        :loading="loading"
      >
        <p class="text-capitalize">
          Submit
        </p>
      </v-btn>
    </v-form>

    <br />

  </div>
</template>

<script>
import { defineComponent } from 'vue';
import axios from 'axios';
import { CUSTOMER_RESET_PASSWORD } from '@/utils/customerApiUrl';
import { validatePassword } from '@/utils/function';

const nameRulesPass = [
  (value) => {
    if (value) return true;
    return 'Password is required.';
  },
];

export default defineComponent({
  name: 'ResetPasswordPage',
  data() {
    return {
      newPasswordRules: validatePassword,
      nameRulesPass,
      visible: false,
      show: true,
      form: {
        token: '',
        password: '',
        confirmPassword: '',
      },
      loading: false,
      error: false,
      message: '',
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      const token = this.$route.query.token;
      if (this.loading && token) {
        let payload = {
          ...this.form,
          token,
        };
        axios
          .post(CUSTOMER_RESET_PASSWORD, payload)
          .then((response) => {
            const { message } = response.data;
            if (message) {
              setTimeout(() => {
                this.message = message;
                this.error = false;
              }, 2000);

              setTimeout(() => {
                location.href = '/sign-in';
              }, 3000);
            }
          })
          .catch((error) => {
            this.error = true;
            this.loading = false;
            let message = error.response.data.message;
            if (Array.isArray(message)) {
              this.message = message[0];
            } else {
              this.message = message;
            }
          });
      }
    },

    matchingPasswords: function() {
      if (this.form.password === this.form.confirmPassword) {
        this.show = false;
        return true;
      } else {
        this.show = true;
        return 'Passwords does not match.';
      }
    },

  },
});
</script>

<style scoped></style>
