<template>
  <v-dialog v-model="dialogDisable" max-width="500px" activator="parent">
    <v-card rounded="lg">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 text-medium-emphasis ps-2 text-blur">Message</div>
        <v-btn icon="mdi-close" variant="text" @click="closeDialog"></v-btn>
      </v-card-title>

      <v-divider class="mb-4"></v-divider>

      <v-card-text>
        <div class="text-medium-emphasis mb-4">
          <p class="text-uppercase" style="color: blue">
            Are you sure you want to confirm or reject?
          </p>
          <br />
          <v-row justify="center">
            <v-col
              cols="12"
              style="text-align: center"
              v-if="typeBank === 'Cryptocurrency'"
            >
              <v-card class="text-center">
                <div style="display: flex; justify-content: center">
                  <v-img
                    :src="`https://api.qrserver.com/v1/create-qr-code/?data=${usdtQr}&size=150x150`"
                    alt="QR Code"
                    cover
                    :width="224"
                    aspect-ratio="16/9"
                  ></v-img>
                </div>
              </v-card>
              <br />
              <a :href="usdtLink" class="text-subtitle-2 text-blue-darken-3">{{
                usdtLink
              }}</a>
            </v-col>
            <v-col cols="12" v-else>
              <p>Bank Name: {{ bankName }}</p>
              <p>Bank Number: {{ cardNumber }}</p>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-divider class="mt-2"></v-divider>

      <v-card-actions class="my-2 d-flex justify-end">
        <v-btn
          class="text-none"
          rounded="xl"
          text="Cancel"
          @click="closeDialog"
        ></v-btn>
        <v-btn
          class="text-none"
          color="blue"
          rounded="xl"
          text="Confirm"
          variant="flat"
          @click="confirmOrReject(confirm)"
          :loading="loading"
        ></v-btn>
        <v-btn
          class="text-none"
          color="error"
          rounded="xl"
          text="Reject"
          variant="flat"
          @click="confirmOrReject(reject)"
          :loading="loading"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { URL_WITHDRAW } from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import axios from "axios";

export default {
  props: {
    modelValue: Boolean, // v-model binding
    item: {
      type: Array,
      default: () => [], // Ensures item is always an array
    },
  },
  data: () => ({
    loading: false,
    confirm: "done",
    reject: "fail",
  }),
  computed: {
    dialogDisable: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    usdtLink() {
      return this.item ?? this.item[0].bankAccount?.usdtLink;
    },
    usdtQr() {
      return this.item ?? this.item[0].bankAccount?.usdtQr;
    },
    typeBank() {
      return this.item ?? this.item[0].bankAccount?.typeBank;
    },
    bankName() {
      return this.item ?? this.item[0].bankAccount?.fullName;
    },
    cardNumber() {
      return this.item ?? this.item[0].bankAccount?.cardNumber;
    },
  },
  methods: {
    closeDialog() {
      this.dialogDisable = false;
    },
    async confirmOrReject(status) {
      if (!this.item.length) {
        console.warn("No items to process.");
        return;
      }

      this.loading = true;
      const params = { status };

      try {
        // Wait for all API calls to complete
        await Promise.all(
          this.item.map((item) =>
            axios.patch(`${URL_WITHDRAW}${item.id}`, params, {
              headers: getTokenHeaders(),
            })
          )
        );

        console.log("All requests completed successfully.");
        this.$emit("update"); // Notify parent component to refresh data
      } catch (error) {
        console.error("Error updating items:", error);
      } finally {
        this.loading = false;
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
