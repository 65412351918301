<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search political risk insurance..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="PoliticalRiskInsuranceCollection"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[
        { key: 'created_at', order: 'desc' },
        { key: 'title', order: 'asc' },
      ]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center mb-4">
                <v-btn
                  v-show="!visible"
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visibleMsg"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                    <v-row>
                      <v-col cols="12">
                        <input type="hidden" v-model="hiddenId" />
                        <v-text-field
                          v-model.trim="title"
                          :rules="[() => !!title || 'This field is required']"
                          label="Title"
                          placeholder="Title"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-label class="font-weight-thin x-small">Description</v-label>
                        <QuillEditor
                          toolbar="essential"
                          contentType="html"
                          style="height: 300px"
                          v-model:content="desc"
                          theme="snow"
                        />
                      </v-col>

                      <input type="hidden" v-model="hdImage" />
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <v-card variant="variant" class="mx-auto" elevation="16">
                      <v-img
                        :src="
                          previewImage === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : previewImage
                        "
                        aspect-ratio="1"
                        color="surface-variant"
                        height="450"
                        contain
                        @click="triggerFileInput"
                      >
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>

                    <v-file-input
                      v-model.trim="image"
                      accept="image/png, image/jpeg, image/bmp"
                      label="Avatar"
                      placeholder="Pick an avatar"
                      prepend-icon="mdi-camera"
                      @change="uploadImage"
                      hide-input
                      class="mt-2"
                      ref="fileInput"
                      style="display: none"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.image`]="{ item }">
        <div v-if="item.image" class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img alt="Avatar" :src="path + item.image" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.desc`]="{ item }">
        <div v-html="item.desc"></div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>
      <template v-slot:[`item.staff.fullName`]="{ item }">
        {{ item.staff.fullName }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-2" size="x-small" color="red-lighten-2" @click="editItem(item)">
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
                      <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_Our_Political_Risk_Insurance_Image,
  URL_Our_Political_Risk_Insurance,
  URL_UPLOAD_Our_Political_Risk_Insurance_IMAGE,
} from "@/utils/apiUrl";
import { getImageTokenHeaders, getTokenHeaders } from "@/utils/headerToken";
import { originalCode } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";
import { getFileExtension, getFileSizeInMB } from "@/utils/reusableFunctions";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    path: Path_Our_Political_Risk_Insurance_Image,
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: false,
    visibleMsg: true,
    isSubmitting: false,

    items: [],
    PoliticalRiskInsuranceCollection: [],

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,

    title: null,
    desc: null,
    image: null,

    previewImage: null,
    hdImage: null,

    editedIndex: -1,
    hiddenId: 0,
    page: 1,
    itemsPerPage: 10,

    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "", key: "image", align: "center" },
      { title: "Title", key: "title", align: "center" },
      { title: "Description", key: "desc", align: "center" },
      { title: "Create at", key: "created_at", align: "center" },
      { title: "Created by", key: "staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Political Risk Insurance"
        : "Update Political Risk Insurance";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.PoliticalRiskInsuranceCollection.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.populatePoliticalRiskInsuranceCollection();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.title = null;
      this.desc = null;
      this.previewImage = null;
      this.visibleMsg = true;
    },

    async populatePoliticalRiskInsuranceCollection() {
      await axios
        .get(URL_Our_Political_Risk_Insurance, { headers: getTokenHeaders() })
        .then((res) => {
          this.PoliticalRiskInsuranceCollection = res.data;
          this.items.push(res.data);
          this.visible = res.data.length !== 0 ? true : false;
        });
    },

    editItem(item) {
      this.editedIndex = this.PoliticalRiskInsuranceCollection.indexOf(item);
      this.hiddenId = item.id;
      this.editedItem = Object.assign({}, item);
      this.title = item.title;
      this.desc = item.desc;
      this.previewImage =
        item.image === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.image;
      this.hdImage = item.image;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.PoliticalRiskInsuranceCollection.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.PoliticalRiskInsuranceCollection.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_Our_Political_Risk_Insurance + id;
      axios
        .delete(url, { headers: getTokenHeaders() })
        .then(function () {
          this.snakeMessage("error", "Data has been deleted.");
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async submitData() {
      const data = {
        title: this.title,
        desc: this.desc,
        image: this.hdImage,
      };

      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_Our_Political_Risk_Insurance + id;
        Object.assign(
          this.PoliticalRiskInsuranceCollection[this.editedIndex],
          this.editedItem
        );

        await axios
          .patch(url, data, { headers: getTokenHeaders() })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visibleMsg = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      } // create new
      else {
        data.created_by = this.staffLoginId;
        await axios
          .post(URL_Our_Political_Risk_Insurance, data, {
            headers: getTokenHeaders(),
          })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visibleMsg = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      }

      //this.clearContent();
      this.populatePoliticalRiskInsuranceCollection();
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }
        //this.dialog = false;
        //this.close();
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    uploadImage(e) {
      const file = e.target.files[0];
      if (file) {
        var obj = this.invalidFileSizeOrFileExtension(file);
        if (obj.isTrue) {
          this.visibleMsg = false;
          this.msgError = obj.msg;
          return;
        }

        this.visibleMsg = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          console.log(this.previewImage);
        };

        let data = new FormData();
        data.append("file", e.target.files[0]);

        axios
          .post(URL_UPLOAD_Our_Political_Risk_Insurance_IMAGE, data, {
            headers: getImageTokenHeaders(),
          })
          .then((response) => {
            this.hdImage = response.data.filename;
            console.log("Image has been uploaded.");
          });
      }
    },

    invalidFileSizeOrFileExtension(file) {
      let isTrue = false;
      let msg = null;
      const fileSize = getFileSizeInMB(file);
      const fileExtension = getFileExtension(file);

      if (fileSize >= 6) {
        // 6MB
        isTrue = true;
        msg = "File size is biggest 6MB.";
      }

      if (fileExtension === null) {
        isTrue = true;
        msg = "File extension must be jpg, jpeg, png, bmp";
      }

      return { isTrue, msg };
    },
  },
};
</script>
