<script>

import axios from 'axios';
import { CUSTOMER_VERIFY_EMAIL } from '@/utils/customerApiUrl';

export default {
  name: 'VerifyEmailPage',
  methods: {
    data() {
      return {
        message: '',
        status: false,
        loading: false,
      };
    },
    sendEmailVerification() {
      // Simulating email verification API request
      this.loading = true;
      const token = this.$route.query.token;
      if (this.loading && token) {
        let payload = {
          token: token,
        };
        axios
          .post(CUSTOMER_VERIFY_EMAIL, payload)
          .then((response) => {
            const { status, message, accessToken } = response.data;
            if (status) {
              localStorage.setItem('client_token', accessToken);
              localStorage.setItem('token', accessToken);
              this.loading = false;
              this.message = message;
              this.status = status;
            }
          })
          .catch((error) => {
            this.loading = false;
            let message = error.response.data.message;
            if (Array.isArray(message)) {
              this.message = message[0];
            } else {
              this.message = message;
            }
          });
      }
    },
    toDashBoard() {
      location.href = '/profile?';
    },
  },
  created() {
    this.sendEmailVerification();
  },
};
</script>

<template>
  <v-card
    class="py-8 px-6 text-center mx-auto ma-4 justify-center"
    elevation="12"
    max-width="500"
    width="100%"
    rounded="xl"
  >
    <h3 class="text-h6 mb-4">Your account has been verified.</h3>

    <div class="text-body-2">
      Congratulations! Your email has been verified successfully. <br>
      You can now access your account.
      <br />
      <br />
      <v-avatar size="100" class="mb-4">
        <v-icon size="100" color="green">mdi-check-circle</v-icon>
      </v-avatar>
    </div>

    <v-btn
      @click="toDashBoard()"
      class="my-4"
      color="primary"
      height="40"
      text="Go to dashboard"
      variant="flat"
      width="70%"
      rounded="xl"
    ></v-btn>

  </v-card>

</template>

<style scoped>

</style>