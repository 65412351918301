<template>
  <DepositMoneyComponents/>
</template>

<script>

import DepositMoneyComponents from '@/views/client/profile/deposit/DepositMoneyComponents.vue';

export default {
  name: 'DepositTab',
  components: { DepositMoneyComponents },
};
</script>

<style scoped>

</style>