<template>
  <MainLayout>
    <h3 class="text-h6 mb-2 p-2 text-left">Change Password</h3>
    <v-card class="py-3 text-center" width="100%">
      <v-form ref="form" lazy-validation>
        <v-row> </v-row>
        <v-col cols="12">
          <v-alert
            border="top"
            :type="msgInvalid !== 'Password has been changed.' ? 'error' : 'success'"
            class="mb-2"
            v-if="msgInvalid !== null"
            variant="outlined"
            prominent
            style="background-color: white"
          >
            {{ msgInvalid }}
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <v-text-field
                v-model.trim="currentPassword"
                :rules="[() => !!currentPassword || 'This field is required']"
                label="Current Password"
                placeholder="Current Password"
                variant="outlined"
                prepend-inner-icon="mdi-lock"
                :append-inner-icon="visiblecurrentPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visiblecurrentPassword ? 'text' : 'password'"
                @click:append-inner="visiblecurrentPassword = !visiblecurrentPassword"
                required
                autocomplete="off"
                density="compact"
                persistent-placeholder
                :disabled="validating"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <v-text-field
                v-model.trim="newPassword"
                :rules="newPasswordRules"
                label="New Password"
                placeholder="New Password"
                variant="outlined"
                prepend-inner-icon="mdi-lock"
                :append-inner-icon="visibleNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visibleNewPassword ? 'text' : 'password'"
                @click:append-inner="visibleNewPassword = !visibleNewPassword"
                required
                autocomplete="off"
                density="compact"
                persistent-placeholder
                :disabled="validating"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <v-text-field
                v-model.trim="confirmPassword"
                :rules="[confirmPasswordRules, matchingPasswords]"
                label="Confirm Password"
                placeholder="Confirm Password"
                variant="outlined"
                prepend-inner-icon="mdi-lock"
                :append-inner-icon="visibleConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visibleConfirmPassword ? 'text' : 'password'"
                @click:append-inner="visibleConfirmPassword = !visibleConfirmPassword"
                required
                autocomplete="off"
                density="compact"
                persistent-placeholder
                :disabled="validating"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-btn
          :loading="validating"
          class="mt-6 text-none bg-surface-variant"
          height="40"
          text="Change"
          variant="plain"
          width="135"
          border
          rounded
          @click="changePasswordClicked"
        ></v-btn>
      </v-form>
    </v-card>
  </MainLayout>
</template>
<script>
import MainLayout from "../../../components/MainLayout.vue";
import { URL_CHANGE_PASS } from "@/utils/apiUrl";
import axios from "axios";
import { getTokenHeaders } from "@/utils/headerToken";
import { originalCode } from "@/utils/formatNumber";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Change Password",
  components: { MainLayout },
  data: () => ({
    validating: false,
    visiblecurrentPassword: false,
    visibleNewPassword: false,
    visibleConfirmPassword: false,

    msgInvalid: null,
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,

    newPasswordRules: [
      (v) =>
        (v &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
            v
          )) ||
        "Minimum 6 characters, One capital latter, Special charater, Number",
      (v) => (v && v.length <= 50) || "Password must be less than 50 characters",
    ],
    confirmPasswordRules: [
      (v) =>
        (v &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
            v
          )) ||
        "Minimum 6 characters, One capital latter, Special charater, Number",
      (v) => (v && v.length <= 50) || "Confirm password must be less than 50 characters",
    ],
  }),
  methods: {
    async changePasswordClicked() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.validating = true;
        const staffId = originalCode(parseInt(localStorage.getItem("id")));
        const url = URL_CHANGE_PASS + staffId;
        const data = {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        };

        setTimeout(() => {
          this.validating = false;

          axios
            .patch(url, data, { headers: getTokenHeaders() })
            .then((response) => {
              this.msgInvalid = response.data.message;
              this.currentPassword = null;
              this.newPassword = null;
              this.confirmPassword = null;
            })
            .catch(
              (error) => {
                if (error.response.status === 400) {
                  this.msgInvalid = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  this.msgInvalid = error.request;
                } else {
                  // Something happened in setting up the request that triggered an Error
                  this.msgInvalid = error.message;
                }
              }
              //console.error("record updated was an error!", error)
            );
        }, 2000);
      }
    },
    matchingPasswords: function () {
      if (this.newPassword === this.confirmPassword) {
        return true;
      } else {
        return "Passwords does not match.";
      }
    },
  },
};
</script>
