import { defineStore } from "pinia";
import axios from "axios";
import { URL_STAFF_LOGOUT, URL_STAFF_LOGIN } from "@/utils/apiUrl";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    id: localStorage.getItem("id") || null,
    staffName: localStorage.getItem("staffName") || null,
    imgProfile: localStorage.getItem("img") || null,
    admin: localStorage.getItem("admin") || null,
    allCountry: localStorage.getItem("allCountry") || null,
    countryId: localStorage.getItem("countryId") || null,
    token: localStorage.getItem("token") || null,
    countryCode: localStorage.getItem("code") || null,
  }),

  actions: {
    // Login action
    async login(email, password) {
      console.log("Login function called");

      const url = URL_STAFF_LOGIN;
      const data = { email, password };

      return axios
        .post(url, data)
        .then((response) => {
          if (response.data.statusCode === 400) {
            return {
              message: response.data.message,
              satus: false
            }; // Stay on the same page
          }

          // Store user data
          this.id = response.data.id;
          this.staffName = response.data.name;
          this.imgProfile = response.data.img;
          this.admin = response.data.admin;
          this.allCountry = response.data.allCountry;
          this.countryId = response.data.countryId;
          this.token = response.data.access_token;
          this.countryCode = response.data.countryCode;

          // Save to localStorage
          localStorage.setItem("id", this.id);
          localStorage.setItem("staffName", this.staffName);
          localStorage.setItem("img", this.imgProfile);
          localStorage.setItem("admin", this.admin);
          localStorage.setItem("allCountry", this.allCountry);
          localStorage.setItem("countryId", this.countryId);
          localStorage.setItem("token", this.token);
          localStorage.setItem("code", this.countryCode);

          // Set global axios authorization
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.token}`;

          return true; // Indicate success
        })
        .catch((error) => {
          console.error("Login failed:", error);

          if (error.response) {
            localStorage.setItem("msgInvalid", error.response.data.message);
          } else {
            localStorage.setItem("msgInvalid", error.message);
          }

          return false; // Stay on the same page
        });
    },
    // Logout action
    async logout(id) {
      try {
        const response = await axios.patch(`${URL_STAFF_LOGOUT}${id}`);
        console.log(response.data.message);
      } catch (error) {
        console.error("Logout failed.", error);
        return error.message;
      }

      // Reset state and remove items from localStorage
      this.clearLocalStorageItems();
    },

    clearLocalStorageItems() {
      // Reset state and remove items from localStorage
      this.id = null;
      this.staffName = null;
      this.imgProfile = null;
      this.admin = null;
      this.allCountry = null;
      this.countryId = null;
      this.token = null;
      this.countryCode = null;

      localStorage.removeItem("id");
      localStorage.removeItem("staffName");
      localStorage.removeItem("img");
      localStorage.removeItem("admin");
      localStorage.removeItem("allCountry");
      localStorage.removeItem("countryId");
      localStorage.removeItem("token");
      localStorage.removeItem("code");

      // Remove Authorization header from axios
      delete axios.defaults.headers.common["Authorization"];
    }
  },
});
