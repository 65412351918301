<template>
  <!--  <pre class="text-red">{{ JSON.stringify(state.form, null, 2) }}</pre>-->
  <v-card rounded="0" class="px-10 pa-3">
    <v-row>
      <v-col cols="6" sm="6" md="2">
        <v-img class="mt-2" width="215px" height="40px" :src="state.logo" alt="" />
      </v-col>

      <v-col cols="12" sm="12" md="8" class="hidden">
        <v-stepper :model-value="state.step" elevation="0" v-if="state.step > 0">
          <v-stepper-header>
            <v-stepper-item title="Account type" value="1" complete></v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item title="Country" value="2"></v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item title="2FA" value="3"></v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item title="Password" value="4"></v-stepper-item>
          </v-stepper-header>
        </v-stepper>
      </v-col>

      <v-col cols="6" sm="6" md="2" v-if="state.step < 12">
        <div class="d-flex justify-end">
          <v-btn variant="text" icon="mdi-close" @click="handleBack"></v-btn>
        </div>
      </v-col>

    </v-row>
  </v-card>

  <!-- Step 1 enter email-->
  <div class="margin-center" v-if="state.step == state.tab[0].tab0">
    <br />
    <br />
    <h2 class="text-center">Create your account</h2>
    <p class="text-center">
      Already have an account
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/sign-in"
      >
        Login ?
      </router-link>
    </p>

    <template v-if="state.error">
      <BoxMessageError :is-message="state.message" />
    </template>

    <br />
    <p class="ma-1">First, enter your email address</p>
    <v-form ref="formLogin">

      <v-text-field
        v-model="state.form.email"
        variant="outlined"
        density="compact"
        placeholder="Enter your email address"
        v-on:keyup="handleChange"
        type="text"
        :rules="nameRules"
      ></v-text-field>

      <p>Referral code</p>

      <v-text-field
        variant="outlined"
        density="compact"
        placeholder="Enter referral code"
        v-model="state.form.referral"
        :disabled="state.disabledReferral"
      />

      <v-btn :loading="state.loading" :disabled="!state.form.email || !state.form.referral" block rounded="xl"
             color="primary"
             @click="handleSubmit()">
        <p class="text-capitalize">Next</p>
      </v-btn>
    </v-form>

    <br />

    <v-row>
      <v-col md="6" cols="12">
        <p class="my-6s">Or login with</p>
      </v-col>
      <v-col md="6" cols="12">
        <router-link
          to="/forget-password"
          class="text-decoration-none text-blue-darken-1"
        >
          <p class="my-6s text-end">Forget password ?</p>
        </router-link>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <GoogleSignInButton
          @success="handleLoginSuccess"
          @error="handleLoginError"
          ref="google"
        ></GoogleSignInButton>
      </v-col>
    </v-row>

    <br />
    <br />
    <p class="text-center">
      By registering, you accept our
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/sign-up"
      >
        Term of use
      </router-link>
      and
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/sign-up"
      >
        Privacy policy
      </router-link>
    </p>
  </div>
  <!-- Step 1-->

  <!-- Step 2 verify code-->
  <div class="margin-center" v-if="state.step == state.tab[0].tab1">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">We just sent you an code to your email</h2>
    <br />
    <p class="text-center">
      Enter the security code we sent to {{ state.form.email }} .
      <br />
      <!--      <span class="text-decoration-none text-black text-decoration-underline">-->
      <!--        learn more-->
      <!--      </span>-->
    </p>
    <br />

    <template v-if="state.error">
      <BoxMessageError :is-message="state.message" />
    </template>

    <br />

    <p>Your 6-digit code</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter your 6-digit code"
      v-model="state.form.code"
    />

    <v-btn
      :loading="state.loading"
      :disabled="!state.form.code"
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="verifyCodeEmail()"
    >
      <p class="text-capitalize">Verify Now</p>
    </v-btn>

    <br />

    <p class="text-none text-center text-decoration-underline">I don't receive a code</p>
    <br />
    <br />
  </div>
  <!-- Step verify code-->

  <!-- Step 3 account type -->
  <div class="margin-center" v-if="state.step == state.tab[0].tab2">
    <br />
    <br />
    <h2 class="text-center">What kind of account would you like to open today ?</h2>
    <p class="text-center">You can add another account later on, too.</p>
    <br />
    <br />
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-card
          color="grey-lighten-3"
          class="text-center pa-3 cursor"
          elevation="0"
          v-on:click="handleClickAccountType('personal')"
        >
          <img
            src="../../../assets/house-medium@1x.webp"
            alt=""
            height="100px"
            width="100px"
          />
          <h3>Personal Account</h3>
          <span class="small-text">
            Send, spend, and receive around the world for less.
          </span>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <!--        v-on:click="handleClickAccountType( 'business')"-->
        <v-card
          color="grey-lighten-3"
          class="text-center pa-3 cursor-not-allow"
          elevation="0"
        >
          <img
            src="../../../assets/briefcase-medium@1x.webp"
            alt=""
            height="100px"
            width="100px"
          />
          <h3>Business Account</h3>
          <span class="small-text"> Do Business or freelance work internationally. </span>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <br />
    <p class="text-center">
      You mus use Wish in line with our
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/"
      >
        Acceptable Use Policy
      </router-link>
      <v-icon size="small">mdi-open-in-new</v-icon>
      . You cannot use a personal account for business purposes.
    </p>
  </div>
  <!-- Step 3-->

  <!-- Step 4-->
  <div class="margin-center" v-if="state.step == state.tab[0].tab3">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />

    <h2 class="text-center">Where do you live most of the time ?</h2>
    <p class="text-center">By law, we may need to ask for proof of your address.</p>
    <br />
    <br />

    <v-autocomplete
      v-model="state.form.country"
      :items="state.countries"
      item-title="name"
      item-value="name"
      variant="outlined"
      density="compact"
    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          v-bind="props"
          :prepend-avatar="getLogoFlag(item.raw.code)"
          :text="item.raw.name"
        ></v-chip>
      </template>

      <template v-slot:item="{ props, item }">
        <v-list-item
          v-bind="props"
          :prepend-avatar="getLogoFlag(item.raw.code)"
          :title="item.raw.name"
        ></v-list-item>
      </template>
    </v-autocomplete>

    <v-btn
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitAddress()"
    >
      <p class="text-capitalize">Continue</p>
    </v-btn>
    <br />
    <br />
    <p class="text-center">
      Migawb is authorized to operate in
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/"
      >
        most states.
      </router-link>
      In the other states to which we'er a service provider
    </p>
  </div>
  <!-- Step 4-->

  <!-- Step 5 phone number -->
  <div class="margin-center" v-if="state.step == state.tab[0].tab4">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Very your phone number with a code</h2>
    <p class="text-center">
      It helps us keep your account secure.
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/"
      >
        Learn more
      </router-link>
      <v-icon size="small">mdi-open-in-new</v-icon>
    </p>
    <br />
    <br />

    <v-row>
      <v-col cols="5" sm="5" md="3">

        <v-autocomplete
          v-model="state.form.prefix"
          :items="state.codes"
          item-title="dial_code"
          item-value="dial_code"
          variant="outlined"
          density="compact"
        >
          <template v-slot:chip="{ props, item }">
            <v-chip
              v-bind="props"
              :prepend-avatar="getLogoFlag(item.raw.code)"
              :text="item.raw.dial_code"
            ></v-chip>
          </template>

          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :prepend-avatar="getLogoFlag(item.raw.code)"
              :title="item.raw.dial_code"
            ></v-list-item>
          </template>
        </v-autocomplete>

      </v-col>


      <v-col cols="7" sm="7" md="9">
        <v-text-field
          variant="outlined"
          density="compact"
          placeholder="Enter your phone number"
          v-model="state.form.phone"
          v-on:keyup="handlePhoneChange"
          type="number"
        />
      </v-col>
    </v-row>

    <v-btn
      :disabled="!state.form.phone"
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitVerificationPhone()"
    >
      <p class="text-capitalize">Next</p>
    </v-btn>
    <br />
    <br />
  </div>
  <!-- Step 5--->

  <!-- Step 6 sent you an SMS -->
<!--  <div class="margin-center" v-if="state.step === state.tab[0].tab5">-->
<!--    <br />-->
<!--    <v-btn variant="text" v-on:click="setStep(state.step - 1)">-->
<!--      <v-icon>mdi-arrow-left</v-icon>-->
<!--      Back-->
<!--    </v-btn>-->
<!--    <br />-->
<!--    <h2 class="text-center">We just sent you an SMS</h2>-->
<!--    <br />-->
<!--    <p class="text-center">-->
<!--      To log in, enter the security code we sent to {{ state.form.phone }} .-->
<!--      <br />-->
<!--      <span class="text-decoration-none text-black text-decoration-underline">-->
<!--        learn more-->
<!--      </span>-->
<!--    </p>-->
<!--    <br />-->
<!--    <br />-->

<!--    <p>Your 6-digit code</p>-->

<!--    <v-text-field-->
<!--      variant="outlined"-->
<!--      density="compact"-->
<!--      placeholder="Enter your 6-digit code"-->
<!--      v-model="state.form.code"-->
<!--    />-->

<!--    <v-btn-->
<!--      :disabled="!state.form.code"-->
<!--      block-->
<!--      rounded="xl"-->
<!--      color="blue"-->
<!--      elevation="0"-->
<!--      v-on:click="handleSubmitVerify()"-->
<!--    >-->
<!--      <p class="text-capitalize">Done</p>-->
<!--    </v-btn>-->

<!--    <br />-->

<!--    <p class="text-none text-center text-decoration-underline">I don't receive a code</p>-->
<!--    <br />-->
<!--    <br />-->
<!--  </div>-->
  <!-- Step 6-->

  <!-- Step 7 Create your password-->
  <div class="margin-center" v-if="state.step == state.tab[0].tab6">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Create your password</h2>
    <br />
    <br />

    <p>Your Password</p>

    <v-text-field
      v-model="state.form.password"
      variant="outlined"
      density="compact"
      autocomplete="off"
      placeholder="Enter your password"
      prepend-inner-icon="mdi-lock"
      :append-inner-icon="!state.visible ? 'mdi-eye-off' : 'mdi-eye'"
      :rules="nameRulesPass"
      :type="!state.visible ? 'password' : 'text'"
      @click:append-inner="state.visible = !state.visible"
      @keydown="checkPassword"
    />

    <p
      class="text-none text-center text-red"
      v-if="state.form.password && !state.passwordStrong"
    >
      <span class="text-small">Password is weak. </span>
    </p>

    <p
      class="text-none text-center text-green"
      v-if="state.form.password && state.passwordStrong"
    >
      <span class="text-small">Password is very strong. </span>
    </p>

    <br />

    <p class="text-none text-center">
      <span class="text-small">Password must contain a</span> latter a number, and be
      minimum of 9 characters
    </p>

    <br />

    <v-btn
      :disabled="!state.passwordStrong"
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitPassword()"
    >
      <p class="text-capitalize">Continue</p>
    </v-btn>

    <br />
  </div>
  <!-- Step 7-->

  <!-- Step 8 Tell us about yourself -->
  <div class="margin-center" v-if="state.step == state.tab[0].tab7">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Tell us about yourself</h2>
    <br />
    <br />

    <p>Country of residence</p>

    <v-autocomplete
      :items="state.countries"
      v-model="state.form.country"
      item-value="name"
      item-title="name"
      variant="outlined"
      density="compact"
      readonly
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :subtitle="item.name"></v-list-item>
      </template>
    </v-autocomplete>

    <p>Your occupation</p>

    <v-autocomplete
      :items="state.occupation"
      v-model="state.form.occupation"
      variant="outlined"
      density="compact"
    >
    </v-autocomplete>

    <p>Full legal first and middle name(s)</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter your first name"
      v-model="state.form.first_name"
    />

    <p>Full legal last name(s)</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter your last name"
      v-model="state.form.last_name"
    />

    <p>Date of birth</p>

    <v-row>
      <v-col cols="3">
        <v-autocomplete
          :items="state.dayOfMonth"
          v-model="state.form.day"
          variant="outlined"
          density="compact"
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          :items="state.month"
          v-model="state.form.month"
          item-value="month"
          item-title="month"
          variant="outlined"
          density="compact"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="item.name"></v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          :items="state.yearOfCalender"
          v-model="state.form.year"
          variant="outlined"
          density="compact"
        >
        </v-autocomplete>
      </v-col>

    </v-row>


    <p>Gender</p>

<!--    <v-autocomplete-->
<!--      :items="['male','female','other']"-->
<!--      v-model="state.form.gender"-->
<!--      variant="outlined"-->
<!--      density="compact"-->
<!--    >-->
<!--    </v-autocomplete>-->

    <v-radio-group
      v-model.trim="state.form.gender"
      color="primary"
      inline
    >
      <v-radio
        label="Male"
        value="male"
      ></v-radio>
      <v-radio
        label="Female"
        value="female"
      ></v-radio>
      <v-radio
        label="Other"
        value="other"
      ></v-radio>
    </v-radio-group>

    <br />

    <v-btn
      :disabled="!state.form.gender || !state.form.year || !state.form.month || !state.form.day || !state.form.first_name || !state.form.last_name || !state.form.occupation "
      block rounded="xl" color="blue" elevation="0" v-on:click="handleSubmitInfo()">
      <p class="text-capitalize">Continue</p>
    </v-btn>

    <br />
  </div>
  <!-- Step 8-->

  <!-- Step 9 Confirm your address -->
<!--  <div class="margin-center" v-if="state.step == state.tab[0].tab8">-->
<!--    <br />-->
<!--    <v-btn variant="text" v-on:click="setStep(state.step - 1)">-->
<!--      <v-icon>mdi-arrow-left</v-icon>-->
<!--      Back-->
<!--    </v-btn>-->
<!--    <br />-->
<!--    <h2 class="text-center">Confirm your address</h2>-->
<!--    <br />-->

<!--    <p class="text-center">You may need to provider proof of this.</p>-->
<!--    <br />-->

<!--    <p>Home address</p>-->

<!--    <v-text-field-->
<!--      variant="outlined"-->
<!--      density="compact"-->
<!--      placeholder="Enter home address"-->
<!--      v-model="state.form.address"-->
<!--    />-->

<!--    <p>City</p>-->

<!--    <v-text-field-->
<!--      variant="outlined"-->
<!--      density="compact"-->
<!--      placeholder="Enter city"-->
<!--      v-model="state.form.city"-->
<!--    />-->

<!--    <p>ZIP code</p>-->

<!--    <v-text-field-->
<!--      variant="outlined"-->
<!--      density="compact"-->
<!--      placeholder="Enter ZIP code "-->
<!--      v-model="state.form.zip"-->
<!--    />-->

<!--    <p>State</p>-->

<!--    <v-text-field-->
<!--      variant="outlined"-->
<!--      density="compact"-->
<!--      placeholder="Enter state "-->
<!--      v-model="state.form.state"-->
<!--    />-->

<!--    <br />-->

<!--    <v-btn-->
<!--      :disabled="!state.form.address || !state.form.city || !state.form.zip || !state.form.state"-->
<!--      block-->
<!--      rounded="xl"-->
<!--      color="blue"-->
<!--      elevation="0"-->
<!--      v-on:click="handleSubmitConfirmAddress()"-->
<!--    >-->
<!--      <p class="text-capitalize">Continue</p>-->
<!--    </v-btn>-->

<!--    <br />-->
<!--  </div>-->
  <!-- Step 9-->

  <!-- Step 10 nationality -->
  <div class="margin-center" v-if="state.step == state.tab[0].tab9">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Additional information</h2>
    <br />

    <p class="text-center">Add the below information to continue.</p>
    <br />

    <p>Nationality</p>

    <v-autocomplete
      :items="state.nationality"
      v-model="state.form.nationality"
      variant="outlined"
      density="compact"
      item-value="nationality"
      item-title="nationality"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :subtitle="item.nationality"></v-list-item>
      </template>
    </v-autocomplete>

    <template v-if="state.error">
      <BoxMessageError :is-message="state.message" />
    </template>

    <br />

    <v-btn
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleNationality()"
      class="text-none"
      :disabled="!state.form.nationality"
      :loading="state.loading"
    >
      <p class="">Confirm and continue</p>
    </v-btn>
    <br />
  </div>
  <!-- Step 10-->

  <!-- Step 11 verify email -->
  <div class="margin-center" v-if="state.step == state.tab[0].tab10">
    <br /><br /><br />
    <v-container class="d-flex justify-center align-center fill-height">
      <v-card rounded="md" class="pa-8 text-center">
        <v-avatar size="100" class="mb-4">
          <v-icon size="100" color="green">mdi-check-circle</v-icon>
        </v-avatar>

        <h2 class="text-h5 font-weight-medium">Congratulations !</h2>

        <p class="text-body-2 mt-2">
          Great! Your email is verified, and your account is ready. Enjoy our services!
        </p>

        <br />

        <v-btn @click="onClickVerifyEmail()" block rounded="xl" color="primary" class="mt-4">
          To Dashboard
        </v-btn>

      </v-card>
    </v-container>
  </div>
  <!-- Step 11-->
</template>

<script setup>
import { reactive, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {
  setLocalStorage,
  getLocalStorage,
  monthOption,
  nationalityOption,
  phoneOption,
  jobOption,
  getDayOfMonth,
  getYearOfCalender,
} from '@/utils/function';
import { decodeCredential } from 'vue3-google-signin';
import axios from 'axios';
import { CUSTOMER_SIGN_UP, SENT_EMAIL_CODE, VERIFY_SENT_EMAIL_CODE } from '@/utils/customerApiUrl';
import moment from 'moment';
import VueCookies from 'vue-cookies';
import { useStore } from 'vuex';
import BoxMessageError from '@/views/client/auth/components/BoxMessageError.vue';
import { setAuthToken } from '@/servicesAxios/axiosInstance';

const router = useRouter();

const countries = phoneOption();

const occupation = jobOption();

const dayOfMonth = getDayOfMonth();

const yearOfCalender = getYearOfCalender();

const month = monthOption();

const nationality = nationalityOption();

const phone = phoneOption();

const state = reactive({
  tab: [
    {
      tab0: 0, // email
      tab1: 1, // verify
      tab2: 2, // account type
      tab3: 3, // country
      tab4: 4, // phone number
      //tab5: 4, //  sent code
      tab6: 5, // password
      tab7: 6, // yourself
      //tab8: 7, // address
      tab9: 7, // nationality
      tab10: 8, // Congratulations
    }
  ],
  logo: null,
  visible: false,
  passwordStrong: false,
  step: 0,
  form: {
    token: '',
    email: '',
    account_type: '',
    prefix: '',
    phone: '',
    code: '',
    first_name: '',
    last_name: '',
    password: '', // Admin@123$
    country: '',
    address: '',
    city: ' ',
    state: ' ',
    zip: '',
    date_of_birth: '',
    gender: 'other',
  },
  month: month,
  countries: countries,
  occupation: occupation,
  loading: false,
  codes: phone,
  nationality: nationality,
  dayOfMonth: dayOfMonth,
  yearOfCalender: yearOfCalender,
  userInfo: {
    ip: '',
    city: '',
    region: '',
    region_code: '',
    country: '',
    country_name: '',
    country_code: '',
    country_capital: '',
    postal: null,
    latitude: 0,
    longitude: 0,
    org: '',
  },
  // message
  error: false,
  mac_address: '',
  disabledReferral: false,
  message: 'Something went wrong.',
  status: '',
});

// setStep
const setStep = async (step) => {
  state.step = step;
  setLocalStorage('step', step);
};

// setNextStep
const setNextStep = () => {
  setStep(parseInt(state.step + 1));
};

// handleBack
const handleBack = () => {
  setStep(0);
  setLocalStorage('form_register', []);
  router.go(-1);
};

//clearMessageError
const clearMessageError = () => {
  setTimeout(() => {
    state.error = false;
    state.message = '';
  }, 3000);
};

// onClickVerifyEmail
const onClickVerifyEmail = () => {
  //router.push('/profile');
  window.location.href = '/profile';
  setLocalStorage('step', 0);
};

// saveFormLocal
const saveFormLocal = () => {
  setLocalStorage('form_register', state.form);
};

// getDefaultNationality
const getDefaultNationality = (country) => {
  let nationalityName = '';
  let name = nationality.filter(i => i.en_short_name === country);
  if (name) {
    nationalityName = name[0].nationality;
  }
  return nationalityName;
};

//
const verifyCodeEmail = () => {
  state.loading = true;
  let payload = {
    email: state.form.email,
    code: state.form.code,
  };
  axios
    .post(VERIFY_SENT_EMAIL_CODE, payload)
    .then((response) => {
      const { message } = response.data;
      if (message) {
        state.loading = false;
        saveFormLocal();
        setNextStep();
      }
    })
    .catch((error) => {
      state.error = true;
      state.loading = false;
      let message = error.response.data.message;
      if (Array.isArray(message)) {
        state.message = message[0];
      } else {
        state.message = message;
      }
      // clear message
      clearMessageError();
    });
};

// step 1
const handleClickAccountType = (accountType = 'personal') => {
  setNextStep();
  state.form.account_type = accountType;
  saveFormLocal();
};

// step 2
const handleSubmitAddress = () => {
  setNextStep();
  saveFormLocal();
  // set phone prefix
  state.form.prefix = getPrefixPhone(state.form.country);
  // nationality
  state.form.nationality = getDefaultNationality(state.form.country);
};

// step 4
const handleSubmitVerificationPhone = () => {
  // clear opt
  state.form.code = '';
  setNextStep();
  saveFormLocal();
};

// step 5
// const handleSubmitVerify = () => {
//   setNextStep();
//   saveFormLocal();
// };

const handlePhoneChange = (event) => {
  state.form.phone = event.target.value;
};

// step 6
const handleSubmitPassword = () => {
  setNextStep();
  saveFormLocal();
};

// step 7
const handleSubmitInfo = () => {
  setNextStep();
  saveFormLocal();
};

// step 8
// const handleSubmitConfirmAddress = () => {
//   setNextStep();
//   saveFormLocal();
// };

const checkPassword = (e) => {
  let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
  let password = e.target.value;
  state.passwordStrong = password.length > 9;
  let isCheck = regex.test(password);
  state.passwordStrong = isCheck;
};

const signUp = (payload) => {
  state.loading = true;
  axios
    .post(CUSTOMER_SIGN_UP, payload)
    .then((response) => {
      const { status, message, accessToken } = response.data;
      if (status) {
        state.loading = false;
        state.message = message;
        state.status = status;
        setLocalStorage('form_register', []);
        // accessToken, refreshToken
        setAuthToken(accessToken);
      }
      setNextStep();
    })
    .catch((error) => {
      state.error = true;
      state.loading = false;
      let message = error.response.data.message;
      if (Array.isArray(message)) {
        state.message = message[0];
      } else {
        state.message = message;
      }
    });
};

const handleNationality = () => {
  let monthIndex = month.findIndex((i) => i === state.form.month) + 1;

  let date_of_birth = moment([state.form.year, monthIndex, state.form.day]).format(
    'YYYY-MM-DD',
  );

  state.form.date_of_birth = date_of_birth;

  saveFormLocal();

  let form = {
    ip: state.userInfo.ip,
    latitude: state.userInfo.latitude.toString(),
    longitude: state.userInfo.longitude.toString(),
    mac_address: state.mac_address,
    regionName: state.userInfo.region,
    verifyCode: '000000',
    ipAddress: state.userInfo.org,
    countryCode: state.userInfo.country_code,
    countryLocation: state.form.state,
    isp: state.userInfo.org,
    gender: 'Other',
    browser: navigator.userAgent,
    register: true,
    customer_login_information: {
      id: 0,
      customer_id: 0,
      latitude: state.userInfo.latitude.toString(),
      longitude: state.userInfo.longitude.toString(),
      ip: state.userInfo.ip,
      browser: navigator.userAgent,
      organization: state.userInfo.org,
      login_date: new Date(),
      country: state.userInfo.country_name,
      country_code: state.userInfo.country_code,
      region: state.userInfo.region,
      city: state.userInfo.city,
    },
    ...state.form,
    ...state.form.address,
  };

  //console.log(form);

  signUp(form);
};

let formLogin = ref(null);

const nameRules = [
  (value) => {
    if (value) return true;
    return 'Email is required.';
  },
  (value) => {
    if (value.length > 0) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Invalid Email address';
    }
  },
];

// next submit email
const handleSubmit = () => {
  formLogin.value.validate().then(({ valid }) => {
    if (valid) {
      // sent email to backend
      let payload = {
        email: state.form.email,
        referral: state.form.referral,
        country: state.form.country,
      };
      //
      state.loading = true;
      axios
        .post(SENT_EMAIL_CODE, payload)
        .then((response) => {
          const { message } = response.data;
          if (message) {
            state.loading = false;
            state.message = message;
            saveFormLocal();
            setNextStep();
            state.form.code = '';
          }
        })
        .catch((error) => {
          state.error = true;
          state.loading = false;
          let message = error.response.data.message;
          if (Array.isArray(message)) {
            state.message = message[0];
          } else {
            state.message = message;
          }
          // clear message
          clearMessageError();
        });
    } else {
      return null;
    }
  });
};
//
const handleChange = (event) => {
  state.form.email = event.target.value;
  state.error = false;
};
//
const handleLoginSuccess = (response) => {
  //console.log(response);

  if (!state.form.referral) {
    state.error = true;
    state.message = 'Please insert referral code';
    // clear message
    clearMessageError();
    return;
  }

  const { credential } = response;

  let decodedCredential = decodeCredential(credential);

  decodedCredential = {
    ...decodedCredential,
    account_type: 'personal',
  };

  state.form = {
    ...state.form,
    token: credential,
    email: decodedCredential.email,
    last_name: decodedCredential.given_name,
    first_name: decodedCredential.family_name,
    name: decodedCredential.name,
    profile: decodedCredential.picture,
  };

  saveFormLocal();

  //responseLogin(decodedCredential);

  // set referral code
  getReferrals();
};
//
// const responseLogin = (data) => {
//   //console.log(state.userInfo);
//   let payload = {
//     ...data,
//     password: data.email,
//     address: data.email,
//     city: state.userInfo.city,
//     country: state.userInfo.country_name,
//     countryCode: state.userInfo.country_code,
//     countryLocation: state.userInfo.city,
//     first_name: data.family_name,
//     gender: 'Other',
//     ip: state.userInfo.ip,
//     latitude: state.userInfo.latitude.toString(),
//     longitude: state.userInfo.longitude.toString(),
//     mac_address: state.mac_address,
//     nationality: '',
//     occupation: '',
//     phone: data.phone,
//     regionName: state.userInfo.region,
//     state: state.userInfo.region,
//     verify_code: '000000',
//     ip_address: data.email,
//     last_name: data.family_name,
//     date_of_birth: '',
//     isp: state.userInfo.org,
//     browser: navigator.userAgent,
//     customer_login_information: {
//       id: 0,
//       customer_id: 0,
//       latitude: state.userInfo.latitude.toString(),
//       longitude: state.userInfo.longitude.toString(),
//       ip: state.userInfo.ip,
//       browser: navigator.userAgent,
//       organization: state.userInfo.org,
//       login_date: new Date(),
//       country: state.userInfo.country_name,
//       country_code: state.userInfo.country_code,
//       region: state.userInfo.region,
//       city: state.userInfo.city,
//     },
//     register: true,
//   };
//   //console.log(payload)
//   signUp(payload);
// };

// handle an error event
const handleLoginError = () => {
  console.error('Login failed');
};

//
const getPrefixPhone = (country) => {
  let phonePrefix = phone.filter((i) => i.name === country);
  if (phonePrefix.length > 0) {
    return phonePrefix[0].dial_code;
  }
};

// fetch user info from IP API to get location data
const userInfo = async () => {
  let userInfo = localStorage.getItem('userInfo');
  if (userInfo !== null) {
    state.userInfo = JSON.parse(userInfo);
    // set country
    state.form.country = state.userInfo.country_name;
  }
  if (userInfo === null) {
    fetch('https://ipapi.co/json')
      .then((response) => response.json())
      .then((data) => {
        state.userInfo = data;
        // set country
        state.form.country = data.country_name;
        //
        localStorage.setItem('userInfo', JSON.stringify(data));
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

//
function recaptcha() {
  const recaptchaScript = document.createElement('script');
  recaptchaScript.setAttribute(
    'src',
    'https://www.google.com/recaptcha/enterprise.js?render=6LccbiwqAAAAAOIku8k_AASk_csbrGdaJRBuYwj0',
  );
  document.head.appendChild(recaptchaScript);
}

function formatMacAddress(mac) {
  const formattedMac = mac.replace(/[^A-Fa-f0-9]/g, ''); // Remove non-hex characters
  if (formattedMac.length === 12) {
    return formattedMac.match(/.{1,2}/g).join(':');
  }
  return null; // Invalid MAC address length
}

function syncMac() {
  let device = VueCookies.get('macAddress');
  if (!device) {
    let mac = moment().format('YYMMDDHHmmss');
    VueCookies.set('macAddress', formatMacAddress(mac), 365 * 10 + 'd');
  }
  state.mac_address = device;
}

const store = useStore();
store.dispatch('getCompanyInfo');

setTimeout(() => {
  state.logo = store.state.company.logo ?? null;
},500);

const route = useRoute();
const getReferrals = () => {
  if (Object.keys(route.query).length > 0) {
    state.form.referral = route.query.code;
    state.disabledReferral = true;
  }
};

// get flag
const getLogoFlag = (code) => {
  return 'https://flagsapi.com/' + code + '/flat/64.png';
};

onMounted(() => {
  syncMac();
  state.step = getLocalStorage('step') || 0;
  state.form = getLocalStorage('form_register') || {};
  userInfo();
  if (!location.host.includes('localhost')) {
    recaptcha();
  }
  // set referral code
  getReferrals();
});
</script>

<style>
.margin-center {
  width: 600px;
  margin: auto;
}

.small-text {
  font-size: 15px;
  color: #666;
}

.cursor {
  cursor: pointer;
}

.cursor-not-allow {
  cursor: not-allowed;
}

@media only screen and (max-width: 600px) {
  .margin-center {
    width: auto;
    margin: 10px;
  }

  .hidden {
    display: none;
  }
}

</style>
