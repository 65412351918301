<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      v-for="(row, index) in mainRewards"
      v-bind:key="row.title"
      class="pa-0"
    >
      <v-card
        height="170px"
        class="pa-2 text-center align-content-center"
        elevation="0"
        style="margin: 1px"
      >
        <h4>
          {{ row.title }}
        </h4>
        <h2 :class="index === 0 ? 'text-blue' : 'text-green'">
          {{ row.total }}
          <v-icon size="22">mdi-account</v-icon>
        </h2>
      </v-card>
    </v-col>

    <!-- <v-col cols="12" md="6">
      <v-layout row>
        <v-text-field
          placeholder="Referral ID "
          class="myTextField"
          variant="solo"
          density="compact"
          v-model="referralId"
          readonly
        >
        </v-text-field>
        <v-btn
          class="myBtn text-none"
          color="primary"
          width="100"
          @click="copyCode(referralId)"
        >
          Copy
        </v-btn>
      </v-layout>
    </v-col> -->

    <!-- <v-col cols="12" md="6">
      <v-layout row>
        <v-text-field
          placeholder="Referral Link "
          class="myTextField"
          variant="solo"
          density="compact"
          v-model="referralLink"
          readonly
        >
        </v-text-field>
        <v-btn
          class="myBtn text-none"
          color="orange"
          width="100"
          @click="copyLink(referralLink)"
        >
          Copy
        </v-btn>
      </v-layout>
    </v-col> -->
  </v-row>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    {{ text }}

    <template v-slot:actions>
      <v-btn color="blue" variant="text" @click="snackbar = false"> Close</v-btn>
    </template>
  </v-snackbar>

  <br />

  <div style="width: 240px">
    <h5 class="text-grey">Invite friends via</h5>
    <v-divider></v-divider>
  </div>

  <div class="my-1"></div>
  <!-- {{ socialMedia }} -->
  <div class="d-flex">
    <v-btn
      color="indigo"
      size="30"
      icon="mdi-facebook"
      elevation="0"
      @click="navigateToInvitationPage"
    ></v-btn>
    <v-btn
      color="primary"
      size="30"
      class="mx-2"
      icon="mdi-linkedin"
      elevation="0"
      @click="navigateToInvitationPage"
    ></v-btn>
    <v-btn
      color="blue"
      size="30"
      class="mx-2"
      icon="mdi-twitter"
      elevation="0"
      @click="navigateToInvitationPage"
    ></v-btn>
    <v-btn
      color="red"
      size="30"
      class="mx-2"
      icon="mdi-youtube"
      elevation="0"
      @click="navigateToInvitationPage"
    ></v-btn>
    <v-btn
      color="green"
      size="30"
      class="mx-2"
      icon="mdi-whatsapp"
      elevation="0"
      @click="navigateToInvitationPage"
    ></v-btn>
    <!--
    <v-btn
      color="indigo"
      size="30"
      icon="mdi-facebook"
      elevation="0"
      @click="copyLink(socialMedia.facebookLink)"
    ></v-btn>
    <v-btn
      color="primary"
      size="30"
      class="mx-2"
      icon="mdi-linkedin"
      elevation="0"
      @click="copyLink(socialMedia.linkedIn)"
    ></v-btn>
    <v-btn
      color="blue"
      size="30"
      class="mx-2"
      icon="mdi-twitter"
      elevation="0"
      @click="copyLink(socialMedia.twitterLink)"
    ></v-btn>
    <v-btn
      color="red"
      size="30"
      class="mx-2"
      icon="mdi-youtube"
      elevation="0"
      @click="copyLink(socialMedia.telegramLink)"
    ></v-btn>
    <v-btn
      color="green"
      size="30"
      class="mx-2"
      icon="mdi-whatsapp"
      elevation="0"
      @click="copyLink(socialMedia.whatsApp)"
    ></v-btn>
    -->
  </div>
  <br />

  <div>
    <h4 class="text-grey">View History by Selecting Date</h4>
    <v-divider></v-divider>
    <br />
  </div>

  <!--    <pre>-->
  <!--      {{ data[0] }}-->
  <!--    </pre>-->

  <!--  ListRewards-->
  <v-row>
    <v-col cols="12" md="6">
      <div class="d-flex justify-start">
        <span class="ma-2">Show</span>

        <div>
          <v-select
            menu-icon=""
            v-model="params.take"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['10', '25', '50', '100', '500', '1000']"
            class="elevation-0"
            color="blue"
          ></v-select>
        </div>

        <span class="ma-2">Entries</span>
      </div>
    </v-col>

    <v-col cols="12" md="3"></v-col>
    <v-col cols="12" md="3">
      <v-text-field
        placeholder="Search ..."
        v-model="params.remark"
        density="comfortable"
        rounded="xl"
        variant="outlined"
        class="elevation-0"
        color="blue"
      >
      </v-text-field>
    </v-col>
  </v-row>

  <v-card rounded="0">
    <v-data-table density="comfortable" hide-default-footer :loading="loading">
      <v-card elevation="0" rounded="0" class="pa-5" v-if="data.length < 1">
        No record available.
      </v-card>

      <thead>
        <tr class="bg-primary">
          <th v-for="row in headers" v-bind:key="row.key" class="row-none-wrap">
            {{ row.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(level, index) in data" v-bind:key="index" class="row-none-wrap">
          <!--          <td class="td">0000{{ index + 1 }}</td>-->
          <td class="td">
            {{ formatDate(level.createdAt) }} {{ formatDate(level.createdAt, "HH:mm A") }}
          </td>
          <td class="td text-centers">
            <v-avatar color="info" size="x-small">
              <v-icon size="small" icon="mdi-account-circle"></v-icon>
            </v-avatar>
            {{ level.name }}
          </td>
          <td class="td text-centers">
            {{ level.phone }}
          </td>
          <td class="td text-centers">
            {{ level.myReferral }}
          </td>
          <td class="td text-centers">
            {{ level.gender }}
          </td>
          <td class="td text-centers">
            {{ level.numberLevel }}
          </td>
          <td class="td text-centers">
            <v-chip rounded="xl" class="ma-2" color="green" label density="compact">
              <v-icon icon="mdi-check-circle" start></v-icon>
              {{ level.status }}
            </v-chip>
          </td>
          <td class="td text-centers">
            <v-avatar size="x-small" color="white">
              <v-img
                :src="'https://flagsapi.com/' + level.countryCode + '/flat/64.png'"
              />
            </v-avatar>

            {{ level.country }}
          </td>
        </tr>
      </tbody>
    </v-data-table>
  </v-card>

  <div class="app-paginate mt-5">
    <v-pagination
      v-model="params.skip"
      :length="totalPages"
      class="d-flex align-center"
      rounded="circle"
      color="primary"
      border
      total-visible="10"
    >
      <template #prev>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip = 1"
            :disabled="1 === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            First
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip--"
            :disabled="params.skip === 1"
            variant="outlined"
            border
            color="primary"
          >
            Previous
          </VBtn>
        </div>
      </template>
      <template #next>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip++"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Next
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip = totalPages"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Last
          </VBtn>
        </div>
      </template>
    </v-pagination>
  </div>
  <!--  ListRewards-->
</template>
<script>
import { formatPrice, getParams, formatDate } from "@/utils/function";
import moment from "moment";
import { REWARDS } from "@/utils/customerApiUrl";
import { mapState } from "vuex";

let headers = [
  //{ align: "start", key: "no", title: "No." },
  { align: "start", key: "date", title: "Registered date" },
  { key: "referral", title: "Name" },
  { key: "phone", title: "Phone number" },
  { key: "referral", title: "Referral" },
  { key: "phone", title: "Gender" },
  { key: "rewards_level", title: "Rewards Level" },
  { key: "status", title: "Status" },
  { key: "country", title: "Country" },
];

export default {
  name: "ListReferrals",
  computed: {
    ...mapState(["profile", "socialMedia"]),
  },
  data() {
    return {
      headers: headers,
      dateRange: null,
      formatPrice: formatPrice,
      formatDate: formatDate,
      data: [],
      total: 0,
      totalPages: 0,
      loading: false,
      params: {
        take: 10,
        skip: 1,
        remark: "",
        startDate: "",
        endDate: "",
        referral: "",
      },
      //
      referralId: "",
      referralLink: location.origin + "/sign-up?code=",
      //
      mainRewards: [
        {
          title: " Successful Referrals",
          total: "0",
        },
        {
          title: " Total Referrals",
          total: "0",
        },
      ],
      //
      snackbar: false,
      text: "Copy",
      timeout: 2000,
      token: null,
    };
  },
  methods: {
    async fetchData() {
      let params = getParams({
        ...this.params,
        referral: this.profile.myReferral,
      });
      this.loading = true;
      this.axios
        .get(REWARDS + params)
        .then((response) => {
          let { data, totalPages, total, totalRewards, totalReferral } = response.data;
          //console.log(response.data.data);
          this.data = data;
          this.total = total;
          this.totalPages = totalPages;
          this.loading = false;
          this.mainRewards[0].total = totalRewards;
          this.mainRewards[1].total = totalReferral;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Failed to", error);
        });
    },

    copyCode(code) {
      this.snackbar = true;
      // navigator.clipboard.writeText(location.origin + "/sign-up?code=" + text);
      navigator.clipboard.writeText(code);
    },
    copyLink(link) {
      this.snackbar = true;
      if (link != null) {
        navigator.clipboard.writeText(link);
      }
    },
    async shareLink() {
      if (this.token !== null) {
        const shareData = {
          title: "Share link",
          text: "Check this out!",
          url: this.referralLink,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },
    navigateToInvitationPage() {
      if (this.token !== null) {
        this.$router.push({ path: "/invitation" });
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },
  },
  created() {
    this.token = localStorage.getItem("client_token");
    this.referralId = this.profile.myReferral;
    this.referralLink = location.origin + "/sign-up?code=" + this.profile.myReferral;
    this.fetchData();
  },
  watch: {
    // filter page
    "params.skip": function () {
      this.fetchData();
    },
    "params.take": function () {
      this.fetchData();
    },
    "params.remark": function (remark) {
      if (remark === "All") {
        this.params.remark = "";
      }
      this.fetchData();
    },
    dateRange: function (dateRange) {
      if (dateRange && dateRange.length > 0) {
        let startDate = dateRange[0];
        let endDate = dateRange[dateRange.length - 1];
        this.params.startDate = moment(startDate).format("YYYY-MM-DD");
        this.params.endDate = moment(endDate).format("YYYY-MM-DD");
        this.fetchData();
      }
    },
  },
};
</script>
<style scoped>
th,
td {
  white-space: nowrap;
}

.myBtn {
  border-radius: 0px 20px 20px 0px;
  height: 40px !important;
}

.myTextField {
  border-radius: 20px 20px 20px 20px !important;
}
</style>
