export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const PREFIX = '/api';
export const BASE_URL_API = BASE_URL + PREFIX;
// customer authentication
export const CUSTOMER_SIGN_IN = BASE_URL_API + '/auth/customer/sign-in';
export const CUSTOMER_SIGN_UP = BASE_URL_API + '/auth/customer/sign-up';
export const CUSTOMER_PROFILE = BASE_URL_API + '/auth/customer/user-profile';
export const UPDATE_PROFILE = BASE_URL_API + '/auth/customer/update-profile';
export const UPDATE_ACCOUNT = BASE_URL_API + '/auth/customer/update-account';
export const UPDATE_ACCOUNT_VERIFICATION = BASE_URL_API + '/auth/customer/update-account-verification';
export const UPLOAD_PHOTO = BASE_URL_API + '/auth/customer/photo';
export const UPLOAD_PHOTO_PATH = BASE_URL_API + '/auth/customer/path/';
export const BANK_TYPE = BASE_URL_API + '/customer/bank-type';
export const WALLET_TYPE = BASE_URL_API + '/customer/wallet/';
export const CUSTOMER_VERIFY_EMAIL = BASE_URL_API + '/auth/customer/verify-email';
export const CUSTOMER_SIGN_IN_WITH_GOOGLE = BASE_URL_API + '/auth/customer/sign-in-with-google';
export const CUSTOMER_SIGN_IN_WITH_PHONE = BASE_URL_API + '/auth/customer/sign-in-with-phone';
export const CUSTOMER_FORGET_PASSWORD = BASE_URL_API + '/client/auth-customer-reset-password/forget-password';
export const CUSTOMER_RESET_PASSWORD = BASE_URL_API + '/client/auth-customer-reset-password/reset-password';
export const BANK_ACCOUNT_WITHDRAW = BASE_URL_API + '/customer/bank-account-withdraw';
export const CUSTOMER_WITHDRAW = BASE_URL_API + '/withdraw';
export const SET_PIN_CODE = BASE_URL_API + '/customer/wallet/set-pin-code';
export const UPDATE_PHOTO = BASE_URL_API + '/auth/customer/update-photo';
export const REFERRAL = BASE_URL_API + '/referral-rewards';
export const REWARDS = BASE_URL_API + '/referral-rewards/transactions';
export const PORTFOLIOS = BASE_URL_API + '/auth/customer/portfolios';
// sent email
export const SENT_EMAIL_CODE = BASE_URL_API + '/auth/auth-customer-register/sent-email-code';
export const VERIFY_SENT_EMAIL_CODE = BASE_URL_API + '/auth/auth-customer-register/verify-sent-email-code';
// projects
export const PROJECT = BASE_URL_API + '/client/project';
export const PROJECT_BY_ID = BASE_URL_API + '/client/project/get-project-by-id';
export const PROJECT_SEARCH = BASE_URL_API + '/client/project/search-project';
export const COUNT_PROJECT_BY_USER_INVEST = BASE_URL_API + '/client/project/count-project-by-user-invest';
// home
export const HOME = BASE_URL_API + '/client/home';
// contact us
export const COMPANY = BASE_URL_API + '/client/company';
// invest
export const CUSTOMER_INVEST = BASE_URL_API + '/client/customer-invest';
export const CUSTOMER_INVEST_TRANSACTION = CUSTOMER_INVEST + '/transactions';
export const SEARCH_INVEST_NO = CUSTOMER_INVEST + '/search-invest-no';
export const RETURN_INTEREST_TRANSACTION = CUSTOMER_INVEST + '/return-interest-transactions';

export const CUSTOMER_DEPOSIT = BASE_URL_API + '/client/customer-deposit';

export const CUSTOMER_TRANSFER = BASE_URL_API + '/client/customer-transfer';

export const CUSTOMER_CLAIM_INTEREST_TRANSACTION = BASE_URL_API + '/client/customer-claim-interest/transactions';
// notification
export const CUSTOMER_NOTIFICATION = BASE_URL_API + '/client/customer-notification';
export const CUSTOMER_NOTIFICATION_CLEAR = CUSTOMER_NOTIFICATION + '/clear';

export const API_URL_USER = 'https://get.geojs.io/v1/ip/country.json';
export const DEFAULT_IMAGE = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNNLEL-qmmLeFR1nxJuepFOgPYfnwHR56vcw&s';
export const LOADING_IMAGE = 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif';
export const CAMERA_IMAGE = '../../../assets/camera.jpg';