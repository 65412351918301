<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search staff permission..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      class="dark"
      rounded="compact"
      divided
      fixed-header
      striped
      hover
      color="secondary"
      :search="search"
      :headers="headers"
      :items="permissions"
      :items-per-page="perPage"
      :server-items-length="totalCount"
      :loading="loading"
      :sort-by="[{ key: 'createdAt', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-sheet class="d-flex flex-row-reverse">
          <v-sheet class="ma-2 pa-2 text-center">
            <v-select
              v-model="perPage"
              :items="[10, 25, 50, 100, 1000, 10000]"
              hide-details
              density="compact"
              variant="outlined"
              @update:model-value="perPage = parseInt($event, 10)"
              style="width: 100px"
            ></v-select>
          </v-sheet>
          <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
        </v-sheet>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-4"></v-divider>
            <v-form ref="form" lazy-validation>
              <v-card-text>
                <v-row class="mt-1">
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-switch
                      v-if="permissionStaffLoginAsAdmin"
                      v-model="admin"
                      color="success"
                      :label="isAdmin"
                      hide-details
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      required
                    ></v-switch>
                    <v-switch
                      v-else
                      v-model="admin"
                      color="success"
                      :label="isAdmin"
                      hide-details
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      required
                      disabled
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-switch
                      v-model="finance"
                      color="success"
                      :label="isFinance"
                      hide-details
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      required
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-switch
                      v-model="customerService"
                      color="success"
                      :label="isCustomerService"
                      hide-details
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      required
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-switch
                      v-model="project"
                      color="success"
                      :label="isProject"
                      hide-details
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      required
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-switch
                      v-model="report"
                      color="success"
                      :label="isReport"
                      hide-details
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      required
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-switch
                      v-model="allCountry"
                      color="success"
                      :label="isAllCountry"
                      hide-details
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      required
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-switch
                      v-model="setting"
                      color="success"
                      :label="isSetting"
                      hide-details
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      required
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="permissions.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.staff.fullName`]="{ item }">
        {{ item.staff.fullName }}
      </template>
      <template v-slot:[`item.admin`]="{ item }">
        <div v-if="item.admin" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.finance`]="{ item }">
        <div v-if="item.finance" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.customerService`]="{ item }">
        <div v-if="item.customerService" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.project`]="{ item }">
        <div v-if="item.project" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.allCountry`]="{ item }">
        <div v-if="item.allCountry" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.setting`]="{ item }">
        <div v-if="item.setting" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.report`]="{ item }">
        <div v-if="item.report" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.staff.imgProfile`]="{ item }">
        <v-avatar size="64" class="my-1" v-if="item.staff.imgProfile">
          <v-img alt="Avatar" :src="path + item.staff.imgProfile" cover>
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular
                  color="grey-lighten-5"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <v-avatar
          class="my-1"
          v-else
          image="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          size="80"
        >
          <template v-slot:placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular
                color="grey-lighten-5"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </template>
        </v-avatar>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="isStaffLoginAsAdmin === 1"
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
        <v-icon
          title="Delete"
          class="me-2"
          size="small"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon title="Delete" size="small" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
            @update:model-value="populateStaffPermission"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_Staff_Image,
  URL_FETCH_PERMISSION_WITH_PAGINATION,
  URL_PERMISSION,
  URL_STAFF_LOGIN_PERMISSION,
} from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import { originalCode } from "@/utils/formatNumber";

export default {
  data: () => ({
    path: Path_Staff_Image,
    loading: false, // Loading state
    search: "",
    snack: false,
    snackColor: "",
    snackText: "",
    dialog: false,
    dialogDelete: false,
    isSubmitting: false,

    permissionStaffLoginAsAdmin: false,
    admin: false,
    finance: false,
    customerService: true,
    project: false,
    allCountry: false,
    setting: false,
    report: false,

    //pagination
    page: 1,
    perPage: 10,
    pageCount: 0,
    totalCount: 0, // Total records count

    staffLoginId: parseInt(localStorage.getItem("id")),
    isStaffLoginAsAdmin: originalCode(parseInt(localStorage.getItem("admin"))),

    items: [],
    permissions: [],

    headers: [
      { title: "", align: "center", key: "actions", sortable: false },
      { title: "Photo", key: "staff.imgProfile", align: "center" },
      { title: "Staff", align: "left", sortable: true, key: "staff.fullName" },
      { title: "is Admin?", align: "center", key: "admin" },
      { title: "is Finance?", align: "center", key: "finance" },
      {
        title: "is Customer Service?",
        align: "center",
        key: "customerService",
      },
      { title: "is Project?", align: "center", key: "project" },
      { title: "is Report?", align: "center", key: "report" },
      { title: "is All Country?", align: "center", key: "allCountry" },
      { title: "is Setting?", align: "center", key: "setting" },
      { title: "create at", align: "center", key: "createdAt" },
    ],

    editedIndex: -1,
    hiddenId: 0,
    editedItem: {
      admin: false,
      finance: false,
      customerService: true,
      project: false,
      report: false,
      allCountry: false,
      setting: false,
    },
    defaultItem: {
      admin: false,
      finance: false,
      customerService: true,
      project: false,
      report: false,
      allCountry: false,
      setting: false,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Permission" : "Update Permission";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    isAdmin() {
      return this.admin.toString() === "true" ? "Admin" : "No Admin";
    },
    isFinance() {
      return this.finance.toString() === "true" ? "Finance" : "No Finance";
    },
    isCustomerService() {
      return this.customerService.toString() === "true"
        ? "Customer Service"
        : "No Customer Service";
    },
    isProject() {
      return this.project.toString() === "true" ? "Project" : "No Project";
    },
    isReport() {
      return this.report.toString() === "true" ? "Report" : "No Report";
    },
    isAllCountry() {
      return this.allCountry.toString() === "true" ? "All Country" : "No All Country";
    },
    isSetting() {
      return this.setting.toString() === "true" ? "Setting" : "No Setting";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page() {
      this.populateStaffPermission();
    },
    perPage() {
      this.populateStaffPermission();
    },
    search() {
      this.populateStaffPermission();
    },
  },

  created() {
    this.getPermissionStaffLogin();
    this.populateStaffPermission();
  },
  methods: {
    async getPermissionStaffLogin() {
      await axios
        .get(URL_STAFF_LOGIN_PERMISSION + this.staffLoginId, {
          headers: getTokenHeaders(),
        })
        .then((res) => {
          this.permissionStaffLoginAsAdmin =
            parseInt(res.data.admin) === 1 ? true : false;
        });
    },
    async populateStaffPermission() {
      const params = {
        page: this.page,
        perPage: this.perPage,
        countryId: this.searchCountryId ? this.searchCountryId : 0,
        textSearch: this.search ? this.search : null,
      };

      await axios
        .get(URL_FETCH_PERMISSION_WITH_PAGINATION, { params, headers: getTokenHeaders() })
        .then((response) => {
          if (response.data && response.data.permissionCollection) {
            this.permissions = response.data.permissionCollection;
            this.totalCount = response.data.totalCount;
            this.pageCount = response.data.pageCount;
            this.items.push(this.permissions);
          } else {
            console.warn("Empty API Response");
            this.permissions = [];
          }
          //this.permissionStaffLoginAsAdmin = res.data[0].admin;
        });
    },
    adminSwitchChanging() {
      if (this.admin) {
        this.finance = false;
        this.customerService = false;
        this.project = false;
        this.allCountry = false;
        this.setting = false;
      }
    },
    financeSwitchChanging() {
      if (this.finance) {
        this.admin = false;
        this.customerService = false;
        this.project = false;
        this.allCountry = false;
        this.setting = false;
      }
    },
    customerServiceSwitchChanging() {
      if (this.customerService) {
        this.admin = false;
        this.finance = false;
        this.project = false;
        this.allCountry = false;
        this.setting = false;
      }
    },
    projectSwitchChanging() {
      if (this.project) {
        this.admin = false;
        this.finance = false;
        this.customerService = false;
        this.allCountry = false;
        this.setting = false;
      }
    },
    allCountrySwitchChanging() {
      if (this.allCountry) {
        this.admin = false;
        this.finance = false;
        this.customerService = false;
        this.setting = false;
        this.project = false;
      }
    },
    settingSwitchChanging() {
      if (this.setting) {
        this.admin = false;
        this.finance = false;
        this.customerService = false;
        this.project = false;
        this.allCountry = false;
      }
    },

    editItem(item) {
      this.editedIndex = this.permissions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.hiddenId = item.id;
      this.admin = this.editedItem.admin.toString() === "1" ? true : false;
      this.finance = this.editedItem.finance.toString() === "1" ? true : false;
      this.report = this.editedItem.report.toString() === "1" ? true : false;
      this.customerService =
        this.editedItem.customerService.toString() === "1" ? true : false;
      this.project = this.editedItem.project.toString() === "1" ? true : false;
      this.allCountry = this.editedItem.allCountry.toString() === "1" ? true : false;
      this.setting = this.editedItem.setting.toString() === "1" ? true : false;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.permissions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.permissions.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_PERMISSION + id;
      axios
        .delete(url, { headers: getTokenHeaders() })
        .then(function () {
          this.snakeMessage("error", "Data has been deleted.");
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async submitData() {
      const data = {
        admin: this.admin === true ? 1 : 0,
        finance: this.finance === true ? 1 : 0,
        customerService: this.customerService === true ? 1 : 0,
        project: this.project === true ? 1 : 0,
        report: this.report === true ? 1 : 0,
        allCountry: this.allCountry === true ? 1 : 0,
        setting: this.setting === true ? 1 : 0,
        createBy: this.staffLoginId,
      };

      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_PERMISSION + id;
        Object.assign(this.permissions[this.editedIndex], this.editedItem);

        await axios
          .patch(url, data, { headers: getTokenHeaders() })
          .then(() => {
            this.snakeMessage("success", "Data has been updated.");
          })
          .catch(
            (error) => {
              if (error.response.status === 400) {
                console.log(error.response.data.message); // This will log the error message
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            }
            //console.error("record updated was an error!", error)
          );

        this.populateStaffPermission();
        this.close();
      }
    },

    async save() {
      if (this.isSubmitting) return; // Prevent multiple submissions
      this.isSubmitting = true;

      try {
        // Send your form data to the server
        await this.submitData();
      } catch (error) {
        console.error(error);
      } finally {
        this.isSubmitting = false; // Re-enable the button after the request
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },
  },
};
</script>
