<template>
  <FrontendLayout>
    <!--            <pre>-->
    <!--              {{ contact }}-->
    <!--            </pre>-->

    <div style="height: 700px; background-color: gainsboro">
      <v-img
        style="height: 700px; width: 100%; background-color: gainsboro"
        cover
        :src="
          contact?.picture !== null
            ? contact?.picture
            : 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif'
        "
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular
              color="grey-lighten-5"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>

    <div class="bg-grey-lighten-4 margin-auto">
      <h3 class="text-uppercase text-center text-blue-darken-3 pa-3 font-format">
        contact us
      </h3>

      <p
        class="text-start text-grey-darken-1 pa-7 font-format"
        v-html="contact?.desc"
      ></p>
    </div>

    <div class="bg-blue-darken-3">
      <div class="margin-auto">
        <v-row class="py-10">
          <v-col md="6" sm="12" class="d-flex justify-center">
            <v-card height="720px" width="350px" class="pa-10" rounded="xl">
              <v-alert
                border="end"
                border-color="warning"
                :color="color"
                class="font-format"
                v-show="error"
                size="10"
              >
                {{ message }}
              </v-alert>
              <p class="mb-1 font-format">
                Email <span class="text-error subscript">*</span>
              </p>
              <v-text-field
                rounded="xl"
                v-model="form.email"
                variant="outlined"
                density="compact"
                autocomplete="off"
                placeholder="Enter your email address"
                v-on:keyup="handleChange"
                type="email"
                :rules="nameRules"
              />
              <p class="mb-1 font-format">Name</p>
              <v-text-field
                rounded="xl"
                v-model="form.name"
                variant="outlined"
                density="compact"
                autocomplete="off"
                placeholder="Enter your name"
              />

              <p class="mb-1 font-format">Occupation</p>
              <v-autocomplete
                rounded="xl"
                v-model="form.job"
                :items="jobs"
                variant="outlined"
                density="compact"
                required
                persistent-placeholder
                placeholder="Choose occupation..."
                return-object
              ></v-autocomplete>

              <p class="mb-1 font-format">Country of Residence</p>
              <v-autocomplete
                rounded="xl"
                v-model="form.country"
                :items="countries"
                :item-title="'name'"
                :item-value="'id'"
                variant="outlined"
                density="compact"
                required
                persistent-placeholder
                placeholder="Choose country..."
                return-object
              ></v-autocomplete>
              <i class="mb-1 font-format"
                >I agree with the terms of the
                <span class="text-primary">Privacy Notice</span> and consent to my
                personal data being processed, to the extent necessary, to subcribe to my
                chosen newsletter.</i
              >
              <v-checkbox class="ml-0" v-model="form.status">
                <template v-slot:label>
                  <span style="font-size: 0.775rem"
                    >I AGREE TO THE TERMS OF USE <span style="color: red">*</span></span
                  >
                </template>
              </v-checkbox>

              <v-btn
                block
                rounded="xl"
                :disabled="isSubmitting"
                color="blue"
                elevation="0"
                @click="handleSubmit()"
              >
                <p class="text-capitalize">Subscribe</p>
              </v-btn>
            </v-card>
          </v-col>

          <v-col md="6" sm="12" class="">
            <div class="my-3 font-format">
              <h3>Our Office</h3>
              <p class="my-2" v-html="data.head_office"></p>
              <p class="my-2" v-html="data.address"></p>
            </div>

            <div class="my-6 font-format">
              <h3 class="my-3">Email</h3>
              <p class="my-2" v-html="data.email"></p>
            </div>

            <div class="my-6 font-format">
              <h3 class="my-2">Phone No</h3>
              <p class="my-2" v-html="data.phone"></p>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </FrontendLayout>
</template>

<script>
import { URL_CONTACT_US, URL_GET_CUS_ID, URL_SUBSCRIBE } from "@/utils/apiUrl";
import FrontendLayout from "../components/FrontendLayout";
import axios from "axios";
import { mapState } from "vuex";
import { getTokenHeaders } from "@/utils/headerToken";
import { useEmailSubscribe } from "@/store/recordFrontendStore";
export default {
  name: "ContactUs",
  components: { FrontendLayout },
  computed: {
    ...mapState(["contact", "profile"]),
  },
  data() {
    return {
      data: {},
      jobs: [],
      countries: [],

      isSubmitting: false,
      error: false,

      form: {
        customerId: null,
        email: null,
        name: null,
        job: null,
        country: null,
        status: "active",
      },

      nameRules: [
        (value) => {
          if (value) return true;
          return "Email is required.";
        },
        (value) => {
          if (value.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid Email address";
          }
        },
      ],
    };
  },
  methods: {
    clearContent() {
      this.form.email = null;
      this.form.name = null;
      this.form.job = null;
      this.form.country = null;
      this.form.status = "active";

      var useEmail = useEmailSubscribe();
      useEmail.clearLocalStorageContents();
    },
    async fetchData() {
      await axios
        .get(URL_CONTACT_US)
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
    async loadJobsFromJSONFile() {
      try {
        const response = await fetch("/assets/job.json");
        this.jobs = await response.json();
      } catch (error) {
        console.error("Error loading options:", error);
      }
    },
    async loadCountriesFromJSONFile() {
      try {
        const response = await fetch("/assets/countries.json");
        this.countries = await response.json();
      } catch (error) {
        console.error("Error loading options:", error);
      }
    },
    handleChange(event) {
      this.form.email = event.target.value;
      this.error = false;
    },
    async subscribe(payload) {
      if (!this.form.email) {
        this.error = true;
        this.message = "Email is required. " + payload.email;
        return;
      }

      await axios
        .post(URL_SUBSCRIBE, payload, { headers: getTokenHeaders() })
        .then((response) => {
          this.error = true;
          this.message = response.data.message;
          this.color = "success";
          this.clearContent();
        })
        .catch(
          (error) => {
            this.error = false;

            if (error.response.data.statusCode === 400) {
              //this.snakeMessage("error", error.response.data.message);
              this.message = error.response.data.message;
            } else if (error.request) {
              // The request was made but no response was received
              //this.snakeMessage("error", error.request);
              this.message = error.request;
              // Something happened in setting up the request that triggered an Error
              //this.snakeMessage("error", error.message);
              this.message = error.message;
            }

            return false;
          }
          //console.error("record updated was an error!", error)
        );
    },

    async handleSubmit() {
      let payload = {
        ...this.form,
        customerId: this.form.customerId,
        country:
          this.form.country && this.form.country.name !== null
            ? this.form.country.name
            : null,
        status: this.form.status ? "active" : "inactive",
      };
      if (this.isSubmitting) return; // Prevent multiple submissions
      this.isSubmitting = true;
      try {
        // Send your form data to the server
        await this.subscribe(payload);
      } catch (error) {
        console.error(error);
      } finally {
        this.isSubmitting = false; // Re-enable the button after the request
      }
    },

    async getCustIdByEmail(email) {
      if (this.profile.token) {
        if (email === null || email === "") return;
        await axios
          .get(URL_GET_CUS_ID + email)
          .then((response) => {
            this.form.customerId = response.data.id || null;
          })
          .catch((error) => {
            console.error("Failed to", error);
          });
        //return custId;
      }
    },
  },
  created() {
    let useEmail = useEmailSubscribe();
    this.form.email = useEmail.mail || null;
    this.getCustIdByEmail(this.form.email);
    this.fetchData();
    this.loadJobsFromJSONFile();
    this.loadCountriesFromJSONFile();
  },
};
</script>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
}
</style>
