<script>
import { formatDate } from '@/utils/function';

export default {
  name: 'ModalOverviewProject',
  props: ['row', 'isDialog', 'closeModal'],
  data() {
    return {
      formatDate: formatDate,
      dialog: false,
    };
  },
  created() {
    this.dialog = this.isDialog;
  },
  watch: {
    isDialog(dialog) {
      this.dialog = dialog;
    },
  },
};
</script>

<template>
  <v-dialog width="1000" v-model="dialog" persistent>
    <v-card rounded="xl">
      <v-card-title class="my-3">
        <div class="d-flex justify-space-between">
          <p class="text-primary">
            <v-icon>mdi-help-circle-outline</v-icon>
            Overview
          </p>
          <v-btn color="red" rounded="xl" density="comfortable" icon="mdi-close" @click="closeModal()">
          </v-btn>
        </div>
      </v-card-title>
      <v-divider />

      <v-card-title class="bg-blue-grey-darken-4">
        <div class="d-flex justify-space-between">
          <p class="text-white text-uppercase">
            Notice At-A-Glance
          </p>
        </div>
      </v-card-title>

      <v-row class="ma-2">
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Project ID</p>
          <p>{{ row.Project.project_id }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Project Title</p>
          <p>{{ row.Project.project_title }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Country</p>
          <p class="text-capitalize">{{ row.Project.Country.name }}</p>
        </v-col>

        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Notice No</p>
          <p>{{ row.Project.notice_no }}</p>
        </v-col>

<!--        <v-col cols="6" md="3">-->
<!--          <p class="font-weight-bold my-2">Invest No</p>-->
<!--          <p>{{ row.invest_no }}</p>-->
<!--        </v-col>-->

        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Notice Type</p>
          <p>{{ row.Project.notice_type }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Notice Status</p>
          <p>{{ row.Project.notice_status }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Borrower Bid Reference</p>
          <p>{{ row.Project.borrower_bid_reference }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Procurement Method</p>
          <p>{{ row.Project.procurement_method }}</p>
        </v-col>

        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Language of Notice</p>
          <p>{{ row.Project.language_of_notice }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Submission Deadline Date/Time</p>
          <p>{{ formatDate(row.Project.created_at, 'MMM DD, YYYY HH:mm') }}</p>
        </v-col>
        <v-col cols="6" md="6">
          <p class="font-weight-bold my-2">Published Date</p>
          <p>{{ formatDate(row.Project.board_approval_date, 'MMM DD, YYYY') }}</p>
        </v-col>


        <!--        <v-col cols="6" md="3">-->
        <!--          <v-btn color="primary" class="text-none">-->
        <!--            Feedback Survey-->
        <!--          </v-btn>-->
        <!--        </v-col>-->

      </v-row>

    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>