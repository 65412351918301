<template>
  <ProjectClientComponent title="CrowdFunding"/>
</template>

<script>
import ProjectClientComponent from '@/views/client/projects/ProjectClientComponent.vue';

export default {
  name: 'CrowdfundingPage',
  components: {
    ProjectClientComponent,
  },
};
</script>

<style scoped></style>
