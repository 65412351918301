<template>
  <v-row
    class="overflow-y-auto row-container"
    :style="$vuetify.display.mobile ? 'height: 130vh;' : undefined"
  >
    <v-col cols="12">
      <v-card flat>
        <template v-slot:text>
          <v-row>
            <v-col cols="12" md="3" lg="3" xl="3">
              <p class="mb-2">Country</p>
              <v-autocomplete
                v-if="(isAllCountry === 1) | (isAdmin === 1)"
                v-model="countryId"
                :items="countries"
                :item-title="'name'"
                :item-value="'id'"
                variant="outlined"
                prepend-inner-icon="mdi-flag-outline"
                required
                autocomplete="off"
                persistent-placeholder
                class="style-chooser"
                @update:modelValue="filterItems"
              >
              </v-autocomplete>

              <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                    but else the data return as value normally -->
              <v-autocomplete
                v-else
                v-model="countryId"
                :items="countries"
                :item-title="'name'"
                :item-value="'id'"
                variant="outlined"
                prepend-inner-icon="mdi-flag-outline"
                required
                autocomplete="off"
                persistent-placeholder
                placeholder="Choose customer..."
                disabled
                @update:modelValue="filterItems"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" lg="6" xl="6">
              <p class="mb-2">Search</p>
              <v-text-field
                v-model="search"
                label="Search customer..."
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
                clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            location="top"
          >
            {{ snackText }}
          </v-snackbar>
        </template>
        <v-row>
          <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center mb-4">
            <v-btn
              v-if="isAdminOrFinance"
              class="mb-2 mr-2"
              color="primary"
              prepend-icon="mdi-sync-off"
              variant="flat"
              @click="openconfirmAndReject"
            >
              <div class="text-none font-weight-regular">Confirm/Reject</div>
            </v-btn>
          </v-col>

          <v-col
            cols="6"
            md="6"
            lg="6"
            xl="6"
            class="text-right d-flex mb-6 flex-row-reverse"
          >
            <v-sheet class="ma-2 pa-2 text-center">
              <v-select
                v-model="perPage"
                :items="[10, 25, 50, 100, 1000, 10000]"
                hide-details
                density="compact"
                variant="outlined"
                @update:model-value="perPage = parseInt($event, 10)"
                style="width: 100px"
              ></v-select>
            </v-sheet>
            <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
          </v-col>
        </v-row>

        <v-tabs
          v-model="tab"
          align-tabs="end"
          @click="filterItems"
          color="deep-purple-accent-4"
        >
          <v-tab :value="0">All</v-tab>
          <v-tab :value="1">Pending</v-tab>
          <v-tab :value="2">Done</v-tab>
          <v-tab :value="3">Fail</v-tab>
        </v-tabs>

        <v-tabs-window v-model="tab">
          <v-tabs-window-item v-for="n in 5" :key="n" :value="n">
            <v-container fluid>
              <v-row>
                <v-data-table
                  color="#b2d7ef"
                  rounded="compact"
                  divided
                  striped
                  hover
                  class="elevation-1"
                  fixed-header
                  :search="search"
                  :headers="headers"
                  :items="withdrawCollection"
                  :items-per-page="perPage"
                  :server-items-length="totalCount"
                  :loading="loading"
                  show-select
                  v-model="blockWithdrawSelected"
                  @update:modelValue="handleSelectAll"
                  @click:row.stop="handleRowClick"
                  :sort-by="[
                    { key: 'createdAt', order: 'desc' },
                    { key: 'name', order: 'asc' },
                  ]"
                >
                  <template v-slot:top>
                    <v-dialog
                      v-model="dialog"
                      persistent
                      fullscreen
                      transition="dialog-bottom-transition"
                    >
                      <v-card rounded="lg">
                        <v-card-title
                          class="d-flex justify-space-between align-center"
                        >
                          <div class="text-h6 ps-2">
                            <v-icon class="me-2"> mdi-face-agent </v-icon>
                            {{ formTitle }}
                          </div>

                          <v-btn
                            icon="mdi-close"
                            variant="text"
                            @click="close"
                          ></v-btn>
                        </v-card-title>

                        <v-divider class="mb-0"></v-divider>

                        <v-card-actions class="my-2 d-flex justify-end">
                          <v-spacer></v-spacer>
                          <v-btn
                            class="text-none"
                            color="error"
                            rounded="xl"
                            text="Reject Now"
                            @click="reject"
                            variant="flat"
                          ></v-btn>

                          <v-btn
                            class="text-none"
                            color="primary"
                            rounded="xl"
                            :text="buttonTitle"
                            variant="flat"
                            @click="save"
                          ></v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="dialogImage"
                      persistent
                      fullscreen
                      transition="dialog-bottom-transition"
                    >
                      <v-card>
                        <v-toolbar>
                          <v-toolbar-title prepend-icon="mdi-form-textbox"
                            >Image Card</v-toolbar-title
                          >

                          <v-btn
                            icon="mdi-close"
                            @click="closeDialgeImage"
                          ></v-btn>
                        </v-toolbar>
                        <v-divider class="mb-0"></v-divider>
                        <v-divider class="mb-0"></v-divider>
                        <v-row>
                          <v-col cols="12">
                            <v-img
                              :lazy-src="frontCard"
                              :src="frontCard"
                              aspect-ratio="1"
                              class="bg-grey-lighten-2"
                              height="85vh"
                              contain
                              max-width="auto"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  align="center"
                                  class="fill-height ma-0"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    color="grey-lighten-5"
                                    indeterminate
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-col>
                        </v-row>
                        <v-card-actions class="my-2 d-flex justify-end">
                          <v-spacer></v-spacer>
                          <v-btn
                            class="text-none"
                            rounded="xl"
                            text="Cancel"
                            @click="closeDialgeImage"
                          ></v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <confirmAndReject
                      v-if="blockWithdrawSelected.length > 0"
                      v-model="showRejectDialog"
                      :item="statusPendingSelected"
                    ></confirmAndReject>
                  </template>
                  <!-- detect when error  -->
                  <template v-slot:no-data>
                    <v-alert
                      v-if="items.length > 0"
                      :value="true"
                      color="error"
                      icon="warning"
                      class="text-left"
                    >
                      No data found.
                    </v-alert>
                  </template>
                  <!-- end detect when error  -->
                  <!-- Custom column table -->

                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      size="small"
                      v-if="item.status === 'pending'"
                      color="info"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      size="small"
                      v-if="item.status === 'done'"
                      color="success"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      size="small"
                      v-if="item.status === 'fail'"
                      color="error"
                    >
                      {{ item.status }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.customer.profile`]="{ item }">
                    <v-avatar
                      size="64"
                      class="my-1"
                      v-if="item.customer.profile !== null"
                    >
                      <v-img :src="path + item.customer.profile" cover></v-img>
                    </v-avatar>
                    <v-avatar
                      class="my-1"
                      v-else
                      image="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
                      size="80"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          align="center"
                          class="fill-height ma-0"
                          justify="center"
                        >
                          <v-progress-circular
                            color="grey-lighten-5"
                            indeterminate
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-avatar>
                    <!--
                      <v-span class="ml-2">
                        {{ item.name }}
                      </v-span> -->
                  </template>
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ new Date(item.createdAt).toLocaleString() }}
                  </template>
                  <template v-slot:[`item.withdrawAmount`]="{ item }">
                    {{ convertNumber1000To1K1M1B(item.withdrawAmount) }}
                  </template>
                  <template v-slot:[`item.withdrawExchange`]="{ item }">
                    {{ convertNumber1000To1K1M1B(item.withdrawExchange) }}
                  </template>
                  <template v-slot:[`item.withdrawReceived`]="{ item }">
                    {{ convertNumber1000To1K1M1B(item.withdrawReceived) }}
                  </template>
                  <template v-slot:bottom>
                    <div class="text-center pt-2">
                      <v-pagination
                        v-model="page"
                        rounded="circle"
                        :length="pageCount"
                        :total-visible="7"
                        @update:model-value="
                          PopulatewithdrawCollection(staffLoginCountryCode)
                        "
                      ></v-pagination>
                    </div>
                  </template>
                  <!-- End custom column table -->
                </v-data-table>
              </v-row>
            </v-container>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import {
  Path_Customer_Image,
  URL_COUNTRY,
  URL_PERMISSION,
  URL_WITHDRAW_WITH_PAGINATION,
} from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import { formatNumber, originalCode } from "@/utils/formatNumber";
import confirmAndReject from "../dialogs/confirmAndReject.vue";

export default {
  components: { confirmAndReject },
  data: () => ({
    path: Path_Customer_Image,
    loading: false, // Loading state
    tab: null,
    snack: false,
    dialog: false,
    dialogImage: false,
    showRejectDialog: false,
    visible: false,
    isAdminOrFinance: false,

    items: [],
    countries: [{ id: 0, name: "-- All --" }],
    statusItems: [],
    withdrawCollection: [],
    blockWithdrawSelected: [],
    statusPendingSelected: [],

    search: null,
    snackColor: null,
    snackText: null,
    profile: null,

    editedIndex: -1,
    hiddenId: 0,
    status: "All",
    countryId: 0,

    //pagination
    page: 1,
    perPage: 10,
    pageCount: 0,
    totalCount: 0, // Total records count

    isAdmin: originalCode(parseInt(localStorage.getItem("admin"))),
    isAllCountry: originalCode(parseInt(localStorage.getItem("allCountry"))),
    staffLoginCountryCode: originalCode(parseInt(localStorage.getItem("code"))),
    staffLoginCountryId: originalCode(
      parseInt(localStorage.getItem("countryId"))
    ),
    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Account", key: "customer.profile", align: "center" },
      { title: "Name", key: "name", align: "center" },
      { title: "Status", key: "status", align: "center" },
      { title: "Country", key: "customer.country", align: "center" },
      {
        title: "Payment Method",
        key: "bankAccount.typeBank",
        align: "center",
      },
      { title: "Bank", key: "bankAccount.bankType.bankName", align: "center" },
      { title: "Account Number", key: "accountNumber", align: "center" },
      { title: "Withdraw Amount", key: "withdrawAmount", align: "center" },
      { title: "Withdraw Exchange", key: "withdrawExchange", align: "center" },
      { title: "Withdraw Received", key: "withdrawReceived", align: "center" },
      { title: "Purpose", key: "purpose", align: "center" },
      { title: "Country", key: "customer.country", align: "center" },
      { title: "Created at", key: "createdAt", align: "center" },
    ],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogImage(val) {
      val || this.close();
    },
    page() {
      this.PopulatewithdrawCollection(this.staffLoginCountryCode);
    },
    perPage() {
      this.PopulatewithdrawCollection(this.staffLoginCountryCode);
    },
    search() {
      this.PopulatewithdrawCollection(this.staffLoginCountryCode);
    },
  },

  created() {
    this.staffLoginCountryCode =
      this.isAdminOrAllCountryPermission() ||
      this.getStaffPermissionCustomerByStaffLogin()
        ? null
        : this.staffLoginCountryCode;

    this.getStaffPermissionCustomerByStaffLogin();
    this.PopulateCountries();
    this.PopulatewithdrawCollection(this.staffLoginCountryCode);
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
    },

    isAdminOrAllCountryPermission() {
      return (this.isAdmin || this.isAllCountry) === 1 ? true : false;
    },

    async getStaffPermissionCustomerByStaffLogin() {
      var isPermissionCustomer = 0;
      var isAdmin = 0;
      var isFinance = 0;

      await axios
        .get(URL_PERMISSION + this.staffLoginId, { headers: getTokenHeaders() })
        .then((res) => {
          isPermissionCustomer = res.data.customerService;
          isAdmin = res.data.admin;
          isFinance = res.data.finance;

          this.isAdminOrFinance = isAdmin || isFinance === 0 ? true : false;
        });

      return isPermissionCustomer;
    },

    PopulateCountries() {
      axios
        .get(URL_COUNTRY, { headers: getTokenHeaders() })
        .then((response) => {
          response.data.forEach((country) => {
            this.countries.push({
              name: country.name,
              id: country.id,
              code: country.code,
            });
          });

          this.countryId = this.isAdminOrAllCountryPermission()
            ? this.countryId === 0
              ? "-- All --"
              : this.staffLoginCountryId
            : this.staffLoginCountryId;
        });
    },

    async filterItems(component) {
      switch (this.tab) {
        case 0:
          this.status = "All";
          break;
        case 1:
          this.status = "pending";
          break;
        case 2:
          this.status = "done";
          break;
        case 3:
          this.status = "fail";
          break;
      }

      //const countryCode = this.getCountryCodeByCountryId(component);
      const countryName = this.getCountryCodeByCountryId(component);
      this.PopulatewithdrawCollection(countryName);
    },

    getCountryCodeByCountryId(countryId) {
      if (countryId === 0) {
        return this.staffLoginCountryCode;
      }
      const country = this.countries.find((item) => item.id === countryId);
      return country ? country.name : null;
    },

    async PopulatewithdrawCollection(countryCode) {
      const params = {
        page: this.page,
        perPage: this.perPage,
        status: this.status,
        countryName: countryCode,
        textSearch: this.search ? this.search : null,
      };

      this.loading = true;
      try {
        await axios
          .get(URL_WITHDRAW_WITH_PAGINATION, {
            params,
            headers: getTokenHeaders(),
          })
          .then((response) => {
            if (response.data && response.data.withdrawCollection) {
              this.withdrawCollection = response.data.withdrawCollection;
              this.totalCount = response.data.totalCount;
              this.pageCount = response.data.pageCount;
              this.items.push(this.withdrawCollection);
            } else {
              this.loading = false;
              console.warn("Empty API Response");
              this.withdrawCollection = [];
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },

    openconfirmAndReject() {
      if (this.blockWithdrawSelected.length === 0) {
        this.snakeMessage(
          "warning",
          "Please choose customer at least one to confirm or reject."
        );
      } else {
        this.statusPendingSelected = [];

        this.blockWithdrawSelected.forEach(async (key) => {
          const customer = this.withdrawCollection.find(
            (item) => item.id === key
          );
          if (customer && customer.status === "pending") {
            this.statusPendingSelected.push(customer);
          }
        });

        if (this.statusPendingSelected.length === 0) {
          this.snakeMessage("warning", "Customer status pending doesn't have.");
        } else {
          this.showRejectDialog = true;
        }
      }
    },

    handleSelectAll(itemSelected) {
      if (itemSelected.length === 0) {
        this.showRejectDialog = false;
        return false;
      }
    },

    handleRowClick(event, { item }) {
      // Prevent dialog from opening when selecting rows
      if (this.blockWithdrawSelected.includes(item)) {
        event.stopPropagation();
      }
    },

    editItem(item) {
      this.editedIndex = this.withdrawCollection.indexOf(item);
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDialgeImage() {
      this.dialogImage = false;
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },
  },
};
</script>
<style scoped>
@import "vue-select/dist/vue-select.css";

.row-container {
  overflow-y: auto;
}

style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: 1px solid #d1d5db;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 55px;
  font-size: medium;
  color: #394066;
  max-height: 200px;
  overflow-y: auto;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
</style>
