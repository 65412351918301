<script>
import { formatDate } from "@/utils/function";
import { LOADING_IMAGE } from "@/utils/customerApiUrl";

export default {
  name: "CardImageItem",
  props: ["row"],
  data() {
    return {
      LOADING_IMAGE: LOADING_IMAGE,
      formatDate: formatDate,
      data: {},
    };
  },
  methods: {
    linkTo(item) {
      let data = {
        ...item,
        investmentCheck: false,
      };
      sessionStorage.setItem("project", JSON.stringify(data));
      sessionStorage.setItem("projectId", data.id);
      sessionStorage.setItem("invest", "false");
      this.$router.push("/project-details?from-page=project");
    },
    // textWord(text){
    //   let word = text ? text.split(' ') : '';
    //   return word[0]  + ' ...';
    // }
  },
  created() {
    this.data = this.row;
  },
};
</script>

<template>
  <v-card class="ma-1" min-width="" rounded="xl" height="450">
    <v-img
      height="250"
      :src="data.image_project === null ? LOADING_IMAGE : data.urlImage"
      cover
      class="d-flex align-end pa-5z"
      :lazy-src="LOADING_IMAGE"
    >
      <div class="px-3 card-item-blur">
<!--        <h4 class="text-orange text-uppercase font-weight-regular">-->
<!--          {{-->
<!--            data?.Country?.name === "Cryptocurrency"-->
<!--              ? "All Country"-->
<!--              : data?.Country?.name-->
<!--          }}-->
<!--        </h4>-->

        <h5
          class="text-white three-line-title text-control"
          v-html="(data.project_title)"
        ></h5>
      </div>
    </v-img>

    <div class="d-flex justify-space-around my-2">
      <span class="text-small" v-html="(data.Sector.name)"> </span>
      <span class="text-small"> | </span>
      <span class="text-small">
        {{ data?.project_status ?? "Active" }}
      </span>
      <span class="text-small"> | </span>
      <span class="text-small">
        {{
          data?.board_approval_date_text
            ? (data?.board_approval_date_text)
            : "."
        }}
      </span>
    </div>

    <VDivider />
    <v-card-text>
      <v-card class="" height="70" elevation="0">
        <h4
          class="font-weight-regular three-line text-control"
          v-html="data.description_project"
        ></h4>
      </v-card>
      <br />
      <v-btn variant="text">
        <h4
          class="text-primary font-weight-bold text-uppercase"
          @click="linkTo(data)"
        >
          Read more
          <VIcon>mdi-arrow-right</VIcon>
        </h4>
      </v-btn>
      <br />
    </v-card-text>
  </v-card>
</template>

<style scoped>
.text-small {
  font-size: 12px;
}

.three-line-title {
  display: -webkit-box; /* Enable flex container for webkit */
  -webkit-line-clamp: 2; /* Limit to 3 lines */
  -webkit-box-orient: vertical; /* Set vertical box orientation */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add "..." for truncated text */
}

.three-line {
  display: -webkit-box; /* Enable flex container for webkit */
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical; /* Set vertical box orientation */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add "..." for truncated text */
}
</style>
