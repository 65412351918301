<template>
  <FrontendLayout>
    <div class="bg-grey-lighten-4">
      <div class="margin-auto">
        <br />
        <div>
          <h2 class="text-center text-uppercase text-primary">{{ title }}</h2>
          <br />
          <h3
              v-if="title === 'Projects'"
            class="font-weight-regular text-justify text-content text-control"
            v-html="projectTitle?.description"
          ></h3>
        </div>
        <br />
      </div>

      <VCard
        class="bg-primary position-relative"
        min-height="140"
        rounded="0"
        elevation="0"
      >
        <div class="margin-auto">
          <VRow class="pa-10">
            <VCol cols="12" md="3">
              <p class="my-2">Status</p>
              <v-autocomplete
                rounded="xl"
                placeholder="Project Status"
                density="compact"
                variant="solo"
                v-model="params.projectStatus"
                :items="projectStatus"
                menu-icon="mdi-chevron-right"
                hide-details="auto"
              />
            </VCol>

            <VCol cols="12" md="3">
              <p class="my-2">Sector</p>
              <v-autocomplete
                elevation="0"
                rounded="xl"
                placeholder="Sector"
                density="compact"
                variant="solo"
                v-model="params.sector"
                :items="sectors"
                menu-icon="mdi-chevron-right"
                hide-details="auto"
              />
            </VCol>

            <VCol cols="12" md="3">
              <ProjectSelect
                :callback-project="callbackProject"
                is-density="compact"
                is-title="Project"
                is-variant="solo"
                :propertyAll="false"
              />
            </VCol>

            <VCol cols="12" md="3">
              <p class="my-2 text-primary">.</p>
              <VBtn rounded="xl" block variant="outlined" @click="onSearch()">
                Search
              </VBtn>
            </VCol>
          </VRow>
        </div>
      </VCard>

      <CardNews :exploreMores="exploreMores" />

      <div class="app-paginate hide">
        <v-pagination
          v-model="params.skip"
          :length="totalPages"
          class="d-flex align-center "
          rounded="circle"
          color="primary"
          border
          total-visible="10"
        >
          <template #prev>
            <div class="d-flex">
              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none"
                @click="params.skip = 1"
                :disabled="1 === totalPages"
                variant="outlined"
                border
                color="primary"
              >
                First
              </VBtn>

              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none "
                @click="params.skip--"
                :disabled="params.skip === 1"
                variant="outlined"
                border
                color="primary"
              >
                Previous
              </VBtn>
            </div>
          </template>
          <template #next>
            <div class="d-flex">
              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none "
                @click="params.skip++"
                :disabled="params.skip === totalPages"
                variant="outlined"
                border
                color="primary"
              >
                Next
              </VBtn>

              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none "
                @click="params.skip = totalPages"
                :disabled="params.skip === totalPages"
                variant="outlined"
                border
                color="primary"
              >
                Last
              </VBtn>
            </div>
          </template>
        </v-pagination>
      </div>

      <div class="mobile">
        <div class="d-flex justify-space-between">

          <VBtn
              width="100"
              rounded="xl"
              class="ma-2 text-none"
              @click="params.skip--"
              :disabled="params.skip === 1"
              variant="outlined"
              border
              color="primary"
          >
            Previous
          </VBtn>

          <VBtn
              width="100"
              rounded="xl"
              class="ma-2 text-none"
              @click="params.skip++"
              :disabled="params.skip === totalPages"
              variant="outlined"
              border
              color="primary"
          >
            Next
          </VBtn>
        </div>
      </div>

      <br />
      <br />
    </div>
  </FrontendLayout>
</template>

<script>
import CardNews from '@/views/client/projects/components/CardNews.vue';
import FrontendLayout from '@/views/client/components/FrontendLayout.vue';
import { getClientToken, getParams } from '@/utils/function';
import { API_URL_USER, PROJECT } from '@/utils/customerApiUrl';
import { mapState } from 'vuex';
import ProjectSelect from '@/views/client/projects/components/ProjectSelect.vue';
import axios from 'axios';

export default {
  name: 'ProjectClientComponent',
  props: ['title', 'pathUrlApi'],
  components: {
    ProjectSelect,
    CardNews,
    FrontendLayout,
  },
  computed: {
    ...mapState(['country']),
  },
  data: () => ({
    projectStatus: ['All', 'Active', 'Sold out', 'Proposed', 'Crowdfunding'],
    sectors: [
      'Agribusiness',
      'Banking',
      'Capital Markets',
      'Chemicals',
      'Construction',
      'Education',
    ],
    crowdfunding: ['All', 'Crowdfunding', 'Normal'],
    params: {
      projectStatus: '',
      sector: '',
      crowdfunding: '',
      country: '',
      projectTitle: '',
      skip: 1,
      take: 9,
      page: '',
    },
    totalPages: 1,
    // mock data
    exploreMores: [
      // {
      //   id: 1,
      //   image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
      //   "description_project": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      // },
    ],
    projectTitle: {},
  }),
  methods: {
    async getLocation() {
      await fetch(API_URL_USER)
        .then((response) => response.json())
        .then((data) => {
          this.params.country = data.country_name ? data.country_name : data.name;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchData() {
      let token = await getClientToken();
      let url = token ? PROJECT : PROJECT + '/by-country';
      let mainAxios = null;
      // for customer login
      if (token) {
        mainAxios = this.axios;
      }
      // for guest
      else {
        mainAxios = axios;
      }

      this.exploreMores = [];
      let params = getParams(this.params);
      mainAxios
        .get(url + params)
        .then((response) => {
          let { data, sectors, projectStatus, totalPages, projectTitle } = response.data;
          this.exploreMores = data;
          this.sectors = ['All', ...sectors];
          this.projectStatus = ['All', ...projectStatus];
          this.totalPages = totalPages;
          this.projectTitle = projectTitle;
        })
        .catch((error) => {
          console.error('Failed to', error);
        });

    },

    onSearch() {
      this.params.skip = 1;
      this.fetchData();
    },
    callbackProject(project) {
      if (project) {
        this.params.projectTitle = project.project_title;
      }
    },
  },
  mounted() {
    this.getLocation();
  },
  created() {
    this.params.page = this.$route.path.replace('/', '');
    setTimeout(() => {
      this.fetchData();
    }, 1000);
  },
  watch: {
    // filter page
    'params.skip': function() {
      this.fetchData();
    },
    //
    'params.projectStatus': function(value) {
      if (value === 'All') {
        this.params.projectStatus = '';
      } else if (value !== 'All') {
        this.params.projectStatus = value;
      }
    },
    'params.sector': function(value) {
      if (value === 'All') {
        this.params.sector = '';
      } else if (value !== 'All') {
        this.params.sector = value;
      }
    },
  },
};
</script>

<style scoped></style>
