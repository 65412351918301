<template>
  <div>
    <!--
    <p>Create Exchange Rate</p>
    <v-divider></v-divider>
    <br />
    <div class="d-flex">
      <p class="ma-1">Add a new exchange rate</p>

      <v-btn class="mx-5" icon="mdi-plus" size="small" color="primary"></v-btn>
    </div>

    <br />
    
    <template v-for="i in list" :key="i">
      <div class="d-flex">
        <div style="width: 20%">
          <v-text-field
            v-model="baseCurrency"
            outlined
            variant="outlined"
            rounded="xl"
            suffix="PST"
            density="compact"
            placeholder="USD"
          ></v-text-field>
        </div>

        <p class="ma-2">to</p>

        <div style="width: 20%">
          <v-text-field
            v-model="targetCurrency"
            clearable
            required
            variant="outlined"
            rounded="xl"
            suffix="PST"
            density="compact"
            :placeholder="i.currency"
          ></v-text-field>
        </div>
        <p class="ma-2"></p>
        <v-btn round color="primary" rounded="xl" class="text-none">
          Update</v-btn
        >
        <p class="ma-2"></p>
        <v-btn round color="red" rounded="xl" class="text-none">Remove</v-btn>
        <v-btn class="mx-4" icon="mdi-plus" size="small" color="green"></v-btn>
      </div>
    </template>
    -->
    <p class="title-exchange mb-3">Exchange Rates</p>
    <div>
      <div class="currency-display">
        <div
          style="
            width: 90%;
            text-align: left;
            display: flex;
            align-items: center;
          "
        >
          <!-- <div
          style="width: 90%; text-align: left; display: flex; align-items: center"
          @click="toggleSheet"
        > -->
          <v-avatar
            variant="variant"
            size="36px"
            color="white"
            style="border: 1px solid white"
          >
            <v-img
              alt="John"
              :src="selectedCurrency.flag"
              contain
              :aspect-ratio="1"
            ></v-img>
          </v-avatar>
          <div class="currency-info ml-3">
            <span
              class="currency-code"
              style="color: white; font-weight: normal; opacity: 1"
            >
              {{ selectedCurrency.code }}</span
            >
            <span
              class="currency-name"
              style="color: white; font-weight: normal; opacity: 0.7"
              >{{ selectedCurrency.name }}</span
            >
          </div>
        </div>
        <div style="width: 10%; text-align: right; margin-right: 10px">
          <span
            class="currency-code"
            style="color: white; text-align: right; opacity: 1"
          >
            <v-text-field
              v-model.number="value"
              placeholder="1.00"
              variant="plain"
              reverse
              class="text-white"
              @keypress="exchangeRateByTyping(value)"
              >{{ currency }}
            </v-text-field>
          </span>
        </div>
      </div>

      <div v-if="showSheet" class="bottom-sheet">
        <div class="sheet-content">
          <h5>Select Currency</h5>
          <br />
          <ul class="list-group">
            <li
              v-for="currency in currencies"
              :key="currency.code"
              class="list-group-item currency-item mb-3"
              @click="selectCurrency(currency)"
            >
              <v-avatar
                variant="variant"
                size="36px"
                color="white"
                style="border: 1px solid black; margin-right: 10px"
              >
                <v-img
                  alt="John"
                  :src="currency.flag"
                  contain
                  :aspect-ratio="3 / 4"
                ></v-img>
              </v-avatar>
              <div class="currency-info">
                <span class="currency-code">{{ currency.code }}</span>
                <span class="currency-name">{{ currency.name }}</span>
              </div>
            </li>
          </ul>
          <v-btn color="error" block @click="toggleSheet">Close</v-btn>
        </div>
      </div>
    </div>

    <!-- List Exchange Rates -->
    <div class="exchange-rates mt-4">
      <div v-if="listExchangeRates.length === 0">
        <p>No exchange rates available.</p>
      </div>
      <div v-else>
        <div
          v-for="rate in listExchangeRates"
          :key="rate.country.code"
          class="currency-rate"
        >
          <div>
            <div style="float: left; margin-right: 10px; margin-top: 5px">
              <v-avatar
                variant="variant"
                size="36px"
                color="white"
                style="border: 1px solid black"
              >
                <v-img
                  alt="John"
                  :src="
                    rate.country.abbr === 'CR'
                      ? `${pathExchangeDepositLogoCurrency}${rate.logo}`
                      : rate.country.image
                  "
                  contain
                  :aspect-ratio="3 / 4"
                ></v-img>
              </v-avatar>
            </div>
            <div class="currency-info">
              <span class="currency-code">{{
                rate.country.abbr === "CR"
                  ? rate.nameCurrency
                  : rate.country.abbr
              }}</span>
              <span class="currency-name">{{ rate.country.currency }}</span>
            </div>
          </div>
          <div class="text-right">
            <span class="currency-code"
              >{{ rate.rate }} {{ rate.country.abbr }}</span
            ><br />
            <span class="currency-name"
              >1 {{ currency }} = {{ rate.rate }} {{ rate.country.abbr }}</span
            >
          </div>
        </div>
      </div>

      <!-- List Exchange Rates -->

      <p class="note" v-html="discription"></p>
    </div>
  </div>
</template>
<script>
import {
  Path_Exchange_Deposit_Logo_Currency,
  URL_Exchange_Deposit_BY_COUNTRY,
} from "@/utils/apiUrl";
import { mapState } from "vuex";

export default {
  name: "ExchangeTab",
  computed: {
    ...mapState(["wallet"]),
  },
  data() {
    return {
      pathExchangeDepositLogoCurrency: Path_Exchange_Deposit_Logo_Currency,
      showSheet: false,
      listExchangeRates: [],
      selectedCurrency: {
        // default currency
        code: "USD",
        name: "United States Dollar",
        flag: "https://flagcdn.com/us.svg",
      },
      currencies: [
        // List default of currencies
        {
          code: "USD",
          name: "United States dollar",
          flag: "https://flagcdn.com/us.svg",
        },
        {
          code: "KHR",
          name: "Cambodian riel",
          flag: "https://flagcdn.com/w320/kh.png",
        },
        {
          code: "TH",
          name: "Thai baht",
          flag: "https://flagcdn.com/w320/th.png",
        },
        {
          code: "IN",
          name: "Indian rupee",
          flag: "https://flagcdn.com/in.svg",
        },
        // Add more currencies and their flags as needed
      ],
      currency: "USD",
      value: "1.00",
      discription: null,
    };
  },
  created() {
    setTimeout(() => {
      this.getCountryCurrencies();
    }, 1000);
  },
  methods: {
    async getCountryCurrencies(currencyName = "United States dollar") {
      const userLoginCountryId = this.wallet[0]?.id;

      await this.axios
        .get(`${URL_Exchange_Deposit_BY_COUNTRY}${userLoginCountryId}`)
        .then((response) => {
          this.listExchangeRates = response.data.filter(
            (rate) =>
              !rate.country.currency
                .toLowerCase()
                .includes(currencyName.toLowerCase())
          );

          // exchange rate
          this.listExchangeRates = this.listExchangeRates.map((rate) => {
            rate.rate = rate.rate * this.value;
            return rate;
          });

          if (this.listExchangeRates.length > 0) {
            this.discription = this.listExchangeRates[0].desc;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exchangeRateByTyping(value) {
      this.value = value;
      this.getCountryCurrencies(this.selectedCurrency.name);
    },
    openBottomSheet(rate) {
      this.selectedRate = rate;
      this.showSheet = true;
    },
    closeBottomSheet() {
      this.showSheet = false;
    },
    toggleSheet() {
      this.showSheet = !this.showSheet;
    },
    selectCurrency(currency) {
      this.selectedCurrency = currency;
      this.currency = currency.code;
      this.getCountryCurrencies(currency.name);
      this.toggleSheet();
    },
  },
};
</script>
<style scoped>
.exchange-rates {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
h1 {
  font-size: 24px;
  margin-bottom: 10px;
}
.currency-rate {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.currency-info {
  display: flex;
  flex-direction: column;
}
.currency-code {
  font-weight: bold;
  margin-bottom: 2px;
  opacity: 0.7;
}
.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e0e0e0 !important;
  box-shadow: 0 -2px 8px rgba(229, 225, 225, 0.1);
  padding: 20px;
  z-index: 1000;
}
.sheet-content {
  display: flex;
  flex-direction: column;
}
.currency-option {
  padding: 10px;
  cursor: pointer;
}
.currency-option:hover {
  background-color: #f0f0f0;
}
.note {
  margin-top: 20px;
  font-size: 12px;
  color: #888;
}
.currency-name {
  font-size: 13px;
  color: #666;
  opacity: 0.9;
}

.currency-display {
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px;
  background-color: rgb(var(--v-theme-primary)) !important;
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 20px;
}

.flag {
  width: 30px;
  height: 20px;
  margin-right: 8px;
}

.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid #ced4da;
  padding: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.sheet-content {
  max-height: 400px;
  overflow-y: auto;
}

.currency-item {
  display: flex;
  align-items: center;
}

.currency-item .flag {
  width: 30px;
  height: 20px;
  margin-right: 8px;
}
.title-exchange {
  font-size: larger;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
