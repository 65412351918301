<template>
  <v-container class="pa-4">
    <v-card class="pa-4">
      <v-toolbar color="#1565C0" dark>
        <v-toolbar-title class="text-center">Transaction Receipt</v-toolbar-title>
        <v-toolbar-items>
          <v-btn @click="navigateToStatementPage">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-row class="mt-4 text-center">
        <v-col>
          <v-icon size="40" color="#005F83">mdi-store</v-icon>
          <h2 class="font-weight-bold">-{{ transferToAccountCollection.amount }} USD</h2>
          <p class="text-subtitle-1 text-uppercase">
            {{ transferToAccountCollection.ToCustomer?.name }}
          </p>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>
      <v-row dense>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p>APV:</p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p class="text-right font-weight-bold">{{ transferToAccountCollection.apv }}</p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p>From account:</p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p class="text-right font-weight-bold text-uppercase">
            {{ transferToAccountCollection.fromCustomer?.name }}
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p>Original amount:</p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p class="text-right font-weight-bold">
            {{ transferToAccountCollection.amount }} USD
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p>Purchase #:</p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p class="text-right font-weight-bold">
            {{ transferToAccountCollection.purchase }}
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p>Reference #:</p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p class="text-right font-weight-bold">
            {{ transferToAccountCollection.reference }}
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p>Seller:</p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p class="text-right font-weight-bold text-uppercase">
            {{ transferToAccountCollection.ToCustomer?.name }}
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p>Transaction date:</p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <p class="text-right font-weight-bold">
            {{ formatDate(transferToAccountCollection.tranferDate) }}
          </p>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <v-row class="text-center">
        <v-col>
          <v-btn color="#1565C0" class="mr-4">
            <v-icon left>mdi-share-variant</v-icon> Share Receipt
          </v-btn>
          <v-btn color="#1565C0"> <v-icon left>mdi-download</v-icon> Download PDF </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { formatDateTime } from "@/utils/formatNumber";

export default {
  props: { transferToAccountCollection: Object }, // Receiving data from parent
  name: "TransactionReceipt",
  methods: {
    navigateToStatementPage() {
      window.location.href = `/transfer-to-account`;
    },
    formatDate(date) {
      return formatDateTime(date);
    },
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
</style>
