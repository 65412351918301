<template>
  <v-tabs v-model="tab" color="blue" grow bg-color="white">
    <v-tab
      v-for="tabIndex in tabList"
      :text="tabIndex.title"
      v-bind:key="tabIndex.key"
      class="text-none"
    />
  </v-tabs>
  <br />
  <v-tabs-window v-model="tab">
    <v-tabs-window-item
      v-for="tabIndex in tabList"
      :value="tabIndex.title"
      v-bind:key="tabIndex.key"
    >
      <component :is="tabIndex.component" v-if="tab === tabIndex.key" />
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script>
import NoticesTab from "@/views/client/profile/transactions/NoticesTab.vue";
import FundDetailsTab from "@/views/client/profile/transactions/FundDetailsTab.vue";
import CustomerCommission from "@/views/client/profile/transactions/CustomerCommission.vue";

let tab = "transactions_tab";

export default {
  name: "TransactionPage",
  components: { CustomerCommission, FundDetailsTab, NoticesTab },
  data() {
    return {
      tab: 0,
      currentComponent: NoticesTab.name,
      tabList: [
        { title: "NOTICES", key: 0, component: NoticesTab.name },
        { title: "FUND DETAILS", key: 1, component: FundDetailsTab.name },
        { title: "REBATE", key: 2, component: CustomerCommission.name },
      ],
    };
  },
  created() {
    let defaultTab = localStorage.getItem(tab);
    this.tab = parseInt(defaultTab) ?? 0;
  },
  watch: {
    tab(val) {
      localStorage.setItem(tab, val);
    },
  },
};
</script>

<style scoped></style>
