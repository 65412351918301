<script>
import { mapState } from 'vuex';
import { formatPriceCurrency } from '@/utils/function';

export default {
  name: 'CardTotal',
  computed: {
    ...mapState([
      "totalReport",
    ]),
  },
  data() {
    return {
      formatPriceCurrency: formatPriceCurrency
    };
  },
};
</script>

<template>
<!--  <pre>-->
<!--    {{totalReport}}-->
<!--  </pre>-->
  <div class="margin-auto">
    <VCard rounded="xl" min-height="170">
      <VRow class="pa-8">

        <VCol cols="12" md="3">
          <VCard color="purple" variant="outlined" rounded="xl">
            <v-card color="purple" variant="tonal" class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">
                    <v-avatar color="purple" size="40" rounded="sm" class="mx-4">
                      <VIcon>mdi-login</VIcon>
                    </v-avatar>
                    <span>{{formatPriceCurrency(totalReport?.total_investor)}}</span>
                  </div>
                  <div class="text-captions mx-10s text-grey-darken-2 text-center">
                    <p class="font-weight-regular">
                      Total Investors
                    </p>
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </VCard>
        </VCol>

        <VCol cols="12" md="3">
          <VCard color="indigo" variant="outlined" rounded="xl">
            <v-card color="indigo" variant="tonal" class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">
                    <v-avatar color="indigo" size="40" rounded="sm" class="mx-4">
                      <VIcon>mdi-currency-usd</VIcon>
                    </v-avatar>
                    {{formatPriceCurrency(totalReport?.total_withdraw)}}
                  </div>
                  <div class="text-captions mx-10s text-grey-darken-2 text-center">
                    <p class="font-weight-regular">
                      Total Withdrawals
                    </p>
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </VCard>
        </VCol>

        <VCol cols="12" md="3">
          <VCard color="blue" variant="outlined" rounded="xl">
            <v-card color="blue" variant="tonal" class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">
                    <v-avatar color="blue" size="40" rounded="sm" class="mx-4">
                      <VIcon>mdi-cash-refund</VIcon>
                    </v-avatar>
                    {{formatPriceCurrency(totalReport?.total_transaction)}}
                  </div>
                  <div class="text-captions mx-10s text-grey-darken-2 text-center">
                    <p class="font-weight-regular">
                      Total Transactions
                    </p>
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </VCard>
        </VCol>

        <VCol cols="12" md="3">
          <VCard color="green" variant="outlined" rounded="xl">
            <v-card color="green" variant="tonal" class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">
                    <v-avatar color="green" size="40" rounded="sm" class="mx-4">
                      <VIcon>mdi-account-circle-outline</VIcon>
                    </v-avatar>
                    {{formatPriceCurrency(totalReport?.total_subscribe)}}
                  </div>
                  <div class="text-captions mx-10s text-grey-darken-2 text-center">
                    <p class="font-weight-regular">
                      Total Subscriptions
                    </p>
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </VCard>
        </VCol>

      </VRow>
    </VCard>
  </div>
</template>

<style scoped>

</style>