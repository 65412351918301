<template>
  <!--page 1-->
  <div v-if="index == 1">
    <!-- <h3 class="text-center">Let's open your migawb account</h3>

    <br /> -->

    <v-card width="" elevation="" rounded="">
      <v-toolbar color="white">
        <v-btn icon="mdi-arrow-left" @click="nextStep(4)"></v-btn>
      </v-toolbar>
      <!-- <p class="mt-5 text-center">steps to get set up</p> -->

      <div class="">
        <v-list class="text-start">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-avatar color="grey-lighten-3" size="small">
                  <v-icon icon="mdi-card-account-details-outline" size="15"></v-icon>
                </v-avatar>

                <v-icon size="12" color="green" class="mt-5"> mdi-check-circle</v-icon>

                <span class="text-light-green-darken-4">
                  Fill in your personal details
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-avatar color="grey-lighten-3" size="small">
                  <v-icon icon="mdi-account-circle-outline" size="15"></v-icon>
                </v-avatar>
                <v-icon
                  size="12"
                  v-if="profile.status.toLowerCase() === 'done'"
                  color="green"
                  class="mt-5"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-if="profile.status.toLowerCase() !== 'done'"
                  size="12"
                  color="amber-accent-4"
                  class="mt-5"
                >
                  mdi-clock-time-eight
                </v-icon>
                <span class="text-light-green-darken-4"> Verify your identity </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="ma-4">
          <v-btn
            v-if="profile.status.toLowerCase() !== 'done'"
            color="primary"
            class="pa-4 text-none"
            block
            rounded="xl"
            @click="nextStep(2)"
          >
            Continue
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
  <!--page 1-->

  <!--page 2-->
  <v-card v-if="index == 2">
    <v-form ref="form">
      <v-toolbar color="white">
        <v-btn icon="mdi-arrow-left" @click="nextStep(1)"></v-btn>

        <span>Account verification</span>
      </v-toolbar>

      <v-row class="mx-5">
        <v-col cols="12" sm="12" md="4">
          <p class="text-start">Name</p>
          <v-text-field
            :rules="fullNameRules"
            placeholder="- - - - - - "
            variant="outlined"
            density="compact"
            v-model="account_verification.name"
            rounded="xl"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="2">
          <p class="text-start">Gender</p>
          <v-select
            :rules="genderRules"
            placeholder="- - - - - - "
            variant="outlined"
            density="compact"
            rounded="xl"
            :items="['Male', 'Female', 'Other']"
            v-model="account_verification.gender"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="12" md="3">
          <p class="text-start">Identification Type</p>
          <v-select
            :rules="cardTypeRules"
            v-model="account_verification.typeCard"
            placeholder="- - - - - - "
            variant="outlined"
            density="compact"
            rounded="xl"
            :items="['Identity Card', 'Passport', 'Driver License']"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="12" md="3">
          <p class="text-start">Identification No.</p>
          <v-text-field
            :rules="cardNumberRules"
            v-model="account_verification.cardNumber"
            placeholder="- - - - - - "
            variant="outlined"
            density="compact"
            rounded="xl"
          ></v-text-field>
        </v-col>

        <!-- <v-col cols="12" sm="12" md="3">
          <p class="text-start">Expire Date</p>
          <v-text-field
            :rules="expireDateRules"
            v-model="account_verification.expireDate"
            placeholder="DD-MM-YYYY"
            variant="outlined"
            density="compact"
            rounded="xl"
          ></v-text-field>
        </v-col> -->

        <v-col cols="12" sm="12" md="5">
          <p class="text-start">Date Expired</p>

          <v-row>
            <v-col cols="4">
              <v-select
                v-model="selectedDay"
                placeholder="DD"
                variant="outlined"
                density="compact"
                rounded="xl"
                :items="days"
                :rules="[(v) => !!v || 'Day is required']"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="selectedMonth"
                placeholder="MMM"
                variant="outlined"
                density="compact"
                rounded="xl"
                :items="months"
                :item-title="'name'"
                :item-value="'id'"
                :rules="[(v) => !!v || 'Month is required']"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="selectedYear"
                placeholder="- - - - - - "
                variant="outlined"
                density="compact"
                rounded="xl"
                :items="years"
              ></v-select>
            </v-col>
          </v-row>
          <!-- Error Message -->
          <v-alert v-if="errorMessage" class="mt-3" type="error" variant="outlined">
            {{ errorMessage }}
          </v-alert>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <p class="text-start">Upload your Id / Passport / Drive Licence</p>

          <v-row>
            <v-col cols="12" md="6">
              <br />
              <p class="text-center text-primary">Front Image</p>

              <img :src="previewImageFront" width="100%" height="200px" alt="" />
              <v-btn
                onclick="document.getElementById('front_image').click()"
                density="compact"
                color="primary"
                class="text-none"
                rounded="xl"
                width="100"
              >
                <v-icon>mdi-camera</v-icon>
                Upload
              </v-btn>
              <input
                style="display: none"
                type="file"
                id="front_image"
                @change="frontImage"
              />
            </v-col>

            <v-col cols="6">
              <br />
              <p class="text-center text-primary">Back Image</p>
              <img :src="previewImageBack" width="100%" height="200px" alt="" />
              <v-btn
                onclick="document.getElementById('back_image').click()"
                density="compact"
                color="primary"
                class="text-none"
                rounded="xl"
                width="100"
              >
                <v-icon>mdi-camera</v-icon>
                Upload
              </v-btn>
              <input
                style="display: none"
                type="file"
                id="back_image"
                @change="backImage"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" class="d-flex justify-sm-end">
          <v-btn
            :loading="loading"
            color="primary"
            class="text-none"
            rounded="xl"
            width="100"
            @click="submitSaveAccountVerification()"
          >
            Submit
          </v-btn>
        </v-col>
        <!-- <v-col cols="12" sm="12" md="12" class="d-flex justify-lg-space-between">
          <v-btn
            block
            color="primary"
            class="text-none"
            rounded="xl"
            width="100"
            @click="submitNextUpdate()"
          >
            Next
          </v-btn>
        </v-col> -->
      </v-row>
    </v-form>
    <br />
  </v-card>
  <!--page 2-->

  <!--page 3-->
  <v-card v-if="index == 3">
    <v-toolbar color="white">
      <v-btn icon="mdi-arrow-left" @click="nextStep(2)"></v-btn>

      <span>Account verification</span>
    </v-toolbar>

    <v-row class="mx-5">
      <v-col cols="12" md="12" sm="12">
        <v-alert
          variant="outlined"
          color="error"
          v-if="message"
          :text="message"
          title="Error"
          border="end"
          density="compact"
          border-color="error"
          type="error"
        ></v-alert>

        <v-alert
          variant="outlined"
          color="success"
          type="success"
          v-if="success"
          :text="success"
          title="Success"
          border="end"
          density="compact"
          border-color="success"
        ></v-alert>
      </v-col>

      <v-col cols="12" sm="12" md="8">
        <p class="text-start">Referral ID</p>
        <v-text-field
          :rules="referralId"
          v-model="account_verification.referral"
          placeholder="xxx-xxx-xxx-xxx"
          variant="outlined"
          density="compact"
          rounded="xl"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <h2>Enter a referral link</h2>

        <p>
          <span class="text-grey-darken-1">
            If you forget to sign up with a link, you can add it here. You and your friend
            will be a step closer to your referral rewards.
          </span>
        </p>
      </v-col>

      <v-col cols="12" sm="12" md="8">
        <p class="text-start">Enter an invite link</p>
        <v-text-field
          v-model="account_verification.referralLink"
          placeholder="www.example.com/1234"
          variant="outlined"
          density="compact"
          rounded="xl"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="12" md="12" class="d-flex justify-sm-end">
        <v-btn
          :loading="loading"
          color="primary"
          class="text-none"
          rounded="xl"
          width="100"
          @click="submitSaveAccountVerification()"
        >
          Submit
        </v-btn>
      </v-col>
    </v-row>

    <br />
  </v-card>
  <!--page 3-->

  <!--page 4-->
  <div v-if="index == 4">
    <v-card rounded="md">
      <v-table>
        <tbody>
          <tr>
            <td colspan="2" class="bg-primary">
              <h2>Profile ' info</h2>
            </td>
          </tr>

          <tr>
            <td>Profile info</td>
            <td>
              <v-btn
                min-width="100"
                class="text-none"
                variant="outlined"
                rounded="xl"
                color="primary"
                size="small"
                @click="showModal()"
              >
                <v-icon>mdi-chevron-right</v-icon>
                Update</v-btn
              >
            </td>
          </tr>
          <tr>
            <td>
              <div class="d-flex">
                Verify account
                <v-spacer />
                <v-chip
                  :color="profile.status === 'pending' ? 'primary' : 'green'"
                  size="small"
                  variant="tonal"
                >
                  {{
                    profile.status === "pending" ? "Is Review" : "Is " + profile.status
                  }}
                </v-chip>
              </div>
            </td>
            <td>
              <v-btn
                min-width="100"
                class="text-none"
                variant="outlined"
                rounded="xl"
                color="primary"
                size="small"
                @click="nextStep(1)"
              >
                <v-icon>mdi-chevron-right</v-icon>
                Verify
              </v-btn>
            </td>
          </tr>
          <tr>
            <td>Information</td>
            <td>
              <v-btn
                min-width="100"
                class="text-none"
                variant="outlined"
                rounded="xl"
                color="primary"
                size="small"
                @click="nextStep(5)"
              >
                <v-icon>mdi-chevron-right</v-icon>
                Review</v-btn
              >
            </td>
          </tr>

          <tr>
            <td>Privacy</td>
            <td>
              <v-btn
                min-width="100"
                class="text-none"
                variant="outlined"
                rounded="xl"
                color="primary"
                size="small"
                @click="routeToPage('PrivacyPage')"
                ><v-icon>mdi-chevron-right</v-icon> Privacy</v-btn
              >
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </div>
  <!--page 4-->

  <!--page 5-->
  <div v-if="index == 5">
    <h3 class="text-center">Information Detail</h3>

    <br />

    <v-card width="" elevation="" rounded="">
      <v-toolbar color="white">
        <v-btn icon="mdi-arrow-left" @click="nextStep(4)"></v-btn>
      </v-toolbar>

      <div>
        <v-card color="basil">
          <v-tabs v-model="tab" bg-color="surface" color="primary" grow>
            <v-tab v-for="item in items" :key="item" :text="item" :value="item"></v-tab>
          </v-tabs>

          <v-tabs-window v-model="tab">
            <v-tabs-window-item v-for="item in items" :key="item" :value="item">
              <v-card color="basil" flat v-if="item === 'Info'">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4" sm="12">
                      <p class="ma-1">First Name</p>
                      <v-text-field
                        v-model="form.firstName"
                        prepend-inner-icon="mdi-account-outline"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="First Name"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <p class="ma-1">Last Name</p>
                      <v-text-field
                        v-model="form.lastName"
                        prepend-inner-icon="mdi-account-outline"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Last Name"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <p class="ma-1">Full Name</p>
                      <v-text-field
                        v-model="form.fullName"
                        prepend-inner-icon="mdi-account-outline"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Full Name"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <p class="ma-1">Sex</p>
                      <v-text-field
                        v-model="form.sex"
                        prepend-inner-icon="mdi-account-outline"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Sex"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <p class="ma-1">Date of Birth</p>
                      <v-text-field
                        v-model="form.dob"
                        prepend-inner-icon="mdi-calendar-range"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Date of Birth"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <p class="ma-1">Email</p>
                      <v-text-field
                        v-model="form.email"
                        prepend-inner-icon="mdi-email"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Email"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <p class="ma-1">Phone</p>
                      <v-text-field
                        v-model="form.phone"
                        prepend-inner-icon="mdi-phone"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Phone"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <p class="ma-1">Nationality</p>
                      <v-text-field
                        v-model="form.nationality"
                        prepend-inner-icon="mdi-flag-variant-outline"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Nationality"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <p class="ma-1">Country</p>
                      <v-text-field
                        v-model="form.country"
                        prepend-inner-icon="mdi-flag-variant-outline"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Country"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <p class="ma-1">Address</p>
                      <v-textarea
                        rows="4"
                        v-model="form.address"
                        clearable
                        variant="outlined"
                        density="compact"
                        required
                        disabled
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card color="basil" flat v-if="item === 'Identification'">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <v-card variant="variant" class="mx-auto" elevation="16">
                        <img
                          :src="previewImageFront"
                          width="100%"
                          height="300px"
                          alt=""
                        />
                      </v-card>
                      <div class="text-center">
                        <span class="my-2 text-button text-h6">Front ID Card Image</span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <v-card variant="variant" class="mx-auto" elevation="16">
                        <img :src="previewImageBack" width="100%" height="300px" alt="" />
                      </v-card>
                      <div class="text-center">
                        <span class="my-2 text-button text-h6">Back ID Card Image</span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                      <p class="ma-1">Type Card</p>
                      <v-text-field
                        v-model.trim="account_verification.typeCard"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Type Card"
                        rounded="xl"
                        disabled
                        prepend-inner-icon="mdi-card-account-details"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                      <p class="ma-1">Name Card</p>
                      <v-text-field
                        v-model.trim="account_verification.name"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Name Card"
                        rounded="xl"
                        disabled
                        prepend-inner-icon="mdi-card-account-details"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                      <p class="ma-1">Card Number</p>
                      <v-text-field
                        v-model.trim="account_verification.cardNumber"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Card Number"
                        rounded="xl"
                        disabled
                        prepend-inner-icon="mdi-card-account-details"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <p class="ma-1">Expired Date</p>
                      <v-text-field
                        v-model.trim="account_verification.expireDate"
                        prepend-inner-icon="mdi-credit-card-clock"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Expired Date"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card color="basil" flat v-if="item === 'Confirmation Info'">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <p class="ma-1">Occupation</p>
                      <v-text-field
                        v-model.trim="confirmation_info.occupation"
                        prepend-inner-icon="mdi-ticket-confirmation"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Occupation"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <p class="ma-1">Level Number</p>
                      <v-text-field
                        v-model.trim="confirmation_info.numberLevel"
                        prepend-inner-icon="mdi-ticket-confirmation"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Level Number"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <p class="ma-1">Referral Link</p>
                      <v-text-field
                        v-model.trim="confirmation_info.referralLink"
                        prepend-inner-icon="mdi-link-variant"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="Referral Link"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <p class="ma-1">My Referral</p>
                      <v-text-field
                        v-model.trim="confirmation_info.myReferral"
                        prepend-inner-icon="mdi-ticket-confirmation"
                        density="compact"
                        variant="outlined"
                        required
                        placeholder="My Referral"
                        rounded="xl"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card color="basil" flat v-if="item === 'Bank Info'">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-card class="mx-auto" subtitle="Wallet">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="3">
                              <p class="ma-1">Available Balance</p>
                              <v-text-field
                                v-model.trim="wallet.availableBalance"
                                prepend-inner-icon="mdi-currency-usd"
                                density="compact"
                                variant="outlined"
                                required
                                placeholder="Available Balance"
                                rounded="xl"
                                disabled
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                              <p class="ma-1">Account Balance</p>
                              <v-text-field
                                v-model.trim="wallet.accountBalance"
                                prepend-inner-icon="mdi-currency-usd"
                                density="compact"
                                variant="outlined"
                                required
                                placeholder="Account Balance"
                                rounded="xl"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <p class="ma-1">Bank Account</p>
                              <v-text-field
                                v-model.trim="wallet.bankAccount"
                                prepend-inner-icon="mdi-account-outline"
                                density="compact"
                                variant="outlined"
                                required
                                placeholder="Bank Account"
                                rounded="xl"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <p class="ma-1">Wallet Type</p>
                              <v-text-field
                                v-model.trim="wallet.walletType"
                                prepend-inner-icon="mdi-wallet-travel"
                                density="compact"
                                variant="outlined"
                                required
                                placeholder="Wallet Type"
                                rounded="xl"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <p class="ma-1">Pin Code</p>
                              <v-text-field
                                v-model.trim="wallet.pin_code"
                                prepend-inner-icon="mdi-barcode"
                                placeholder="Pin Code"
                                density="compact"
                                variant="outlined"
                                required
                                rounded="xl"
                                disabled
                                type="password"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card>
      </div>
    </v-card>
  </div>
  <!--page 5-->

  <!--page 6-->
  <div v-if="index == 6">
    <h2 class="text-center">{{ privacyData.title }}</h2>

    <br />

    <v-card width="" elevation="" rounded="">
      <v-toolbar color="white">
        <v-btn icon="mdi-arrow-left" @click="nextStep(4)"></v-btn>
      </v-toolbar>

      <div>
        <v-list disabled>
          <v-list-item>
            <v-list-item-title>
              <div v-html="privacyData.desc" class="text-justify text-wrap"></div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
  </div>
  <!--page 6-->

  <!--form-->
  <div class="pa-4 text-center">
    <v-dialog v-model="dialog" max-width="70%">
      <v-card rounded="xl">
        <v-card-title>
          <div class="d-flex justify-lg-space-between my-3">
            <p></p>
            <h4 class="text-grey-darken-1">Profile Information</h4>
            <v-btn
              density="compact"
              icon="mdi-close"
              color="red"
              @click="dialog = false"
            ></v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form ref="formProfile">
            <v-alert
              variant="outlined"
              color="error"
              v-if="message"
              :text="message"
              title="Error"
              border="end"
              density="compact"
              border-color="error"
              type="error"
            ></v-alert>

            <v-alert
              variant="outlined"
              color="success"
              type="success"
              v-if="success"
              :text="success"
              title="Success"
              border="end"
              density="compact"
              border-color="success"
            ></v-alert>

            <v-row dense>
              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">First Name</p>
                <v-text-field
                  v-model="form.firstName"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="First Name"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">Last Name</p>
                <v-text-field
                  v-model="form.lastName"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Last Name"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">Email</p>
                <v-text-field
                  disabled
                  v-model="form.email"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Email"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">Phone Number</p>
                <v-text-field
                  :disabled="form.isPhoneNumberUpdatedOneTime"
                  v-model="form.phone"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Phone Number"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <p class="ma-1">Facebook Link</p>
                <v-text-field
                  v-model="form.facebookLink"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Facebook Link"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <p class="ma-1">Telegram Link</p>
                <v-text-field
                  v-model="form.telegramLink"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Telegram Link"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <p class="ma-1">Twitter Link</p>
                <v-text-field
                  v-model="form.twitterLink"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Twitter Link"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <p class="ma-1">WhatsApp</p>
                <v-text-field
                  v-model="form.whatsApp"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="WhatsApp Link"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="12">
                <p class="ma-1">Current Password</p>
                <v-text-field
                  v-model="form.oldPassword"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Current Password"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="12">
                <p class="ma-1">New Password</p>
                <v-text-field
                  :rules="form.oldPassword ? newPasswordRules : []"
                  v-model="form.password"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="New Password"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <!-- <v-col cols="12" md="4" sm="12"> </v-col> -->

              <v-col cols="12" md="4" sm="12">
                <p class="ma-1">Confirm Password</p>
                <v-text-field
                  :rules="form.oldPassword ? [newPasswordRules, matchingPasswords] : []"
                  v-model="form.confirmPassword"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Confirm Password"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <v-btn
                  color="primary"
                  :loading="loading"
                  text="Update"
                  variant="flat"
                  class="text-none"
                  rounded="xl"
                  @click="submitUpdate()"
                  width="150"
                ></v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <!--form-->

  <template v-if="isDialog">
    <ErrorDialog :dialog="isDialog" :message="isMessage" :onCloseModal="onCloseModal" />
  </template>
</template>

<script>
import { mapState } from "vuex";
import { URL_TERM_PRIVACY } from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import {
  UPDATE_PROFILE,
  UPDATE_ACCOUNT_VERIFICATION,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_PATH,
} from "@/utils/customerApiUrl";
import axios from "axios";
import {
  validateDate,
  validatePassword,
  fieldRequired,
  validateImage,
} from "@/utils/function";
import ErrorDialog from "@/views/client/components/dialog/ErrorDialog.vue";
import { useFormData, useJsonForm } from '@/servicesAxios/axiosInstance';

let keyLocalStorage = "account_setting_index";
let identity = "https://thumb.ac-illust.com/55/55b4c3cab2ae5c35a359562b95b89c14_t.jpeg";

let allRules = {
  newPasswordRules: validatePassword,
  expireDateRules: validateDate("expire date"),
  fullNameRules: fieldRequired("full name"),
  genderRules: fieldRequired("gender"),
  cardTypeRules: fieldRequired("card type"),
  cardNumberRules: fieldRequired("card number"),
  referralId: fieldRequired("referral id is required"),
};

export default {
  name: "AccountSettings",
  components: { ErrorDialog },
  computed: {
    ...mapState(["profile", "socialMedia"]),
  },
  data: () => ({
    isDialog: false,
    index: 1,
    dialog: false,
    tab: "Appetizers",
    items: ["Info", "Identification", "Confirmation Info", "Bank Info"],
    privacyData: [],
    form: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      facebookLink: "",
      telegramLink: "",
      twitterLink: "",
      whatsApp: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
      fullName: "",
      sex: "",
      dob: "",
      phone: "",
      nationality: "",
      country: "",
      address: "",
      isPhoneNumberUpdatedOneTime: 0,
    },
    previewImageFront: identity,
    previewImageBack: identity,
    account_verification: {
      name: "",
      gender: "",
      typeCard: "",
      cardNumber: "",
      expireDate: "",
      frontCard: "",
      backCard: "",
      referral: "",
      referralLink: "",
      nameCard: "",
    },
    confirmation_info: {
      occupation: "",
      numberLevel: "",
      referral: "",
      verifyCode: "",
      referralLink: "",
      myReferral: "",
      countReferral: "",
      serviceAgreement: "",
      privacyPolicy: "",
      emailVerifyAt: "",
      confirmAt: "",
    },
    wallet: {
      availableBalance: "",
      accountBalance: "",
      bankAccount: "",
      walletType: "",
      pin_code: "",
    },
    deposit: {
      deposit_amount: "",
      deposit_amount_currcency: "",
      tax_fee: "",
      fee: "",
      amount_dollar: "",
      amount_received: "",
      purpose: "",
    },
    loading: false,
    message: "",
    success: "",
    //
    ...allRules,
    isMessage: "",

    selectedDay: null,
    selectedMonth: null,
    selectedYear: new Date().getFullYear(),
    months: [
      { name: "Jan", id: 1 },
      { name: "Feb", id: 2 },
      { name: "Mar", id: 3 },
      { name: "Apr", id: 4 },
      { name: "May", id: 5 },
      { name: "Jun", id: 6 },
      { name: "Jul", id: 7 },
      { name: "Aug", id: 8 },
      { name: "Sep", id: 9 },
      { name: "Oct", id: 10 },
      { name: "Nov", id: 11 },
      { name: "Dec", id: 12 },
    ],
    years: Array.from({ length: 20 }, (_, i) => new Date().getFullYear() + i),
    days: Array.from({ length: 31 }, (_, i) => i + 1),
    errorMessage: null,
  }),
  methods: {
    fieldRequired(fieldName) {
      return (v) => (v ? true : `${fieldName} is required`);
    },
    setLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getLocalStorage(key) {
      return localStorage.getItem(key);
    },
    nextStep(index) {
      this.index = index;
      this.setLocalStorage(keyLocalStorage, index);
    },
    showModal() {
      const value =
        this.profile.isPhoneNumberUpdatedOneTime !== 0
          ? this.profile.isPhoneNumberUpdatedOneTime
          : this.form.phoneNumber !== null
          ? 1
          : 0;

      this.form = {
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        email: this.profile.email,
        phone: this.profile.phone,
        facebookLink: this.socialMedia.facebookLink,
        telegramLink: this.socialMedia.telegramLink,
        twitterLink: this.socialMedia.twitterLink,
        whatsApp: this.socialMedia.whatsApp,
        oldPassword: "",
        password: "",
        confirmPassword: "",
        isPhoneNumberUpdatedOneTime: value,
      };

      this.dialog = true;
    },

    async submitUpdate() {
      const { valid } = await this.$refs.formProfile.validate();

      if (valid) {
        this.loading = true;
        this.axios
          .post(UPDATE_PROFILE, this.form)
          .then((response) => {
            console.error("Failed to", response);
            this.success = response.data.message;
            this.loading = false;
            setTimeout(() => {
              //this.dialog = false;
              this.$store.dispatch("getUserProfile");
              this.message = "";
              this.success = "";
              this.dialog = false;
            }, 4000);
          })
          .catch((error) => {
            this.message = error.response.data.message;
            this.loading = false;
            console.error("Failed to", error);
          });
      }
    },

    async frontImage(e) {
      const image = e.target.files[0];
      if (image) {
        let isValidateImage = validateImage(e);
        if (isValidateImage) {
          this.isMessage = isValidateImage;
          this.isDialog = true;
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onload = (e) => {
            this.previewImageFront = e.target.result;
            // console.log(this.previewImageFront);
          };
          let data = new FormData();
          data.append("file", e.target.files[0]);

          useFormData();
          this.axios
            .post(UPLOAD_PHOTO, data)
            .then((response) => {
              useJsonForm();
              this.account_verification.frontCard = response.data.filename;
              // console.log("Image has been uploaded.");
            })
            .catch((error) => {
              useJsonForm();
              this.isMessage = error.response.data.message;
              this.isDialog = true;
            });
        }
      }
    },

    async backImage(e) {
      const image = e.target.files[0];
      if (image) {
        let isValidateImage = validateImage(e);
        if (isValidateImage) {
          this.isMessage = isValidateImage;
          this.isDialog = true;
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onload = (e) => {
            this.previewImageBack = e.target.result;
            //console.log(this.previewImageBack);
          };

          let data = new FormData();
          data.append("file", e.target.files[0]);

          useFormData();
          this.axios
            .post(UPLOAD_PHOTO, data)
            .then((response) => {
              useJsonForm();
              this.account_verification.backCard = response.data.filename;
              //console.log("Image has been uploaded.");
            })
            .catch((error) => {
              useJsonForm();
              this.isMessage = error.response.data.message;
              this.isDialog = true;
            });
        }
      }
    },

    onCloseModal(dialog) {
      this.isDialog = dialog;
    },

    async submitNextUpdate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.nextStep(3);
      }
    },
    async submitSaveAccountVerification() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.selectedDay === null) {
          this.errorMessage = "Day is required.";
          setTimeout(() => {
            this.errorMessage = null;
          }, 5000);
          return;
        }
        if (this.selectedMonth === null) {
          this.errorMessage = "Month is required.";
          setTimeout(() => {
            this.errorMessage = null;
          }, 5000);
          return;
        }
        if (!this.account_verification.frontCard || !this.account_verification.backCard) {
          this.errorMessage = "Both front and back images are required.";
          this.loading = false;
          setTimeout(() => {
            this.errorMessage = null;
          }, 5000);
          return;
        }

        this.loading = true;
        this.account_verification.expireDate = `${this.selectedDay}-${this.selectedMonth}-${this.selectedYear}`;

        // Validate referral ID
        // if (!this.account_verification.referral) {
        //   this.message = "Referral ID is required.";
        //   this.loading = false;
        //   return;
        // }
        //console.log(this.form);
        let form = {
          ...this.account_verification,
          status: "review",
        };

        this.axios
          .post(UPDATE_ACCOUNT_VERIFICATION, form)
          .then((response) => {
            //console.error("response to", response);
            this.success = response.data.message;
            setTimeout(() => {
              this.$store.dispatch("getUserProfile");
              this.loading = false;
              this.errorMessage = "";
              this.message = "";
              this.success = "";
              this.nextStep(4);
            }, 4000);
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
            this.loading = false;
            console.error("Failed to", error.response.data.message);
          });
      }
    },

    matchingPasswords: function () {
      if (this.form.password === this.form.confirmPassword) {
        return true;
      } else {
        return "Passwords does not match.";
      }
    },

    async fetchPrivacyData() {
      try {
        const response = await axios.get(URL_TERM_PRIVACY, {
          headers: getTokenHeaders(),
        });
        this.privacyData = response.data[0];
      } catch (error) {
        console.error("Failed to fetch privacy data", error);
      }
    },

    routeToPage(page) {
      //this.$router.push({ name: page });
      console.log(page);
    },
  },
  created() {
    this.index = this.getLocalStorage(keyLocalStorage) || 1;

    setTimeout(() => {
      (this.selectedDay = this.profile.expireDate
        ? parseInt(this.profile.expireDate.split("-")[0])
        : null),
        (this.selectedMonth = this.profile.expireDate
          ? parseInt(this.profile.expireDate.split("-")[1])
          : null),
        (this.selectedYear = this.profile.expireDate
          ? parseInt(this.profile.expireDate.split("-")[2])
          : new Date().getFullYear()),
        this.fetchPrivacyData();
      this.account_verification = {
        name: this.profile.name,
        gender: this.profile.gender,
        typeCard: this.profile.typeCard,
        cardNumber: this.profile.cardNumber,
        expireDate: this.profile.expireDate,
        frontCard: this.profile.frontCard,
        backCard: this.profile.backCard,
        referral: this.profile.referral,
        referralLink: `${window.location.origin}/${this.profile.referral}`,
        //referralLink: this.profile.referralLink,
        nameCard: this.profile.nameCard,
      };
      this.previewImageFront = this.profile.frontCard
        ? UPLOAD_PHOTO_PATH + this.profile.frontCard
        : identity;
      this.previewImageBack = this.profile.backCard
        ? UPLOAD_PHOTO_PATH + this.profile.backCard
        : identity;
      this.form = {
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        fullName: this.profile.name,
        email: this.profile.email,
        sex: this.profile.gender,
        dob:
          this.profile.dateOfBirth !== null
            ? new Date(this.profile.dateOfBirth).toLocaleDateString()
            : "",
        phoneNumber: this.profile.phone,
        nationality: this.profile.nationality,
        country: this.profile.country,
        address: this.profile.address,
      };

      this.confirmation_info = {
        occupation: this.profile.occupation,
        numberLevel: this.profile.numberLevel,
        referral: this.profile.referral,
        verifyCode: this.profile.verifyCode,
        referralLink: this.profile.referralLink,
        myReferral: this.profile.myReferral,
        countReferral: this.profile.countReferral,
        serviceAgreement: this.profile.serviceAgreement,
        privacyPolicy: this.profile.privacyPolicy,
        emailVerifyAt:
          this.profile.emailVerifyAt !== null
            ? new Date(this.profile.emailVerifyAt).toLocaleDateString()
            : "",
        confirmAt:
          this.profile.confirmAt !== null
            ? new Date(this.profile.confirmAt).toLocaleDateString()
            : "",
      };
      if (this.profile.wallet && this.profile.wallet[0]) {
        this.wallet = {
          availableBalance: this.profile.wallet[0].availableBalance,
          accountBalance: this.profile.wallet[0].accountBalance,
          bankAccount: this.profile.wallet[0].bankAccount,
          walletType: this.profile.wallet[0].walletType,
          pin_code: this.profile.wallet[0].pin_code,
        };
      }
      if (this.profile.deposit && this.profile.deposit[0]) {
        this.deposit = {
          deposit_amount: this.profile.deposit[0].deposit_amount,
          deposit_amount_currcency: this.profile.deposit[0].deposit_amount_currcency,
          tax_fee: this.profile.deposit[0].tax_fee,
          fee: this.profile.deposit[0].fee,
          amount_dollar: this.profile.deposit[0].amount_dollar,
          amount_received: this.profile.deposit[0].amount_received,
          purpose: this.profile.deposit[0].purpose,
        };
      }
    }, 1500);
  },
};
</script>

<style scoped></style>
