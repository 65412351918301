<template>
  <v-tabs v-model="tab" color="blue" grow bg-color="white">
    <v-tab class="text-none" v-for="tabIndex in tabList" :text="tabIndex.title" v-bind:key="tabIndex.key" />
  </v-tabs>
  <br />
  <v-tabs-window v-model="tab">
    <v-tabs-window-item v-for="tabIndex in tabList" :value="tabIndex.title" v-bind:key="tabIndex.key">
      <component :is="tabIndex.component" v-if="tabIndex.key === tab" />
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script>
import MyReferralsTab from './referrals/MyReferralsTab';
import MyRewardsTab from './rewards/MyRewardsTab';

let referralsRewardsKey = 'referrals_rewards_tab';

export default {
  name: 'ReferralPage',
  components: { MyReferralsTab, MyRewardsTab },
  data() {
    return {
      tab: 1,
      currentComponent: MyRewardsTab.name,
      tabList: [
        { title: 'My Referrals', key: 0, component: MyReferralsTab.name },
        { title: 'My Rewards', key: 1, component: MyRewardsTab.name },
      ],
    };
  },
  created() {
    let defaultTab = localStorage.getItem(referralsRewardsKey);
    this.tab = parseInt(defaultTab) ?? 1;
  },
  watch: {
    tab(val) {
      localStorage.setItem(referralsRewardsKey, val);
    },
  },
};
</script>

<style scoped></style>
