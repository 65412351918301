<template>
  <div>
    <v-card rounded="0" class="px-10 pa-3">
      <v-row>

        <v-col cols="6" sm="6" md="2">
          <v-img class="mt-2" width="215px" height="40px" :src="company.logo" alt="" />
        </v-col>


        <v-col cols="12" sm="12" md="8" class="hidden"></v-col>
      </v-row>
    </v-card>

    <div class="margin-center">
      <br />
      <br />
      <h2 class="text-center">Welcome back to Staff section</h2>
      <v-alert
        border="top"
        type="error"
        class="mb-2"
        v-if="msgInvalidLogin !== null"
        variant="outlined"
        prominent
        style="background-color: white"
      >
        {{ msgInvalidLogin }}
      </v-alert>
      <br />

      <v-form ref="form" lazy-validation>
        <p class="mb-2"></p>

        <v-text-field
          v-model.trim="email"
          :rules="emailRules"
          label="Email"
          placeholder="Email"
          variant="outlined"
          prepend-inner-icon="mdi-email"
          required
          autocomplete="off"
          density="compact"
          persistent-placeholder
        ></v-text-field>

        <p class="mb-2"></p>

        <v-text-field
          v-model.trim="password"
          :rules="passwordRules"
          label="Password"
          placeholder="Password"
          variant="outlined"
          prepend-inner-icon="mdi-lock"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          @click:append-inner="visible = !visible"
          required
          autocomplete="off"
          density="compact"
          persistent-placeholder
        ></v-text-field>

        <br />

        <v-btn class="me-4" block color="primary" rounded="xl" @click="signIn">
          Sign In
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/store/staffAuth";
import axios from "axios";
import { getTokenHeaders } from "@/utils/headerToken";
import { URL_STAFF_VIEW_LOGIN } from "@/utils/apiUrl";
import { originalCode } from "@/utils/formatNumber";
import { mapState } from 'vuex';

const browserInfo = {
  appName: navigator.appName,
  appVersion: navigator.appVersion,
  userAgent: navigator.userAgent,
  platform: navigator.platform,
};

export default {
  name: "StaffLoginPage",
  computed: {
    ...mapState(['company']),
  },
  data: () => ({
    userInfo: null, // Initialize as null to better handle loading
    email: "",
    emailRules: [
      (v) => !!v || "Email is required",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Email must be valid",
    ],
    password: "",
    passwordRules: [(v) => !!v || "Password is required"],
    visible: false,
    msgInvalidLogin: null,
  }),
  created() {
    this.$store.dispatch("getCompanyInfo");
    this.getUserInfo();
    if (
      window.location.origin.includes("localhost") ||
      window.location.origin.includes("127.0.0.1")
    ) {
      this.email = "admin@gmail.com";
      this.password = "12345678";
    }
  },
  methods: {
    // Get user info (IP, location, browser)
    async getUserInfo() {
      try {
        const response = await axios.get("https://ipapi.co/json");
        this.userInfo = response.data;
      } catch (error) {
        console.error("Error fetching user info:", error);
        this.userInfo = {}; // Handle errors by initializing as an empty object
      }
    },

    // Handle the sign-in process
    async signIn() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;

      try {
        const authStore = useAuthStore();
        await authStore.login(this.email, this.password).then((success) => {
          if (success) {
            if (authStore.token) {
              localStorage.removeItem("msgInvalid");
              this.getUserLoginInfo(originalCode(authStore.id));
              this.$router.push({ path: "/dashboard" }); // Use Vue Router for redirection
            } else {
              // Handle invalid login response (e.g., incorrect credentials)
              this.msgInvalidLogin = localStorage.getItem("msgInvalid");
            }
          } else {
            this.msgInvalidLogin = localStorage.getItem("msgInvalid");
          }
        });
      } catch (error) {
        console.error("Login failed:", error);
      }
    },

    // Track user login information (location, IP, browser)
    async getUserLoginInfo(staffId) {
      const data = {
        staffId: parseInt(staffId),
        ip: this.userInfo.ip,
        isp: this.userInfo.org,
        browser: browserInfo.userAgent,
        longitude: this.userInfo.longitude?.toString() || "",
        latitude: this.userInfo.latitude?.toString() || "",
        city: this.userInfo.city || "",
        country: this.userInfo.country_name || "",
        countryCode: this.userInfo.country_code || "",
        countryRegion: this.userInfo.region_code || "",
      };

      try {
        const response = await axios.post(URL_STAFF_VIEW_LOGIN, data, {
          headers: getTokenHeaders(),
        });

        if (response.data.statusCode === 400) {
          console.log("Login info recorded successfully.");
        }
      } catch (error) {
        console.error("Error recording user login info:", error);
      }
    },
  },
};
</script>
