<script>
import { Path_Feedback_Image, URL_Feedback_Admin_BY_RANDOM } from '@/utils/apiUrl';
import {DEFAULT_IMAGE} from "@/utils/customerApiUrl";

export default {
  name: 'MemberFeedback',
  data() {
    return {
      DEFAULT_IMAGE,
      path: Path_Feedback_Image,
      members: [],
      animationDuration: 60000, // Duration of the animation 1 min
    };
  },
  methods: {
    // Shuffle function to randomize the array
    shuffleArray(array) {
      let shuffled = array.slice(); // Create a copy of the array
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
      }
      return shuffled;
    },
    async fetchData() {
      try {
        const response = await this.axios.get(`${URL_Feedback_Admin_BY_RANDOM}`, {
          params: { limit: 10 }, // You can adjust the limit if needed
        });
        //const shuffled = this.shuffleArray(response.data);
        //this.members = this.shuffleArray(shuffled); // Shuffle records if needed
        let data = response.data;
        if(response.data.length < 30) {
          for(let i = 1; i <= 5; i++) {
            data.push(...data);
          }
        }
        this.members = data;
      } catch (error) {
        console.error('Failed to fetch next set of records', error);
      }
    },

    //!!! don`t use loop request to backend make server crash
    startAnimation() {
      setInterval(() => {
        this.fetchData(); // Fetch new 10 records after each cycle
      }, this.animationDuration);
    },
  },
  created() {
    this.fetchData(); // Fetch initial 10 records
    //!!! don`t use loop request to backend make server crash
    //this.startAnimation(); // Start animation after first fetch
  },
};
</script>

<template>
  <div>
    <h2 class="text-primary text-center text-uppercase">Member Feedback</h2>
    <br />
    <p class="text-content text-center">
      Our members are happy and satisfied with our services.
    </p>
    <br />

    <div class="div">
      <div class="d-flex" style="width: 100vw" v-if="members.length > 0">
        <template v-for="row in members" v-bind:key="row.id">
          <v-card class="mx-2" min-width="400" rounded="xl" height="200">
            <v-card-text class="pa-5">
              <h4 class="font-weight-regular">
                <span v-html="row.desc" class="flex-ellipsis"></span>
              </h4>
            </v-card-text>
            <v-card-actions>
              <v-list-item class="">
                <VRow>
                  <VCol cols="4">
                    <v-avatar
                      class="elevation-1"
                      color="grey-lighten-3"
                      rounded="100"
                      size="90"
                    >
                      <v-avatar rounded="100" size="80">
                        <v-img :src=" row.photoUser ? path + row.photoUser : DEFAULT_IMAGE" cover></v-img>
                      </v-avatar>
                    </v-avatar>
                  </VCol>
                  <VCol cols="8">
                    <v-list-item-title
                      class="text-uppercase text-primary font-weight-bold"
                    >
                      {{ row.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-orange"
                    >@{{ row.nameUser }}
                    </v-list-item-subtitle
                    >
                    <br />
                    <v-list-item-media class="text-uppercase text-grey font-weight-bold">
                      {{ row.job }}
                    </v-list-item-media>
                  </VCol>
                </VRow>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </template>
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<style scoped>
.flex-ellipsis {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:350px;
}

.div {
  margin: 50px 0;
  width: 100%;
  height: 100px;
  white-space: nowrap;
  position: relative;
  animation: moveToRight 15s linear infinite;
}

.div:nth-child(1) {
  animation-delay: 0s;
}

.div:nth-child(2) {
  animation-delay: 2s;
}

.div:nth-child(3) {
  animation-delay: 4s;
}

@keyframes moveToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
