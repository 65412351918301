<script>
import { formatDate, getParams } from '@/utils/function';
import { CUSTOMER_NOTIFICATION, CUSTOMER_NOTIFICATION_CLEAR } from '@/utils/customerApiUrl';

export default {
  props: ['closeDrawer', 'notificationCount', 'drawer'],
  data() {
    return {
      formatDate: formatDate,
      notifications: [],
      params: {
        take: 10,
        skip: 1,
      },
      totalPages: 0,
      loading: false,
    };
  },
  methods: {
    async onClear(row) {
      let form = {
        id: row.id,
      };
      await this.axios
        .post(CUSTOMER_NOTIFICATION_CLEAR, form)
        .then((response) => {
          //console.log("response to", response.data);
          if (response) {
            this.notifications = this.notifications.filter(i => i.id !== row.id);
            // get data
            this.openDrawerData('get');
            // load profile
            this.$store.dispatch('getUserProfile');
          }
          //clear all notifications
          if (row.id < 0) {
            this.notifications = [];
          }
        })
        .catch((error) => {
          console.error('Failed to', error.response.data.message);
        });
    },

    async openDrawerData(type = '') {
      let params = getParams(this.params);
      await this.axios
        .get(CUSTOMER_NOTIFICATION + params)
        .then((response) => {
          let { data, totalPages } = response.data;
          this.totalPages = totalPages;
          // add more data
          if(type === 'scroll'){
            this.notifications = [...this.notifications, ...data];
          }
          //
          if(type === 'get') {
            this.notifications = [];
            this.notifications = data;
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error('Failed to', error);
        });
    },

    handleScroll(event) {
      const container = event.target;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
        if (this.params.skip <= this.totalPages) {
          this.loading = true;
          this.params.skip = this.params.skip + 1;
          // set delay
          setTimeout(() => {
            this.openDrawerData('scroll');
          }, 1000);

        }
      }
    },

  },
  watch: {
    drawer() {
      this.openDrawerData('get');
    },
  },
};
</script>

<template>
  <div class="">
    <div class="pa-4 parent">
      <div class="fixed">
        <div class="d-flex justify-lg-space-between">
          <VIcon @click="closeDrawer()"> mdi-arrow-left</VIcon>
          <h3>Notifications</h3>
        </div>

      </div>

      <div class="expanding" @scroll="handleScroll">

        <v-card
          class="mx-auto pa-3 my-4"
          v-for="(row, index) in notifications"
          v-bind:key="index"
          rounded="md"
          border
          flat
        >
          <v-card-title>
            <VIcon color="grey-lighten-1"> mdi-bullhorn</VIcon>
            <span class="text-h5 text-primary"> {{ row.title }} </span>
          </v-card-title>
          <v-card-text>
            <p class="three-line text-control">
              {{ row.desc }}
            </p>
            <br />
            <p>Time : <b>{{ formatDate(row.createdAt, 'DD-MM-YYYY HH:MM A') }}</b></p>
          </v-card-text>

          <v-card-title>
            <v-btn rounded="xl" density="compact" variant="outlined" @click="onClear(row)" color="blue">
              clear
            </v-btn>
          </v-card-title>
        </v-card>

        <v-btn :loading="loading" block elevation="0"></v-btn>

      </div>

      <div class="fixed-bottom">
        <v-btn v-if="notificationCount > 0" block class="text-none py-2" variant="tonal" @click="onClear({id: -1})">
          Clear All
        </v-btn>
      </div>

    </div>
  </div>
</template>

<style scoped>

.parent {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

.fixed {
  height: 100px;
}

.expanding {
  flex-grow: 1; /* Expands to fill remaining space */
  overflow-y: auto; /* Enables scrolling if content overflows */
}

.fixed-bottom {
  height: 100px;
}

</style>
