<template>
  <h4>Upgrade Account</h4>
  <v-divider />
  <br />
  <div class="text-center">
    <v-row>
      <v-col
        cols="12"
        md="3"
        sm="3"
        class="text-left"
        style="display: flex; justify-content: center; align-items: center; height: 120px"
      >
        <v-card elevation="0"> Wallet Type : {{ walletType }}</v-card>
      </v-col>
      <v-col cols="12" md="9" sm="9" class="text-center">
        <v-btn
          size="100"
          class="mb-0"
          stacked
          style="background-color: rgb(var(--v-theme-primary)) !important"
          @click="openDialog"
          :loading="loading"
        >
          <v-icon size="x-large" color="white">mdi-swap-horizontal</v-icon>
          <p style="font-size: 11px; color: white">{{ buttonText }}</p>
        </v-btn>
        <br /><br />
        <v-col cols="12">
          <v-container class="fill-height d-flex align-center justify-center">
            <v-col cols="12" md="5">
              <v-card class="pa-5 text-left" rounded="xl">
                <v-chip color="grey" variant="flat" size="small">Prompt</v-chip>
                <br />
                <p
                  style="font-size: smaller; opacity: 0.5"
                  class="mt-2"
                  v-html="descCrypto"
                ></p>
              </v-card>
            </v-col>
          </v-container>
        </v-col>
        <!-- <v-btn
          class="text-none ml-4"
          rounded="xl"
          color="primary"
          @click="openDialog"
          :loading="loading"
        >
          <v-icon size="x-large" color="white">mdi-swap-horizontal</v-icon>
          {{ buttonText }}
        </v-btn> -->

        <v-dialog v-model="showDialog" max-width="500px">
          <v-alert border="top" type="warning" color="primary" variant="flat" prominent>
            Would you like to update your account to business account?
          </v-alert>
          <v-card>
            <v-card-actions>
              <v-btn color="red darken-1" @click="showDialog = false"> Cancel </v-btn>
              <v-btn color="blue darken-1 text-success" @click="updateWalletType">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <div class="d-flex justify-start">
          <span class="ma-2">Show</span>

          <div>
            <v-select
              menu-icon=""
              v-model="perPage"
              density="comfortable"
              rounded="xl"
              variant="outlined"
              :items="['10', '25', '50', '100', '500', '1000']"
              class="elevation-0"
              color="blue"
              @update:model-value="perPage = parseInt($event, 10)"
            ></v-select>
          </div>

          <span class="ma-2">Entries</span>
        </div>
      </v-col>
      <v-col cols="12" md="10">
        <div class="d-flex justify-end">
          <span class="ma-2">Search</span>

          <div style="width: 30%">
            <v-text-field
              placeholder="search ..."
              append-inner-icon="mdi-magnify"
              width="100%"
              menu-icon=""
              v-model="params.search"
              density="comfortable"
              rounded="xl"
              variant="outlined"
              class="elevation-0"
              color="blue"
            ></v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-card rounded="0">
      <v-data-table
        density="comfortable"
        hide-default-footer
        :loading="loading"
        :items="transactionInfo"
        :headers="headers"
        :search="params.search"
        :items-per-page="perPage"
        :server-items-length="totalCount"
        loading-text="Loading transactions..."
      >
        <!-- No records available message -->
        <template v-slot:loading>
          <v-card elevation="0" rounded="0" class="pa-5"> Loading transactions...</v-card>
        </template>

        <template v-slot:empty>
          <v-card elevation="0" rounded="0" class="pa-5"> No record available.</v-card>
        </template>

        <!-- Custom Row Rendering -->
        <template v-slot:item="{ item }">
          <tr :class="getAvatarColor(item.ToCustomer.wallet[0].bankAccount)">
            <td>
              <div class="d-flex pa-4 text-left">
                <div class="px-4">
                  <h3>
                    {{
                      params.cusOwnCardNumber === item.ToCustomer.wallet[0].bankAccount
                        ? item.fromCustomer.wallet[0].bankAccount
                        : item.ToCustomer.wallet[0].bankAccount
                    }}
                  </h3>
                  <span>{{ formatDate(item.tranferDate, "YYYY-MM-DD HH:mm A") }}</span>
                </div>

                <v-avatar
                  size="30"
                  :class="getAvatarColor(item.ToCustomer.wallet[0].bankAccount)"
                >
                  <v-icon>{{
                    getArrowIcon(item.ToCustomer.wallet[0].bankAccount)
                  }}</v-icon>
                </v-avatar>
              </div>
            </td>
            <td>
              <h3 class="text-left">
                {{ titleStatus(item.ToCustomer.wallet[0].bankAccount) }}
              </h3>
            </td>
            <td>
              <h3 class="text-left">{{ formatPrice(item.amount) }} USD</h3>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <div class="app-paginate text-center mt-5">
      <v-btn
        variant="outlined"
        color="primary"
        rounded="xl"
        :disabled="page === 1"
        @click="goToPage(1)"
        class="no-uppercase"
      >
        First
      </v-btn>

      <v-btn
        variant="outlined"
        rounded="xl"
        color="primary"
        border
        :disabled="page === 1"
        @click="goToPage(page - 1)"
        class="no-uppercase"
      >
        Previous
      </v-btn>

      <v-btn
        variant="outlined"
        rounded="xl"
        color="primary"
        :disabled="page === pageCount"
        @click="goToPage(page + 1)"
        class="no-uppercase"
      >
        Next
      </v-btn>

      <v-btn
        variant="outlined"
        rounded="xl"
        color="primary"
        border
        :disabled="page === pageCount"
        @click="goToPage(pageCount)"
        class="no-uppercase"
      >
        Last
      </v-btn>
    </div>
  </div>
  <v-form ref="form" v-if="false">
    <v-row dense class="mx-10">
      <v-col cols="12" md="12" sm="12">
        <v-alert
          variant="outlined"
          color="error"
          v-if="message"
          :text="message"
          title="Error"
          border="end"
          density="compact"
          border-color="error"
          type="error"
        ></v-alert>

        <v-alert
          variant="outlined"
          color="success"
          type="success"
          v-if="success"
          :text="success"
          title="Success"
          border="end"
          density="compact"
          border-color="success"
        ></v-alert>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">National / Passport / Licence Number *</p>
        <v-text-field
          v-model="form.identity"
          density="compact"
          variant="outlined"
          required
          placeholder="xxx xxx xxx"
          rounded="xl"
          append-inner-icon="mdi-card-account-details-outline"
          :rules="identityRules"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Type Card *</p>
        <v-text-field
          v-model="form.typeCard"
          density="compact"
          variant="outlined"
          required
          placeholder="Enter expiry date"
          rounded="xl"
          append-inner-icon="mdi-card-account-details-outline"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Expiry Date *</p>
        <v-text-field
          v-model="form.expireDate"
          density="compact"
          variant="outlined"
          required
          placeholder="Enter expiry date"
          rounded="xl"
          append-inner-icon="mdi-clock-time-eight-outline"
          :rules="expireDateRules"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Full Name *</p>
        <v-text-field
          v-model="form.name"
          density="compact"
          variant="outlined"
          required
          placeholder="Enter Full Name"
          rounded="xl"
          :rules="fullNameRules"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Gender</p>
        <v-select
          v-model="form.gender"
          density="compact"
          variant="outlined"
          rounded="xl"
          :items="gender"
          menu-icon="mdi-chevron-right"
          :rules="genderRules"
          disabled
        ></v-select>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Nationality *</p>
        <v-autocomplete
          v-model="form.nationality"
          density="compact"
          variant="outlined"
          rounded="xl"
          :items="nationality"
          menu-icon="mdi-chevron-right"
          :rules="nationalityRules"
          disabled
          item-value="nationality"
          item-title="nationality"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="item.nationality"></v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Country of Birth *</p>
        <v-autocomplete
          v-model="form.countryOfBirth"
          density="compact"
          variant="outlined"
          rounded="xl"
          :items="nationality"
          menu-icon="mdi-chevron-right"
          :rules="countryOfBirthRules"
          disabled
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">City of Birth *</p>
        <v-text-field
          v-model="form.cityOfBirth"
          density="compact"
          variant="outlined"
          required
          placeholder="Enter your city of birth"
          rounded="xl"
          :rules="cityOfBirthRules"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Date of Birth *</p>
        <v-text-field
          v-model="form.dateOfBirth"
          density="compact"
          variant="outlined"
          required
          placeholder="Enter date of Birth"
          rounded="xl"
          :rules="dateOfBirthRules"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Occupation *</p>
        <v-autocomplete
          v-model="form.occupation"
          density="compact"
          variant="outlined"
          rounded="xl"
          :items="job"
          menu-icon="mdi-chevron-right"
          :rules="occupationRules"
          disabled
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="12" sm="12" class="text-end" style="display: none">
        <v-btn
          color="primary"
          text="Next"
          variant="flat"
          class="text-none"
          rounded="xl"
          @click="submitUpdate"
          width="150"
          :loading="loading"
        ></v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { UPDATE_ACCOUNT, WALLET_TYPE } from "@/utils/customerApiUrl";
import { mapState } from "vuex";
import {
  fieldRequired,
  formatDate,
  genderOption,
  jobOption,
  nationalityOption,
  validateDate,
  formatPrice,
} from "@/utils/function";
import axios from "axios";
import { URL_FEE, URL_FETCH_TRANSFER_INFO_WITH_PAGINATION } from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import moment from "moment/moment";

let allValid = {
  identityRules: fieldRequired("identity"),
  expireDateRules: validateDate("expire date"),
  fullNameRules: fieldRequired("full name"),
  nationalityRules: fieldRequired("nationality"),
  countryOfBirthRules: fieldRequired("country of birth"),
  cityOfBirthRules: fieldRequired("date of birth"),
  dateOfBirthRules: validateDate("date of birth"),
  occupationRules: fieldRequired("occupation"),
};

export default {
  name: "TabUpgrade",
  computed: {
    ...mapState(["profile", "wallet"]),
  },
  data: () => ({
    job: jobOption(),
    nationality: nationalityOption(),
    gender: genderOption(),
    transactionInfo: [],
    formatPrice: formatPrice,
    formatDate: formatDate,
    form: {
      identity: "",
      expireDate: "",
      name: "",
      gender: "",
      nationality: "",
      countryOfBirth: "",
      cityOfBirth: "",
      dateOfBirth: "",
      occupation: "",
      typeCard: "",
    },
    params: {
      cusOwnCardNumber: null,
      startDate: "",
      endDate: "",
      search: "",
    },
    message: "",
    success: "",
    loading: false,
    showDialog: false,
    buttonText: null,
    descCrypto: null,
    walletType: null,
    // validate form
    ...allValid,

    //pagination
    page: 1,
    perPage: 10,
    pageCount: 0,
    totalCount: 0, // Total records count

    headers: [
      {
        align: "start",
        key: "trandferNo",
        sortable: false,
        title: "Transaction",
      },
      { key: "status", title: "Status" },
      { key: "amount", title: "Amount" },
    ],
  }),
  watch: {
    wallet: {
      handler(newWallet) {
        if (newWallet && newWallet.length > 0) {
          this.descriptionCrypto();
          this.walletType = newWallet[0].walletType;
          this.buttonText =
            newWallet[0].walletType === "business account" ? "Transfer" : "Update";
        }
      },
      immediate: true, // Run immediately on component mount if wallet already has data
      deep: true, // Watch for deep changes in the wallet array
    },
    "params.search": function (search) {
      this.params.search = search;
      this.fetchCustomerWithdrawalTransaction();
    },
    dateRange: function (dateRange) {
      if (dateRange && dateRange.length > 0) {
        let startDate = dateRange[0];
        let endDate = dateRange[dateRange.length - 1];
        this.params.startDate = moment(startDate).format("YYYY-MM-DD");
        this.params.endDate = moment(endDate).format("YYYY-MM-DD");
        this.fetchCustomerWithdrawalTransaction();
        //this.fetchData();
      }
    },
    page() {
      this.fetchCustomerWithdrawalTransaction();
    },
    perPage() {
      this.fetchCustomerWithdrawalTransaction();
    },
  },
  methods: {
    getAvatarColor(accountNumber) {
      return this.params.cusOwnCardNumber === accountNumber ? "text-green" : "text-error";
    },
    getArrowIcon(accountNumber) {
      return this.params.cusOwnCardNumber === accountNumber
        ? "mdi-arrow-bottom-left-thick"
        : "mdi-arrow-top-right-thick";
    },
    titleStatus(accountNumber) {
      return this.params.cusOwnCardNumber === accountNumber ? "Recieved" : "Transfered";
    },
    async submitUpdate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        console.log(this.form);
        this.axios
          .post(UPDATE_ACCOUNT, this.form)
          .then((response) => {
            console.error("response to", response);
            this.success = response.data.message;
            this.loading = false;
            setTimeout(() => {
              this.message = "";
              this.success = "";
              this.$store.dispatch("getUserProfile");
            }, 4000);
          })
          .catch((error) => {
            this.message = error.response.data.message;
            this.loading = false;
            console.error("Failed to", error);
          });
      }
    },
    async descriptionCrypto() {
      await axios
        .get(URL_FEE, { headers: getTokenHeaders() })
        .then((response) => {
          this.descCrypto = response.data[0]?.desc_crypto;
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.loading = false;
        });
    },
    async updateWalletType() {
      this.loading = true;
      await this.axios
        .patch(WALLET_TYPE + this.wallet[0].id)
        .then((response) => {
          this.success = response.data.message;
          this.showDialog = false;
          setTimeout(() => {
            this.message = "";
            this.success = "";
            this.$store.dispatch("wallet");
            this.loading = false;
            location.reload();
          }, 4000);
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.loading = false;
        });
    },
    openDialog() {
      if (this.walletType !== "business account") {
        this.showDialog = true;
      } else {
        this.showDialog = false;
        this.$router.push({ name: "TransferToAccountPage" });
      }
    },
    async fetchCustomerWithdrawalTransaction() {
      const params = {
        cusCardNumber: this.params.cusOwnCardNumber,
        page: this.page,
        perPage: this.perPage,
        startDate: this.params.startDate,
        endDate: this.params.endDate,
        textSearch: this.params.search ? this.params.search : null,
      };

      this.loading = true;
      try {
        await axios
          .get(URL_FETCH_TRANSFER_INFO_WITH_PAGINATION, {
            params,
            headers: getTokenHeaders(),
          })
          .then((response) => {
            if (response.data && response.data.transferInfoCollection) {
              this.transactionInfo = response.data.transferInfoCollection;
              this.totalCount = response.data.totalCount;
              this.pageCount = response.data.pageCount;
              this.items.push(this.transactionInfo);
            } else {
              this.loading = false;
              console.warn("Empty API Response");
              this.transactionInfo = [];
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    goToPage(newPage) {
      if (newPage >= 1 && newPage <= this.pageCount) {
        this.page = newPage;
        this.fetchCustomerWithdrawalTransaction();
      }
    },
  },
  created() {
    setTimeout(() => {
      this.params = {
        cusOwnCardNumber: this.wallet[0]?.bankAccount,
      };
      this.fetchCustomerWithdrawalTransaction();
    }, 300);
  },
};
</script>

<style scoped>
.share-option {
  background-color: #4caf50;
  color: white;
}

button {
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.no-uppercase {
  text-transform: none !important;
}
</style>
