<template>
  <ListReferrals />
</template>

<script>
import ListReferrals from "./ListReferrals.vue";
import { mapState } from "vuex";

export default {
  computed: mapState(["profile", "socialMedia"]),
  name: "MyReferralsTab",
  components: {
    ListReferrals,
  },
};
</script>

<style scoped>

</style>
