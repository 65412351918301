<template>
  <div class="margin-auto" style="display: none">
    <v-card class="card-padding" rounded="xl" elevation="0">
      <!--        <pre>-->
      <!--          {{form}}-->
      <!--        </pre>-->

      <v-row>
        <v-col cols="12" md="3">
          <p class="mb-3 text-h5">Available Balance</p>

          <v-card height="50" border variant="outlined" color="primary" rounded="xl">
            <div class="h1 pt-2 px-4">
              <h2>{{ getAvailableBalance(wallet) }} USD</h2>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="9"></v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5">Select Payment Method</p>

          <v-sheet rounded="xl" class="d-flex" color="" height="50" width="100%">
            <v-sheet
              border
              class="d-flex align-center justify-center rounded-ts-xl rounded-bs-xl"
              color="white"
              width="50"
            >
              <v-icon class="">mdi-cash-multiple</v-icon>
            </v-sheet>

            <v-sheet border width="100%" class="rounded-te-xl rounded-be-xl">
              <v-autocomplete
                density="comfortable"
                v-model="paymentMethod"
                :items="paymentMethodItems"
                :item-title="'name'"
                :item-value="'id'"
                variant="plain"
                rounded="xl"
                suffix="USD"
                menu-icon="mdi-chevron-right"
                return-object
                class="ml-2"
                @update:modelValue="fetchBankNameByPaymentMethod(paymentMethod.name)"
              >
              </v-autocomplete>
            </v-sheet>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="9"></v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5">Bank</p>

          <v-sheet rounded="xl" class="d-flex" color="" height="50" width="100%">
            <v-sheet
              border
              class="d-flex align-center justify-center rounded-ts-xl rounded-bs-xl"
              color="white"
              width="50"
            >
              <v-icon class="">mdi-bank</v-icon>
            </v-sheet>

            <v-sheet border width="100%" class="rounded-te-xl rounded-be-xl">
              <v-autocomplete
                density="comfortable"
                v-model="bankName"
                :items="bankNameItems"
                :item-title="'name'"
                :item-value="'id'"
                variant="plain"
                rounded="xl"
                suffix="USD"
                menu-icon="mdi-chevron-right"
                return-object
                class="ml-2"
                @update:modelValue="fetchBankAccountInfoByBank(bankName)"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props">
                    <template v-slot:prepend>
                      <v-avatar size="24">
                        <v-img :src="item.raw.logoBank" alt="Bank Logo"></v-img>
                      </v-avatar>
                    </template>
                  </v-list-item>
                </template>

                <template v-slot:selection="{ item }">
                  <v-avatar size="24">
                    <v-img :src="item.raw.logoBank" alt="Bank Logo"></v-img>
                  </v-avatar>
                  <span class="ml-2">{{ item.raw.name }}</span>
                </template>
              </v-autocomplete>
            </v-sheet>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="9"></v-col>

        <v-col cols="12" md="3" v-if="isBankAccount">
          <p class="mb-3 text-h5">Bank Account</p>
          <v-text-field
            readonly
            v-model.number="form.account_number"
            rounded="xl"
            placeholder="Enter Bank Account"
            variant="outlined"
            density="comfortable"
            hide-details
            color="primary"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5">Currency</p>
          <v-btn
            height="50"
            rounded="xl"
            width="45%"
            class="mx-1"
            variant="tonal"
            color=""
          >
            USD
          </v-btn>
          <!--            <v-btn height="50" rounded="xl" width="45%" class="mx-1" variant="outlined" color="" disabled>-->
          <!--              SGD-->
          <!--            </v-btn>-->
        </v-col>

        <v-col cols="12" md="6" v-if="isColsBlank"></v-col>

        <v-col cols="12" md="3" v-if="isBankName">
          <p class="mb-3 text-h5">Name</p>
          <v-text-field
            readonly
            v-model.number="form.name"
            rounded="xl"
            placeholder="Enter Name"
            variant="outlined"
            density="comfortable"
            hide-details
            color="primary"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5">Withdraw Amount</p>
          <v-text-field
            type="number"
            v-model.number="form.withdraw_amount"
            rounded="xl"
            placeholder="Enter Withdraw Amount"
            variant="outlined"
            density="comfortable"
            hide-details
            color="primary"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6"></v-col>

        <v-col cols="12" md="3" style="margin-top: -20px">
          <v-card class="ma-2" width="200" v-if="isCryptoCurrency">
            <v-avatar rounded="0" size="200">
              <v-img :src="usdtQr" cover></v-img>
            </v-avatar>
          </v-card>
          <div class="ma-2" style="font-size: smaller; opacity: 0.5">
            <a :href="usdtLink" target="_blank">{{ usdtLink }}</a>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="margin-top: -25px">
          <p style="font-size: smaller; opacity: 0.5" class="mt-2">
            Exchange Rate: 1 USD = {{ exchange_rate ?? 0 }}
            {{ form.currency }}
          </p>
          <v-chip size="small" class="ma-2 bg-error" color="white">
            Total Tax:
            {{ totalTax ?? "0" }} %
          </v-chip>
          <br />
          <v-chip size="small" class="ma-2 bg-primary" color="white">
            Debit Amount:
            {{ debitAmount ?? "0" }} USD
          </v-chip>
          <br />

          <v-chip size="small" color="blue" class="mt-2">
            Withdrawal Amount: {{ fee_amount_withdraw }} USD
          </v-chip>
        </v-col>

        <v-col cols="12" md="6"></v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5">Purpose</p>
          <v-text-field
            v-model="form.purpose"
            rounded="xl"
            placeholder="For Withdraw"
            variant="outlined"
            density="comfortable"
            hide-details
            color="primary"
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5">Amount to received</p>

          <v-card height="50" border variant="outlined" color="primary" rounded="xl">
            <div class="h1 pt-2 px-4">
              <h2>{{ form.withdraw_received }} {{ form.currency }}</h2>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <br />

      <v-btn
        class="text-capitalize"
        color="blue-darken-3"
        rounded="xl"
        size="large"
        variant="tonal"
        width="140"
        @click="submitCustomerWithdraw()"
        :loading="loading"
      >
        Withdraw
      </v-btn>

      <br /><br />

      <v-col cols="12" md="5">
        <v-card class="pa-5" rounded="xl">
          <v-chip color="grey" variant="flat" size="small">Prompt</v-chip>
          <br />
          <p class="mt-2" v-html="description"></p>
        </v-card>
      </v-col>
    </v-card>
  </div>

  <template v-if="isErrorDialog">
    <ErrorDialog
      :dialog="isErrorDialog"
      :message="isErrorMessage"
      :onCloseModal="onCloseModalError"
    />
  </template>

  <template v-if="isSuccessDialog">
    <SuccessModel
      :dialog="isSuccessDialog"
      :message="isSuccessMessage"
      :onCloseModal="onCloseModalSuccess"
    />
  </template>
</template>

<script>
import { mapState } from "vuex";
import { BANK_ACCOUNT_WITHDRAW, CUSTOMER_WITHDRAW } from "@/utils/customerApiUrl";
import ErrorDialog from "@/views/client/components/dialog/ErrorDialog.vue";
import SuccessModel from "@/views/client/components/dialog/SuccessModel.vue";
import { getAvailableBalance } from "@/utils/function";
import axios from "axios";
import { getTokenHeaders } from "@/utils/headerToken";
import { URL_WITHDRAW_Crypto_Currency_Name } from "@/utils/apiUrl";

export default {
  name: "WithdrawTab",
  components: { SuccessModel, ErrorDialog },
  computed: {
    ...mapState(["wallet", "profile", "exchangeWithdraw", "fee"]),
  },
  data() {
    return {
      isCryptoCurrency: false,
      blockWithdraw: false,
      isBankAccount: true,
      isBankName: true,
      isColsBlank: true,

      paymentMethodItems: [
        { id: 0, name: "Online Bank Transfer" },
        { id: 1, name: "Cryptocurrency" },
      ],
      bankNameItems: [],
      getAvailableBalance: getAvailableBalance,
      paymentMethod: null,
      bankName: null,
      countryCode: null,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
      fee_amount_withdraw: 0,
      debitAmount: 0,
      exchange_rate: 0,
      withdraw_amount_currency: 0,

      tax_fee: 0,
      withdraw_fee: 0,
      totalTax: 0,

      usdtQr: null,
      usdtLink: null,

      form: {
        bankAccountWithdrawId: null,
        exchangeWithdrawId: null,
        withdraw_amount: 0,
        tax: 0,
        fee: 0,
        withdraw_exchange: 0,
        withdraw_received: 0,
        name: null,
        account_number: null,
        purpose: "withdraw",
        status: "pending",
      },

      loading: false,
      // modal message
      isErrorDialog: false,
      isErrorMessage: "",
      // modal success
      isSuccessDialog: false,
      isSuccessMessage: "",
    };
  },
  methods: {
    onCloseModalError(dialog) {
      this.isErrorDialog = dialog;
      this.form.withdraw_amount = "";
      this.debitAmount = 0;
      this.fee_amount_withdraw = this.fee.amount_withdraw ?? 0;
      this.form.withdraw_received = 0;
    },

    onCloseModalSuccess(dialog) {
      this.isSuccessDialog = dialog;
    },

    clearContent() {
      this.paymentMethod = null;
      this.bankName = null;
      this.bankNameItems = [];
      this.form.account_number = null;
      this.form.name = null;
      this.usdtLink = null;
      this.form.withdraw_amount = 0; // Reset withdraw amount
      this.debitAmount = 0;
      this.form.purpose = "withdraw";
      this.form.withdraw_received = 0; // Reset withdraw received
      this.fee_amount_withdraw = this.fee.fee_amount_withdraw ?? 0; // Reset fee_amount_withdraw to 0
      this.walletAvailableBallanceReturned = null;
      this.isCryptoCurrency = false;
      this.usdtLink = null;
      this.usdtQr = null;

      this.exchange_rate = this.exchangeWithdraw.rate ?? 0;
      this.form.currency = this.exchangeWithdraw.Country?.currency;
      this.description = this.exchangeWithdraw?.desc;
    },

    async fetchBankNameByPaymentMethod(paymentMethod) {
      this.bankNameItems = [];
      this.bankName = null;
      this.form.name = null;
      this.form.account_number = null;
      this.isCryptoCurrency = false;
      this.isBankAccount = true;
      this.isBankName = true;
      this.isColsBlank = true;
      this.usdtLink = null;
      this.debitAmount = 0;
      this.form.withdraw_amount = 0;

      if (!paymentMethod) return;

      if (paymentMethod === "Cryptocurrency") {
        this.isBankAccount = false;
        this.isBankName = false;
        this.isColsBlank = false;
      }

      try {
        const urlBank = `${BANK_ACCOUNT_WITHDRAW}/get-bank-name-by-payment-method?paymentMethod=${paymentMethod}&countryCode=${this.countryName}`;
        const response = await axios.get(urlBank, {
          headers: getTokenHeaders(),
        });

        if (response.data && response.data.data) {
          this.bankNameItems = response.data.data.map((item) => ({
            id: item.bankType.id,
            name: item.bankType.bankName,
            logoBank: item.bankType.logoBank,
            fullName: item.fullName,
            account_number: item.cardNumber,
            bankAccountWithdrawId: item.id,
            usdtQr: item.usdtQr,
            usdtLink: item.usdtLink,
          }));
        }
      } catch (error) {
        console.error("Error fetching bank names:", error);
      }
    },

    async fetchBankAccountInfoByBank(component) {
      this.form.account_number = null;
      this.form.name = null;
      this.debitAmount = 0;
      this.form.withdraw_amount = 0;

      if (component) {
        this.form.name = component.fullName;
        this.form.account_number = component.account_number;
        this.exchange_rate = this.exchangeWithdraw.rate;
        this.form.currency = this.exchangeWithdraw.Country?.currency;
        this.description = this.exchangeWithdraw?.desc;
      }

      if (this.paymentMethod.name === "Cryptocurrency") {
        this.isCryptoCurrency = true;
        this.getCryptoCurrencyRateByCryptoCurrencyName(component.name);
        this.usdtQr =
          this.bankNameItems[0].usdtQr ??
          "https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg";
        this.usdtLink = this.bankNameItems[0].usdtLink ?? "";
      }
    },

    async submitCustomerWithdraw() {
      if (this.profile.status.toLowerCase() !== "done") {
        this.isErrorMessage =
          "Your account is not complete document. Please submit your document.";
        this.isErrorDialog = true;
        return;
      }

      if (!this.bankName || !this.bankName.bankAccountWithdrawId) {
        this.isErrorMessage = "Please select a valid bank.";
        this.isErrorDialog = true;
        return;
      }

      if (!this.bankName || !this.bankName.bankAccountWithdrawId) {
        this.isErrorMessage = "Please select a valid bank.";
        this.isErrorDialog = true;
        return;
      }

      if (!this.form.withdraw_amount) {
        this.isErrorMessage = "Please input withdraw amount.";
        this.isErrorDialog = true;
        return;
      }

      if (this.blockWithdraw) {
        this.form.withdraw_amount = "";
        this.isErrorMessage = "Please contact Customer Service.";
        this.isErrorDialog = true;
        return;
      }

      const amount = parseFloat(this.withdraw_amount ?? 0); // Ensure it's a number
      const availableBallance = getAvailableBalance(this.wallet);

      if (amount < this.fee_amount_withdraw) {
        this.isErrorMessage =
          "Withdrawal amount cannot smaller than your amount withdraw. Please check it again";
        this.isErrorDialog = true;
        return;
      }

      if (amount > availableBallance) {
        this.isErrorMessage =
          "Withdraw amount is bigger than your available ballance. Please check it again.";
        this.isErrorDialog = true;
        return;
      }

      this.form.withdraw_received = this.debitAmount * this.exchange_rate;
      this.form.bankAccountWithdrawId = this.bankName.bankAccountWithdrawId;
      this.form.exchangeWithdrawId = this.exchangeWithdraw.id;
      this.form.withdraw_exchange = this.form.withdraw_received;

      this.loading = true;

      let form = {
        ...this.form,
      };

      await this.axios
        .post(CUSTOMER_WITHDRAW, form)
        .then((response) => {
          this.loading = false;
          this.isSuccessMessage = response.data.message;
          this.isSuccessDialog = true;
          this.$store.dispatch("getUserProfile");
          this.clearContent();
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.isErrorMessage = message;
          if (Array.isArray(message)) {
            this.isErrorMessage = message[0];
          }
          this.isErrorDialog = true;
          this.loading = false;
          //console.error("Failed to", error.response.data.message);
        });
    },

    async getCryptoCurrencyRateByCryptoCurrencyName(currencyName) {
      if (!currencyName) return;

      const response = await this.axios.get(
        `${URL_WITHDRAW_Crypto_Currency_Name}?currencyName=${currencyName}`,
        { headers: getTokenHeaders() }
      );

      if (response.data !== null) {
        this.exchange_rate = response.data[0]?.rate;
        this.form.currency = response.data[0]?.name_currency;
        this.description = response.data[0]?.desc;
      }
    },

    // async investNow() {
    //   if (this.profile.status.toLowerCase() !== "done") {
    //     this.isErrorMessage =
    //       "Your account is not complete document. Please submit your document.";
    //     this.isErrorDialog = true;
    //     return;
    //   }

    //   this.loading = true;
    //   let form = {
    //     ...this.form,
    //   };
    //   await this.axios
    //     .post(CUSTOMER_DEPOSIT, form)
    //     .then((response) => {
    //       //console.log("response to", response.data);
    //       setTimeout(() => {
    //         this.loading = false;

    //         this.form = {
    //           withdraw_amount: 0,
    //           withdraw_amount_currency: 0,
    //           tax_fee: 0,
    //           fee: 0,
    //           feePercent: 2.5,
    //           amount_dollar: 0,
    //           withdraw_received: 0,
    //           purpose: "",
    //           status: "pending",
    //           bank_account_deposit_id: 1,
    //         };
    //         this.paymentMethod = "";

    //         this.isSuccessMessage = response.data.message;
    //         this.isSuccessDialog = true;
    //       }, 2000);

    //       setTimeout(() => {
    //         this.isSuccessDialog = false;
    //       }, 5000);

    //       this.$store.dispatch("getUserProfile");
    //     })
    //     .catch((error) => {
    //       let message = error.response.data.message;
    //       this.isErrorMessage = message;
    //       if (Array.isArray(message)) {
    //         this.isErrorMessage = message[0];
    //       }
    //       this.isErrorDialog = true;
    //       this.loading = false;
    //       //console.error("Failed to", error.response.data.message);
    //     });
    // },
  },
  created() {
    setTimeout(() => {
      if (this.profile) {
        //this.form.name = this.profile.name;
        this.countryName = this.profile.country;
        // Check if blockWithdraw exists and has at least one entry
        this.blockWithdraw = this.profile.blockWithdraw?.[0]?.block_withdraw === 1;
      }

      if (this.fee) {
        this.fee_amount_withdraw = this.fee.amount_withdraw ?? 0;
        this.tax_fee = this.fee.tax_fee;
        this.withdraw_fee = this.fee.withdraw_fee;
        this.totalTax = this.fee.tax_fee + this.fee.withdraw_fee;
      }
    }, 500);
  },
  watch: {
    "form.withdraw_amount"(amount) {
      // Ensure amount is not empty
      if (amount) {
        this.withdraw_amount = parseFloat(amount); // Ensure it's a number
        const totalTax = this.totalTax / 100;
        this.debitAmount = this.withdraw_amount - amount * totalTax;

        // Customize handling based on fee.fee_amount_withdraw
        if (this.fee.fee_amount_withdraw === 0) {
          // If no withdrawal limit is set
          this.form.withdraw_received = this.debitAmount * this.exchange_rate; // Keep as string
        } else {
          // Otherwise calculate based on available withdrawal balance
          this.form.withdraw_received = this.debitAmount * this.exchange_rate; // Keep as string
        }
      } else {
        this.form.withdraw_received = 0;
      }
    },
    exchangeWithdraw: {
      handler(newExchangeWithdraw) {
        if (newExchangeWithdraw) {
          this.exchange_rate = newExchangeWithdraw.rate;
          this.form.currency = newExchangeWithdraw.Country?.currency;
          this.description = newExchangeWithdraw.desc;
          this.form.exchangeWithdrawId = newExchangeWithdraw.id;
        }
      },
      immediate: true, // Ensures the handler runs when the component is mounted
    },
  },
};
</script>

<style scoped></style>
