<template>
  <FrontendLayout>
    <CardProfile />

    <div class="bg-grey-lighten-5 py-1" style="padding: 10px">
      <div class="text-centers my-6">
        <ButtonTab :active="tab" :set_tab="setTab" />

        <br />
        <br />

        <!--Portfolios-->
        <template v-if="tab === 'Portfolios'">
          <PortfoliosTab />
        </template>
        <!--Portfolios-->

        <!--Portfolios-->
        <template v-if="tab === 'Deposit'">
          <DepositTab />
        </template>
        <!--Portfolios-->

        <!--Upgrade-->
        <template v-if="tab === 'Upgrade' || tab === 'Transfer'">
          <TabUpgrade />
        </template>
        <!--Upgrade-->

        <!--Upgrade-->
        <template v-if="tab === 'Exchange'">
          <ExchangeTab />
        </template>
        <!--Upgrade-->

        <!--Upgrade-->
        <template v-if="tab === 'Referral'">
          <ReferralPage />
        </template>
        <!--Upgrade-->

        <!--TransactionPage-->
        <template v-if="tab === 'Statement'">
          <TransactionPage />
        </template>
        <!--TransactionPage-->

        <!--WithdrawTab-->
        <template v-if="tab === 'Withdraw'">
          <WithdrawTab />
        </template>
        <!--WithdrawTab-->

        <!--Setting-->
        <template v-if="tab === 'Settings'">

          <br />

          <v-row>
            <v-col cols="12"  md="3">
              <template v-for="(row, index) in nav" v-bind:key="index">
                <v-btn
                  border
                  size="x-large"
                  rounded="xl"
                  class="text-none mx-2"
                  width="80%"
                  elevation="0"
                  :color="btnNav === row.title ? 'primary' : 'grey-lighten-2'"
                  v-on:click="setBtnNav(row.title)"
                >
                  <p class="text-center">
                    <v-icon>{{ row.icon }}</v-icon>
                    {{ row.title }}
                  </p>
                </v-btn>
                <br />
                <br />
              </template>
            </v-col>

            <v-col cols="12" md="9">
              <template v-if="btnNav === 'Profile Info'">
                <ProfileInfo />
              </template>

              <template v-if="btnNav === 'Payment Info'">
                <PaymentInfo />
              </template>

              <template v-if="btnNav === 'My Wallets'">
                <MyWallets />
              </template>

              <template v-if="btnNav === 'Account Settings'">
                <AccountSettings />
              </template>
            </v-col>
          </v-row>
        </template>
        <!--Setting-->
      </div>
    </div>
  </FrontendLayout>
</template>

<script>
import FrontendLayout from "../components/FrontendLayout";
import ButtonTab from "./ButtonTab";
import ProfileInfo from "./ProfileInfo";
import PaymentInfo from "./PaymentInfo";
import MyWallets from "./MyWallets";
import AccountSettings from "./AccountSettings";
import CardProfile from "./CardProfile";
import TabUpgrade from "./TabUpgrade";
import { mapState } from "vuex";
import PortfoliosTab from "./portfolios/PortfoliosTab";
import DepositTab from "./deposit/DepositTab";
import ExchangeTab from "./exchange/ExchangeTab";
import ReferralPage from "./referral-rewards/ReferralPage";
import TransactionPage from "@/views/client/profile/transactions/TransactionPage.vue";
import WithdrawTab from "@/views/client/profile/withdraw/WithdrawTab.vue";

let nav = [
  {
    title: "Profile Info",
    icon: "mdi-account",
  },
  {
    title: "Payment Info",
    icon: "mdi-credit-card-settings-outline",
  },
  {
    title: "My Wallets",
    icon: "mdi-wallet",
  },
  {
    title: "Account Settings",
    icon: "mdi-cog",
  },
];

export default {
  name: "ProfilePage",
  components: {
    WithdrawTab,
    TransactionPage,
    ReferralPage,
    DepositTab,
    TabUpgrade,
    CardProfile,
    AccountSettings,
    MyWallets,
    PaymentInfo,
    ProfileInfo,
    ButtonTab,
    FrontendLayout,
    PortfoliosTab,
    ExchangeTab,
  },
  computed: {
    ...mapState(["login", "profile"]),
  },
  data: () => ({
    btnNav: nav[0].title,
    nav: nav,
    tab: "Portfolios",
  }),
  methods: {
    setBtnNav(type) {
      this.btnNav = type;
      this.$router.push({
        path: "profile",
        query: {
          ...this.$route.query,
          nav: type,
        },
      });
    },
    setTab(type) {
      this.tab = type;
    },
  },
  created() {
    this.tab = this.$route.query.tab || "Portfolios";
    this.btnNav = this.$route.query.nav || nav[0].title;
  },
};
</script>

<style scoped></style>
