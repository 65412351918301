<template>
  <v-dialog v-model="dialogDisable" max-width="500px" activator="parent">
    <v-card rounded="lg">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 text-medium-emphasis ps-2">Dialog Reject Status</div>

        <v-btn icon="mdi-close" variant="text" @click="closeDialog"></v-btn>
      </v-card-title>

      <v-divider class="mb-4"></v-divider>

      <v-card-text>
        <div class="text-medium-emphasis mb-4">
          Are you sure you want to change status pending to reject?
        </div>
      </v-card-text>

      <v-divider class="mt-2"></v-divider>

      <v-card-actions class="my-2 d-flex justify-end">
        <v-btn class="text-none" rounded="xl" text="Cancel" @click="closeDialog"></v-btn>

        <v-btn
          class="text-none"
          color="error"
          rounded="xl"
          text="Disable"
          variant="flat"
          @click="reject"
          :loading="loading"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { URL_CHANGE_CUS_STATUS } from "@/utils/apiUrl";
import { getTokenHeaders } from "@/utils/headerToken";
import axios from "axios";

export default {
  props: {
    modelValue: Boolean, // v-model binding
    item: Array,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    dialogDisable: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.dialogDisable = false;
    },
    reject() {
      this.loading = true;
      try {
        this.item.forEach((item) => {
          axios
            .patch(
              `${URL_CHANGE_CUS_STATUS}${item.id}`,
              {},
              {
                headers: getTokenHeaders(),
              }
            )
            .then((response) => {
              console.log(response.data.message);
              this.loading = false;
            });
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }

      this.closeDialog();
      location.reload();
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
