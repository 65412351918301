import moment from 'moment';
import nationality from '../assets/nationality.json';
import job from '../assets/job.json';
import phone from '../assets/phone.json';

export const getLocalStorage = (key) => {
  let local = localStorage.getItem(key);
  if (local === 0) {
    return JSON.parse(local);
  }
  return JSON.parse(local) || [];
};

export const setLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const formatPrice = (price = 0) => {
  if (price) {
    return parseFloat(price).toFixed(2).toLocaleString();
  }
  return 0;
};

export const formatPriceWithComma = (price = 0) => {
  if (price) {
    return parseFloat(price).toLocaleString();
  }
  return 0;
};

export const formatDate = (date, format = 'DD-MM-YYYY') => {
  return date ? moment(date).format(format) : '';
};

export const validatePassword = [
  (v) =>
    (v &&
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        v,
      )) ||
    'Minimum 8 characters, One capital latter, Special character, Number',
  (v) => (v && v.length <= 50) || 'Password must be less than 50 characters',
];

export const validateDate = (name = 'Required') => {
  return [
    (v) => !!v || name + ' is required',
    (v) =>
      /^\d{2}-\d{2}-\d{4}$/.test(v) || 'DD-MM-YYYY Expression must be valid',
  ];
};

export const fieldRequired = (name = 'Required') => {
  return [(v) => !!v || name + ' is required'];
};

export const nationalityOption = () => {
  return nationality;
};

export const phoneOption = () => {
  return phone;
};

export const jobOption = () => {
  return job;
};

export const genderOption = () => {
  return ['Male', 'Female', 'Other'];
};

export const countryOption = () => {
  return [
    {
      id: '1',
      country: 'Cambodia',
    },
    {
      id: '2',
      country: 'Thailand',
    },
    {
      id: '3',
      country: 'Myanmar',
    },
    {
      id: '4',
      country: 'Lao',
    },
    {
      id: '5',
      country: 'Singapore',
    },
    {
      id: '6',
      country: 'Malaysia',
    },
    {
      id: '7',
      country: 'Indonesia',
    },
    {
      id: '8',
      country: 'Brunei',
    },
    {
      id: '9',
      country: 'Philippine',
    },
    {
      id: '10',
      country: 'Vietnam',
    },
  ];
};

export const monthOption = () => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
};

export const getParams = (params) => {
  let str = Object.keys(params)
    .map(function(key) {
      return key + '=' + params[key];
    })
    .join('&');
  return '?' + str;
};

export const formatPriceCurrency = (money = 0) => {
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  });
  return formatter.format(money);
};

export const validateImage = (e) => {
  const maxSize = 1024 * 1024;
  const image = e.target.files[0];
  const validImageTypes = ['image/jpeg', 'image/png', 'image/PNG', 'image/jpg'];
  if (!validImageTypes.includes(image.type)) {
    e.target.value = ''; // Clear the input
    return 'Please upload a valid image file (JPEG, PNG).';
  } else if (image.size > maxSize) {
    // Clear the input
    e.target.value = '';
    return 'File size must be less than 1MB.';
  }
  return '';
};

export const stringToBoolean = (str) => {
  if (typeof str !== 'string') return false;

  // Convert to lowercase to handle case insensitivity
  str = str.toLowerCase();

  // Define truthy and falsy values
  const truthyValues = ['true', '1', 'yes', 'y'];
  const falsyValues = ['false', '0', 'no', 'n'];

  if (truthyValues.includes(str)) return true;
  if (falsyValues.includes(str)) return false;

  // Optional: return undefined or throw an error for unexpected values
  return undefined;
};

// get available balance for client
export const getAvailableBalance = (wallet = []) => {
  let availableBalance = 0;
  wallet.forEach((row) => {
    availableBalance += row.availableBalance;
  });
  return formatPrice(availableBalance);
};

// get account balance for client
export const getAccountBalance = (wallet = []) => {
  let accountBalance = 0;
  wallet.forEach((row) => {
    accountBalance += row.accountBalance;
  });
  return formatPrice(accountBalance);
};

export const uuid = () => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
  );
};

export const getClientToken = async () => {
  return localStorage.getItem('client_token');
};

export const getDayOfMonth = () => {
  let day = [];
  for (let i = 1; i <= 31; i++) {
    day.push(i);
  }
  return day;
};

export const getYearOfCalender = () => {
  let currentYear = new Date().getFullYear() - 35;
  let year = [];
  for (let i = currentYear; i >= 1900; i--) {
    year.push(i);
  }
  return year;
};


export const formatCardNumberText = (cardNumber)  => {
  // 1. Remove all non-digit characters from the input. This is crucial.
  const cleanedCardNumber = cardNumber.replace(/\D/g, '');

  // 2. Enforce the maximum length (16 digits).
  const limitedCardNumber = cleanedCardNumber.slice(0, 16);

  // 3. Format the number with spaces every 4 digits.
  let formattedCardNumber = '';
  for (let i = 0; i < limitedCardNumber.length; i++) {
    formattedCardNumber += limitedCardNumber[i];
    if ((i + 1) % 4 === 0 && i !== limitedCardNumber.length - 1) {
      formattedCardNumber += ' ';
    }
  }

  return formattedCardNumber;
}