<template>
  <Suspense timeout="0">
    <template v-if="!show">
      <v-container class="d-flex align-center justify-center box-height">
        <v-col cols="auto">
          <v-btn density="comfortable" icon="mdi-open-in-new" :loading="true"></v-btn>
        </v-col>
      </v-container>
    </template>
    <template v-else>
      <router-view />
    </template>
  </Suspense>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState(['company']),
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    document.title = 'Welcome to APAC Investment Projects';

    setTimeout(() => {
      this.show = true;
    }, 1000); // 5s delay

  },
};
</script>

<style scoped>
.box-height {
  height: 100vh;
}
</style>
