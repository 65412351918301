<script>
import { mapState } from "vuex";
export default {
  name: "CardShareLink",
  computed: {
    ...mapState(["profile"]),
  },
  data() {
    return {
      links: [
        {
          icon: "mdi-facebook",
          link: "https://www.facebook.com/watch?v=q8Wmz1tyPaY",
        },
        {
          icon: "mdi-send-circle",
          link: "https://www.telegram.com/watch?v=q8Wmz1tyPaY",
        },
        {
          icon: "mdi-twitter",
          link: "https://www.twitter.com/watch?v=q8Wmz1tyPaY",
        },
        {
          icon: "mdi-wechat",
          link: "https://www.wechat.com/watch?v=q8Wmz1tyPaY",
        },
      ],

      referralLink: null,
      token: null,
    };
  },
  methods: {
    copyLink(link) {
      if (link != null) {
        navigator.clipboard.writeText(link);
      }
    },
    getLocation() {
      return location.origin + "/sign-up?code=" + this.profile.myReferral;
    },
    async shareLink() {
      if (this.token !== null) {
        const shareData = {
          title: "Share link",
          text: "Check this out!",
          url: this.referralLink,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },
    navigateToInvitationPage() {
      if (this.token !== null) {
        this.$router.push({ path: "/invitation" });
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },
  },
  created() {
    this.token = localStorage.getItem("client_token");
    setTimeout(() => {
      this.referralLink = location.origin + "/sign-up?code=" + this.profile.myReferral;
    }, 2000);
  },
};
</script>

<template>
  <VCard class="my-5" min-height="180" variant="outlined" rounded="xl">
    <div class="text-center">
      <br />
      <h3 class="font-weight-regular">
        Share Links Via
        <template v-for="row in links" v-bind:key="row.icon">
          <v-btn
            @click="navigateToInvitationPage"
            :icon="row.icon"
            size="small"
            variant="text"
            class="mx-1"
          >
            <VIcon size="30" color="primary">{{ row.icon }}</VIcon>
          </v-btn>
          <!--
          <v-btn
            @click="copyLink(row.link)"
            :icon="row.icon"
            size="small"
            variant="text"
            class="mx-1"
          >
            <VIcon size="30" color="primary">{{ row.icon }}</VIcon>
          </v-btn>
          -->
        </template>
      </h3>
    </div>

    <br />

    <VCard elevation="0" class="mx-5 pa-1 d-flex justify-lg-space-between" rounded="xl">
      <VBtn variant="text" class="text-none" rounded="xl" elevation="0">
        <VIcon color="grey" class="mx-2">mdi-link-variant</VIcon>
      </VBtn>
      <span class="mt-1" style="text-overflow: ellipsis">
        <router-link to="/" class="text-decoration-none text-grey">
          {{ getLocation() }}
        </router-link>
      </span>
      <VBtn
        @click="copyLink(getLocation())"
        class="text-none"
        rounded="xl"
        width="120"
        color="primary"
      >
        Copy
      </VBtn>
    </VCard>
  </VCard>
</template>

<style scoped></style>
