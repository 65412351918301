<template>
  <v-card flat>
    <template v-slot:text>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-select
            v-if="(isStaffLoginAllCountry === 1) | (isStaffLoginAdmin === 1)"
            v-model="searchCountryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            label="Country"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            single-line
            clearable
            @update:modelValue="filterItems"
          >
          </v-select>
          <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
          <v-select
            v-else
            v-model="searchCountryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            label="Country"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            single-line
            disabled
            @update:modelValue="filterItems"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            v-model="search"
            label="Search exchange deposit..."
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            single-line
            @keypress.enter="filterItems"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="exchangeDeposites"
      :items-per-page="perPage"
      :server-items-length="totalCount"
      :loading="loading"
      :sort-by="[{ key: 'createdAt', order: 'desc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center mb-4">
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="perPage"
                    :items="[10, 25, 50, 100, 1000, 10000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="perPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                    <v-row>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-select
                          v-if="(isAllCountry === 1) | (isAdmin === 1)"
                          v-model="countryId"
                          :items="countries"
                          :item-title="'name'"
                          :item-value="'id'"
                          label="Country"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-flag-outline"
                          required
                          persistent-placeholder
                          placeholder="Choose country..."
                          return-object
                          @update:modelValue="selectedChangeItems"
                          :disabled="disabled == 0"
                        >
                        </v-select>
                        <!--NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
                        <v-select
                          v-else
                          v-model="countryId"
                          :items="countries"
                          :item-title="'name'"
                          :item-value="'id'"
                          :rules="[() => !!countryId || 'This field is required']"
                          label="Country"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-flag-outline"
                          required
                          persistent-placeholder
                          placeholder="Choose country..."
                          return-object
                          @update:modelValue="selectedChangeItems"
                          disabled
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-text-field
                          v-if="(isAllCountry === 1) | (isAdmin === 1)"
                          v-show="textFieldNameCurrency"
                          v-model.trim="currency"
                          label="Name Currency"
                          placeholder="Name Currency"
                          variant="outlined"
                          prepend-inner-icon="mdi-currency-usd"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                        <v-text-field
                          v-else
                          v-show="textFieldNameCurrency"
                          v-model.trim="currency"
                          label="Name Currency"
                          placeholder="Name Currency"
                          variant="outlined"
                          prepend-inner-icon="mdi-currency-usd"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                        <v-select
                          v-show="comboBankName"
                          v-model="bankNameCryptoCurrency"
                          :items="bankNameCollection"
                          :item-title="'bankName'"
                          :item-value="'id'"
                          label="Bank Name"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-bank-circle-outline"
                          required
                          autocomplete="off"
                          persistent-placeholder
                          placeholder="Choose bank name..."
                          return-object
                          @update:modelValue="
                            showHideMessageErrorWhenBankNameSelectOrNotSelected
                          "
                          :disabled="disabled == 0"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <input type="hidden" v-model="hiddenId" />
                        <input type="hidden" v-model="hdCurrencyImage" />
                        <v-text-field
                          v-model.number.trim="rate"
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                          label="Rank"
                          placeholder="Rank"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.number.trim="startRank"
                          label="Start rank"
                          placeholder="Start"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.number.trim="toRank"
                          label="To rank"
                          placeholder="To"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <QuillEditor
                          toolbar="essential"
                          contentType="html"
                          style="height: 150px"
                          v-model:content="desc"
                          theme="snow"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <v-card
                      variant="variant"
                      class="mx-auto"
                      elevation="16"
                      v-if="isCryptoCurrency"
                    >
                      <v-img
                        :src="
                          previewImage === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : previewImage
                        "
                        aspect-ratio="1"
                        color="surface-variant"
                        height="450"
                        contain
                        @click="triggerFileInput"
                      >
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-file-input
                        v-model.trim="image"
                        accept="image/png, image/jpeg, image/bmp"
                        label="Avatar"
                        placeholder="Pick an avatar"
                        prepend-icon="mdi-camera"
                        @change="uploadImage"
                        hide-input
                        class="mt-2"
                        ref="fileInput"
                        style="display: none"
                      ></v-file-input>
                    </v-card>
                    <v-card variant="variant" class="mx-auto" elevation="5" v-else>
                      <v-img
                        :src="
                          previewImage === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : previewImage
                        "
                        height="360"
                        max-width="auto"
                        cover
                      >
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.country.logoCurrency`]="{ item }">
        <div v-if="item.country.name" class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img
              alt="Avatar"
              :src="
                item.country.name === 'Cryptocurrency'
                  ? item.logo !== null
                    ? pathImageCrypto + item.logo
                    : 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                  : item.country.image
              "
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.rate`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.rate) }}
      </template>
      <template v-slot:[`item.startRank`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.startRank) }}
      </template>
      <template v-slot:[`item.toRank`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.toRank) }}
      </template>
      <template v-slot:[`item.country.name`]="{ item }">
        {{ item.country.name }}
      </template>
      <template v-slot:[`item.desc`]="{ item }">
        <div
          v-html="
            item.desc !== null
              ? item.desc.length > 50
                ? item.desc.substring(0, 50) + '...'
                : item.desc
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-2" size="x-small" color="red-lighten-2" @click="editItem(item)">
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
        <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
            @update:model-value="PopulateExchangeDepositsByCountry"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_Exchange_Deposit_Logo_Currency,
  URL_BANK_NAME_BY_TYPE_METHOD_PAYMENT,
  URL_COUNTRY,
  URL_Exchange_Deposit,
  URL_FETCH_EXCHANGE_DEPOSIT_WITH_PAGINATION,
  URL_STAFF_COUNTRY_PERMISSION,
  URL_UPLOAD_LOGO_CURRENCY,
} from "@/utils/apiUrl";
import { getTokenHeaders, getImageTokenHeaders } from "@/utils/headerToken";
import { formatNumber, originalCode } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";
import { getFileExtension, getFileSizeInKB } from "@/utils/reusableFunctions";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    pathImageCrypto: Path_Exchange_Deposit_Logo_Currency,
    loading: false, // Loading state
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: true,
    isSubmitting: false,
    comboBankName: false,
    textFieldNameCurrency: true,
    isCryptoCurrency: false,

    isAdmin: 0,
    isAllCountry: 0,
    countryIdFromDb: 0,

    staffLoginCountryId: originalCode(parseInt(localStorage.getItem("countryId"))),
    isStaffLoginAdmin: originalCode(parseInt(localStorage.getItem("admin"))),
    isStaffLoginAllCountry: originalCode(parseInt(localStorage.getItem("allCountry"))),

    items: [],
    exchangeDeposites: [],
    countries: [{ id: 0, name: "-- All --" }],
    bankNameCollection: [],

    validation: {
      min: 0,
      max: 10,
      decimal: 10,
    },

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    //nameCurrency: null,
    previewImage: null,
    image: null,
    currency: null,
    bankNameCryptoCurrency: null,
    disabled: null,
    searchCountryId: null,

    rate: 0,
    startRank: 0,
    toRank: 0,
    desc: null,
    countryId: 0,

    editedIndex: -1,
    hiddenId: 0,
    hdCurrencyImage: null,

    //pagination
    page: 1,
    perPage: 10,
    pageCount: 0,
    totalCount: 0, // Total records count

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Logo", key: "country.logoCurrency", align: "center" },
      { title: "Country", key: "country.name" },
      { title: "Name Currency", key: "nameCurrency", align: "center" },
      { title: "Rate", key: "rate", align: "center" },
      { title: "Start Rank", key: "startRank", align: "center" },
      { title: "To Rank", key: "toRank", align: "center" },
      { title: "Description", key: "desc", align: "center" },
      { title: "Created at", key: "createdAt", align: "center" },
      { title: "created by", key: "staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Exchange Deposit"
        : "Update Exchange Deposit";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    filterHeaders() {
      return this.headers.filter((header) => header.key !== "Logo Currency");
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page() {
      this.PopulateExchangeDepositsByCountry();
    },
    perPage() {
      this.PopulateExchangeDepositsByCountry();
    },
    search() {
      this.PopulateExchangeDepositsByCountry();
    },
  },

  created() {
    //this.searchCountryId = this.isAdminOrAllCountry() ? 0 : this.staffLoginCountryId;
    this.PopulateCountries();
    this.PopulateExchangeDepositsByCountry();
    this.getStaffCountryAndPermission();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      //this.nameCurrency = null;
      //this.previewImage = "https://bpcdn.co/images/2017/03/09071328/global-currency-symbols.jpeg";
      this.rate = 0;
      this.startRank = 0;
      this.toRank = 0;
      this.desc = null;
      this.visible = true;
      //this.countryId = null;
      this.disabled = 1;
    },

    async PopulateExchangeDepositsByCountry() {
      const params = {
        page: this.page,
        perPage: this.perPage,
        countryId: this.searchCountryId ? this.searchCountryId : 0,
        textSearch: this.search ? this.search : null,
      };

      await axios
        .get(URL_FETCH_EXCHANGE_DEPOSIT_WITH_PAGINATION, {
          params,
          headers: getTokenHeaders(),
        })
        .then((response) => {
          //const searchCountryId = this.searchCountryId.id;
          if (response.data && response.data.exchangeDepositCollection) {
            this.exchangeDeposites = response.data.exchangeDepositCollection;
            this.totalCount = response.data.totalCount;
            this.pageCount = response.data.pageCount;
            this.items.push(this.exchangeDeposites);
          } else {
            console.warn("Empty API Response");
            this.exchangeDeposites = [];
          }
        });
    },

    async filterItems(component) {
      this.searchCountryId = component.id;
      this.PopulateExchangeDepositsByCountry();
    },

    editItem(item) {
      this.editedIndex = this.exchangeDeposites.indexOf(item);
      this.hiddenId = item.id;
      this.disabled = 0;
      //this.editedItem = Object.assign({}, item);
      this.currency = item.nameCurrency;
      this.rate = item.rate;
      this.countryId = item.countryId;
      this.startRank = item.startRank;
      this.toRank = item.toRank;
      this.desc = item.desc;
      this.isCryptoCurrency = item.country.name === "Cryptocurrency" ? true : false;
      this.previewImage =
        item.country.name === "Cryptocurrency"
          ? item.logo !== null
            ? `${this.pathImageCrypto}${item.logo}`
            : "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : item.country.image !== null
          ? item.country.image
          : "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain";
      /*
      this.previewImage =
        item.country.name === "Cryptocurrency"
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : item.country.image; */
      this.hdCurrencyImage =
        item.country.name === "Cryptocurrency" ? item.logo : item.country.logoCurrency;

      const countryCollection = this.filterCountryById(item.countryId);
      if (countryCollection[0].name === "Cryptocurrency") {
        this.textFieldNameCurrency = false;
        this.comboBankName = true;
        this.PopulateBankNameByMethodPayment(countryCollection[0].name);
        this.bankNameCryptoCurrency = item.nameCurrency;
      } else {
        this.textFieldNameCurrency = true;
        this.comboBankName = false;
        this.bankNameCollection = [];
        this.bankNameCryptoCurrency = null;
      }

      this.dialog = true;
    },

    filterCountryById(id) {
      return this.countries.filter((country) => country.id.toString().includes(id));
    },

    deleteItem(item) {
      this.editedIndex = this.exchangeDeposites.indexOf(item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.exchangeDeposites.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_Exchange_Deposit + id;
      axios
        .delete(url, { headers: getTokenHeaders() })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    isAdminOrAllCountry() {
      return (this.isStaffLoginAdmin || this.isStaffLoginAdmin) === 1 ? true : false;
    },

    async submitData() {
      if (this.comboBankName == true) {
        if (this.bankNameCryptoCurrency === null) {
          this.visible = false;
          this.msgError = "Please choose bank name";
          return;
        }
      }

      const staffLoginId = originalCode(parseInt(localStorage.getItem("id")));
      const data = {
        countryId: parseInt(this.countryId),
        nameCurrency:
          this.currency !== null ? this.currency : this.bankNameCryptoCurrency.bankName,
        logo: this.hdCurrencyImage,
        rate: parseFloat(this.rate),
        startRank: parseFloat(this.startRank),
        toRank: parseFloat(this.toRank),
        createBy: staffLoginId,
        desc: this.desc,
      };

      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_Exchange_Deposit + id;

        await axios
          .patch(url, data, { headers: getTokenHeaders() })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );

        this.close();
      } // create new
      else {
        await axios
          .post(URL_Exchange_Deposit, data, { headers: getTokenHeaders() })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      }

      //this.clearContent();
      //this.PopulateExchangeDeposits();
      var countryId = this.isAdminOrAllCountry() ? null : this.staffLoginCountryId;
      this.PopulateExchangeDepositsByCountry(countryId);
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }

        //this.dialog = false;
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    async PopulateCountries() {
      await axios.get(URL_COUNTRY, { headers: getTokenHeaders() }).then((response) => {
        response.data.forEach((country) => {
          this.countries.push({
            id: country.id,
            name: country.name,
            currency: country.currency,
            logoCurrency: country.logoCurrency,
            image: country.image,
          });
        });
      });

      this.searchCountryId = this.isAdminOrAllCountry() ? 0 : this.staffLoginCountryId;
      this.currency = this.countries.filter(
        (country) => country.id === this.staffLoginCountryId
      )[0].currency;
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },

    async getStaffCountryAndPermission() {
      const staffId = originalCode(parseInt(localStorage.getItem("id")));
      axios
        .get(URL_STAFF_COUNTRY_PERMISSION + staffId, {
          headers: getTokenHeaders(),
        })
        .then((response) => {
          this.isAdmin = response.data.permission.admin;
          this.isAllCountry = response.data.permission.allCountry;
          this.countryIdFromDb = response.data.countryId;
          this.countryId = this.countryIdFromDb;
          if (response.data.country !== null) {
            if (response.data.country === "Cryptocurrency") {
              if (response.data.logo !== null) {
                this.previewImage = this.path + response.data.logo;
              } else {
                this.previewImage =
                  "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain";
              }
            } else {
              if (response.data.country.image !== null) {
                this.previewImage = response.data.country.image;
              } else {
                this.previewImage =
                  "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain";
              }
            }
          } else {
            this.previewImage =
              "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain";
          }
        });
    },

    selectedChangeItems(component) {
      // it get the data as json
      this.countryId = this.countryId.id;

      if (component.name === "Cryptocurrency") {
        this.isCryptoCurrency = true;
        this.previewImage =
          "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain";
        this.comboBankName = true;
        this.textFieldNameCurrency = false;
        this.currency = null;
        this.PopulateBankNameByMethodPayment(component.name);
      } else {
        this.isCryptoCurrency = false;
        this.previewImage =
          component.image === null
            ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
            : component.image;
        this.comboBankName = false;
        this.textFieldNameCurrency = true;
        this.bankNameCryptoCurrency = null;
        this.msgError = null;
        this.visible = true;
        this.currency = component.currency;
      }
    },

    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },

    PopulateBankNameByMethodPayment(methodPayment) {
      const countryId = this.isAdminOrAllCountry() ? 0 : this.staffLoginCountryId;
      axios
        .get(URL_BANK_NAME_BY_TYPE_METHOD_PAYMENT + `${countryId}/${methodPayment}`, {
          headers: getTokenHeaders(),
        })
        .then((response) => {
          this.bankNameCollection = [];
          response.data.forEach((item) => {
            this.bankNameCollection.push({
              id: item.id,
              bankName: item.bankName,
            });
          });
        });
    },

    showHideMessageErrorWhenBankNameSelectOrNotSelected(component) {
      if (component.bankName !== null) {
        this.visible = true;
        this.msgError = null;
      }
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    uploadImage(e) {
      const file = e.target.files[0];
      if (file) {
        var obj = this.invalidFileSizeOrFileExtension(file);
        if (obj.isTrue) {
          this.visibleMsg = false;
          this.msgError = obj.msg;
          return;
        }

        this.visibleMsg = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          console.log(this.previewImage);
        };

        let data = new FormData();
        data.append("file", e.target.files[0]);

        axios
          .post(URL_UPLOAD_LOGO_CURRENCY, data, {
            headers: getImageTokenHeaders(),
          })
          .then((response) => {
            this.hdCurrencyImage = response.data.filename;
            console.log("Image has been uploaded.");
          });
      }
    },

    invalidFileSizeOrFileExtension(file) {
      let isTrue = false;
      let msg = null;
      const fileSize = getFileSizeInKB(file);
      const fileExtension = getFileExtension(file);

      if (fileSize >= 500) {
        // 500 KB
        isTrue = true;
        msg = "File size is biggest 500KB.";
      }

      if (fileExtension === null) {
        isTrue = true;
        msg = "File extension must be jpg, jpeg, png, bmp";
      }

      return { isTrue, msg };
    },
  },
};
</script>
