<script>
import { URL_TRANSACTION_DEPOSIT_OR_WITHDRAW } from "@/utils/apiUrl";
import { CUSTOMER_INVEST_TRANSACTION } from "@/utils/customerApiUrl";
import { formatDate, formatPrice, getParams } from "@/utils/function";
import { getTokenHeaders } from "@/utils/headerToken";
import axios from "axios";
import moment from "moment/moment";
import { mapState } from "vuex";

export default {
  name: "FundDetailsTab",
  computed: {
    ...mapState(["wallet"]),
  },
  data() {
    return {
      transactionInfo: [],
      formatPrice: formatPrice,
      formatDate: formatDate,
      dateRange: null,
      params: {
        take: 10,
        skip: 1,
        projectId: "",
        startDate: "",
        endDate: "",
        status: "",
        type: "",
        search: "",
        customerId: null,
      },
      loading: false,
      totalPages: 0,
      total: 0,
      data: [],
      headers: [
        {
          align: "start",
          key: "trandferNo",
          sortable: false,
          title: "Transaction​ Date",
        },
        { key: "", title: "Deposit Amount" },
        { key: "", title: "Funding Account" },
        { key: "", title: "Bank Name" },
        { key: "", title: "Status" },
      ],

      typeTransaction: "Deposit",
      //pagination
      page: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0, // Total records count
    };
  },
  methods: {
    getDateWithdrawOrDeposit(component) {
      const dateWithdrawOrDeposit =
        this.typeTransaction == "Deposit"
          ? component.deposit[0]?.created_at
          : component.withdraw[0]?.createdAt;

      return dateWithdrawOrDeposit;
    },
    getStatusWithdrawOrDeposit(component) {
      const status =
        this.typeTransaction == "Deposit"
          ? component.deposit[0]?.status
          : component.withdraw[0]?.status;

      return status;
    },
    getAmountWithdrawOrDeposit(component) {
      const amount =
        this.typeTransaction === "Deposit"
          ? component.deposit[0]?.deposit_amount
          : component.withdraw[0]?.withdrawAmount;

      return amount;
    },
    getFundingAccount(component) {
      const fundingAccount =
        this.typeTransaction == "Deposit"
          ? component.deposit[0]?.BankAccountDeposit?.card_number
          : component.withdraw[0]?.bankAccount?.cardNumber;

      return fundingAccount;
    },
    getBankName(component) {
      const bankName =
        this.typeTransaction == "Deposit"
          ? component.deposit[0]?.BankAccountDeposit?.bank_account
          : component.withdraw[0]?.bankAccount?.fullName;

      return bankName;
    },
    getAvatarColor() {
      return this.typeTransaction === "Deposit"
        ? " row-none-wrap text-green"
        : " row-none-wrap text-error";
    },
    getArrowIcon() {
      return this.typeTransaction == "Deposit"
        ? "mdi-arrow-bottom-left-thick"
        : "mdi-arrow-top-right-thick";
    },
    async fetchData() {
      this.loading = true;
      let params = getParams(this.params);
      await this.axios
        .get(CUSTOMER_INVEST_TRANSACTION + params)
        .then((response) => {
          let { data, total, totalPages } = response.data;
          ///console.log(response.data.data);
          this.data = data;
          this.total = total;
          this.totalPages = totalPages;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Failed to", error);
        });
    },
    navigateToTransferToAccountPage() {
      this.$router.push({ name: "TransferToAccountPage" });
    },
    async fetchDataCustomerTransfer() {
      const params = {
        cusId: this.params.customerId,
        page: this.page,
        perPage: this.perPage,
        typeTransaction: this.typeTransaction ?? "all",
        status: this.params.status ?? "all",
        startDate: this.params.startDate ?? null,
        endDate: this.params.endDate ?? null,
        textSearch: this.params.search ? this.params.search : null,
      };

      this.loading = true;
      try {
        await axios
          .get(URL_TRANSACTION_DEPOSIT_OR_WITHDRAW, {
            params,
            headers: getTokenHeaders(),
          })
          .then((response) => {
            if (response.data && response.data.transaction) {
              this.transactionInfo = response.data.transaction;
              this.totalCount = response.data.totalCount;
              this.pageCount = response.data.pageCount;
              this.transactionInfo.length > 0
                ? this.items.push(this.transactionInfo)
                : null;
            } else {
              this.loading = false;
              console.warn("Empty API Response");
              this.transactionInfo = [];
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    goToPage(newPage) {
      if (newPage >= 1 && newPage <= this.pageCount) {
        this.page = newPage;
        this.fetchDataCustomerTransfer();
      }
    },
  },
  created() {
    //this.fetchData();
    setTimeout(() => {
      this.params.customerId = this.wallet[0].customerId;
      this.fetchDataCustomerTransfer();
    }, 1000);
  },
  watch: {
    // filter page
    "params.skip": function () {
      this.fetchData();
    },
    "params.take": function () {
      this.fetchData();
    },
    "params.status": function (status) {
      if (!status) return; // Prevents error if status is null or undefined

      if (Array.isArray(status) && status.includes("All")) {
        this.params.status = null;
      } else if (status === "All") {
        this.params.status = null;
      }
      // this.fetchData();
      this.fetchDataCustomerTransfer();
    },
    "params.search": function (search) {
      this.params.search = search;
      //this.fetchData();
      this.fetchDataCustomerTransfer();
    },
    dateRange: function (dateRange) {
      if (dateRange && dateRange.length > 0) {
        let startDate = dateRange[0];
        let endDate = dateRange[dateRange.length - 1];
        this.params.startDate = moment(startDate).format("YYYY-MM-DD");
        this.params.endDate = moment(endDate).format("YYYY-MM-DD");
        this.fetchDataCustomerTransfer();
        //this.fetchData();
      }
    },
    page() {
      this.fetchDataCustomerTransfer();
    },
    perPage() {
      this.fetchDataCustomerTransfer();
    },
    typeTransaction: function (typeTransaction) {
      if (!typeTransaction) return; // Prevents error if status is null or undefined

      if (Array.isArray(typeTransaction) && typeTransaction.includes("All")) {
        this.typeTransaction = null;
      } else if (typeTransaction === "All") {
        this.typeTransaction = null;
      }
      this.fetchDataCustomerTransfer();
    },
  },
};
</script>

<template>
  <!--    <pre>-->
  <!--      {{data[0]}}-->
  <!--    </pre>-->
  <v-card class="pa-5" rounded="0" min-height="140" elevation="0" border>
    <v-row>
      <v-col cols="12" md="3">
        <p class="ma-2">Sort transaction by</p>

        <div>
          <v-autocomplete
            menu-icon="mdi-chevron-right"
            v-model="typeTransaction"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['Deposit', 'Withdraw']"
            class="elevation-0"
          ></v-autocomplete>
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <p class="ma-2">Filter by Date</p>

        <div>
          <v-date-input
            prepend-icon=""
            v-model="dateRange"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            class="elevation-0"
            append-inner-icon="mdi-calendar-range"
            placeholder="YYYY/MM/DD - YYYY/MM/DD"
            color="blue"
            multiple="range"
          ></v-date-input>
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <p class="ma-2">Status</p>

        <div>
          <v-autocomplete
            menu-icon="mdi-chevron-right"
            v-model="params.status"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['All', 'Failed', 'Successfull', 'Pending']"
            class="elevation-0"
            color="blue"
          ></v-autocomplete>
        </div>
      </v-col>
    </v-row>
  </v-card>

  <br />
  <v-row>
    <v-col cols="12" md="2">
      <div class="d-flex justify-start">
        <span class="ma-2">Show</span>

        <div>
          <v-select
            menu-icon=""
            v-model="perPage"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['10', '25', '50', '100', '500', '1000']"
            class="elevation-0"
            color="blue"
            @update:model-value="perPage = parseInt($event, 10)"
          ></v-select>
        </div>

        <span class="ma-2">Entries</span>
      </div>
    </v-col>
    <v-col cols="12" md="10">
      <div class="d-flex justify-end">
        <span class="ma-2">Search</span>

        <div style="width: 30%">
          <v-text-field
            placeholder="search ..."
            append-inner-icon="mdi-magnify"
            width="100%"
            menu-icon=""
            v-model="params.search"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            class="elevation-0"
            color="blue"
          ></v-text-field>
        </div>
      </div>
    </v-col>
  </v-row>

  <v-card rounded="0">
    <v-data-table
      density="comfortable"
      hide-default-footer
      :loading="loading"
      :items="transactionInfo"
      :search="params.search"
      :items-per-page="perPage"
      :server-items-length="totalCount"
    >
      <!-- No records available message -->
      <template v-slot:empty>
        <v-card elevation="0" rounded="0" class="pa-5"> No record available.</v-card>
      </template>

      <!-- Table Headers -->

      <thead>
        <tr class="bg-primary">
          <th v-for="header in headers" :key="header.key" class="row-none-wrap">
            {{
              header.title === "Deposit Amount"
                ? typeTransaction === "Deposit"
                  ? "Deposit Amount"
                  : "Withdraw Amount"
                : header.title
            }}
          </th>
        </tr>
      </thead>

      <!-- Table Body -->
      <tbody>
        <tr v-for="row in transactionInfo" :key="row.id" :class="getAvatarColor()">
          <td>
            <div class="d-flex pa-4">
              <div class="px-4">
                <h3>{{ row.trandferNo }}</h3>
                <span>
                  {{ formatDate(getDateWithdrawOrDeposit(row), "YYYY-MM-DD HH:mm A") }}
                </span>
              </div>

              <v-avatar size="30" :class="getAvatarColor()">
                <v-icon>{{ getArrowIcon() }}</v-icon>
              </v-avatar>
            </div>
          </td>
          <td>
            <h3>{{ formatPrice(getAmountWithdrawOrDeposit(row)) }} USD</h3>
          </td>
          <td>{{ getFundingAccount(row) }}</td>
          <td>{{ getBankName(row) }}</td>
          <td>
            <h3>
              {{ getStatusWithdrawOrDeposit(row) }}
            </h3>
          </td>
        </tr>
      </tbody>
    </v-data-table>
  </v-card>

  <div class="app-paginate text-center mt-5">
    <v-btn
      variant="outlined"
      color="primary"
      rounded="xl"
      :disabled="page === 1"
      @click="goToPage(1)"
      class="no-uppercase"
    >
      First
    </v-btn>

    <v-btn
      variant="outlined"
      rounded="xl"
      color="primary"
      border
      :disabled="page === 1"
      @click="goToPage(page - 1)"
      class="no-uppercase"
    >
      Previous
    </v-btn>

    <v-btn
      variant="outlined"
      rounded="xl"
      color="primary"
      :disabled="page === pageCount"
      @click="goToPage(page + 1)"
      class="no-uppercase"
    >
      Next
    </v-btn>

    <v-btn
      variant="outlined"
      rounded="xl"
      color="primary"
      border
      :disabled="page === pageCount"
      @click="goToPage(pageCount)"
      class="no-uppercase"
    >
      Last
    </v-btn>
  </div>
</template>

<style scoped>
.share-option {
  background-color: #4caf50;
  color: white;
}

button {
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.no-uppercase {
  text-transform: none !important;
}
</style>
