import moment from "moment";

export const formatNumber = (value) => {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(1) + "B";
  }
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  }
  if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  }
  return value;
};

const PREFIX_CODE = 619248;
const SUFFIX_CODE = 485619;

export const routedAliasCode = () => {
  return PREFIX_CODE + SUFFIX_CODE;
};

export const encrypt = (text, key) => {
  let result = "";
  for (let i = 0; i < text.length; i++) {
    result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }
  return result;
};

export const decrypt = (text, key) => {
  return encrypt(text, key); // XOR encryption is symmetric
};

export const originalCode = (value) => {
  return value - routedAliasCode();
};

export const dateFormatted = (value) => {
  return moment(value).format("MMMM DD, YYYY");
};

export const formatDateTime = (value) => { // Feb 05, 2025, 11:13 AM
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return new Date(value).toLocaleString("en-US", options);
};
