<template>
  <VCard
    min-height="600"
    width="100%"
    style="background: linear-gradient(0deg, #1867c0 50%, white 50%)"
    class="d-flex justify-center"
  >
    <VCard rounded="xl" style="margin: 60px 0" min-height="300" color="grey-lighten-5">

      <br />
      <VRow class="ma-2">
        <VCol cols="12" md="7">
          <h3 class="text-center">Value of Contracts by Procurement Category</h3>
          <br />

          <div style="height: 350px;" v-if="contracts.data.count > 0">
            <Doughnut :data="contracts.data" :options="options" />
          </div>

        </VCol>

        <VCol cols="12" md="5">
          <VCard color="white" class="pa-5" elevation="0" rounded="xl">
            <h2>Contracts</h2>
            <p>
              Investment Project Financing Portfolio
              <VIcon>mdi-help-circle-outline</VIcon>
            </p>
            <br />
            <p class="text-end">
              Number of Contracts
            </p>
            <div class="text-end">
              <v-chip label color="grey">
                <h3 class="text-blue"> {{contracts.numberOfContract}}</h3>
              </v-chip>
            </div>
            <br />
            <p class="text-end">
              Value of Contracts
            </p>
            <div class="text-end">
              <v-chip label color="grey">
                <h3 class="text-blue"> $ {{contracts.valueOfContract}}</h3>
              </v-chip>
            </div>
          </VCard>

          <br/>

          <v-row class="">
            <v-col cols="6">
              <template v-for="(row, index) in contracts.sectors" v-bind:key="index">
                <div class="d-flex align-content-start">
                  <v-avatar color="white" size="18" class="mt-1">
                    <v-avatar :color="colorSector[index]" size="14">
                    </v-avatar>
                  </v-avatar>
                  <h3 class="mx-2 text-grey-darken-1">{{row}}</h3>
                </div>
              </template>
            </v-col>

            <v-col cols="6">
              <template v-for="(row, index) in contracts.sectors2" v-bind:key="index">
                <div class="d-flex align-content-start">
                  <v-avatar color="white" size="18" class="mt-1">
                    <v-avatar :color="colorSector[index]" size="14">
                    </v-avatar>
                  </v-avatar>
                  <h3 class="mx-2 text-grey-darken-1">{{row}}</h3>
                </div>
              </template>
            </v-col>
          </v-row>

        </VCol>
      </VRow>
    </VCard>

  </VCard>
</template>

<script>
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'vue-chartjs';

const colors = [
  'indigo-lighten-5',
  'blue-lighten-4',
  'blue',
  'indigo',
  'green',
  'yellow',
  'red',
  'orange',
  'brown',
  'pink',
  'indigo-lighten-5',
  'blue-lighten-4',
  'blue',
  'indigo',
  'green',
  'yellow',
  'red',
  'orange',
  'brown',
  'pink',
];

let colorsCode = [
  '#3F51B5', '#2196F3', '#BBDEFB', '#E8EAF6', '#4CAF50', '#FFEB3B','#FF5722', '#795548', '#039BE5', '#039BE5',
  '#3F51B5', '#2196F3', '#BBDEFB', '#E8EAF6', '#4CAF50', '#FFEB3B','#FF5722', '#795548', '#039BE5', '#039BE5'
];

ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  name: 'ValueOfContacts',
  props: ['contracts'],
  components:{
    Doughnut
  },
  data() {
    return {
      sectors:colors,
      data: null,
      options: null,
      colorSector: colors
    };
  },
  created() {
    this.data = {
      datasets: [
        {
          backgroundColor: colorsCode,
          data: this.contracts.data,
        }
      ],
    };
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
    };
  }
};
</script>

<style scoped>

</style>