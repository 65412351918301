<script>
import { Path_Our_Process_Image, URL_OUR_PROCESS } from "@/utils/apiUrl";
import FrontendLayout from "@/views/client/components/FrontendLayout.vue";
import axios from "axios";

export default {
  name: "OurImpactPage",
  components: { FrontendLayout },
  data() {
    return {
      data: {},
      path: Path_Our_Process_Image,
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_OUR_PROCESS)
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <FrontendLayout>
    <div style="height: 700px">
      <v-img
        style="height: 700px; width: 100%"
        cover
        :src="
          data.image !== null
            ? path + data.image
            : 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif'
        "
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular
              color="grey-lighten-5"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </template>
        <!--
        <h3 class="text-uppercase text-center text-white py-5">
          {{ data.title }}
        </h3> -->
      </v-img>
    </div>

    <div class="bg-grey-lighten-4">
      <h3 class="text-uppercase text-center text-blue-darken-3 pa-5 font-format">
        {{ data.title }}
      </h3>

      <div class="margin-auto" style="line-height: normal">
        <h3 class="text-uppercase text-blue-darken-3 font-format">
          <!-- {{ data.title }} -->
        </h3>

        <p class="text-content text-justify font-format" v-html="data.desc"></p>
        <br /><br /><br />
      </div>
    </div>
  </FrontendLayout>
</template>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
}
</style>

<!--
<template>
  <FrontendLayout>
    <div style="height: 700px; background-color: gainsboro">
      <v-img
        style="height: 700px; width: 100%; background-color: gainsboro"
        cover
        :src="ourProcess.image"
        lazy-src="https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif"
      >
        <h2 class="text-uppercase text-center text-white" v-html="ourProcess.title"></h2>
      </v-img>
    </div>

    <div class="bg-grey-lighten-4">
      <h3 class="text-uppercase text-center text-blue-darken-3 pa-5">
        {{ data.title }}
      </h3>

      <div class="margin-auto py-10">
        <p class="text-justify font-format" v-html="ourProcess.desc"></p>
      </div>
    </div>
  </FrontendLayout>
</template>

<script>
// import {  URL_OUR_PROCESS } from "@/utils/apiUrl";
import FrontendLayout from "../components/FrontendLayout";
import { mapState } from "vuex";
export default {
  name: "OutProcessPage",
  components: { FrontendLayout },
  computed: {
    ...mapState(["ourProcess"]),
  },
};
</script>

<style scoped>
.font-format {
  font-size: 16px;
  line-height: 25px;
}
</style>
-->
